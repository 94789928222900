import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChevronArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="m4.768 3.17-.106.107.106.106L9.78 8.39l.044.044H9.94v.247H9.823l-.044.044-5.01 5.008-.107.106.106.107.39.388.105.106.106-.106 5.668-5.67.106-.106-.106-.107-5.668-5.67-.106-.106-.106.106z"
    />
  </svg>
);
export default SvgChevronArrow;
