import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import GlobalStyle from './styles/global';
import reportWebVitals from './reportWebVitals';
import './styles/font.css';
import { AuthProvider } from './context/AuthContext';
import SignalRProvider from './config/signalR/provider';
import store from './redux/store';
import ScrollLockProvider from './context/ScrollLockContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <SignalRProvider>
            <ScrollLockProvider>
              <GlobalStyle />
              <App />
            </ScrollLockProvider>
          </SignalRProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
