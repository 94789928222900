import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  & > div {
    box-sizing: border-box;
    padding: 19px 16px;
    height: 57px;
    border-bottom: 1px solid ${theme.color.secondary.grey[4]};
  }
`;
