import styled from 'styled-components';
import theme from '../../styles/theme';

export const StyledTabs = styled.div<{ gliderWidth?: number; gliderPosition?: number; isPending?: boolean }>`
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);

  .Tabs__Tab {
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #262626;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px 24px;
    cursor: pointer;
    border: none;
  }

  .Tabs__Tab:hover,
  .Tabs__Tab--active {
    color: ${theme.color.primary.interaction};
  }

  .glider {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-bottom: 2px solid ${theme.color.primary.interaction};
    box-sizing: border-box;
    border-radius: 99px;
  }

  .pre_glider {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    top: 100%;
    left: 0;
    right: 0;
    border-bottom: 2px solid ${theme.color.secondary.grey[5]};
    border-radius: 99px;
  }
`;
