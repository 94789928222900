const paginate = <T>(arr: T[], limit: number) => {
  const itemsPerPage = limit;
  const numberOfPages = Math.ceil(arr.length / itemsPerPage);

  return Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    return arr.slice(start, start + itemsPerPage);
  });
};

export default paginate;
