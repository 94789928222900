import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }

  .Align_Container {
    gap: 24px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
`;
