import { SignalRHubInfo, SignalRHubName } from './types';
import SignalRAuthService from '../../services/Auth/SignalRAuthService';
import { billActions } from '../../redux/reducers/billSlice';
import { invoiceActions } from '../../redux/reducers/invoiceSlice';

const hubs: Record<SignalRHubName, SignalRHubInfo> = {
  [SignalRHubName.BILL]: {
    authMethod: SignalRAuthService.signinBillHub,
    senders: [
      {
        name: 'RequestBills',
        method: 'send',
        data: JSON.stringify({
          Clients: [],
          StartDate: null,
          FinalDate: null,
        }),
      },
    ],
    listeners: [
      {
        name: 'ReceiveBill',
        action: billActions.receiveData,
      },
    ],
  },
  [SignalRHubName.INVOICE]: {
    authMethod: SignalRAuthService.signinInvoiceHub,
    senders: [
      {
        name: 'RequestInvoices',
        method: 'send',
        data: JSON.stringify({
          Clients: [],
          StartDate: null,
          FinalDate: null,
        }),
      },
    ],
    listeners: [
      {
        name: 'ReceiveInvoice',
        action: invoiceActions.receiveData,
      },
    ],
  },
};

export default hubs;
