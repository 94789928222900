import { createContext } from 'react';
import {
  BillAsideStage,
  BillMinimalData,
  BillOrder,
  BillOrderBy,
  BillSearchBy,
  BillStatus,
} from '../../../../../@types/bill';
import { Layout } from '../../../../../hooks/useLayout';
import { GetSelectionPropsFunc } from '../../../../../hooks/useDataSelection';

export interface BillContextProps {
  data: BillMinimalData[];
  dataToDownload: BillMinimalData[];
  getSelectionProps: GetSelectionPropsFunc<BillMinimalData>;
  closeSelectDate: VoidFunction;
  toggleLayout: VoidFunction;
  handleSelectAll: VoidFunction;
  clearSearch: VoidFunction;
  clearRangeSelect: VoidFunction;
  clearFilterByStatus: VoidFunction;
  openMultipleDownloadModal: VoidFunction;
  closeMultipleDownloadModal: VoidFunction;
  closeSearchModal: VoidFunction;
  openSearchAction: VoidFunction;
  requestSort: (key: BillOrderBy, direction?: 'asc' | 'desc') => void;
  actionsIsDisabled: boolean;
  selectDateIsOpen: boolean;
  range: DateRange | undefined;
  onChangeRange: (newRange: DateRange | undefined) => void;
  requestSearch: (newSearch: string, newSearchKeys: Array<BillSearchBy>) => void;
  search: string;
  loading: boolean;
  layout: Layout;
  showGrid: boolean;
  areElementsSelected: boolean;
  selectedData: Array<string>;
  order: BillOrder | null;
  handleOrder: (newOrder: BillOrder | null) => void;
  handleSearch: (newSearch: string) => void;
  searchKeys: Array<BillSearchBy>;
  handleSearchKeys: (newSearchKeys: Array<BillSearchBy>) => void;
  statusFilter: Array<BillStatus>;
  handleFilterByStatus: (newFilterStatus: Array<BillStatus>) => void;
  handlePrint: (id: BillMinimalData['id']) => void;
  signalRConnectionReachedMaxRetries: boolean;
  dataIsEmpty: boolean;
  filterIsEmpty: boolean;
  multipleDownloadModalIsOpen: boolean;
  searchModalIsOpen: boolean;
  asideActionsIsOpen: boolean;
  asideStage: BillAsideStage;
  setAsideStage: SetState<BillAsideStage>;
  openAsideActions: VoidFunction;
  closeAsideActions: VoidFunction;
  openSelectDateAction: VoidFunction;
}

const BillContext = createContext<BillContextProps | null>(null);

export default BillContext;
