import styled, { css } from 'styled-components';
import { slideToLeft, slideToRight } from '../../styles/keyframes';
import theme from '../../styles/theme';

interface AnimatedProps {
  isLeaving: boolean;
}

export const Container = styled.div<AnimatedProps>`
  display: flex;
  background: #fff;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  /* fall-back */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  overflow: auto;
  flex-direction: column;
  z-index: ${theme.defaults.asidePortalZIndex};
  transition: 850ms;
  width: 100%;

  animation: ${slideToRight} 0.35s forwards;

  ${({ isLeaving }) =>
    isLeaving &&
    css`
      animation: ${slideToLeft} 0.35s ease-in-out forwards;
    `};
`;
