import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import { WrapperButton } from '../../styles/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Panel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  max-width: 325px;
  height: 36px;
  background: #f6f6f6;
  border: 1px solid #000000;

  .icon {
    margin-bottom: 2px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    min-width: 255px;
    max-width: unset;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;

  button {
    max-width: 75px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    min-width: 255px;
    width: 100%;
    button {
      max-width: unset;
      width: 50%;
    }
  }
`;

export const ChangeViewButton = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  height: 36px;
  background: #ffffff;
  border: 1px solid ${theme.color.secondary.grey[5]};
  box-shadow: 0 1px 1px rgba(50, 43, 80, 0.08);

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: inherit;

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: ${theme.color.primary.interaction};
    `}
`;

export const CalendarHeaderOneView = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .month-year {
    display: flex;
    justify-self: center;
    margin: 0 auto;

    .month-name {
      text-transform: capitalize;
    }

    .year {
      color: ${theme.color.primary.interaction};
    }
  }
`;

export const CalendarHeaderDoubleView = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    width: 252px;
    justify-content: space-between;
    user-select: none;

    .month-year {
      display: flex;
      justify-self: center;
      margin: 0 auto;

      .month-name {
        text-transform: capitalize;
      }

      .year {
        color: ${theme.color.primary.interaction};
      }
    }
  }
`;

export const ButtonArrowWrapper = styled(WrapperButton)<{ disabled?: boolean }>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      ${theme.defaults.disabled}
      color: ${theme.color.secondary.grey[4]};
    `}
`;

export const YearHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
