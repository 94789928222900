import { createContext } from 'react';
import { PasswordFormStage } from '../../../../type';

export interface PasswordActionContextProps {
  setStage: SetState<PasswordFormStage>;
  stage: PasswordFormStage;
}

const PasswordActionContext = createContext<PasswordActionContextProps | null>(null);

export default PasswordActionContext;
