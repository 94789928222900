import React, { useContext } from 'react';
import SelectDateModal from './components/SelectDateModal';
import ToggleViewButton from '../../../../../../../components/ToggleViewButton';
import Button from '../../../../../../../components/Button';
import GridOrderButton from '../../../../../shared/components/OrderButton';
import Search from '../../../../../../../components/Search';
import FilterButton from './components/FilterButton';
import { Container } from './style';
import InvoiceContext, { InvoiceContextProps } from '../../context';
import { INVOICE_ORDER_OPTIONS } from '../../constants';
import { WrapperDiv } from '../../../../../../../styles/components';
import { SearchContainer } from '../../../../../shared/style';
import SearchInvoiceModal from './components/SearchInvoiceModal';
import ButtonDropdown from '../../../../../../../components/Floating/components/ButtonDropdown';
import ButtonStack from '../../../../../../../components/ButtonStack';
import { CalendarIcon, PageIcon } from '../../../../../../../icons';
import CustomText from '../../../../../../../components/CustomText';
import { BREAKPOINTS } from '../../../../../../../utils/constants/breakpoints';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';

const DesktopActions = () => {
  const {
    dataIsEmpty,
    toggleLayout,
    layout,
    openSelectDateAction,
    handleSelectAll,
    showGrid,
    areElementsSelected,
    selectedData,
    order,
    search,
    handleSearch,
    handleOrder,
    range,
    handleDownloadMultipleInvoices,
    actionsIsDisabled,
    loading,
    openSearchAction,
    signalRConnectionReachedMaxRetries,
  } = useContext(InvoiceContext) as InvoiceContextProps;

  const dateConfigIsActive = range && !loading;
  const showButtons = !dataIsEmpty;
  const { width } = useWindowDimensions();
  const showActionsInDropdown = width < BREAKPOINTS.fullHd && showGrid;

  return (
    <>
      <SearchInvoiceModal />
      <SelectDateModal />
      {showButtons && (
        <Container>
          <WrapperDiv gap={16}>
            <ToggleViewButton onClick={toggleLayout} layout={layout} />
            {showActionsInDropdown ? (
              <ButtonDropdown
                variant="tertiary"
                size="small"
                disabled={loading || signalRConnectionReachedMaxRetries}
                placement="bottom-start"
                floating={
                  <ButtonStack
                    stack={[
                      {
                        handler: openSelectDateAction,
                        render: (
                          <>
                            <CalendarIcon />
                            <CustomText>Configurar data</CustomText>
                          </>
                        ),
                      },
                      {
                        handler: openSearchAction,
                        render: (
                          <>
                            <PageIcon width={24} height={24} />
                            <CustomText>Buscar nova nota fiscal</CustomText>
                          </>
                        ),
                      },
                    ]}
                  />
                }
              >
                Ações
              </ButtonDropdown>
            ) : (
              <>
                <Button
                  variant={dateConfigIsActive ? 'secondary' : 'tertiary'}
                  size="small"
                  onClick={openSelectDateAction}
                  disabled={loading}
                >
                  {dateConfigIsActive ? 'Configurar data ativado' : 'Configurar data'}
                </Button>
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={openSearchAction}
                  disabled={loading || signalRConnectionReachedMaxRetries}
                >
                  Buscar nova nota fiscal
                </Button>
              </>
            )}
            {showGrid && (
              <Button variant="tertiary" size="small" onClick={handleSelectAll} disabled={actionsIsDisabled}>
                Selecionar todos
              </Button>
            )}
            <Button
              variant="tertiary"
              size="small"
              onClick={() => handleDownloadMultipleInvoices(selectedData)}
              disabled={!areElementsSelected || actionsIsDisabled}
            >
              Baixar selecionados
            </Button>
          </WrapperDiv>
          <SearchContainer>
            {showGrid && (
              <GridOrderButton
                order={order}
                handleOrder={handleOrder}
                options={INVOICE_ORDER_OPTIONS}
                disabled={actionsIsDisabled}
              />
            )}
            <Search
              value={search}
              onChange={(event) => handleSearch(event.target.value)}
              placeholder="Buscar"
              disabled={actionsIsDisabled}
            >
              <FilterButton />
            </Search>
          </SearchContainer>
        </Container>
      )}
    </>
  );
};

export default DesktopActions;
