import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrowLink = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 11 10" fill="none" {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M10.887 0v10h-1.6V0z" />
      <path d="M.887 0h10v1.6h-10z" />
      <path d="M.887 9.068 9.289.665l.932.932L1.82 10z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.887 0h10v10h-10z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowLink;
