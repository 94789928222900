import api from '../../config/http/api';

const requestDownload = (endpoint: string, signal?: AbortSignal) =>
  api.get<undefined, Blob>(endpoint, undefined, {
    signal,
    responseType: 'blob',
  });

export default {
  requestDownload,
};
