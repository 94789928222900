import * as React from 'react';
import type { SVGProps } from 'react';

const SvgEntry = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
    <path
      fill="#262626"
      stroke="#262626"
      strokeWidth={0.3}
      d="M4.5 1.227h-.15v6.75H.85v.8h7.21L6.216 10.62l-.106.106.107.106.355.353.106.105.106-.106 2.702-2.702.106-.106-.106-.106-2.702-2.703-.106-.106-.106.106-.355.352-.107.106.106.107L8.06 7.977H5.15v-5.95h9.2v12.7h-9.2v-2.5h-.8v3.3h10.8v-14.3H15z"
    />
  </svg>
);
export default SvgEntry;
