import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFeedbackWarn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m8.462 4.271 5.02 8.673H3.442l5.02-8.673Zm0-2.66L1.13 14.278h14.667L8.462 1.61Zm.667 9.333H7.796v1.334h1.333v-1.334Zm0-4H7.796v2.667h1.333V6.944Z"
    />
  </svg>
);
export default SvgFeedbackWarn;
