import styled from 'styled-components';

export const TooltipContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 0;
  background: #262626;
  box-shadow: 0 1px 4px rgba(38, 38, 38, 0.18), 0 4px 14px rgba(38, 38, 38, 0.1);
  border-radius: 4px;
  max-width: 300px;

  p {
    white-space: break-spaces;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
`;
