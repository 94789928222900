import React from 'react';
import { Container } from '../../style';
import CustomText from '../../../../components/CustomText';
import { useAuth } from '../../../../context/AuthContext';
import Input from '../../../../components/Input';

const Header = () => {
  const { user } = useAuth();
  const name = user?.name;

  return (
    <Container>
      <CustomText as="h1">Meu perfil</CustomText>
      <Input.Form
        helper={
          <CustomText>
            Para editar seu nome entre em contato com o suporte TKE pelo chatbot no canto direito.
          </CustomText>
        }
        label="Nome completo"
        defaultValue={name}
        readOnly
      />
    </Container>
  );
};

export default Header;
