import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${BREAKPOINTS.mobile}px) {
    display: grid;
    grid-template-columns: 55.625% auto;
    height: 100vh;
    width: 100vw;
  }
`;
