import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, useTransition } from 'react';
import {
  ALL_SEARCH_KEYS,
  BuildingAsideStage,
  BuildingData,
  BuildingOrder,
  BuildingOrderBy,
  BuildingSearchBy,
  LinkContract,
} from '../../../../../@types/building';
import { removeSpecialCharacters } from '../../../../../utils/helpers/string';
import usePagination from '../../../../../hooks/usePagination';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import BuildingService from '../../../../../services/Buildings/BuildingService';
import getOrderDirection from '../../../../../utils/helpers/getOrderDirection';
import Alert from '../../../../../components/Toast/toast';
import useSearch from '../../../../../hooks/useSearch';
import ClientContext, { ClientContextProps } from '../../context';
import { findAndUpdate, pipe } from '../../../../../utils/helpers/array';
import useLayout from '../../../../../hooks/useLayout';
import useDisclosure from '../../../../../hooks/useDisclosure';
import BuildingPolicy from '../../../../../strategy/rules/BuildingPolicy';
import useAsideActions from '../../../../../hooks/useAsideActions';

const useBuilding = () => {
  const { scrollToRef, updateFinancialData } = useContext(ClientContext) as ClientContextProps;
  const { layout, toggleLayout, showGrid } = useLayout();
  const { isMobileWidth } = useWindowDimensions();
  const [data, setData] = useState<BuildingData[]>([]);
  const [paginatedData, setPaginatedData] = useState<BuildingData[][]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen: searchModalIsOpen, close: closeSearchModal, open: openSearchModal } = useDisclosure();
  const [order, setOrder] = useState<BuildingOrder | null>(null);
  const dataIsEmpty = useMemo(() => data.length < 1 && !loading, [data, loading]);
  const filterIsEmpty = useMemo(() => paginatedData.length < 1 && !dataIsEmpty, [paginatedData, dataIsEmpty]);
  const [isPending, startTransition] = useTransition();
  const { applySearch, search, searchKeys, handleSearchKeys, handleSearch, clearSearch } = useSearch({
    data,
    allSearchKeys: ALL_SEARCH_KEYS,
    searchBy: (searchByKeys: Array<BuildingSearchBy>, searchBuildingData: BuildingData) => [
      ...searchByKeys
        .filter((key) => key !== 'contract')
        .map((key) => String(searchBuildingData[key as Exclude<BuildingSearchBy, 'contract'>])),
      ...(searchByKeys.includes('contract')
        ? [...searchBuildingData.contracts.map((contract) => contract.contractCode)]
        : []),
    ],
  });

  const {
    isOpen: asideActionsIsOpen,
    open: openAsideActions,
    close: closeAsideActions,
    stage: asideStage,
    setStage: setAsideStage,
  } = useAsideActions<BuildingAsideStage>(BuildingAsideStage.SELECT_ACTION);

  const handleOrder = (newOrder: BuildingOrder | null) => {
    setOrder(newOrder);
  };

  const requestSearch = useCallback((newSearch: string, newSearchKeys: Array<BuildingSearchBy>) => {
    handleSearchKeys(newSearchKeys);
    handleSearch(removeSpecialCharacters(newSearch));
  }, []);

  const applyOrder = (dataToTransform: BuildingData[]) => {
    const sortableItems = [...dataToTransform];
    if (!order) return sortableItems;

    const isAscendingOrder = order.direction === 'asc';

    return sortableItems.sort((a, b) => {
      if (order.orderBy === 'vinculated') {
        const aIsLinkedInternally = BuildingPolicy.contractsAreLinkedInternally(a);
        const bIsLinkedInternally = BuildingPolicy.contractsAreLinkedInternally(b);

        if (aIsLinkedInternally < bIsLinkedInternally) {
          return isAscendingOrder ? -1 : 1;
        }
        if (aIsLinkedInternally > bIsLinkedInternally) {
          return isAscendingOrder ? 1 : -1;
        }
      } else if (order.orderBy === 'favorite') {
        if (a.favorite < b.favorite) {
          return isAscendingOrder ? 1 : -1;
        }
        if (a.favorite > b.favorite) {
          return isAscendingOrder ? -1 : 1;
        }
      } else if (order.orderBy === 'document' || order.orderBy === 'id') {
        const aNumeric = Number(a[order.orderBy]);
        const bNumeric = Number(b[order.orderBy]);

        if (aNumeric < bNumeric) {
          return isAscendingOrder ? -1 : 1;
        }
        if (aNumeric > bNumeric) {
          return isAscendingOrder ? 1 : -1;
        }
      } else {
        if (a[order.orderBy] < b[order.orderBy]) {
          return isAscendingOrder ? -1 : 1;
        }
        if (a[order.orderBy] > b[order.orderBy]) {
          return isAscendingOrder ? 1 : -1;
        }
      }
      return 0;
    });
  };

  const requestSort = useCallback(
    (key: BuildingOrderBy) =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    [order]
  );

  const { limit, getPaginationProps, currentPageIndex, applyPagination } = usePagination<BuildingData>(data.length);

  const currentPage = paginatedData[currentPageIndex];

  const handleEditItem = (id: string | number, item: BuildingData) => setData(findAndUpdate('id', id, item));

  const fetchBuildingsData = async () => {
    setLoading(true);
    const buildingData = await BuildingService.getBuildings();
    setData(buildingData);
    setLoading(false);
  };

  const openSearchAction = () => {
    if (isMobileWidth) {
      openAsideActions();
      setAsideStage(BuildingAsideStage.SEARCH_NEW_BUILDING);
      return;
    }

    openSearchModal();
  };

  const linkNewContract = async (newContract: LinkContract) => {
    await BuildingService.addContractRelation(newContract);
    fetchBuildingsData();
    updateFinancialData();
  };

  const unlinkNewContract = async (unlinkContracts: Array<string>) => {
    await BuildingService.removeContractRelation(unlinkContracts);
    fetchBuildingsData();
    updateFinancialData();
  };

  const favoriteBuilding = async (buildingData: BuildingData, signal: AbortSignal) => {
    await BuildingService.toggleFavorite({ id: buildingData.id, favorite: !buildingData.favorite }, signal);
    Alert.SUCCESS(
      `Contrato ${buildingData.id}, ${buildingData.favorite ? 'desfavoritado' : 'favoritado'} com sucesso!`
    );
    handleEditItem(buildingData.id, {
      ...buildingData,
      favorite: !buildingData.favorite,
    });
  };

  const fetchNewContract = async (newContract: LinkContract, signal?: AbortSignal) =>
    BuildingService.getBuildingsByContract(newContract, signal);

  const surveyedContractIsAlreadyOnList = useCallback(
    (surveyedBuildingDataArray: BuildingData[]) =>
      surveyedBuildingDataArray.every((surveyedBuildingData) =>
        surveyedBuildingData.contracts.every(({ contractCode }) => {
          const contractsCodeInActualList = data.flatMap((actualBuildingData) =>
            actualBuildingData.contracts.map((contract) => contract.contractCode)
          );
          return contractsCodeInActualList.includes(contractCode);
        })
      ),
    [data]
  );

  useLayoutEffect(() => {
    startTransition(() => {
      const filteredBuildingData = pipe(data, [applySearch, applyOrder]);
      const paginatedBuildingData = applyPagination(filteredBuildingData);
      setPaginatedData(paginatedBuildingData);
    });
  }, [data, order, search, searchKeys, limit]);

  useEffect(() => {
    if (isMobileWidth) {
      scrollToRef();
    }
  }, [currentPageIndex]);

  useEffect(() => {
    fetchBuildingsData();
  }, []);

  return {
    currentPage,
    handleEditItem,
    unlinkNewContract,
    linkNewContract,
    surveyedContractIsAlreadyOnList,
    filterIsPending: isPending,
    fetchNewContract,
    fetchBuildingsData,
    layout,
    toggleLayout,
    getPaginationProps,
    loading,
    showGrid,
    openSearchModal,
    closeSearchModal,
    clearSearch,
    requestSearch,
    requestSort,
    order,
    search,
    handleSearch,
    searchModalIsOpen,
    searchKeys,
    handleSearchKeys,
    favoriteBuilding,
    handleOrder,
    dataIsEmpty,
    filterIsEmpty,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    openSearchAction,
  };
};

export default useBuilding;
