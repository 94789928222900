import styled from 'styled-components';
import theme from '../../styles/theme';

export const Divider = styled.div`
  border: none;
  width: 100%;
  height: 1px;
  background-color: ${theme.color.secondary.grey[4]};
  margin: 16px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-width: 640px;
  box-sizing: border-box;
  overflow: auto;
  min-height: 528px;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }
`;
