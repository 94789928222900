import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

const LINK_DISABLED_STYLE = css`
  color: ${theme.color.secondary.grey[4]};
  pointer-events: none;
  cursor: default;
  .link_icon {
    fill: ${theme.color.secondary.grey[4]};
    stroke: ${theme.color.secondary.grey[4]};
  }

  ${theme.defaults.disabled};
`;

export const StyledLink = styled.div<{ disabled?: boolean; $color?: string; $hoverColor?: string }>`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  color: ${({ $color }) => $color || theme.color.primary.interaction};

  .forward_icon {
    transform: rotate(180deg);
  }

  &:hover {
    ${({ $hoverColor }) =>
      $hoverColor
        ? css`
            color: ${$hoverColor};
          `
        : css`
            color: currentColor;
          `};
  }

  ${({ disabled }) => disabled && LINK_DISABLED_STYLE}
`;
