import styled, { css } from 'styled-components';
import { Grid } from 'react-flexbox-grid';
import { boxStyle } from '../../../styles/components';
import Input from '../../../components/Input';
import theme from '../../../styles/theme';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

export const boxTitle = css`
  h2 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const DesktopHeaderContainer = styled.header<{ historicIsOpen: boolean }>`
  ${boxStyle};

  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;

  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
  }

  gap: 1.2rem;

  position: relative;

  .status-badge {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ historicIsOpen }) =>
    historicIsOpen &&
    css`
      .historic-button {
        outline: 1px solid ${theme.color.primary.interaction};
        color: ${theme.color.primary.interaction};
      }
    `}
`;

export const HistoricContainer = styled.div`
  ${boxStyle};
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 168px);
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex: 1;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    max-height: unset;
    height: unset;
  }
`;

export const DetailsContainer = styled.div`
  ${boxStyle};
  ${boxTitle};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormComposition = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
  }
`;

export const Form = styled(Grid).attrs({
  fluid: true,
})`
  padding: 0;

  .row:not(:first-child) {
    margin-top: 24px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    .row {
      margin-top: 0 !important;
    }

    .row > div {
      margin-top: 16px;
    }

    .row:first-child > div:first-child {
      margin-top: 0;
    }
  }
`;

export const DetailsInput = styled(Input.Form).attrs({
  useHelper: false,
  placeholder: '',
  readOnly: true,
  'data-clarity-mask': 'True',
})`` as typeof Input.Form;

export const Composition = styled.div`
  ${boxStyle};
  ${boxTitle};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Instructions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const InstructionsContent = styled.div`
  padding: 12px;
  border: 1px solid ${theme.color.secondary.grey[2]};
  background: ${theme.color.secondary.grey[6]};

  p {
    color: ${theme.color.primary.black};
    font-size: 1.4rem !important;
    font-style: normal;
    font-weight: 400;
  }
`;

export const FormCompositionHeader = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  min-height: 32px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
    align-items: flex-start;

    p {
      width: 70%;
    }
  }
`;

export const DetailsMenuButton = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid ${theme.color.secondary.grey[5]};
  background: none;
  outline: none;
  width: 90px;
  cursor: pointer;
`;
