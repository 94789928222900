import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 24px 40px;
  box-sizing: border-box;
`;

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const SkeletonBox = styled.div<{ width?: number; height?: number; mb?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  margin-bottom: ${({ mb }) => mb && `${mb}px`};
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: ${theme.color.secondary.grey[5]};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    transform: translateX(-100%);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 100%
      )
      no-repeat;
    display: inline-block;
    animation: ${shimmer} 2s infinite;
  }
`;

export const SkeletonBackground = styled(SkeletonBox)`
  width: 100%;
  height: 100%;
  background: ${theme.color.secondary.grey[5]};
`;

export const Grid = styled.div`
  display: flex;
  overflow-x: hidden;

  @media (min-width: ${BREAKPOINTS.xl}px) {
    display: grid;
    grid-template-columns: 55.625% auto;
    height: 100vh;
    width: 100vw;
  }
`;
