import React from 'react';
import { addMonths, getMonthName } from '../../../../utils/helpers/date';
import CustomText from '../../../CustomText';
import { ButtonArrowWrapper, CalendarHeaderDoubleView, CalendarHeaderOneView } from '../../style';
import { ChevronArrowIcon } from '../../../../icons';

interface CalendarHeaderProps {
  activeDate: Date;
  onPrevious: VoidFunction;
  onNext: VoidFunction;
  showDoubleView: boolean;
  disableNext?: boolean;
  disablePrevious?: boolean;
}

const CalendarHeader = ({
  activeDate,
  onPrevious,
  onNext,
  disableNext,
  disablePrevious,
  showDoubleView,
}: CalendarHeaderProps) => {
  if (!showDoubleView) {
    return (
      <CalendarHeaderOneView>
        <ButtonArrowWrapper onClick={onPrevious} cursor="pointer" disabled={disablePrevious}>
          <ChevronArrowIcon className="rotate-180" />
        </ButtonArrowWrapper>
        <div className="month-year">
          <CustomText className="month-name">
            {getMonthName(activeDate)}{' '}
            <CustomText as="span" className="year">
              {activeDate.getFullYear()}
            </CustomText>
          </CustomText>
        </div>
        <ButtonArrowWrapper onClick={onNext} cursor="pointer" disabled={disableNext}>
          <ChevronArrowIcon />
        </ButtonArrowWrapper>
      </CalendarHeaderOneView>
    );
  }

  return (
    <CalendarHeaderDoubleView>
      <div>
        <ButtonArrowWrapper onClick={onPrevious} cursor="pointer" disabled={disablePrevious}>
          <ChevronArrowIcon className="rotate-180" />
        </ButtonArrowWrapper>
        <div className="month-year">
          <CustomText className="month-name">
            {getMonthName(activeDate)}{' '}
            <CustomText as="span" className="year">
              {activeDate.getFullYear()}
            </CustomText>
          </CustomText>
        </div>
      </div>
      <div>
        <div className="month-year">
          <CustomText className="month-name">
            {getMonthName(addMonths(activeDate, 1))}{' '}
            <CustomText as="span" className="year">
              {addMonths(activeDate, 1).getFullYear()}
            </CustomText>
          </CustomText>
        </div>
        <ButtonArrowWrapper onClick={onNext} cursor="pointer" disabled={disablePrevious}>
          <ChevronArrowIcon />
        </ButtonArrowWrapper>
      </div>
    </CalendarHeaderDoubleView>
  );
};

export default CalendarHeader;
