import { useMemo, useState, useCallback } from 'react';
import paginate from '../utils/helpers/paginate';

const INITIAL_LIMIT = 10;

export interface PaginationCallbackProps {
  limit: number;
  offset: number;
}

export type GetPaginationPropsFn = () => {
  initialLimit: number;
  amount: number;
  offset: number;
  setLimit: (value: ((prevState: number) => number) | number) => void;
  setOffset: (value: ((prevState: number) => number) | number) => void;
  limit: number;
};

const usePagination = <T>(initialAmount: number) => {
  const [total, setTotal] = useState(initialAmount);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const currentPageIndex = useMemo(() => offset - 1, [offset]);

  const applyPagination = useCallback(
    (dataToTransform: T[]) => {
      const paginatedBuildingData = paginate<T>(dataToTransform, limit);

      if (offset > initialAmount) {
        setOffset(1);
      }

      setTotal(dataToTransform.length);

      return paginatedBuildingData;
    },
    [limit]
  );

  return {
    applyPagination,
    getPaginationProps: () => ({
      amount: total,
      initialLimit: INITIAL_LIMIT,
      limit,
      setLimit,
      offset,
      setOffset,
    }),
    currentPageIndex,
    limit,
    setLimit,
    total,
    setTotal,
    offset,
    setOffset,
  };
};

export default usePagination;
