import { createContext } from 'react';
import {
  InvoiceAsideStage,
  InvoiceData,
  InvoiceOrder,
  InvoiceOrderBy,
  InvoiceSearchBy,
} from '../../../../../@types/invoice';
import { Layout } from '../../../../../hooks/useLayout';
import { GetSelectionPropsFunc } from '../../../../../hooks/useDataSelection';

export interface InvoiceContextProps {
  closeSelectDate: VoidFunction;
  toggleLayout: VoidFunction;
  handleSelectAll: VoidFunction;
  clearSearch: VoidFunction;
  clearRangeSelect: VoidFunction;
  closeSearchModal: VoidFunction;
  openSearchAction: VoidFunction;
  actionsIsDisabled: boolean;
  selectDateIsOpen: boolean;
  range: DateRange | undefined;
  onChangeRange: (newRange: DateRange | undefined) => void;
  requestSearch: (newSearch: string, newSearchKeys: Array<InvoiceSearchBy>) => void;
  search: string;
  loading: boolean;
  layout: Layout;
  showGrid: boolean;
  areElementsSelected: boolean;
  selectedData: Array<string>;
  order: InvoiceOrder | null;
  handleOrder: (newOrder: InvoiceOrder | null) => void;
  handleSearch: (newSearch: string) => void;
  searchKeys: Array<InvoiceSearchBy>;
  handleSearchKeys: (newSearchKeys: Array<InvoiceSearchBy>) => void;
  handleDownloadInvoice: (id: string) => void;
  handleDownloadMultipleInvoices: (idsToDownload: string[]) => void;
  handlePrint: (id: InvoiceData['id']) => void;
  signalRConnectionReachedMaxRetries: boolean;
  dataIsEmpty: boolean;
  filterIsEmpty: boolean;
  data: InvoiceData[];
  getSelectionProps: GetSelectionPropsFunc<InvoiceData>;
  requestSort: (key: InvoiceOrderBy, direction?: 'asc' | 'desc') => void;
  searchModalIsOpen: boolean;
  asideActionsIsOpen: boolean;
  asideStage: InvoiceAsideStage;
  setAsideStage: SetState<InvoiceAsideStage>;
  openAsideActions: VoidFunction;
  closeAsideActions: VoidFunction;
  openSelectDateAction: VoidFunction;
}

const InvoiceContext = createContext<InvoiceContextProps | null>(null);

export default InvoiceContext;
