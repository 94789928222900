import React, { FunctionComponent } from 'react';
import CustomText from '../../../../components/CustomText';
import { Container, Content, Cover, Info, Links } from './style';

interface ExternalAppCardProps {
  children?: Children;
  title: string;
  description: string;
  background: string;
}

const ExternalAppCard: FunctionComponent<ExternalAppCardProps> = ({ children, title, description, background }) => (
  <Container>
    <Cover url={background} />
    <Content>
      <Info>
        <CustomText className="title">{title}</CustomText>
        <CustomText className="description">{description}</CustomText>
      </Info>
      <Links>{children}</Links>
    </Content>
  </Container>
);

export default ExternalAppCard;
