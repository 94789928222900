import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 200px;
  z-index: ${theme.defaults.downloadListZIndex};
  width: 268px;
  box-shadow: 0 0 4px 0 rgba(196, 196, 196, 0.25);
`;

export const DownloadListHeader = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;
  height: 50px;

  background: ${theme.color.secondary.purple[4]};

  > div {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  > p {
    font-family: 'TKEType-Medium', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
  }

  button {
    cursor: pointer;
  }

  .dropdown-icon {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }
`;

export const DownloadListBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;

  p {
    font-size: 1.4rem;
    font-style: normal;
  }

  button {
    cursor: pointer;
  }

  background: ${theme.color.primary.white};
`;

export const DownloadItemBody = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  ${({ $color }) =>
    $color &&
    css`
      .progress-bar {
        background-color: ${$color || theme.color.secondary.purple[2]} !important;
      }

      color: ${$color || 'currentColor'};
    `}
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 700px;
  box-sizing: border-box;
  overflow: auto;
  gap: 16px;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  button {
    width: 180px;
  }
`;

export const DownloadListErrorAdvisor = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: ${theme.color.feedback.error};
  background: ${theme.color.secondary.orange[4]};

  p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;
