export interface Consultant {
  name: string;
  phone: string;
  type: '1' | '2';
}

export interface BuildingAddress {
  city: string;
  uf: string;
  cep: string;
}

export enum ContractLink {
  FATHER = 1,
  CHILD = 2,
  FRIEND = 3,
  TK = 4,
}

export enum ContractType {
  VS = 1,
  ON = 2,
  MI = 3,
  MP = 4,
  ACCESSIBILITY = 5,
  ESP = 6,
}

export interface BusinessContract {
  id: string;
  type: ContractType;
  contractCode: string;
  link: ContractLink;
}

export interface BuildingData {
  id: number | string;
  name: string;
  clientName: string;
  contracts: Array<BusinessContract>;
  consultants: Array<Consultant>;
  document: string;
  favorite: boolean;
  city: string;
  uf: string;
  cep: string;
}

export interface LinkContract {
  contract: string;
  document: string;
}

export type BuildingSearchBy = 'clientName' | 'id' | 'uf' | 'city' | 'document' | 'contract';

export const ALL_SEARCH_KEYS: Array<BuildingSearchBy> = ['clientName', 'id', 'uf', 'city', 'document', 'contract'];

export type BuildingOrderBy = 'clientName' | 'id' | 'uf' | 'city' | 'favorite' | 'document' | 'vinculated';

export interface BuildingOrder {
  orderBy: BuildingOrderBy;
  direction: 'asc' | 'desc';
}

export enum BuildingAsideStage {
  SELECT_ACTION = 'SELECT_ACTION',
  FILTER_ACTION = 'FILTER_ACTION',
  SEARCH_NEW_BUILDING = 'SEARCH_NEW_BUILDING',
}
