import React, { useMemo } from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Container } from './style';
import Header from './components/Header';
import Content from './components/Content';
import Modal from './components/Actions/DesktopActions';
import useActions from './hooks/useActions';
import AccountContext from './context';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import MobileModal from './components/Actions/MobileActions';

const Account = () => {
  const { open, type, closeAction, openAction } = useActions();
  const { isMobileWidth } = useWindowDimensions();

  const context = useMemo(
    () => ({
      open,
      type,
      closeAction,
      openAction,
      isMobileWidth,
    }),
    [open, type, isMobileWidth]
  );

  return (
    <AccountContext.Provider value={context}>
      <PageLayout boxStyle>
        <Container>
          <Header />
          <Content />
          {isMobileWidth ? <MobileModal /> : <Modal />}
        </Container>
      </PageLayout>
    </AccountContext.Provider>
  );
};

export default Account;
