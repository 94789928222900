import styled from 'styled-components';
import { StepComponentContainer } from '../../../../../Building/components/AsideActions/style';

export const MobileEmptyResult = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9d9d9d;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;
export const Container = styled(StepComponentContainer)`
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  flex: 1;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
`;
