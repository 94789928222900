import React, { ForwardedRef, forwardRef } from 'react';
import { ButtonContent, PrimaryButton, SecondaryButton, TertiaryButton } from './style';
import { ButtonProps } from './types';
import Loader from '../Loader';

const componentMapping = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
};

export type ButtonOwnProps<E extends React.ElementType> = {
  as?: E;
  variant: 'primary' | 'secondary' | 'tertiary';
} & ButtonProps;

export type ButtonType<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps<E>>;

const Button = <E extends React.ElementType = 'button'>(
  { loading, children, variant, ...props }: ButtonType<E>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const ButtonVariant = componentMapping[variant];
  return (
    <ButtonVariant {...props} ref={ref}>
      {loading && <Loader />}
      <ButtonContent>{children}</ButtonContent>
    </ButtonVariant>
  );
};

export default forwardRef(Button) as <E extends React.ElementType = 'button'>(
  props: ButtonType<E> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof Button>;
