import React from 'react';
import CustomText from '../../../../../components/CustomText';
import { Content } from './style';
import { formatDate } from '../../../../../utils/helpers/date';

interface SendEmailContentProps {
  emittedAt: string;
  invoiceNumber: string;
}

const InvoiceSendEmailContent = ({ emittedAt, invoiceNumber }: SendEmailContentProps) => (
  <Content>
    <CustomText className="__modal-content-title bold">Nota fiscal:</CustomText>
    <CustomText className="__modal-content">
      Nº NF{' '}
      <CustomText as="span" className="bold">
        {invoiceNumber}
      </CustomText>
    </CustomText>
    <CustomText className="__modal-content">
      Emissão{' '}
      <CustomText as="span" className="bold">
        {formatDate(new Date(emittedAt))}
      </CustomText>
    </CustomText>
  </Content>
);

export default InvoiceSendEmailContent;
