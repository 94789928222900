import { useCallback, useRef } from 'react';

export const useScrollToRef = <T extends HTMLElement>() => {
  const scrollRef = useRef<T>(null);

  const scrollToRef = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [scrollRef]);

  return {
    scrollRef,
    scrollToRef,
  };
};
