import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none" viewBox="0 0 15 17" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M.65 12.064v.1H1.413v-9h9.925V15.34H.65V16.102H12.1v-13.7H.65v.1z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M10.694 5.286v-.1H2.056V5.95h8.638v-.1zM10.694 7.283v-.1H2.056v.762h8.638v-.1zM7.544 9.252v-.1H2.056v.762H7.544v-.1zM10.694 11.22v-.1H2.056V11.884h8.638v-.1zM8.838 13.19v-.1H2.056v.762H8.838v-.1z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M3.563.152h-.1v.762h10.125V11.602h.762V.152h-.662z"
    />
  </svg>
);
export default SvgDocument;
