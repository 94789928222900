import React, { FunctionComponent, ReactNode } from 'react';
import Header from '../Header';
import CustomText from '../../../../../../../components/CustomText';
import { Container, HeaderContainer } from './style';

interface ConfirmCodeProps {
  title: string;
  description: string;
  highlight: ReactNode;
  children: Children;
}

type HeaderDescriptionProps = Pick<ConfirmCodeProps, 'description' | 'highlight'>;

const HeaderDescription: FunctionComponent<HeaderDescriptionProps> = ({ description, highlight }) => (
  <HeaderContainer>
    <CustomText as="p">{description}</CustomText>
    {highlight}
  </HeaderContainer>
);

const ConfirmCode: FunctionComponent<ConfirmCodeProps> = ({ title, description, highlight, children }) => (
  <Container>
    <Header title={title} description={<HeaderDescription description={description} highlight={highlight} />} />
    {children}
  </Container>
);

export default ConfirmCode;
