import React, { PropsWithChildren } from 'react';
import { StyledAccordionContent, StyledAccordionHeader, StyledAccordionItem, StyledAccordionRoot } from './style';
import { ChevronArrowIcon } from '../../icons';
import { HTMLDivProps } from '../../@types/html';
import useToggle from '../../hooks/useToggle';

const AccordionRoot = ({ children, ...props }: PropsWithChildren<HTMLDivProps & { gap?: number }>) => (
  <StyledAccordionRoot {...props}>{children}</StyledAccordionRoot>
);

type PropsByAccordionItem = {
  onToggleAccordion: VoidFunction;
  isActive: boolean;
};

interface AccordionItemProps {
  children: (props: PropsByAccordionItem) => Children;
}

const AccordionItem = ({ children }: AccordionItemProps) => {
  const [active, toggle] = useToggle(false);

  return <StyledAccordionItem>{children({ isActive: active, onToggleAccordion: toggle })}</StyledAccordionItem>;
};

interface AccordionContentProps {
  children: Children;
  active: boolean;
}

const AccordionContent = ({ active, children }: AccordionContentProps) => (
  <StyledAccordionContent
    className="accordion-content"
    role="region"
    aria-hidden={active ? 'false' : 'true'}
    isLeaving={!active}
  >
    <div>{children}</div>
  </StyledAccordionContent>
);

interface AccordionHeaderProps {
  children: Children;
  active: boolean;
  onToggleAccordion: VoidFunction;
}

const AccordionHeader = ({ children, active, onToggleAccordion }: AccordionHeaderProps) => (
  <StyledAccordionHeader
    role="button"
    className={`accordion ${active}`}
    onClick={(event) => {
      event.stopPropagation();
      onToggleAccordion();
    }}
  >
    <div className="children-container">{children}</div>
    <ChevronArrowIcon className={active ? 'accordion-icon rotate' : 'accordion-icon'} />
  </StyledAccordionHeader>
);

export default {
  Root: AccordionRoot,
  Item: AccordionItem,
  Content: AccordionContent,
  Header: AccordionHeader,
};
