import { DefaultActions } from './constants';
import store from '../store';

function clearGlobalStateAction() {
  return {
    type: DefaultActions.CLEAR,
  };
}

export default function clearGlobalState() {
  store.dispatch(clearGlobalStateAction());
}
