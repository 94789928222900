import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import billReducer, { BillState } from './billSlice';
import invoiceReducer, { InvoiceState } from './invoiceSlice';
import { DefaultActions } from '../actions/constants';

export type RootState =
  | {
      bill: BillState;
      invoice: InvoiceState;
    }
  | undefined;

const combinedReducer = combineReducers({
  bill: billReducer,
  invoice: invoiceReducer,
});

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === DefaultActions.CLEAR) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
