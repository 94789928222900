import styled, { css } from 'styled-components';
import theme from '../../../../../styles/theme';

const HIGHLIGHT_BUTTON_STYLE = css`
  border-color: ${theme.color.primary.interaction};
  color: ${theme.color.primary.interaction};
`;

export const Container = styled.div<{ disabled: boolean; highlightButton: boolean }>`
  display: flex;
  align-items: center;

  .tooltip_button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;
    height: 38px;
    background: #ffffff;
    border: 1.5px solid #e9e9e9;
  }

  .tooltip_button_open {
    ${HIGHLIGHT_BUTTON_STYLE};
  }

  ${({ highlightButton }) =>
    highlightButton &&
    css`
      .tooltip_button {
        ${HIGHLIGHT_BUTTON_STYLE};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.color.secondary.grey[4]};
      ${theme.defaults.disabled}
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #fff;
  width: 205px;
  box-sizing: border-box;

  .Align_Container {
    gap: 32px;
  }
`;
