import React from 'react';
import CustomText from '../../../../../../components/CustomText';

const InvoiceErrorMessage = () => (
  <CustomText>
    <CustomText as="span">Não conseguimos disponibilizar a sua nota fiscal</CustomText>
    <br />
    Isso pode ocorrer caso ela tenha sido emitida recentemente. Tente novamente em alguns instantes ou se for o caso,
    pedimos que aguarde o prazo de 3 dias para processamento. Caso contrário entre em contato pelo chat ou pelo WhatsApp
    51 99977 9006 com o Departamento Financeiro na opção &quot;Demais Solicitações&quot;.
  </CustomText>
);

export default InvoiceErrorMessage;
