import styled from 'styled-components';
import { TertiaryButton } from '../Button/style';
import theme from '../../styles/theme';

export const TabsButton = styled(TertiaryButton)`
  width: 95px;
  height: 40px;

  min-width: unset;
  padding: unset;

  &:hover {
    color: ${theme.color.secondary.purple[2]};
  }
`;
