import { createContext } from 'react';
import { BuildingAsideStage } from '../../../../../../../@types/building';

export interface AsideActionsContextProps {
  changeStage: (newStage: BuildingAsideStage) => void;
  closeHandler: VoidFunction;
}

const AsideActionsContext = createContext<AsideActionsContextProps | null>(null);

export default AsideActionsContext;
