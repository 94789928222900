import React, { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, DesktopSidebar, Overlay } from './style';
import useAnimatedUnmount from '../../../../hooks/useAnimatedUnmount';
import TopbarContext, { TopbarContextProps } from '../../context';
import { SIDEBAR_CLIENT_ITEMS, SIDEBAR_PARTNER_ITEMS } from '../../data';
import NavButton from '../NavButton';
import { WrapperDiv } from '../../../../styles/components';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import AsidePortal from '../../../AsidePortal';
import { useScrollLock } from '../../../../context/ScrollLockContext';
import AsideHeader from '../../../../components/AsideHeader';
import { ChevronArrowIcon } from '../../../../icons';

const SidebarContent = () => {
  const { isPartnerUser, closeSidebar } = useContext(TopbarContext) as TopbarContextProps;
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const currentSidebarItems = isPartnerUser ? SIDEBAR_PARTNER_ITEMS : SIDEBAR_CLIENT_ITEMS;

  const navigateToItem = (path: string) => {
    closeSidebar();
    navigate(path);
  };

  const enableSupportButton = width <= 480;

  return (
    <>
      <AsideHeader onBack={closeSidebar} />
      <ButtonContainer>
        {currentSidebarItems.map(({ title, path, key }) => (
          <NavButton key={key} onClick={() => navigateToItem(path)}>
            <WrapperDiv>
              {title}
              <ChevronArrowIcon />
            </WrapperDiv>
          </NavButton>
        ))}
        {enableSupportButton && (
          <NavButton
            onClick={() => {
              closeSidebar();
              // @ts-ignore
              document?.getElementsByClassName('ib-widget-button')?.[0]?.click();
            }}
          >
            <WrapperDiv>
              Contate o suporte
              <ChevronArrowIcon />
            </WrapperDiv>
          </NavButton>
        )}
      </ButtonContainer>
    </>
  );
};

const SidebarPortal = ({
  visible,
  onClose,
  children,
}: PropsWithChildren<{ visible: boolean; onClose: VoidFunction }>) => {
  const { shouldRender, ref } = useAnimatedUnmount<HTMLDivElement>(visible);
  useScrollLock(shouldRender);
  if (!shouldRender) return null;

  return (
    <Overlay isLeaving={!visible} ref={ref} onClick={onClose}>
      <DesktopSidebar isLeaving={!visible}>{children}</DesktopSidebar>
    </Overlay>
  );
};

const Sidebar = () => {
  const { isMobileWidth } = useWindowDimensions();
  const { closeSidebar, sideBarIsOpen } = useContext(TopbarContext) as TopbarContextProps;

  return isMobileWidth ? (
    <AsidePortal visible={sideBarIsOpen}>
      <SidebarContent />
    </AsidePortal>
  ) : (
    <SidebarPortal visible={sideBarIsOpen} onClose={closeSidebar}>
      <SidebarContent />
    </SidebarPortal>
  );
};

export default Sidebar;
