import { Col, Row } from 'react-flexbox-grid';
import React, { Fragment, useContext, useRef } from 'react';
import CustomText from '../../../../../components/CustomText';
import { Composition, DetailsInput, DetailsContainer, Form, Instructions, InstructionsContent } from '../../style';
import { formatDate } from '../../../../../utils/helpers/date';
import { formatNumberToBRL } from '../../../../../utils/helpers/money';
import { WrapperDiv } from '../../../../../styles/components';
import Button from '../../../../../components/Button';
import { Label } from '../../../../../components/Input/style';
import FinancialDetailsContext, { FinancialDetailsContextProps } from '../../context';
import InvoiceAccordion from '../../components/InvoiceAccordion';
import BillPolicy from '../../../../../strategy/rules/BillPolicy';
import { MAP_BILL_STATUS } from '../../../Client/containers/Bill/constants';
import Input from '../../../../../components/Input';
import { maskCPForCNPJ } from '../../../../../utils/helpers/mask';
import Accordion from '../../../../../components/Accordion';

const Details = () => {
  const { details } = useContext(FinancialDetailsContext) as FinancialDetailsContextProps;

  const billCodeRef = useRef<HTMLInputElement>(null);

  const hasComposition = details?.composition && details.composition.length > 0;

  return (
    <>
      <DetailsContainer>
        <CustomText as="h2">Dados gerais do boleto</CustomText>
        <Form>
          <Row>
            <Col lg={8} sm={12}>
              <DetailsInput label="Razão social" value={details?.client.name} />
            </Col>
            <Col lg={4} sm={12}>
              <Input.BaseNumberMasked
                customInput={DetailsInput}
                readOnly
                inputMode="numeric"
                label="CPF ou CNPJ"
                value={details?.client.document}
                useHelper={false}
                format={maskCPForCNPJ}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <DetailsInput label="Contrato" value={details?.contract} />
            </Col>
            <Col lg={4} sm={12}>
              <DetailsInput label="Número do boleto" value={details?.billNumber} />
            </Col>
            <Col lg={1} sm={12}>
              <DetailsInput label="Exercício" value={details?.exercise} />
            </Col>
            <Col lg={3} sm={12}>
              <DetailsInput label="Status" value={details?.status ? MAP_BILL_STATUS[details.status] : ''} />
            </Col>
          </Row>
          <Row>
            <Col lg sm={12}>
              <DetailsInput
                label="Vencimento original"
                value={formatDate(new Date(details?.originalExpiresAt || ''))}
              />
            </Col>
            <Col lg sm={12}>
              <DetailsInput label="Vencimento prorrogado" value={formatDate(new Date(details?.expiresAt || ''))} />
            </Col>
            <Col lg sm={12}>
              <DetailsInput label="Juros (R$)" value={formatNumberToBRL(details?.taxAmount)} />
            </Col>
            <Col lg sm={12}>
              <DetailsInput label="Total (R$)" value={formatNumberToBRL(details?.documentAmount)} />
            </Col>
            <Col lg sm={12}>
              <DetailsInput label="Total a pagar (R$)" value={formatNumberToBRL(details?.totalAmount)} />
            </Col>
          </Row>
          {BillPolicy.shouldShowBankCodeInDetails(details?.status) && (
            <>
              <Row>
                <Col lg={10} sm={12}>
                  <DetailsInput value={details?.bankCode} label="Linha digitável" ref={billCodeRef} />
                </Col>
                <Col lg={2} sm={12}>
                  <WrapperDiv height="100%" flexDirection="column" justifyContent="flex-end">
                    <Button
                      size="fit"
                      variant="tertiary"
                      onClick={() => {
                        if (billCodeRef.current) {
                          navigator.clipboard.writeText(billCodeRef.current.value);
                        }
                      }}
                    >
                      Copiar linha digitável
                    </Button>
                  </WrapperDiv>
                </Col>
              </Row>
              <Row>
                <Col lg sm={12}>
                  <Instructions>
                    <Label>Instruções</Label>
                    <InstructionsContent>
                      {details?.instructions.map(
                        (instruction) =>
                          instruction.description && (
                            <Fragment key={`InstructionsContent-${instruction.id}`}>
                              <CustomText>{instruction.description}</CustomText>
                              <br />
                            </Fragment>
                          )
                      )}
                    </InstructionsContent>
                  </Instructions>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </DetailsContainer>
      <Composition>
        <CustomText as="h2">Composição do boleto</CustomText>
        {hasComposition && (
          <Accordion.Root gap={16}>
            {details?.composition.map((composition) => (
              <InvoiceAccordion key={`${composition.id}-${composition.description}`} composition={composition} />
            ))}
          </Accordion.Root>
        )}
      </Composition>
    </>
  );
};

export default Details;
