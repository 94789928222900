import * as React from 'react';
import type { SVGProps } from 'react';

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="m16.969 7.583.374-.271h-6.369L9.149 1.695l-.143-.44-.143.44-1.859 5.718-.064.196H7.847l.033-.104 1.126-3.46 1.182 3.637.127.389.033.103h4.339l-3.092 2.246-.332.242-.088.064.034.104.126.388 1.182 3.636-3.091-2.249-.332-.24-.088-.062-.088.063-.332.239-3.092 2.249 1.182-3.636.127-.388.034-.104-.088-.064-.332-.242-3.092-2.246h1.88V7.312H.668l.374.271 4.777 3.471-1.825 5.617-.143.44.374-.272 4.78-3.471 4.777 3.471.374.271-.143-.439-1.825-5.617z"
    />
  </svg>
);
export default SvgStar;
