/* eslint @typescript-eslint/naming-convention: 0 */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { JwtToken } from '../../../../@types/auth';
import { useAuth } from '../../../../context/AuthContext';
import FullScreenPageLayout from '../../../../layouts/FullScreenPageLayout';
import { stringToObject } from '../../../../utils/helpers/string';

const PartnerLogin = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { id_token } = stringToObject(hash) as { id_token: JwtToken };
  const { signInPartner } = useAuth();
  const [isValidating, setIsValidating] = useState<Boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<Boolean>(false);

  const validateCollaboratorLoginRoute = () => {
    if (!hash || !id_token) {
      setIsValidating(false);
    } else {
      signInPartner(id_token)
        .then(() => {
          setIsAuthorized(true);
          setIsValidating(false);
        })
        .catch(() => {
          setIsValidating(false);
        });
    }
  };

  useEffect(() => {
    validateCollaboratorLoginRoute();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      navigate('/partner');
    } else {
      setTimeout(() => {
        navigate('/');
      }, 5 * 1000);
    }
  }, [isValidating]);

  const hasError = !isValidating && !isAuthorized;

  return hasError ? (
    <FullScreenPageLayout
      title="Ops...Acesso não validado"
      description="A autenticação via Microsoft falhou. Estamos redirecionando você para a tela de login. Por favor, tente novamente."
    />
  ) : (
    <FullScreenPageLayout
      title="Acessando o TKE Digital"
      description="Estamos validando o seu acesso. Aguarde para acessar..."
      loading
    />
  );
};

export default PartnerLogin;
