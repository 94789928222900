import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomText from '../../../../../../../../components/CustomText';
import { Form } from './style';
import Input from '../../../../../../../../components/Input';
import Button from '../../../../../../../../components/Button';
import { CredentialsContainer } from '../../../common/style';
import { REGEX_PASSWORD_PATTERN } from '../../../../../../../../utils/constants/regex';
import HookFeedbackError from '../../../../../../../../components/HookFeedbackError';
import { SubmitCodeContainer } from '../../../../../../style';
import AccountService from '../../../../../../../../services/Account/AccountService';
import { CustomErrorType, errorHandler } from '../../../../../../../../exceptions/errorHandler';
import AccountContext, { AccountContextProps } from '../../../../../../context';
import { PasswordFormStage } from '../../../../../../type';
import RequestForm from '../RequestForm';
import { ContactType } from '../../../../../../../../@types/auth';
import { EXCEPTIONS_MESSAGE, ExceptionSlug } from '../../../../../../../../utils/constants/exceptions';
import Alert from '../../../../../../../../components/Toast/toast';
import useAbortController from '../../../../../../../../hooks/useAbortController';
import PasswordActionContext, { PasswordActionContextProps } from '../../context';
import { PasswordErrorHelper, PasswordSecurityWarning } from '../../../common/PasswordHelper';

interface CredentialsData {
  oldPass: string;
  newPass: string;
  newPassConfirmation: string;
  confirmationCode: string;
}

const Credentials = () => {
  const {
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<CredentialsData>({
    mode: 'onChange',
    defaultValues: {
      oldPass: '',
      newPass: '',
      newPassConfirmation: '',
      confirmationCode: '',
    },
  });
  const [passwordConfirmationAlreadyValidate, setPasswordConfirmationAlreadyValidate] = useState(false);
  const [loadingConfirmationCode, setLoadingConfirmationCode] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { abort, getSignal } = useAbortController();

  const { closeAction } = useContext(AccountContext) as AccountContextProps;
  const { setStage } = useContext(PasswordActionContext) as PasswordActionContextProps;

  const watchNewPassword = watch('newPass');
  const watchNewPasswordConfirm = watch('newPassConfirmation');
  const watchConfirmationCode = watch('confirmationCode');

  const isValidForm =
    isValid && watchConfirmationCode.length === 6 && watchNewPassword.length === watchNewPasswordConfirm.length;

  const onError = (e: unknown) => {
    const handledError = errorHandler(e);
    if (handledError.type === CustomErrorType.AXIOS_ERROR) {
      const { firstSlug } = handledError;
      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_EXPIRED) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_EXPIRED,
        });
        return;
      }

      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_INVALID) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_INVALID,
        });
        return;
      }

      if (firstSlug === ExceptionSlug.PASSWORD_INCORRECT) {
        setError('oldPass', { type: 'value', message: 'A senha atual informada está incorreta.' });
        return;
      }

      closeAction();
      Alert.ERROR('Ocorreu um erro ao tentar trocar sua senha. Por favor, tente novamente em alguns instantes.');
    }

    if (handledError.type === CustomErrorType.STOCK_ERROR) {
      console.error(e);
    }
  };

  const requestConfirmationCode = async (data: { contactType: ContactType }) => {
    setLoadingConfirmationCode(true);

    abort();
    const signal = getSignal();

    try {
      await AccountService.getConfirmationCode(data.contactType, signal);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingConfirmationCode(false);
    }
  };

  const onSubmit = async (data: CredentialsData) => {
    if (data.oldPass === data.newPass) {
      setError('newPass', { type: 'newPassSameAsCurrent' });
      return;
    }

    setLoadingSubmit(true);
    abort();
    const signal = getSignal();

    try {
      await AccountService.changePassword(
        {
          confirmationCode: data.confirmationCode,
          oldPassword: data.oldPass,
          newPassword: data.newPass,
        },
        signal
      );
      setStage(PasswordFormStage.FEEDBACK);
    } catch (e) {
      onError(e);
    } finally {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (watchNewPassword !== watchNewPasswordConfirm && watchNewPasswordConfirm.length) {
      setError('newPassConfirmation', { type: 'validate', message: 'Senhas não coincidem.' });
    }

    if (watchNewPassword === watchNewPasswordConfirm && watchNewPasswordConfirm.length) {
      clearErrors('newPassConfirmation');
    }
  }, [watchNewPassword]);

  return (
    <CredentialsContainer>
      <RequestForm onSubmit={requestConfirmationCode} loading={loadingConfirmationCode} />
      <SubmitCodeContainer>
        <CustomText as="p">Digite o código recebido por e-mail ou SMS e cadastre uma nova senha.</CustomText>
        <Controller
          name="confirmationCode"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Form
              {...field}
              label="Código"
              placeholder="000000"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              error={Boolean(error)}
              maxLength={6}
              autoComplete="chrome-off"
            />
          )}
        />
      </SubmitCodeContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CustomText as="p" className="title">
          Digite sua senha atual, nova senha e confirme a nova senha.
        </CustomText>
        <Controller
          name="oldPass"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Password
              {...field}
              placeholder="Digite sua senha atual"
              label="Senha atual"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              error={Boolean(error)}
              autoComplete="chrome-off"
            />
          )}
        />
        <Controller
          name="newPass"
          rules={{
            required: 'Campo obrigatório',
            pattern: REGEX_PASSWORD_PATTERN,
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Password
              {...field}
              placeholder="Digite sua nova senha"
              label="Nova senha"
              helper={error ? <PasswordErrorHelper error={error} /> : <PasswordSecurityWarning />}
              error={Boolean(error)}
              autoComplete="chrome-off"
            />
          )}
        />
        <Controller
          name="newPassConfirmation"
          rules={{
            required: 'Campo obrigatório',
            validate: (passwordConfirmation) => {
              if (passwordConfirmation.length < watchNewPassword.length && !passwordConfirmationAlreadyValidate) {
                return true;
              }

              if (passwordConfirmation === watchNewPassword) {
                return true;
              }

              setPasswordConfirmationAlreadyValidate(true);
              return 'Senhas não coincidem.';
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Password
              {...field}
              label="Confirma a nova senha"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              placeholder="Digite novamente sua nova senha"
              error={Boolean(error)}
              autoComplete="chrome-off"
            />
          )}
        />
        <Button disabled={!isValidForm || loadingSubmit} loading={loadingSubmit} variant="primary" type="submit">
          Alterar senha
        </Button>
      </Form>
    </CredentialsContainer>
  );
};

export default Credentials;
