import React, { ReactNode, useState } from 'react';
import CustomText from '../../../CustomText';
import UnstyledButtonWithFloating, { UnstyledButtonWithFloatingProps } from '../UnstyledButtonWithFloating';
import { Container, MobileTooltipButton } from './style';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import AsideHeader from '../../../AsideHeader';
import AsidePortal from '../../../../layouts/AsidePortal';
import { ChevronArrowIcon } from '../../../../icons';

interface TextIconDropdownProps extends Omit<UnstyledButtonWithFloatingProps, 'content'> {
  label: string;
  icon: JSX.Element;
}

interface MobileAsideTextIconContentProps {
  disabled?: boolean;
  btnContent: ReactNode;
  tooltipContent: ReactNode;
}

const MobileAsideTextIconContent = ({ disabled, btnContent, tooltipContent }: MobileAsideTextIconContentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MobileTooltipButton
        className={isOpen ? 'tooltip_button tooltip_button_open' : 'tooltip_button'}
        disabled={disabled}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {btnContent}
      </MobileTooltipButton>
      <AsidePortal visible={isOpen}>
        <AsideHeader
          onBack={() => {
            setIsOpen(false);
          }}
        />
        {tooltipContent}
      </AsidePortal>
    </>
  );
};

const TextIconDropdown = ({ disabled, label, icon, floating }: TextIconDropdownProps) => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Container disabled={disabled}>
      {isMobileWidth ? (
        <MobileAsideTextIconContent
          disabled={disabled}
          tooltipContent={floating}
          btnContent={
            <>
              {icon}
              <CustomText>{label}</CustomText>
              <ChevronArrowIcon rotate="90deg" />
            </>
          }
        />
      ) : (
        <UnstyledButtonWithFloating
          disabled={disabled}
          content={
            <>
              {icon}
              <CustomText>{label}</CustomText>
              <ChevronArrowIcon style={{ transform: 'rotate(90deg)' }} />
            </>
          }
          floating={floating}
        />
      )}
    </Container>
  );
};

export default TextIconDropdown;
