import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

interface IconProps {
  cursor?: 'initial' | 'pointer';
  variant?: 'small' | 'normal';
}

interface StyledInputProps {
  readOnly?: boolean;
  state?: 'error' | 'warning' | 'success';
  disabled?: boolean;
  error?: boolean;
}

const LABEL_DISABLED_STYLE = css`
  color: ${theme.color.secondary.grey[4]};
  ${theme.defaults.disabled}
`;

const INPUT_READONLY_STYLE = css`
  background: #f6f6f6;
  border: 1px solid #434343;

  ${theme.defaults.disabled}
`;

const INPUT_DISABLED_STYLE = css`
  border: 1px solid ${theme.color.secondary.grey[4]} !important;
  color: ${theme.color.secondary.grey[4]} !important;

  & > input::placeholder {
    color: ${theme.color.secondary.grey[4]} !important;
  }

  ${theme.defaults.disabled};
`;

const feedbackColorMapping = {
  error: theme.color.feedback.error,
  warning: theme.color.feedback.alert,
  success: theme.color.feedback.success,
};

const INPUT_FEEDBACK_STYLE = css<StyledInputProps>`
  border: 1px solid ${({ state }) => state && feedbackColorMapping[state]} !important;
  color: ${({ state }) => state && feedbackColorMapping[state]} !important;
`;

const INPUT_MOUSE_EVENTS_STYLE = css`
  &:hover {
    border: 1px solid ${theme.color.primary.interaction};
    color: ${theme.color.primary.interaction};
  }

  &:focus-within {
    border: 1px solid ${theme.color.primary.interaction};
    color: ${theme.color.primary.interaction};
  }
`;

export const InputControl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const StyledInput = styled.input<{ variant?: 'small' | 'normal' }>`
  font-family: 'TKEType-Regular', sans-serif;
  font-size: 1.33rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 14px 12px;
  width: 100%;
  border: none;
  background: inherit;
  outline: none;

  ::placeholder {
    color: ${theme.color.secondary.grey[3]};
  }

  ${({ variant }) =>
    variant === 'small' &&
    css`
      height: 34px;
      padding: 9.5px 12px;
    `}
`;

export const Icon = styled.div<IconProps>`
  display: flex;
  padding-right: 12px;
  margin: auto 0;
`;

export const InputBox = styled.div<StyledInputProps>`
  display: flex;
  border: 1px solid #434343;
  background: #ffffff;
  transition: background-color 0.2s;
  ${INPUT_MOUSE_EVENTS_STYLE};
  ${({ state }) => state && INPUT_FEEDBACK_STYLE};
  ${({ disabled }) => disabled && INPUT_DISABLED_STYLE};
  ${({ readOnly }) => readOnly && INPUT_READONLY_STYLE};
`;

export const Label = styled.label<StyledInputProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: 'TKEMono-Regular', sans-serif;

  ${({ error }) =>
    error &&
    css`
      color: ${theme.color.feedback.error};
    `};
  ${({ disabled }) => disabled && LABEL_DISABLED_STYLE};
`;

export const Helper = styled.div<{ disabled?: boolean }>`
  min-height: 12px;
  ${({ disabled }) =>
    disabled &&
    css`
      & > * {
        color: ${theme.color.secondary.grey[4]} !important;
      }
    `};
`;
