import { keyframes } from 'styled-components';

export const slideToRight = keyframes`
  from {left: -100%;}
  to {left: 0;}
`;

export const slideToLeft = keyframes`
  from {left: 0;}
  to {left: -100%;}
`;

export const fadeIn = keyframes`
  from {  background-color: transparent;
  }
  to {  background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const fadeOut = keyframes`
  from {  background-color: rgba(0, 0, 0, 0.7);
  }
  to {  background-color: transparent;
  }
`;
