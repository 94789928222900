import { useEffect, useRef, useState } from 'react';

const useAnimatedUnmount = <T extends HTMLElement>(visible: boolean, onAfterClose?: VoidFunction) => {
  const [shouldRender, setShouldRender] = useState<boolean>();

  const ref = useRef<T>(null);

  useEffect(() => {
    if (visible) {
      setShouldRender(true);
    }

    const handleAnimationEnd = () => {
      setShouldRender(false);
      onAfterClose?.();
    };

    const elementRef = ref.current;

    if (!visible && elementRef) {
      elementRef.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (elementRef) {
        elementRef.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [visible]);

  return {
    shouldRender,
    ref,
  };
};

export default useAnimatedUnmount;
