import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;

  .title {
    font-family: 'TKEType-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
  }

  .mobile_title {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    color: #262626;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
