import { useState } from 'react';
import { ActionsType } from '../type';

const useActions = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionsType | undefined>();

  const closeAction = () => {
    setOpen(false);
  };

  const openAction = (newType: ActionsType) => {
    setOpen(true);
    setActionType(newType);
  };

  return { open, type: actionType, closeAction, openAction };
};

export default useActions;
