import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const ErrorItemLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  color: ${theme.color.feedback.error};
`;

export const ErrorItemFloating = styled.div`
  display: flex;
  width: 530px;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border: 1px solid ${theme.color.secondary.grey[2]};
  background: #fff;
  box-shadow: 0 0 14px 0 ${theme.color.secondary.grey[5]};
`;
