import React, { useMemo } from 'react';
import { BillStatus } from '../../../@types/bill';
import Callout from '../../../components/Callout';
import CustomText from '../../../components/CustomText';
import LoadingContainer from '../../../layouts/LoadingContainer';
import PageLayout from '../../../layouts/PageLayout';
import FinancialMapper from '../../../strategy/mappers/FinancialMapper';
import BillDownloadModal from '../shared/components/BillDownloadModal';
import BillSendEmailContent from '../shared/components/BillSendEmailContent';
import SendEmailAction from '../shared/components/SendEmail/SendEmailAction';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import Details from './containers/Details';
import Historic from './containers/Historic';
import FinancialDetailsContext from './context';
import useFinancialDetails from './useFinancialDetails';

const FinancialDetails = () => {
  const {
    id,
    details,
    toggleHistoric,
    historicIsOpen,
    backToPanel,
    openHistoric,
    closeHistoric,
    handleBillDownload,
    handleOpenBillDocument,
    handleOpenInvoiceDocument,
    handleSendBillByEmail,
    loading,
    billDownloadIsOpen,
    closeBillDownload,
    billSendEmailIsOpen,
    closeBillSendEmail,
    openBillSendEmail,
    isMobileWidth,
    sendEmailIsLoading,
    handleInvoiceDownload,
  } = useFinancialDetails();

  const context = useMemo(
    () => ({
      id,
      details,
      toggleHistoric,
      historicIsOpen,
      backToPanel,
      openHistoric,
      closeHistoric,
      handleBillDownload,
      handleOpenBillDocument,
      handleOpenInvoiceDocument,
      openBillSendEmail,
      handleInvoiceDownload,
    }),
    [id, details, historicIsOpen]
  );

  const renderContent = Boolean(details) && !loading;
  const showAdvise = Boolean(details) && details?.status === BillStatus.EXPIRED;

  return (
    <FinancialDetailsContext.Provider value={context}>
      <PageLayout style={{ justifyContent: 'unset' }}>
        {loading && (
          <LoadingContainer
            title="Abrindo a visualização detalhada do boleto."
            back={{
              label: 'Voltar para lista',
              handler: backToPanel,
            }}
          />
        )}
        {renderContent && (
          <>
            {details && (
              <BillDownloadModal
                data={[FinancialMapper.detailsToMinimalData(details, id)]}
                visible={billDownloadIsOpen}
                onClose={closeBillDownload}
              />
            )}
            <SendEmailAction
              loading={sendEmailIsLoading}
              visible={billSendEmailIsOpen}
              onClose={closeBillSendEmail}
              onSubmit={({ customEmail }) => {
                handleSendBillByEmail(customEmail || null);
              }}
              title="Enviar boleto por e-mail."
              submitButtonLabel="Enviar boleto"
              selectLabel="Escolha a melhor forma para lhe enviarmos o boleto e as notas fiscais:"
              description={
                <BillSendEmailContent
                  documentNumber={details?.documentNumber}
                  expiresAt={details?.expiresAt}
                  totalAmount={details?.totalAmount}
                />
              }
            />
            {showAdvise && (
              <Callout fit>
                <CustomText>
                  <CustomText as="span" bold>
                    Importante:
                  </CustomText>{' '}
                  Para gerar uma segunda via do boleto favor entrar em contato pelo chat.
                </CustomText>
              </Callout>
            )}
            {isMobileWidth ? <MobileHeader /> : <DesktopHeader />}
            {historicIsOpen ? <Historic /> : <Details />}
          </>
        )}
      </PageLayout>
    </FinancialDetailsContext.Provider>
  );
};

export default FinancialDetails;
