import * as React from 'react';
import type { SVGProps } from 'react';

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={14} viewBox="0 0 17 14" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M.454 12.953v.15h16.018V.453h-1.114l-.04.031-5.544 4.289a2.13 2.13 0 0 1-1.311.451h-.001a2.095 2.095 0 0 1-1.273-.421L7.186 4.8 2.332 1.314h7.684V.453H.454v12.5ZM15.61 1.352v10.89H1.315V1.595L6.673 5.49a2.985 2.985 0 0 0 3.634-.043l5.303-4.095Z"
    />
  </svg>
);
export default SvgEmail;
