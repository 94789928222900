import React, { forwardRef, Ref } from 'react';
import { AlignContainer, GroupContainer, GroupTitle } from '../../style';
import RadioComponent from '../index';
import { OptionValue, SelectableGroupProps } from '../../types';
import { RadioContainer } from './style';

interface RadioGroupProps<T extends OptionValue> extends SelectableGroupProps<T> {
  selectedOption?: T | null;
  onChange: (value: T) => void;
  name: string;
}

const Component = <T extends OptionValue>(
  { align, options, title, disabled, selectedOption, onChange, name, ...props }: RadioGroupProps<T>,
  ref: Ref<HTMLDivElement>
) => {
  const handleChange = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.target as unknown as { value: T };
    return onChange(value);
  };

  return (
    <GroupContainer ref={ref}>
      {title && <GroupTitle>{title}</GroupTitle>}
      <AlignContainer className="Align_Container" align={align}>
        {options.map(({ label, option, readOnly }) => {
          const checked = String(selectedOption) === String(option);

          return (
            <RadioContainer key={`RadioContainer-${name}${label}-${option}`}>
              <RadioComponent
                key={`RadioGroup-${label}-${option}`}
                label={label}
                name={name}
                value={option}
                checked={checked}
                handleChange={handleChange}
                disabled={disabled || readOnly}
                id={`id-${name}${label}-${option}`}
                {...props}
              />
            </RadioContainer>
          );
        })}
      </AlignContainer>
    </GroupContainer>
  );
};

const RadioGroup = forwardRef(Component) as <T extends OptionValue>(
  props: RadioGroupProps<T> & { ref?: Ref<HTMLDivElement> }
) => JSX.Element;

export default RadioGroup;
