import React, { FunctionComponent, Suspense, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Container } from './style';
import Background from './components/Background';
import Form from './components/Form';
import { FormStage } from './components/Form/type';
import RegisterContext from './context';
import { RegisterPayload, RegisterStatus } from './type';
import { ConfirmationToken, ContactType, MaskedUserData } from '../../../../@types/auth';
import { CustomErrorType, errorHandler } from '../../../../exceptions/errorHandler';
import Alert from '../../../../components/Toast/toast';
import AuthService from '../../../../services/Auth/AuthService';
import SkeletonRegister from '../../../../layouts/Skeleton/components/Register';
import useAbortController from '../../../../hooks/useAbortController';
import { EXCEPTIONS_MESSAGE } from '../../../../utils/constants/exceptions';

const Register: FunctionComponent = () => {
  const [stage, setStage] = useState<FormStage>(FormStage.CREDENTIALS);
  const [selectedContactType, setSelectedContactType] = useState<ContactType | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [confirmationToken, setConfirmationToken] = useState<ConfirmationToken | null>(null);
  const [user, setUser] = useState<MaskedUserData | null>(null);
  const [registerStatus, setRegisterStatus] = useState<RegisterStatus>(RegisterStatus.NEW_USER);
  const { abort, getSignal } = useAbortController();

  const methods = useForm<RegisterPayload>({
    defaultValues: {
      login: '',
      name: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onChange',
  });

  const handleContactTypeChange = (contactType: ContactType) => {
    setSelectedContactType(contactType);
  };

  const resetForm = () => {
    methods.reset();
    methods.clearErrors();
    setStage(FormStage.CREDENTIALS);
  };

  const requestCode = async (callback?: VoidFunction) => {
    if (!confirmationToken || !selectedContactType) return;

    try {
      abort();
      const signal = getSignal();
      await AuthService.requestCodeService(confirmationToken, selectedContactType, signal);
      setStage(FormStage.SUBMIT_CODE);
      callback?.();
    } catch (e) {
      const handledError = errorHandler(e);
      if (handledError.type === CustomErrorType.AXIOS_ERROR) {
        const { status } = handledError;

        if (status === 401 || status === 403) {
          Alert.WARNING(EXCEPTIONS_MESSAGE.LOGIN_EXPIRED);
          resetForm();
          return;
        }

        if (status === 500) {
          Alert.ERROR(EXCEPTIONS_MESSAGE.HTTP_500);
          resetForm();
          return;
        }

        Alert.ERROR('Ocorreu um erro ao solicitar seu código, tente novamente!');
      }

      console.error(e);
    }
  };

  const context = useMemo(
    () => ({
      stage,
      setStage,
      captchaToken,
      setCaptchaToken,
      registerStatus,
      setRegisterStatus,
      confirmationToken,
      setConfirmationToken,
      user,
      setUser,
      requestCode,
      handleContactTypeChange,
      resetForm,
    }),
    [stage, captchaToken, registerStatus, confirmationToken, selectedContactType]
  );

  return (
    <FormProvider {...methods}>
      <RegisterContext.Provider value={context}>
        <Container>
          <Suspense fallback={<SkeletonRegister />}>
            <Background />
            <Form stage={stage} />
          </Suspense>
        </Container>
      </RegisterContext.Provider>
    </FormProvider>
  );
};

export default Register;
