/* eslint-disable no-storage/no-browser-storage */
export enum LocalStorageKey {
  TOKEN = '@auth:token',
  REFRESH_TOKEN = '@auth:refresh_token',
  USER = '@auth:user',
  ALREADY_NOTICED_FAVORITE_MESSAGE = '@system:favorite_message',
}

const saveToLocalStorage = (key: LocalStorageKey, data: string) => localStorage.setItem(key, data);
const getFromLocalStorage = (key: LocalStorageKey): string | null => localStorage.getItem(key);
const removeFromLocalStorage = (key: LocalStorageKey) => localStorage.removeItem(key);
const clearLocalStorage = () => localStorage.clear();

export { saveToLocalStorage, getFromLocalStorage, removeFromLocalStorage, clearLocalStorage };
