import React, { useMemo } from 'react';
import useBuilding from './useBuilding';
import AsideActions from './components/AsideActions';
import BuildingContext from './context';
import DesktopActions from './components/DesktopActions';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import BuildingContent from './components/BuildingContent';

const BuildingContainer = () => {
  const {
    currentPage,
    getPaginationProps,
    unlinkNewContract,
    linkNewContract,
    surveyedContractIsAlreadyOnList,
    fetchBuildingsData,
    fetchNewContract,
    layout,
    toggleLayout,
    loading,
    searchModalIsOpen,
    openSearchAction,
    closeSearchModal,
    clearSearch,
    showGrid,
    requestSearch,
    requestSort,
    order,
    search,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    handleEditItem,
    favoriteBuilding,
    handleOrder,
    filterIsPending,
    filterIsEmpty,
    dataIsEmpty,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
  } = useBuilding();

  const context = useMemo(
    () => ({
      data: currentPage,
      unlinkNewContract,
      linkNewContract,
      surveyedContractIsAlreadyOnList,
      fetchNewContract,
      fetchBuildingsData,
      requestSearch,
      requestSort,
      order,
      search,
      handleEditItem,
      favoriteBuilding,
      currentPage,
      loading,
      clearSearch,
      layout,
      toggleLayout,
      handleSearch,
      handleSearchKeys,
      handleOrder,
      showGrid,
      searchKeys,
      filterIsPending,
      filterIsEmpty,
      searchModalIsOpen,
      dataIsEmpty,
      closeSearchModal,
      asideActionsIsOpen,
      asideStage,
      setAsideStage,
      openAsideActions,
      closeAsideActions,
      getPaginationProps,
      openSearchAction,
    }),
    [
      showGrid,
      order,
      search,
      searchKeys,
      surveyedContractIsAlreadyOnList,
      currentPage,
      loading,
      filterIsPending,
      filterIsEmpty,
      dataIsEmpty,
      searchModalIsOpen,
      asideActionsIsOpen,
      asideStage,
    ]
  );

  const { isMobileWidth } = useWindowDimensions();

  return (
    <BuildingContext.Provider value={context}>
      {isMobileWidth ? <AsideActions /> : <DesktopActions />}
      <BuildingContent />
    </BuildingContext.Provider>
  );
};

export default BuildingContainer;
