import { ContractType } from './building';

export enum BillStatus {
  NOT_GENERATED = 0,
  PENDING = 1,
  PAID = 2,
  OVERDUE = 3,
  EXPIRED = 4,
  CANCELED = 5,
}

export enum DisabledBillStatus {
  NOT_GENERATED = 0,
  PAID = 2,
  EXPIRED = 4,
}

export interface BillMinimalComposition {
  id: string;
  invoiceNumber: string;
}

export interface BillMinimalData {
  id: string;
  clientName: string;
  clientDocument: string;
  contract: string;
  documentNumber: string;
  expiresAt: string;
  totalAmount: number;
  status: Exclude<BillStatus, BillStatus.CANCELED>;
  composition: Array<BillMinimalComposition>;
  hasFullBillAvailable: boolean;
}

export type DownloadData = Exclude<BillMinimalData, 'totalAmount' | 'status'>;

export type BillOrderBy =
  | 'clientName'
  | 'clientDocument'
  | 'contract'
  | 'documentNumber'
  | 'expiresAt'
  | 'totalAmount'
  | 'status';

export interface BillOrder {
  orderBy: BillOrderBy;
  direction: 'asc' | 'desc';
}

export type BillSearchBy =
  | 'clientName'
  | 'clientDocument'
  | 'contract'
  | 'documentNumber'
  | 'expiresAt'
  | 'totalAmount'
  | 'status'
  | 'invoice';

export const ALL_SEARCH_KEYS: Array<BillSearchBy> = [
  'clientName',
  'clientDocument',
  'contract',
  'documentNumber',
  'expiresAt',
  'totalAmount',
  'status',
  'invoice',
];

export enum BillAsideStage {
  SELECT_ACTION = 'SELECT_ACTION',
  FILTER_ACTION = 'FILTER_ACTION',
  SELECT_DATE_ACTION = 'SELECT_DATE_ACTION',
  SEARCH_NEW_BILL = 'SEARCH_NEW_BILL',
}

export enum HistoricOperation {
  VIEW = 1,
  DOWNLOAD = 2,
  SEND_EMAIL = 3,
  NEW_NEGOTIATION = 4,
}

export enum HistoricUserType {
  UNDEFINED = 0,
  CUSTOMER = 1,
  COLLABORATOR = 2,
  ADMINISTRATOR = 3,
  EXTERNAL = 4,
}

export interface FinancialHistoricData {
  id: string;
  userName?: string;
  userType: HistoricUserType;
  information: string;
  createdAt: string;
  type: HistoricOperation;
}

export type FinancialHistoricSearchBy = 'userName' | 'date' | 'hour' | 'type' | 'userType' | 'information';

export const FINANCIAL_HISTORIC_ALL_SEARCH_KEYS: Array<FinancialHistoricSearchBy> = [
  'userName',
  'date',
  'hour',
  'userType',
  'type',
  'information',
];

export type FinancialHistoricOrderBy = 'userName' | 'date' | 'hour' | 'type' | 'userType' | 'information';

export interface FinancialHistoricOrder {
  orderBy: FinancialHistoricOrderBy;
  direction: 'asc' | 'desc';
}

export enum FinancialHistoricStage {
  SELECT_ACTION = 'SELECT_ACTION',
  FILTER_ACTION = 'FILTER_ACTION',
}

export interface BillComposition {
  id: string;
  invoiceNumber: string;
  description: string; // Título do accordion
  paymentAmount: number; // Valor
  totalAmount: number;
  installment?: null; // 3/8 -> parcela/total
  title: string;
  emittedAt: string;
  hasInvoice: boolean;
}

export interface BillDetails {
  id: string;
  status: BillMinimalData['status'];
  contract: string;
  contractType: ContractType;
  documentNumber: string;
  client: {
    name: string;
    document: string;
  };
  billNumber: string;
  bankCode: string; // Linha digitável
  barCode: string;
  exercise: number;
  amount: number; // Total
  totalAmount: number; // Total a pagar
  discountAmount: number; // Desconto
  lateInterestAmount: number; // Mora
  fineAmount: number; // Multa
  deductionsAmount: number;
  additionsAmount: number;
  documentAmount: number;
  taxAmount: number; // Juros
  documentAt: string;
  emittedAt: string;
  processedAt: string;
  expiresAt: string; // Vencimento prorrogado
  originalExpiresAt: string; // Vencimento original
  instructions: Array<{
    id: number;
    description: string;
  }>;
  composition: Array<BillComposition>;
  hasFullBillAvailable: boolean;
}

export enum BillDataType {
  JSON = 0,
  CSV = 1,
}
