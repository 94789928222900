import React, { useState } from 'react';
import CustomText from '../CustomText';
import { TabsButton } from './style';
import AsidePortal from '../../layouts/AsidePortal';
import AsideTabs from '../../layouts/AsideTabs';
import { Tab } from '../../@types/dashboard';
import { ChevronArrowIcon } from '../../icons';

interface MobileTabsProps<T extends string> {
  title: string;
  tabs: Array<Tab<T>>;
  onChange: (value: T) => void;
}

const MobileTabs = <T extends string>({ title, tabs, onChange }: MobileTabsProps<T>) => {
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const changeTab = (newTab: T) => {
    onChange(newTab);
    closeHandler();
  };

  return (
    <>
      <AsidePortal visible={open}>
        <AsideTabs onChange={changeTab} onClose={closeHandler} actions={tabs} />
      </AsidePortal>
      <TabsButton onClick={openHandler}>
        <CustomText>{title}</CustomText>
        <ChevronArrowIcon />
      </TabsButton>
    </>
  );
};
export default MobileTabs;
