import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../../../utils/constants/breakpoints';

export const CredentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    button {
      width: 100%;
    }
  }
`;
