import styled from 'styled-components';
import theme from '../../styles/theme';

export const UserIcon = styled.div<{ isPartnerUser: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${({ isPartnerUser }) =>
    isPartnerUser ? theme.color.primary.interaction : theme.color.primary.highlight};
  cursor: pointer;

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 3px rgba(38, 35, 33, 0.14);
`;
