import { Key, useState } from 'react';
import { TableConfig } from '../components/TableView/types';

type GetTableRowSelectionProps<T extends object> = {
  selectedData: Array<string>;
  handleSelectAll: VoidFunction;
  handleSelect: (item: T) => void;
  selectedDataHasElement: (item: T) => boolean;
  allDataAreSelected: boolean;
  withSelection: boolean;
  disableSelectionPerRowWhen: TableConfig<T>['disableSelectionPerRowWhen'];
};

export type GetSelectionPropsFunc<T extends object> = () => GetTableRowSelectionProps<T>;

const useDataSelection = <T extends object, K extends Key>(
  data: T[],
  keyExtractor: (item: T) => K,
  disableSelectionPerRowWhen?: TableConfig<T>['disableSelectionPerRowWhen']
) => {
  const [selectedData, setSelectedData] = useState<K[]>([]);
  const selectableData = disableSelectionPerRowWhen ? data.filter((item) => !disableSelectionPerRowWhen(item)) : data;
  const dataListAreFilled = selectableData?.length > 0;
  const allDataAreSelected = dataListAreFilled && selectedData.length === selectableData?.length;
  const areElementsSelected = selectedData.length >= 1;

  const selectedDataHasElement = (item: T) => selectedData.includes(keyExtractor(item));

  const handleSelectAll = () => {
    if (allDataAreSelected) {
      setSelectedData([]);
      return;
    }

    if (disableSelectionPerRowWhen) {
      setSelectedData(selectableData.map(keyExtractor));
    } else {
      setSelectedData(data.map(keyExtractor));
    }
  };

  const handleSelect = (item: T) => {
    setSelectedData((prevSelectedData) => {
      const newSelectedData = prevSelectedData.slice();
      const key = keyExtractor(item);
      const selectedIndex = newSelectedData.indexOf(key);

      if (selectedIndex !== -1) {
        newSelectedData.splice(selectedIndex, 1);
      } else {
        newSelectedData.push(key);
      }

      return newSelectedData;
    });
  };

  return {
    areElementsSelected,
    handleSelectAll,
    handleSelect,
    selectedData,
    selectedDataHasElement,
    allDataAreSelected,
    getSelectionProps: () => ({
      selectedData,
      handleSelectAll,
      handleSelect,
      selectedDataHasElement,
      allDataAreSelected,
      withSelection: true,
      disableSelectionPerRowWhen,
    }),
  };
};

export default useDataSelection;
