import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import React from 'react';
import { Form } from './style';
import { LinkContract } from '../../../../../@types/building';
import Input from '../../../../../components/Input';
import HookFeedbackError from '../../../../../components/HookFeedbackError';
import Button from '../../../../../components/Button';
import { isCPFOrCNPJ } from '../../../../../utils/helpers/validate';
import { maskCPForCNPJ } from '../../../../../utils/helpers/mask';
import { REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS } from '../../../../../utils/constants/regex';
import { SearchIcon } from '../../../../../icons';

type FindContractFormProps =
  | {
      onSubmit?: SubmitHandler<LinkContract>;
      loading?: boolean;
      mobile: true;
    }
  | {
      onSubmit: SubmitHandler<LinkContract>;
      loading?: boolean;
      mobile?: false;
    };

const FindContractForm: React.FC<FindContractFormProps> = ({ onSubmit, loading, mobile = false }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useFormContext<LinkContract>();

  const watchDocument = watch('document');
  const formIsValid = isValid && isCPFOrCNPJ(watchDocument);
  const buttonVariant = mobile ? 'normal' : 'small';

  return (
    <Form className="find_contract_form">
      <Controller
        name="document"
        rules={{
          required: 'Campo obrigatório',
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input.BaseNumberMasked
            {...field}
            inputMode="numeric"
            customInput={Input.Form}
            variant={buttonVariant}
            type="text"
            label={mobile ? undefined : 'CPF ou CNPJ'}
            placeholder="Buscar CPF ou CNPJ"
            helper={<HookFeedbackError errors={errors} name={field.name} />}
            error={Boolean(error)}
            format={maskCPForCNPJ}
            {...field}
          />
        )}
      />
      <Controller
        name="contract"
        rules={{
          required: 'Campo obrigatório',
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input.Regex
            {...field}
            handleChange={field.onChange}
            allowCharacters={REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS}
            variant={buttonVariant}
            type="text"
            label={mobile ? undefined : 'Número do contrato'}
            placeholder="Buscar número do contrato"
            helper={<HookFeedbackError errors={errors} name={field.name} />}
            error={Boolean(error)}
          />
        )}
      />
      {onSubmit && (
        <Button disabled={loading || !formIsValid} variant="primary" size="small" onClick={handleSubmit(onSubmit)}>
          Buscar
          <SearchIcon />
        </Button>
      )}
    </Form>
  );
};

export default FindContractForm;
