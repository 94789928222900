import React, { memo, PropsWithChildren } from 'react';
import { StyledCardCell, StyledCardData, StyledCardLabel, StyledCardRoot, StyledCardRow } from './style';

type DivProps = PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const CardCell = ({ children, ...props }: DivProps) => <StyledCardCell {...props}>{children}</StyledCardCell>;

const CardRow = ({ children, ...props }: DivProps) => <StyledCardRow {...props}>{children}</StyledCardRow>;

const CardRoot = ({ children, height, ...props }: DivProps & { height?: number }) => (
  <StyledCardRoot height={height} {...props}>
    {children}
  </StyledCardRoot>
);

export default {
  Root: memo(CardRoot),
  Row: CardRow,
  Cell: CardCell,
  Label: StyledCardLabel,
  Data: StyledCardData,
};
