import React, { ReactNode, SyntheticEvent } from 'react';
import { Container, StackActionButton } from './style';
import generateUUID from '../../utils/helpers/uuid';

interface ModularStackButton {
  handler: VoidFunction;
  render?: ReactNode;
  disabled?: boolean;
  className?: string;
}

interface ButtonStackProps {
  stack: Array<ModularStackButton>;
}

const stopPropagationEvent = <T extends SyntheticEvent>(event: T, cb: () => void | undefined) => {
  event.stopPropagation();
  cb();
};

const ButtonStack = ({ stack }: ButtonStackProps) => (
  <Container>
    {stack.map(({ handler, render, disabled }) => (
      <StackActionButton
        onClick={(e) => stopPropagationEvent(e, handler)}
        disabled={disabled}
        key={`StackButton-${generateUUID()}`}
      >
        {render}
      </StackActionButton>
    ))}
  </Container>
);

export default ButtonStack;
