import { useCallback, useEffect, useRef } from 'react';

const useAbortController = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  useEffect(() => () => getAbortController().abort(), [getAbortController]);

  const abort = useCallback(() => {
    getAbortController().abort();
    abortControllerRef.current = new AbortController();
  }, [getAbortController]);

  const getSignal = useCallback(() => getAbortController().signal, [getAbortController]);

  return { getSignal, abort };
};

export default useAbortController;
