import { createContext } from 'react';
import { FormStage } from './components/Form/type';
import { ConfirmationToken, ContactType, MaskedUserData } from '../../../../@types/auth';
import { RegisterStatus } from './type';

export interface RegisterContextProps {
  stage: FormStage;
  setStage: SetState<FormStage>;
  captchaToken: string | null;
  setCaptchaToken: SetState<string | null>;
  confirmationToken: ConfirmationToken | null;
  setConfirmationToken: SetState<ConfirmationToken | null>;
  registerStatus: RegisterStatus;
  setRegisterStatus: SetState<RegisterStatus>;
  user: MaskedUserData | null;
  setUser: SetState<MaskedUserData | null>;
  requestCode: (callback?: VoidFunction) => Promise<void>;
  handleContactTypeChange: (contactType: ContactType) => void;
  resetForm: VoidFunction;
}

const RegisterContext = createContext<RegisterContextProps | null>(null);

export default RegisterContext;
