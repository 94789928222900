import React, { FunctionComponent } from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo_without_label.svg';
import CustomText from '../../components/CustomText';
import Button from '../../components/Button';
import { Container, Content } from './style';

interface LoadingPageLayoutProps {
  title: string;
  back?: {
    label: string;
    handler: VoidFunction;
  };
}

const LoadingContainer: FunctionComponent<LoadingPageLayoutProps> = ({ title, back }) => (
  <Container>
    <Logo />
    <Content>
      <CustomText as="h1">{title}</CustomText>
      <CustomText as="p">Aguarde...</CustomText>
      {back && (
        <Button variant="secondary" onClick={back.handler}>
          {back.label}
        </Button>
      )}
    </Content>
  </Container>
);

export default LoadingContainer;
