import React from 'react';
import styled, { css } from 'styled-components';

type TextProps = {
  children: Children;
};

interface StylingTextProps {
  bold?: boolean;
  medium?: boolean;
  crop?: boolean;
  fontSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';
  hoverColor?: string;
}

export type TextOwnProps<E extends React.ElementType> = {
  as?: E;
} & TextProps &
  StylingTextProps;

const mapFontSize = {
  xs: '1.2rem',
  sm: '1.4rem',
  base: '1.6rem',
  lg: '1.8rem',
  xl: '2rem',
  '2xl': '2.4rem',
  '3xl': '3.2rem',
};

export const StyledText = styled.p<StylingTextProps>`
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? mapFontSize[fontSize] : mapFontSize.base)};
  color: inherit;
  line-height: ${({ crop }) => (crop ? 0.8 : 1.2)};
  letter-spacing: normal;
  word-spacing: normal;

  ${({ medium }) =>
    medium &&
    css`
      font-family: 'TKEType-Medium', sans-serif;
      font-weight: 500;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-family: 'TKEType-Bold', sans-serif;
      font-weight: bold;
    `}

  color: ${({ color }) => color || 'inherit'};

  &:hover {
    ${({ hoverColor }) =>
      hoverColor
        ? css`
            color: ${hoverColor};
          `
        : css`
            color: currentColor;
          `};
  }
`;

export type TextType<E extends React.ElementType> = TextOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TextOwnProps<E>>;

const CustomText = <E extends React.ElementType = 'p'>({ children, ...props }: TextType<E>) => (
  <StyledText {...props}>{children}</StyledText>
);

export default CustomText;
