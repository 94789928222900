import React, { useMemo, useState } from 'react';
import { matchPath, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Auth/Client/Login';
import { useAuth } from './context/AuthContext';
import Register from './pages/Auth/Client/Register';
import ForgotPassword from './pages/Auth/Client/Forgot';
import ClientDashboard from './pages/Dashboard/Client';
import Topbar from './layouts/Topbar';
import ExternalApplications from './pages/ExternalApplications';
import RedirectPage from './pages/IntegrationRedirect';
import PartnerDashboard from './pages/Dashboard/Partner';
import Account from './pages/Account';
import PartnerLogin from './pages/Auth/Partner/Login';
import Chatbot from './layouts/Chatbot';
import { LoginFormStage } from './@types/auth';
import { LoginStateContext } from './context/LoginStateContext';
import FullScreenPageLayout from './layouts/FullScreenPageLayout';
import {
  BILL_DOCUMENT_REDIRECT_TITLE,
  ERROR_404_PAGE_DESCRIPTION,
  ERROR_404_PAGE_TITLE,
  INVOICE_DOCUMENT_REDIRECT_TITLE,
} from './utils/constants/text';
import { dashboardRoutesByUserType, routesByUserType } from './utils/helpers/permissions';
import FinancialDetails from './pages/Dashboard/FinancialDetails';
import FavoriteWarning from './layouts/FavoriteWarning';
import DocumentRedirect from './pages/DocumentRedirect';
import { SignalRHubName } from './config/signalR/types';
import DownloadList from './layouts/DownloadList';
import { DocumentItemType } from './config/download/types';
import { ExternalLogin } from './pages/Auth/Client/External';

const RoutesWithDownloadList = () => (
  <>
    <DownloadList />
    <Outlet />
  </>
);

const RoutesWithFavoriteWarning = () => (
  <>
    <FavoriteWarning />
    <Outlet />
  </>
);

const RoutesWithTopbar = () => (
  <>
    <Topbar />
    <Outlet />
  </>
);

const RoutesWithChatbot = () => (
  <>
    <Chatbot />
    <Outlet />
  </>
);

const PrivateRoutes = () => {
  const { signed, getUserRole } = useAuth();
  const location = useLocation();
  if (signed) {
    const userType = getUserRole();

    const hasPermissionToSeeScreen = routesByUserType[userType].some((route) => matchPath(route, location.pathname));

    if (!hasPermissionToSeeScreen) {
      const fallback = dashboardRoutesByUserType[userType];
      return <Navigate to={fallback} />;
    }

    return <Outlet />;
  }

  return <Navigate to="/" />;
};

const SessionWrapper = () => {
  const { signed, getUserRole } = useAuth();

  if (signed) {
    const userType = getUserRole();
    const fallback = dashboardRoutesByUserType[userType];
    return <Navigate to={fallback} />;
  }

  return <Outlet />;
};

const LoginWrapper = () => {
  const [loginStage, setLoginStage] = useState<LoginFormStage>(LoginFormStage.USER_TYPE_SELECT);

  const context = useMemo(
    () => ({
      loginStage,
      setLoginStage,
    }),
    [loginStage]
  );

  return (
    <LoginStateContext.Provider value={context}>
      <Outlet />
    </LoginStateContext.Provider>
  );
};

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<RoutesWithChatbot />}>
        <Route path="/" element={<LoginWrapper />}>
          <Route path="/" element={<SessionWrapper />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route path="/register" element={<Register />} />
          <Route path="/external" element={<ExternalLogin />} />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Route>
        <Route path="/callback" element={<PartnerLogin />} />
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/" element={<RoutesWithDownloadList />}>
            <Route path="/" element={<RoutesWithTopbar />}>
              <Route path="/" element={<RoutesWithFavoriteWarning />}>
                <Route path="/partner" element={<PartnerDashboard />} />
                <Route path="/home" element={<ClientDashboard />} />
                <Route path="/home/financial-details/:id" element={<FinancialDetails />} />
                <Route path="/account" element={<Account />} />
                <Route path="/apps" element={<ExternalApplications />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="/home/view/bill/:id"
            element={
              <DocumentRedirect
                title={BILL_DOCUMENT_REDIRECT_TITLE}
                hubName={SignalRHubName.BILL}
                documentType={DocumentItemType.BILL}
              />
            }
          />
          <Route
            path="/home/view/invoice/:id"
            element={
              <DocumentRedirect
                title={INVOICE_DOCUMENT_REDIRECT_TITLE}
                hubName={SignalRHubName.INVOICE}
                documentType={DocumentItemType.INVOICE}
              />
            }
          />
          <Route path="/redirect" element={<RedirectPage />} />
        </Route>
        <Route
          path="*"
          element={<FullScreenPageLayout title={ERROR_404_PAGE_TITLE} description={ERROR_404_PAGE_DESCRIPTION} />}
        />
      </Route>
    </Routes>
  );
}
