import React, { useContext } from 'react';
import BillContext, { BillContextProps } from '../../../../context';
import MobileSelectDate from '../../../../../../../../../layouts/MobileSelectDate';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import { BillAsideStage } from '../../../../../../../../../@types/bill';
import { SELECT_DATE_BILL_DESCRIPTION, SELECT_DATE_BILL_TITLE } from '../../../../../../../../../utils/constants/text';

const SelectDateStep = () => {
  const { range, onChangeRange } = useContext(BillContext) as BillContextProps;

  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <MobileSelectDate
      title={SELECT_DATE_BILL_TITLE}
      description={SELECT_DATE_BILL_DESCRIPTION}
      onBack={() => changeStage(BillAsideStage.SELECT_ACTION)}
      onClose={closeHandler}
      range={range}
      onChangeRange={onChangeRange}
    />
  );
};

export default SelectDateStep;
