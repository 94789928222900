/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { FailedQueueItem, MountRegisterListenerFn, MountSingleSendFn, SignalRHubName } from '../types';
import { useAuth } from '../../../context/AuthContext';
import clearGlobalState from '../../../redux/actions/clear';
import hubs from '../hubs';
import createSignalRConnection from '../helpers/createSignalRConnection';
import generateRequestID from '../helpers/generateRequestID';
import { isObject } from '../../../utils/helpers/object';
import DisconnectedSignalRHubError from '../../../exceptions/DisconnectedSignalRHubError';

const CONNECTION_MAX_RETRY_NUMBER = 3;

function isConnectionConnecting(connection: HubConnection) {
  return (
    connection.state === HubConnectionState.Connected ||
    connection.state === HubConnectionState.Reconnecting ||
    connection.state === HubConnectionState.Connecting
  );
}

const useSignalR = () => {
  const { signed } = useAuth();
  const dispatch = useDispatch();

  const [connections, setConnections] = useState<Map<SignalRHubName, HubConnection>>(new Map());
  const failureQueueMap = useRef<Map<SignalRHubName, FailedQueueItem[]>>(new Map());
  const connectionsRetryMap = useRef<Map<SignalRHubName, number>>(new Map());

  const resetState = () => {
    clearGlobalState();
    setConnections(new Map());
    failureQueueMap.current = new Map();
    connectionsRetryMap.current = new Map();
  };

  const addRetryConnection = (hubName: SignalRHubName) => {
    if (connectionsRetryMap.current?.has(hubName)) {
      const retryNumbers = connectionsRetryMap.current.get(hubName);

      if (retryNumbers) {
        connectionsRetryMap.current?.set(hubName, retryNumbers + 1);
      }
    } else {
      connectionsRetryMap.current?.set(hubName, 1);
    }
  };

  const connectionRetryReachLimit = (hubName: SignalRHubName) => {
    const retryNumbers = connectionsRetryMap.current.get(hubName);
    return !!retryNumbers && retryNumbers >= CONNECTION_MAX_RETRY_NUMBER;
  };

  async function startConnection(connection: HubConnection, hubName: SignalRHubName) {
    if (!isConnectionConnecting(connection)) {
      try {
        await connection.start();
        failureQueueMap.current?.get(hubName)?.forEach((failedEvent) => {
          connection[failedEvent.method](failedEvent.eventName, failedEvent.data);
        });
        failureQueueMap.current?.delete(hubName);
      } catch (err) {
        addRetryConnection(hubName);
        console.error(err);
      }
    }
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timer;
    if (signed) {
      timeoutId = setTimeout(() => {
        connections.forEach((connection, hubName) => {
          if (!connectionRetryReachLimit(hubName)) {
            startConnection(connection, hubName);
          }
        });
      }, 15000);
    }
    return () => clearTimeout(timeoutId);
  }, [signed]);

  useEffect(() => {
    if (signed) {
      Object.entries(hubs).forEach(([hubName, { authMethod, listeners, senders }]) => {
        if (connections.has(hubName as SignalRHubName)) {
          return;
        }

        createSignalRConnection(authMethod).then((connection) => {
          setConnections((prevState) => prevState.set(hubName as SignalRHubName, connection));
          startConnection(connection, hubName as SignalRHubName).then(() => {
            senders?.forEach((sender) => {
              connection[sender.method](sender.name, sender.data);
            });
            listeners?.forEach((listener) => {
              connection.on(listener.name, (args) => {
                dispatch(listener.action(args));
              });
            });
          });
        });
      });
    } else {
      resetState();
      connections.forEach((connection) => {
        if (isConnectionConnecting(connection)) {
          connection.stop();
        }
      });
    }
  }, [signed]);

  useEffect(
    () => () => {
      resetState();
      connections.forEach((connection) => {
        if (isConnectionConnecting(connection)) {
          connection.stop();
        }
      });
    },
    []
  );

  const _mountRegisterListener: MountRegisterListenerFn =
    (hubName: SignalRHubName) => (eventName: string, callback: (item: any) => void) => {
      const currentConnection = connections.get(hubName);

      if (!currentConnection || currentConnection.state !== HubConnectionState.Connected) {
        if (failureQueueMap.current?.has(hubName)) {
          const existingFailedQueue = failureQueueMap.current.get(hubName);

          if (existingFailedQueue) {
            existingFailedQueue?.push({ eventName, data: callback, method: 'on' });

            failureQueueMap.current?.set(hubName, existingFailedQueue);
          }
        } else {
          failureQueueMap.current?.set(hubName, [{ eventName, data: callback, method: 'on' }]);
        }
      } else if (currentConnection.state === HubConnectionState.Connected) {
        currentConnection?.on(eventName, callback);
      }
    };

  const _mountRemoveListener = (hubName: SignalRHubName) => (eventName: string, callback: (item: any) => void) => {
    const currentConnection = connections.get(hubName);
    currentConnection?.off(eventName, callback);
  };

  const _mountSingleSend: MountSingleSendFn = (hubName: SignalRHubName) => (args: [string, any]) => {
    const currentConnection = connections.get(hubName);
    const requestID = generateRequestID();
    const [eventName, data] = args;

    const sendData = isObject(data) ? JSON.stringify({ ...data, RequestId: requestID }) : data;
    return new Promise((resolve, reject) => {
      if (!currentConnection || currentConnection.state !== HubConnectionState.Connected) {
        if (failureQueueMap.current?.has(hubName)) {
          const existingFailedQueue = failureQueueMap.current.get(hubName);

          if (existingFailedQueue) {
            existingFailedQueue?.push({ eventName, data: sendData, method: 'send' });
            failureQueueMap.current?.set(hubName, existingFailedQueue);
          }
        } else {
          failureQueueMap.current?.set(hubName, [{ eventName, data: sendData, method: 'send' }]);
        }
        reject(new DisconnectedSignalRHubError());
      } else if (currentConnection.state === HubConnectionState.Connected) {
        currentConnection
          .send(eventName, sendData)
          .then(() => {
            resolve(requestID);
          })
          .catch(reject);
      }
    });
  };

  const getErrors = (hubName: SignalRHubName) => ({
    connectionReachedMaxRetries: connectionRetryReachLimit(hubName),
  });

  return {
    connections,
    _mountRegisterListener,
    _mountRemoveListener,
    _mountSingleSend,
    getErrors,
    connectionsRetryMap,
  };
};

export default useSignalR;
