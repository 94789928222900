import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPrinter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={17} viewBox="0 0 19 17" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M3.586 12.59H4.76v3.928H14.168v-3.929h1.173a2.778 2.778 0 0 0 2.774-2.773V4.377H16.293v-3.34h-.907v3.34H3.542V1.945H13.864v-.907H2.634v3.34H.813V9.815a2.778 2.778 0 0 0 2.773 2.773Zm.11-3.662H4.76v2.754H3.586A1.869 1.869 0 0 1 1.72 9.816V5.285h15.487v4.531a1.869 1.869 0 0 1-1.866 1.866h-1.173V8.928h1.214V8.02H3.545v.907h.15Zm9.565 6.683H5.667V8.928h7.594v6.683Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M11.74 13.332v-.15H7.19v.907H11.74v-.757ZM11.74 10.6v-.15H7.19v.907H11.74v-.758Z"
    />
  </svg>
);
export default SvgPrinter;
