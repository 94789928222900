import { BillMinimalData, BillStatus } from '../../@types/bill';

class BillPolicy {
  shouldEnableActionButtons(status: BillStatus, hasFullBillAvailable: boolean): boolean {
    const disableActionByStatus = [BillStatus.NOT_GENERATED, BillStatus.PAID, BillStatus.EXPIRED];
    return !disableActionByStatus.includes(status) && hasFullBillAvailable;
  }

  shouldEnableAccessToDetails(data: BillMinimalData): boolean {
    return data.status !== BillStatus.NOT_GENERATED && data.hasFullBillAvailable;
  }

  shouldShowBankCodeInDetails(status: BillStatus | undefined): boolean {
    if (!status) return false;
    const showBankCodeByStatus = [BillStatus.OVERDUE, BillStatus.PENDING];
    return showBankCodeByStatus.includes(status);
  }

  // When return undefined, should use default message
  getFileActionMessageByStatus(status: BillStatus): string | undefined {
    return status === BillStatus.EXPIRED
      ? 'Para gerar uma segunda via do boleto favor entrar em contato pelo chat.'
      : undefined;
  }
}

export default new BillPolicy();
