import React, { useMemo } from 'react';
import { ListContainer, ViewContainer, WarningContainer } from '../../../Client/style';
import FinancialHistoricGrid from './components/Grid';
import FinancialHistoricTable from './components/Table';
import DisableFinancialsDetailsHeader from './components/DisableHistoricHeader';
import CustomText from '../../../../../components/CustomText';
import { HistoricContainer } from '../../style';
import FinancialHistoricContext from './context';
import AsideActions from './components/AsideActions';
import DesktopActions from './components/DesktopActions';
import Pagination from '../../../../../components/Pagination';
import { PaginateFooter } from '../../../shared/style';
import useHistoric from './useHistoric';

const Historic = () => {
  const {
    currentPage,
    paginatedData,
    actionsIsDisabled,
    clearSearch,
    layout,
    toggleLayout,
    showGrid,
    order,
    handleOrder,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    requestSearch,
    search,
    loading,
    isMobileWidth,
    requestSort,
    getPaginationProps,
  } = useHistoric();

  const context = useMemo(
    () => ({
      actionsIsDisabled,
      clearSearch,
      layout,
      toggleLayout,
      showGrid,
      order,
      handleOrder,
      handleSearch,
      searchKeys,
      handleSearchKeys,
      requestSearch,
      search,
      loading,
    }),
    [order, loading, layout, searchKeys, search, actionsIsDisabled]
  );

  return (
    <FinancialHistoricContext.Provider value={context}>
      <HistoricContainer>
        {isMobileWidth ? <AsideActions /> : <DesktopActions />}
        {!loading && paginatedData.length > 0 && (
          <ListContainer>
            <ViewContainer>
              {showGrid ? (
                <FinancialHistoricGrid data={currentPage} />
              ) : (
                <FinancialHistoricTable data={currentPage} requestSort={requestSort} order={order} />
              )}
            </ViewContainer>
            <PaginateFooter>
              <Pagination {...getPaginationProps()} />
            </PaginateFooter>
          </ListContainer>
        )}
        {loading && (
          <>
            {!isMobileWidth && <DisableFinancialsDetailsHeader />}
            <WarningContainer obfuscate>
              <CustomText as="h1">Buscando histórico...</CustomText>
            </WarningContainer>
          </>
        )}
        {!loading && paginatedData.length < 1 && (
          <>
            {!isMobileWidth && <DisableFinancialsDetailsHeader />}
            <WarningContainer>
              <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
            </WarningContainer>
          </>
        )}
      </HistoricContainer>
    </FinancialHistoricContext.Provider>
  );
};

export default Historic;
