import styled from 'styled-components';
import { WarningContainer } from '../../../../../../style';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 48px);
  padding: 24px 40px;

  @media (max-width: 1400px) {
    padding: 24px 32px;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

export const ModalWarning = styled(WarningContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
