import React, { useContext } from 'react';
import ToggleViewButton from '../../../../../../../components/ToggleViewButton';
import Button from '../../../../../../../components/Button';
import GridOrderButton from '../../../../../shared/components/OrderButton';
import { BUILDING_ORDER_OPTIONS } from '../../constants';
import Search from '../../../../../../../components/Search';
import FilterButton from './components/FilterButton';
import { isNotEmptyArray } from '../../../../../../../utils/helpers/array';
import { Container } from './style';
import BuildingContext, { BuildingContextProps } from '../../context';
import { WrapperDiv } from '../../../../../../../styles/components';
import { SearchContainer } from '../../../../../shared/style';
import NewContractModal from './components/NewContractModal';

const DesktopActions = () => {
  const {
    loading,
    toggleLayout,
    layout,
    showGrid,
    order,
    search,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    handleOrder,
    openSearchAction,
    dataIsEmpty,
  } = useContext(BuildingContext) as BuildingContextProps;

  const showButtons = !dataIsEmpty;

  return (
    <>
      <NewContractModal />
      {showButtons && (
        <Container>
          <WrapperDiv gap={16}>
            <ToggleViewButton onClick={toggleLayout} layout={layout} />
            <Button variant="tertiary" size="small" onClick={openSearchAction} disabled={loading}>
              Buscar novo contrato
            </Button>
          </WrapperDiv>
          <SearchContainer>
            {showGrid && <GridOrderButton order={order} handleOrder={handleOrder} options={BUILDING_ORDER_OPTIONS} />}
            <Search
              disabled={loading}
              value={search}
              onChange={(event) => handleSearch(event.target.value)}
              placeholder="Buscar"
            >
              <FilterButton isSelected={isNotEmptyArray(searchKeys)} value={searchKeys} onChange={handleSearchKeys} />
            </Search>
          </SearchContainer>
        </Container>
      )}
    </>
  );
};

export default DesktopActions;
