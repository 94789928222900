import React, { FunctionComponent } from 'react';
import {
  CalloutContainer,
  DialogActions,
  DialogContainer,
} from '../../../Client/containers/Building/components/DesktopActions/components/NewContractModal/style';
import Callout from '../../../../../components/Callout';
import CustomText from '../../../../../components/CustomText';
import Button from '../../../../../components/Button';

interface ListDataProps {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  contractIsAlreadyOnList: boolean;
  loading: boolean;
  resultLength: number;
}

const FindContractDialog: FunctionComponent<ListDataProps> = ({
  resultLength,
  onSubmit,
  onCancel,
  contractIsAlreadyOnList,
  loading,
}) => (
  <CalloutContainer>
    <Callout>
      {contractIsAlreadyOnList ? (
        <CustomText>
          {resultLength > 1 ? 'Estes contratos já estão no seu painel.' : 'Este contrato já está no seu painel.'}
        </CustomText>
      ) : (
        <DialogContainer>
          <CustomText>
            {resultLength > 1
              ? 'Sua busca retornou resultados. Deseja fixar no seu painel?'
              : 'Sua busca retornou resultado. Deseja fixar no seu painel?'}
          </CustomText>
          <DialogActions>
            <Button size="fit" variant="primary" onClick={onCancel} disabled={loading}>
              Não
            </Button>
            <Button size="fit" onClick={onSubmit} variant="primary" disabled={loading}>
              Sim
            </Button>
          </DialogActions>
        </DialogContainer>
      )}
    </Callout>
  </CalloutContainer>
);

export default FindContractDialog;
