import React, { createContext, useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { LocalStorageKey, removeFromLocalStorage, saveToLocalStorage } from '../utils/helpers/storage';
import AuthService, { ExternalLoginResponse } from '../services/Auth/AuthService';
import { ConfirmationCode, ConfirmationToken, ExternalToken, JwtToken, User, UserType } from '../@types/auth';
import useLocalStorage from '../hooks/useLocalStorage';

type SignIn = (confirmationToken: ConfirmationToken, confirmationCode: ConfirmationCode) => Promise<void>;
type SignInPartner = (idToken: JwtToken) => Promise<void>;
type SignInExternal = (
  externalToken: ExternalToken,
  clientId: string
) => Promise<ExternalLoginResponse['externalInfo']>;
type SignOut = VoidFunction;

export interface AuthContextProps {
  signed: boolean;
  user: User | null;
  signIn: SignIn;
  signOut: SignOut;
  signInPartner: SignInPartner;
  signInExternal: SignInExternal;
  getUserRole: () => UserType;
  updateUserEmail: (email: string) => void;
  updateUserPhone: (phone: string) => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider = ({ children }: ContextProvider) => {
  const [user, setUser] = useLocalStorage<User | null>(LocalStorageKey.USER, null);

  const signOut = () => {
    removeFromLocalStorage(LocalStorageKey.TOKEN);
    removeFromLocalStorage(LocalStorageKey.REFRESH_TOKEN);
    removeFromLocalStorage(LocalStorageKey.USER);
    setUser(null);
    return <Navigate to="/" />;
  };

  const signIn: SignIn = async (confirmationToken, confirmationCode) => {
    const { data: authData } = await AuthService.confirmationAuth(confirmationToken, confirmationCode);

    saveToLocalStorage(LocalStorageKey.TOKEN, authData.accessToken);
    saveToLocalStorage(LocalStorageKey.REFRESH_TOKEN, authData.refreshToken);

    saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(authData.user));
    setUser(authData.user);
  };

  const signInPartner: SignInPartner = async (idToken) => {
    const { data: collaboratorData } = await AuthService.signInPartner(idToken);
    saveToLocalStorage(LocalStorageKey.TOKEN, collaboratorData.accessToken);

    const { data: userData } = await AuthService.getUserData(collaboratorData.accessToken);
    saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(userData));
    setUser(userData);
  };

  const signInExternal: SignInExternal = async (externalToken, clientId) => {
    const { data } = await AuthService.signInExternal(externalToken, clientId);
    saveToLocalStorage(LocalStorageKey.TOKEN, data.accessToken);
    saveToLocalStorage(LocalStorageKey.REFRESH_TOKEN, data.refreshToken);
    saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(data.user));
    setUser(data.user);
    return data.externalInfo;
  };

  const getUserRole = () => {
    const { roles } = user as User;
    return roles[0];
  };

  const updateUserEmail = (email: string) => {
    const newUser = {
      ...user,
      email,
    } as User;

    setUser(newUser);
    saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(newUser));
  };

  const updateUserPhone = (phone: string) => {
    const newUser = {
      ...user,
      phone,
    } as User;

    setUser(newUser);
    saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(newUser));
  };

  const context = useMemo(
    () => ({
      user,
      signIn,
      signInPartner,
      signInExternal,
      signOut,
      signed: Boolean(user),
      getUserRole,
      updateUserEmail,
      updateUserPhone,
    }),
    [user]
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext) as AuthContextProps;
