import React, { useContext } from 'react';
import InvoiceContext, { InvoiceContextProps } from '../../../../context';
import MobileSelectDate from '../../../../../../../../../layouts/MobileSelectDate';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import { InvoiceAsideStage } from '../../../../../../../../../@types/invoice';
import {
  SELECT_DATE_INVOICE_DESCRIPTION,
  SELECT_DATE_INVOICE_TITLE,
} from '../../../../../../../../../utils/constants/text';

const SelectDateStep = () => {
  const { range, onChangeRange } = useContext(InvoiceContext) as InvoiceContextProps;

  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <MobileSelectDate
      title={SELECT_DATE_INVOICE_TITLE}
      description={SELECT_DATE_INVOICE_DESCRIPTION}
      onBack={() => changeStage(InvoiceAsideStage.SELECT_ACTION)}
      onClose={closeHandler}
      range={range}
      onChangeRange={onChangeRange}
    />
  );
};

export default SelectDateStep;
