import styled from 'styled-components';
import theme from '../../styles/theme';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 20px;
  border-bottom: 1px solid ${theme.color.secondary.grey[5]};
`;
