import React from 'react';
import RequestCode from '../../../common/RequestCode';
import { CodeProvider } from '../../../../../../../../@types/auth';
import Radio from '../../../../../../../../components/SelectableButton/Radio';
import PhoneLabel from '../../../../../../../../components/Label/components/PhoneLabel';
import { useAuth } from '../../../../../../../../context/AuthContext';
import { maskPhone, obfuscatePhone } from '../../../../../../../../utils/helpers/mask';
import { RequestFormProps } from '../../../../../../type';
import { Container } from './style';

const RequestForm = ({ onSubmit, loading }: RequestFormProps) => {
  const { user } = useAuth();
  const phone = user?.phone!;

  return (
    <Container>
      <RequestCode loading={loading} onSubmit={() => onSubmit({ contactType: CodeProvider.SMS })}>
        <Radio
          className="Radio"
          label={<PhoneLabel label={maskPhone(obfuscatePhone(phone))} />}
          name="RequestForm-EmailModal"
          value={CodeProvider.SMS}
          checked
        />
      </RequestCode>
    </Container>
  );
};

export default RequestForm;
