import { GTMEventName } from '../../../@types/analytics';
import { Tab, TabAccessor } from '../../../@types/dashboard';

export const WAIT_TIME_SIGNALR_LOADING = 15000;

export const mapTabLabel = {
  [TabAccessor.BUILDING]: 'Contratos',
  [TabAccessor.FINANCIAL]: 'Boletos',
  [TabAccessor.INVOICE]: 'Notas fiscais',
};

export const tabs: Tab<TabAccessor>[] = [
  {
    label: mapTabLabel[TabAccessor.BUILDING],
    accessor: TabAccessor.BUILDING,
  },
  {
    label: mapTabLabel[TabAccessor.FINANCIAL],
    accessor: TabAccessor.FINANCIAL,
  },
  {
    label: mapTabLabel[TabAccessor.INVOICE],
    accessor: TabAccessor.INVOICE,
  },
];

export const mapTabToGTMEvent = {
  [TabAccessor.BUILDING]: GTMEventName.VIEW_CONTRACTS,
  [TabAccessor.FINANCIAL]: GTMEventName.VIEW_BILLS,
  [TabAccessor.INVOICE]: GTMEventName.VIEW_INVOICES,
};
