import axios from 'axios';
import { EXCEPTIONS_MESSAGE } from '../utils/constants/exceptions';

interface Problem {
  slug: string;
  description: string;
}

interface ErrorData {
  detail?: string;
  problems?: Problem[];
  status?: number;
  title?: string;
  type?: string;
}

export enum CustomErrorType {
  AXIOS_ERROR = 'axios-error',
  STOCK_ERROR = 'stock-error',
  CANCELED_ERROR = 'canceled-error',
}

export const getErrorMessage = (slug: string | undefined) => {
  const key = (slug as keyof typeof EXCEPTIONS_MESSAGE) ?? 'DEFAULT';

  const message = EXCEPTIONS_MESSAGE[key];

  return message ?? EXCEPTIONS_MESSAGE.DEFAULT;
};

export const errorHandler = <T extends ErrorData>(error: unknown) => {
  if (axios.isCancel(error)) {
    return {
      type: CustomErrorType.CANCELED_ERROR,
    };
  }

  if (axios.isAxiosError<T>(error)) {
    return {
      error,
      type: CustomErrorType.AXIOS_ERROR,
      status: error?.response?.status,
      data: error?.response?.data,
      firstSlug: error?.response?.data?.problems?.[0]?.slug,
    };
  }

  return {
    error,
    type: CustomErrorType.STOCK_ERROR,
  };
};
