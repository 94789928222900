import React, { useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../../../../components/Button';
import CustomText from '../../../../../../components/CustomText';
import HookFeedbackError from '../../../../../../components/HookFeedbackError';
import Input from '../../../../../../components/Input';
import { LinkContract } from '../../../../../../@types/building';
import PartnerContext, { PartnerContextProps } from '../../../context';
import { Container, Form, MobileEmptyResult } from './style';
import { maskCPForCNPJ } from '../../../../../../utils/helpers/mask';
import { isCPFOrCNPJ } from '../../../../../../utils/helpers/validate';
import { PartnerSearchStatus } from '../../../usePartner';
import AsideHeader from '../../../../../../components/AsideHeader';
import AsidePortal from '../../../../../../layouts/AsidePortal';
import Grid from './components/Grid';
import { REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS } from '../../../../../../utils/constants/regex';

const PartnerMobileView = () => {
  const { buildings, setStatus, fetchBuildings, status } = useContext(PartnerContext) as PartnerContextProps;
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LinkContract>({
    mode: 'onChange',
    defaultValues: {
      document: '',
      contract: '',
    },
  });

  const fetchData = async (data: LinkContract) => {
    setLoading(true);
    await fetchBuildings(data);
    setLoading(false);
  };

  const onSubmit: SubmitHandler<LinkContract> = (data) => {
    fetchData(data);
  };

  const showResults = status === PartnerSearchStatus.EMPTY_RESULT || status === PartnerSearchStatus.HAS_RESULT;

  const watchDocument = watch('document');
  const watchContract = watch('contract');
  const formIsValid = (watchDocument.length > 0 && isCPFOrCNPJ(watchDocument)) || watchContract.length > 0;

  return (
    <>
      <AsidePortal visible={showResults}>
        <AsideHeader onBack={() => setStatus(PartnerSearchStatus.NOT_COMPLETED)} />
        <Container>
          <CustomText as="h1" className="title">
            Resultado
          </CustomText>
          {buildings.length ? (
            <Grid data={buildings} />
          ) : (
            <MobileEmptyResult>
              <CustomText as="h1">Nenhum resultado encontrado</CustomText>
            </MobileEmptyResult>
          )}
        </Container>
      </AsidePortal>
      <Container>
        <Form>
          <CustomText as="h1" className="title">
            Buscar novo contrato
          </CustomText>
          <CustomText as="p" className="disclaimer">
            Para buscar novo edifício, preencha pelo menos um dos campos: CPF ou CNPJ ou Nº Contrato. Atenção ao formato
            ao digitar número de contrato. Exemplo: Nova Instalação (12345678), Modernização integral (12345678),
            Modernização parcial (123456) e Serviço (123456).
          </CustomText>
          <Controller
            name="document"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input.BaseNumberMasked
                {...field}
                inputMode="numeric"
                customInput={Input.Form}
                type="text"
                placeholder="Buscar CPF ou CNPJ"
                helper={<HookFeedbackError errors={errors} name={field.name} />}
                error={Boolean(error)}
                format={maskCPForCNPJ}
              />
            )}
          />
          <Controller
            name="contract"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input.Regex
                {...field}
                handleChange={field.onChange}
                allowCharacters={REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS}
                type="text"
                placeholder="Buscar número do contrato"
                helper={<HookFeedbackError errors={errors} name={field.name} />}
                error={Boolean(error)}
              />
            )}
          />
        </Form>
        <Button
          disabled={!formIsValid || loading}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="primary"
        >
          Buscar
        </Button>
      </Container>
    </>
  );
};

export default PartnerMobileView;
