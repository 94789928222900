import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
    <path
      fill="#262626"
      stroke="#262626"
      strokeWidth={0.6}
      d="M19.457 5.82v5.03H3.109V5.82a1.342 1.342 0 0 1 1.258-1.258h2.515v.629h.628v-.629h7.546v.629h.628V3.305h-.628v.628H7.51v-.628h-.628v.628H4.367A1.971 1.971 0 0 0 2.48 5.82v10.688a1.971 1.971 0 0 0 1.887 1.887h13.832a1.971 1.971 0 0 0 1.886-1.887V5.82h-.628Zm0 8.488h-3.773v-2.83h3.773v2.83Zm-8.488.629v2.829H7.51v-2.83h3.459Zm-3.459-.63v-2.829h3.459v2.83H7.51Zm4.087.63h3.459v2.829h-3.459v-2.83Zm0-.63v-2.829h3.459v2.83h-3.459Zm-4.715-2.829v2.83H3.109v-2.83h3.773Zm-3.773 5.03v-1.571h3.773v2.829H4.367a1.342 1.342 0 0 1-1.258-1.258Zm15.09 1.258h-2.515v-2.83h3.773v1.572a1.342 1.342 0 0 1-1.258 1.258Z"
    />
    <path fill="#262626" d="M15.685 7.39H6.883v.63h8.802v-.63Z" />
  </svg>
);
export default SvgCalendar;
