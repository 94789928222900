import React, { Suspense, useMemo, useState } from 'react';
import CustomText from '../../../../../../components/CustomText';
import { PasswordFormStage } from '../../../../type';
import PasswordActionContext from './context';
import Form from './Steps/Form';
import Feedback from './Steps/Feedback';

const formMapping = {
  [PasswordFormStage.FORM]: Form,
  [PasswordFormStage.FEEDBACK]: Feedback,
} as const;

const PasswordAction = () => {
  const [stage, setStage] = useState(PasswordFormStage.FORM);
  const context = useMemo(() => ({ stage, setStage }), [stage]);
  const Step = formMapping[stage];

  return (
    <PasswordActionContext.Provider value={context}>
      <Suspense fallback={<CustomText as="h1">Loading...</CustomText>}>
        <Step />
      </Suspense>
    </PasswordActionContext.Provider>
  );
};

export default PasswordAction;
