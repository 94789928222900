import styled, { css } from 'styled-components';
import theme from '../../../styles/theme';

export const CheckboxInput = styled.input`
  display: none;
`;

interface SelectableLabelProps {
  checked: boolean;
  disabled?: boolean;
}

export const CheckboxLabel = styled.label<SelectableLabelProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.color.secondary.grey[1]};

  &:active {
    border-width: 2px;
  }

  & > svg {
    display: none;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: ${theme.color.primary.black};
      color: ${theme.color.primary.white};

      & > svg {
        display: block;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${theme.color.secondary.grey[3]};
      color: ${theme.color.secondary.grey[3]};
    `}

  ${({ disabled, checked }) =>
    disabled &&
    checked &&
    css`
      border: none;
      background: ${theme.color.secondary.grey[4]};
    `}
`;
