import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLogoWithoutLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 114 50" {...props}>
    <path
      fill="url(#logo_without_label_svg__a)"
      d="M28.689 49.14H16.182V19.151h12.513zm64.32-29.993v-7.464h20.981V.377H73.07L54.627 18.635V.377H.01v11.306h41.779V49.14h12.84v-18.3l18.44 18.3h40.921V37.152H93.01v-7.63h18.419V19.15h-18.42v10.374H80.49v9.61L65.868 24.344 80.49 9.896v9.262z"
    />
    <defs>
      <linearGradient
        id="logo_without_label_svg__a"
        x1={24.397}
        x2={97.684}
        y1={57.361}
        y2={-15.929}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.15} stopColor="#7000BD" />
        <stop offset={0.18} stopColor="#7A09AE" />
        <stop offset={0.28} stopColor="#9A2382" />
        <stop offset={0.38} stopColor="#B5395D" />
        <stop offset={0.49} stopColor="#CB4C3E" />
        <stop offset={0.6} stopColor="#DC5A26" />
        <stop offset={0.71} stopColor="#E96415" />
        <stop offset={0.84} stopColor="#F06A0B" />
        <stop offset={1} stopColor="#F26C08" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgLogoWithoutLabel;
