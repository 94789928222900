import React, { FunctionComponent } from 'react';
import { Consultant } from '../../../../../../@types/building';
import { ContactWrapper, Container, PhoneWrapper } from './style';
import ExternalLink from '../../../../../../components/Link/ExternalLink';
import CustomText from '../../../../../../components/CustomText';
import { maskPhone } from '../../../../../../utils/helpers/mask';
import { MAP_CONSULTANT_TYPE } from '../../../../Client/containers/Building/constants';
import { WhatsappIcon } from '../../../../../../icons';

interface ContactsTooltipProps {
  contacts: Array<Consultant>;
}

const ContactsTooltip: FunctionComponent<ContactsTooltipProps> = ({ contacts }) => (
  <Container>
    <CustomText className="header">Telefones úteis</CustomText>
    {contacts.map((contact) => (
      <ContactWrapper key={`ContactWrapper-${contact.name}-${contact.phone}`}>
        <CustomText className="type">{MAP_CONSULTANT_TYPE[contact.type]}</CustomText>
        <CustomText className="name">{contact.name}</CustomText>
        <PhoneWrapper>
          <ExternalLink
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?1=pt_BR&phone=${contact.phone}`}
            text={maskPhone(contact.phone)}
          />
          <WhatsappIcon />
        </PhoneWrapper>
      </ContactWrapper>
    ))}
    <ContactWrapper>
      <CustomText className="type">Whatsapp TKE</CustomText>
      <PhoneWrapper>
        <ExternalLink
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?1=pt_BR&phone=555199779006"
          text={maskPhone('5551999779006')}
        />
        <WhatsappIcon />
      </PhoneWrapper>
    </ContactWrapper>
  </Container>
);

export default ContactsTooltip;
