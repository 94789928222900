import React from 'react';
import styled, { css } from 'styled-components';

interface LoaderProps {
  center?: boolean;
  width?: number;
  height?: number;
}

const StyledLoader = styled.div<LoaderProps>`
  .spinner {
    height: ${({ height }) => {
      if (!height) {
        return 'initial';
      }

      return typeof height === 'string' ? height : `${height}px`;
    }};
    width: ${({ width }) => {
      if (!width) {
        return 'initial';
      }

      return typeof width === 'string' ? width : `${width}px`;
    }};
  }

  ${({ center }) =>
    center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
  }

  .spinner_path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Loader = (props: LoaderProps) => (
  <StyledLoader {...props}>
    <svg className="spinner" viewBox="0 0 50 50">
      <circle
        className="spinner_path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </StyledLoader>
);

export default Loader;
