import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import AsideTabs from '../../../../../../../../../layouts/AsideTabs';
import { FinancialHistoricStage } from '../../../../../../../../../@types/bill';

const SelectStep = () => {
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <AsideTabs
      onClose={closeHandler}
      onChange={changeStage}
      actions={[
        {
          label: 'Buscar histórico',
          accessor: FinancialHistoricStage.FILTER_ACTION,
        },
      ]}
    />
  );
};

export default SelectStep;
