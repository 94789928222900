import styled from 'styled-components';

export const Container = styled.div`
  width: 135px;
  display: flex;
  gap: 8px;

  .External_Link {
    width: 100%;
    justify-content: space-between;
  }

  svg path {
    fill: #fff;
  }
`;

export const Anchor = styled.a<{ imageUrl: string }>`
  background-size: contain;
  display: block;
  overflow: hidden;
  text-decoration: none;
  width: 100%;
  height: 100%;
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
  background-repeat: no-repeat;
`;
