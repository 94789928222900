import React, { PropsWithChildren } from 'react';
import AsideHeader from '../../../../../../../../components/AsideHeader';
import Button from '../../../../../../../../components/Button';
import CustomText from '../../../../../../../../components/CustomText';
import { MobileContainer } from '../../../style';
import { SendEmailProps } from '../../../types';
import SelectEmail from '../SelectEmail';

const MobileSendEmail = ({
  selectedOption,
  onChangeSelectedOption,
  onChangeCustomEmail,
  customEmail,
  title,
  children,
  onClose,
  onSubmit,
  description,
  selectLabel,
  customEmailError,
  loading,
}: PropsWithChildren<SendEmailProps>) => (
  <>
    <AsideHeader onBack={onClose} />
    <MobileContainer>
      <div>
        <CustomText as="h1">{title}</CustomText>
        {description}
        <SelectEmail
          {...{
            selectedOption,
            onChangeSelectedOption,
            onChangeCustomEmail,
            customEmail,
            description: selectLabel,
            customEmailError,
          }}
        />
        {children}
      </div>
      <Button disabled={!selectedOption || loading} onClick={onSubmit} variant="primary">
        Enviar boleto
      </Button>
    </MobileContainer>
  </>
);

export default MobileSendEmail;
