import styled from 'styled-components';
import theme from '../../styles/theme';

export const BannerContainer = styled.div<{ imagePath: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  & > *:not(img) {
    z-index: 2;
  }

  img {
    width: 100%;
    max-height: 100%;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
  }

  box-sizing: border-box;
  padding: 58px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: 'TKEType-Regular', sans-serif;
  position: relative;
  z-index: 1;

  color: ${theme.color.primary.white};

  &:before {
    content: '';
    background: ${theme.color.primary.black};
    opacity: 0.6;
    z-index: 2;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    &:before {
      background: ${theme.color.primary.interaction};
    }
  }
`;

export const BannerTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 2.6rem;
`;
