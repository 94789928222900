import styled from 'styled-components';

export const TitleBox = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  h1 {
    font-weight: 500;
    font-size: 3rem;
    line-height: 40px;
    color: #434343;
  }

  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 19px;
  }
`;
