import styled, { css, keyframes } from 'styled-components';
import theme from '../../styles/theme';

const fadeIn = keyframes`
  0% {
    background: transparent;
  }
  100% {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const fadeOut = keyframes`
  0% {
    background: rgba(0, 0, 0, 0.6);
  }
  100% {
    background: transparent;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.6)
  }
  100% {
    transform: scale(1)
  }
`;

const scaleOut = keyframes`
  0% {
    transform: scale(1)
  }
  100% {
    opacity: 0;
    transform: scale(1.02)
  }
`;

interface ContainerProps {
  isLeaving?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
  animation: ${scaleIn} 0.35s forwards;
  overflow: auto;

  max-height: 100vh;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100%;

          .modal_content_wrapper {
            height: 100%;
          }
        `
      : css`
          max-height: 100%;

          .modal_content_wrapper {
            height: fit-content;
          }
        `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;

      .modal_content_wrapper {
        width: 100%;
      }
    `}

  ${({ isLeaving }) =>
    isLeaving &&
    css`
      animation: ${scaleOut} 0.2s forwards;
    `};
`;

export const Spacing = styled.div`
  box-sizing: border-box;
  padding: 24px;
  height: 100%;
  // Center Modal
  display: flex;
  justify-content: center;
`;

export const Overlay = styled.div<{ isLeaving: boolean }>`
  z-index: ${theme.defaults.modalZIndex};
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  ${({ isLeaving }) =>
    isLeaving &&
    css`
      animation: ${fadeOut} 0.2s forwards;
    `};

  box-sizing: border-box;
  animation: ${fadeIn} 0.35s forwards;
`;
