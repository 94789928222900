import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import CustomText from '../CustomText';

export const VideoPlayerTitle = styled(CustomText).attrs({
  fontSize: '3xl',
})`
  color: currentColor;
  font-style: normal;
  font-weight: 500;
`;

export const VideoPlayerContainer = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
  box-sizing: border-box;
  min-height: 245px;
  width: 640px;

  .banner-container {
    min-width: 300px;
    max-width: 500px;
  }

  .title-container {
    min-width: 300px;
    max-width: 350px;
  }

  @media (max-width: ${BREAKPOINTS.hd}px) {
    width: 100%;
    min-width: unset;

    .banner-container {
      min-width: 50%;
      max-width: unset;
    }

    .title-container {
      min-width: unset;
    }
  } ;
`;

export const FrameContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.hd}px) {
    width: 100%;
    .container-player {
      border-radius: 6px;
      overflow: hidden;
      z-index: 1;
      width: 100%;
      box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.4);
    }
    .iframe-player {
      width: 100%;
    }
  }
`;
