import { useState } from 'react';
import useDisclosure from './useDisclosure';

type UseAsideActionsReturnFn<T extends string> = {
  stage: T;
  setStage: SetState<T>;
  isOpen: boolean;
  open: VoidFunction;
  close: VoidFunction;
};

const useAsideActions = <T extends string>(defaultValue: T) => {
  const { isOpen, open, close } = useDisclosure(false);
  const [stage, setStage] = useState<T>(defaultValue);

  return {
    stage,
    setStage,
    isOpen,
    open,
    close,
  } as UseAsideActionsReturnFn<T>;
};

export default useAsideActions;
