import { useContext } from 'react';
import { SignalRContext, SignalRContextProps } from '../context';
import { SignalRHubName } from '../types';

const useHub = (hubName: SignalRHubName) => {
  const { _mountRegisterListener, _mountSingleSend, _mountRemoveListener, getErrors } = useContext(
    SignalRContext
  ) as SignalRContextProps;

  return {
    removeListener: _mountRemoveListener(hubName),
    registerListener: _mountRegisterListener(hubName),
    singleSend: _mountSingleSend(hubName),
    errors: getErrors(hubName),
  };
};

export default useHub;
