import React, { useContext } from 'react';
import InvoiceContext, { InvoiceContextProps } from '../../../../context';
import DesktopSelectDate from '../../../../../../../../../layouts/DesktopSelectDate';
import {
  SELECT_DATE_INVOICE_DESCRIPTION,
  SELECT_DATE_INVOICE_TITLE,
} from '../../../../../../../../../utils/constants/text';

const SelectDateModal = () => {
  const { selectDateIsOpen, closeSelectDate, range, onChangeRange } = useContext(InvoiceContext) as InvoiceContextProps;

  return (
    <DesktopSelectDate
      title={SELECT_DATE_INVOICE_TITLE}
      description={SELECT_DATE_INVOICE_DESCRIPTION}
      isOpen={selectDateIsOpen}
      onClose={closeSelectDate}
      range={range}
      onChangeRange={onChangeRange}
    />
  );
};

export default SelectDateModal;
