import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFeedbackSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M8.464 1.611a6.67 6.67 0 0 0-6.667 6.667 6.67 6.67 0 0 0 6.667 6.666 6.67 6.67 0 0 0 6.666-6.666A6.67 6.67 0 0 0 8.464 1.61Zm0 12A5.34 5.34 0 0 1 3.13 8.278a5.34 5.34 0 0 1 5.334-5.334 5.34 5.34 0 0 1 5.333 5.334 5.34 5.34 0 0 1-5.333 5.333Zm3.06-8.28L7.13 9.724l-1.726-1.72-.94.94 2.666 2.667 5.333-5.333-.94-.947Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.463.278h16v16h-16z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFeedbackSuccess;
