import styled, { css } from 'styled-components';
import theme from '../../../styles/theme';

const NavButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 20px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${theme.color.secondary.purple[3]};
  }

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${theme.color.primary.black};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.color.secondary.grey[4]};
      ${theme.defaults.disabled}

      .icon path {
        stroke: ${theme.color.secondary.grey[4]};
        fill: ${theme.color.secondary.grey[4]};
      } ;
    `};
`;

export default NavButton;
