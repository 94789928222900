import React from 'react';
import { BillMinimalComposition } from '../../../../../../../@types/bill';
import CustomText from '../../../../../../../components/CustomText';
import TextDropdown from '../../../../../../../components/Floating/components/TextDropdown';
import { BillTooltip, Container } from './style';

interface InvoicesTooltipProps {
  composition: Array<BillMinimalComposition>;
}

const InvoicesTooltip = ({ composition }: InvoicesTooltipProps) => (
  <Container disabled={composition.length < 1}>
    <TextDropdown
      disabled={composition.length < 1}
      label="NFs"
      floating={
        <BillTooltip>
          <CustomText className="title">Notas fiscais</CustomText>
          {composition.map(({ invoiceNumber }, index) => (
            <CustomText key={index} className="invoice_text">
              Nº {invoiceNumber}
            </CustomText>
          ))}
        </BillTooltip>
      }
    />
  </Container>
);

export default InvoicesTooltip;
