import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSms = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.056 5.798H9.5M5.056 8.909h7.11m-4.725 5.333h5.792c1.494 0 2.24 0 2.811-.29.502-.256.91-.664 1.165-1.166.291-.57.291-1.317.291-2.81v-4.8c0-1.494 0-2.24-.29-2.811A2.667 2.667 0 0 0 16.043 1.2c-.57-.291-1.317-.291-2.81-.291H5.766c-1.494 0-2.24 0-2.811.29-.502.256-.91.664-1.165 1.166-.291.57-.291 1.317-.291 2.81v11.143c0 .474 0 .71.097.832a.444.444 0 0 0 .348.168c.156 0 .34-.149.71-.444l2.12-1.697c.434-.346.65-.52.892-.643.214-.109.441-.189.677-.237.265-.055.542-.055 1.097-.055"
    />
  </svg>
);
export default SvgSms;
