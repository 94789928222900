/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type RequestID = Brand<string, 'REQUEST_ID'>;

export type SignalRCallMethods = 'send' | 'invoke';

export interface SignalRSignInReturn {
  serviceUrl: string;
  accessToken: string;
}

export type SignalRSignInMethod = () => Promise<SignalRSignInReturn>;

export enum SignalRHubName {
  BILL = 'BILL',
  INVOICE = 'INVOICE',
}

export interface SignalRHubInfo {
  authMethod: SignalRSignInMethod;
  senders?: { name: string; method: SignalRCallMethods; data: any }[];
  listeners?: { name: string; action: ActionCreatorWithPayload<any> }[];
}

export interface FailedQueueItem {
  eventName: string;
  data: any;
  method: SignalRCallMethods | 'on';
}

export type MountSingleSendFn = (hubName: SignalRHubName) => (args: [string, any]) => Promise<RequestID>;

export type MountRegisterListenerFn = (
  hubName: SignalRHubName
) => (eventName: string, callback: (item: any) => void) => void;

export type MountRemoveListenerFn = (
  hubName: SignalRHubName
) => (eventName: string, callback: (item: any) => void) => void;

export interface SignalRErrors {
  connectionReachedMaxRetries: boolean;
}

export type GetErrorsFn = (hubName: SignalRHubName) => SignalRErrors;
