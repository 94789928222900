import { createContext } from 'react';
import {
  BuildingAsideStage,
  BuildingData,
  BuildingOrder,
  BuildingOrderBy,
  BuildingSearchBy,
  LinkContract,
} from '../../../../../@types/building';
import { Layout } from '../../../../../hooks/useLayout';
import { GetPaginationPropsFn } from '../../../../../hooks/usePagination';

export interface BuildingContextProps {
  data: Array<BuildingData>;
  clearSearch: VoidFunction;
  toggleLayout: VoidFunction;
  closeSearchModal: VoidFunction;
  openSearchAction: VoidFunction;
  requestSort: (key: BuildingOrderBy, direction?: 'asc' | 'desc') => void;
  requestSearch: (newSearch: string, newSearchKeys: Array<BuildingSearchBy>) => void;
  search: string;
  order: BuildingOrder | null;
  handleOrder: (newOrder: BuildingOrder | null) => void;
  fetchNewContract: (newContract: LinkContract, signal?: AbortSignal) => Promise<BuildingData[]>;
  surveyedContractIsAlreadyOnList: (buildingData: BuildingData[]) => boolean;
  linkNewContract: (newContract: LinkContract) => Promise<void>;
  unlinkNewContract: (unlinkContracts: Array<string>) => Promise<void>;
  handleEditItem: (id: number | string, buildingData: BuildingData) => void;
  favoriteBuilding: (data: BuildingData, signal: AbortSignal) => void;
  currentPage: BuildingData[];
  loading: boolean;
  layout: Layout;
  showGrid: boolean;
  handleSearch: (newSearch: string) => void;
  searchKeys: Array<BuildingSearchBy>;
  handleSearchKeys: (newSearchKeys: Array<BuildingSearchBy>) => void;
  dataIsEmpty: boolean;
  filterIsEmpty: boolean;
  searchModalIsOpen: boolean;
  asideActionsIsOpen: boolean;
  asideStage: BuildingAsideStage;
  setAsideStage: SetState<BuildingAsideStage>;
  openAsideActions: VoidFunction;
  closeAsideActions: VoidFunction;
  getPaginationProps: GetPaginationPropsFn;
}

const BuildingContext = createContext<BuildingContextProps | null>(null);

export default BuildingContext;
