import * as React from 'react';
import type { SVGProps } from 'react';

const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={15} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1.5 3.614 6.532 4.572c.529.37.793.556 1.081.627a1.6 1.6 0 0 0 .774 0c.288-.071.552-.256 1.081-.627L17.5 3.614m-12.16 10.4h8.32c1.344 0 2.016 0 2.53-.262a2.4 2.4 0 0 0 1.048-1.048c.262-.514.262-1.186.262-2.53v-5.12c0-1.344 0-2.016-.262-2.53a2.4 2.4 0 0 0-1.048-1.048c-.514-.262-1.186-.262-2.53-.262H5.34c-1.344 0-2.016 0-2.53.262a2.4 2.4 0 0 0-1.048 1.048C1.5 3.038 1.5 3.71 1.5 5.054v5.12c0 1.344 0 2.016.262 2.53a2.4 2.4 0 0 0 1.048 1.048c.514.262 1.186.262 2.53.262Z"
    />
  </svg>
);
export default SvgMessage;
