import styled from 'styled-components';
import theme from '../../../../../../styles/theme';
import { BREAKPOINTS } from '../../../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #434343;
  box-shadow: 0 0 14px ${theme.color.secondary.grey[5]};
  min-width: 175px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAKPOINTS.mobile}px) {
    border: none !important;
    box-shadow: unset;
    gap: 24px;

    .header {
      font-weight: 500;
      font-size: 18px;
    }

    .label,
    .content {
      font-size: 16px !important;
    }
  }
`;

export const ContractWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    font-style: normal;
    font-weight: 400;
    color: #262626;
  }

  .content {
    font-family: 'TKEType-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #262626;
  }
`;
