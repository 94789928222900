import React, { useContext, useMemo } from 'react';
import { Container } from './style';
import AsidePortal from '../../../../../../../layouts/AsidePortal';
import ActionSelect from './components/SelectStep';
import AsideActionsContext from './context';
import FilterStep from './components/FilterStep';
import NewContractStep from './components/SearchNewBuildingStep';
import { BuildingAsideStage } from '../../../../../../../@types/building';
import BuildingContext, { BuildingContextProps } from '../../context';
import TextIconButton from '../../../../../../../components/TextIconButton';
import { CloseIcon } from '../../../../../../../icons';

const stepMapping = {
  [BuildingAsideStage.SELECT_ACTION]: ActionSelect,
  [BuildingAsideStage.FILTER_ACTION]: FilterStep,
  [BuildingAsideStage.SEARCH_NEW_BUILDING]: NewContractStep,
} as const;

const AsideActions = () => {
  const {
    dataIsEmpty,
    search,
    clearSearch,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
  } = useContext(BuildingContext) as BuildingContextProps;

  const openHandler = () => {
    openAsideActions();
  };

  const closeHandler = () => {
    closeAsideActions();
    setAsideStage(BuildingAsideStage.SELECT_ACTION);
  };

  const changeStage = (newStage: BuildingAsideStage) => {
    setAsideStage(newStage);
  };

  const context = useMemo(
    () => ({
      changeStage,
      closeHandler,
    }),
    []
  );

  const Step = stepMapping[asideStage];

  const showButtons = !dataIsEmpty;

  return (
    <AsideActionsContext.Provider value={context}>
      <AsidePortal visible={asideActionsIsOpen}>
        <Step />
      </AsidePortal>
      {showButtons && (
        <Container>
          <input readOnly placeholder="Buscar" onClick={openHandler} />
          {search && (
            <TextIconButton onClick={clearSearch} label="Busca ativada" icon={<CloseIcon width={12} height={12} />} />
          )}
        </Container>
      )}
    </AsideActionsContext.Provider>
  );
};

export default AsideActions;
