import { createContext } from 'react';
import { FormStage } from '../../../../type';

export interface EmailActionContextProps {
  setStage: SetState<FormStage>;
  stage: FormStage;
  selectedEmail: string;
  setSelectedEmail: SetState<string>;
}

const EmailActionContext = createContext<EmailActionContextProps | null>(null);

export default EmailActionContext;
