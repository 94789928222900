import { BuildingOrderBy, BuildingSearchBy, ContractType } from '../../../../../@types/building';

export const BUILDING_ORDER_OPTIONS: Array<{ label: string; option: BuildingOrderBy }> = [
  {
    option: 'favorite',
    label: 'Favorito',
  },
  {
    option: 'id',
    label: 'Número do edifício',
  },
  {
    option: 'clientName',
    label: 'Cliente',
  },
  {
    option: 'city',
    label: 'Localização',
  },
  {
    option: 'document',
    label: 'CPF ou CNPJ',
  },
];

export const BUILDING_FILTER_OPTIONS: Array<{ label: string; option: BuildingSearchBy }> = [
  {
    label: 'Número do edifício',
    option: 'id',
  },
  {
    label: 'Cliente',
    option: 'clientName',
  },
  {
    label: 'Contrato',
    option: 'contract',
  },
  {
    label: 'CPF ou CNPJ',
    option: 'document',
  },
  {
    label: 'Cidade',
    option: 'city',
  },
  {
    label: 'UF',
    option: 'uf',
  },
];

export const MAP_BUSINESS_CONTRACT_SLUG: Record<ContractType, string> = {
  [ContractType.VS]: 'Vendas de serviço',
  [ContractType.ON]: 'Novas instalações',
  [ContractType.MI]: 'Modernização integral',
  [ContractType.MP]: 'Modernização parcial',
  [ContractType.ACCESSIBILITY]: 'Acessibilidade',
  [ContractType.ESP]: 'Especiais',
};

export const MAP_CONSULTANT_TYPE: Record<number, string> = {
  1: 'Consultor comercial',
  2: 'Consultor técnico',
};
