export const ERROR_403_PAGE_TITLE = '(Erro 403) Ops... Acesso negado.';
export const ERROR_403_PAGE_DESCRIPTION =
  'Você não tem permissão de acesso a esta área. Caso seja um erro, por favor, contate nosso suporte via chat.';

export const ERROR_404_PAGE_TITLE = '(Erro 404) Ops... Página não encontrada.';
export const ERROR_404_PAGE_DESCRIPTION =
  'Esta página não existe. Por favor, confira a URL ou tente novamente mais tarde.';

export const DEFAULT_LOGIN_FORM_TITLE = 'Acesse sua conta';
export const DEFAULT_REGISTER_FORM_TITLE = 'Crie sua conta';
export const DEFAULT_FORGOT_FORM_TITLE = 'Solicite uma nova senha';
export const DEFAULT_FORGOT_FORM_TITLE_CREATE = 'Crie uma nova senha';
export const DEFAULT_LOGIN_FORM_DISCLAIMER =
  'Olá, crie sua conta pessoal com seu CPF no primeiro acesso ao sistema. Este processo é necessário para assegurar conformidade legal e garantir acesso aos documentos, sejam eles de pessoa física ou jurídica.';
export const DEFAULT_CAPTCHA_DISCLAIMER = 'Para continuar preencha o CAPTCHA.';
export const DEFAULT_REQUEST_CODE_DISCLAIMER = 'Vamos enviar um código via SMS ou e-mail. Escolha a melhor forma:';

export const LOGIN_FORM_USER_TYPE_TITLE = 'Bem vindo!';
export const LOGIN_FORM_USER_TYPE_DISCLAIMER = `Cliente TK Elevator Brasil, estamos construindo um novo portal para você. Em primeiro momento, você encontrará informações sobre seu financeiro e sua instalação, além da gestão de nosso relacionamento digital. Em breve mais novidades!`;
export const LOGIN_FORM_FEEDBACK_TITLE = 'Seu código foi validado!';
export const LOGIN_FORM_FEEDBACK_DISCLAIMER = 'Vamos redirecioná-lo para o seu painel.';
export const LOGIN_FORM_SUBMIT_CODE_DISCLAIMER = 'Digite o código recebido e realize seu acesso.';
export const LOGIN_FORM_SUBMIT_CODE_DISCLAIMER_CREATE = 'Digite o código recebido e cadastre uma nova senha.';

export const REGISTER_BACK_TEXT = 'Voltar para o cadastro.';
export const REGISTER_FORM_DESCRIPTION = 'Forneça abaixo seus dados para o novo acesso.';

export const REGISTER_FORM_FEEDBACK_TITLE = 'Seu cadastro foi criado!';
export const REGISTER_FORM_FEEDBACK_DISCLAIMER = 'Vamos redirecioná-lo para a página de acesso à conta.';

export const FORGOT_FORM_FEEDBACK_TITLE = 'Sua senha foi redefinida!';
export const FORGOT_FORM_FEEDBACK_DISCLAIMER = 'Vamos redirecioná-lo para a página de acesso à conta.';

export const FORGOT_FORM_DESCRIPTION = 'Você receberá orientações para criar uma nova senha.';
export const FORGOT_BACK_TEXT = 'Lembrei minha senha, voltar.';

export const EXTERNAL_APP_TKE_DIGITAL_APP_TITLE = 'TKE Digital App';
export const EXTERNAL_APP_TKE_DIGITAL_APP_DESCRIPTION =
  'Com autosserviços na palma da mão, o TKE Digital App facilita o dia a dia de visitantes, moradores e administradores, possibilitando desde a chamada remota do seu equipamento, à gestão completa de seus contratos de serviços.';

export const EXTERNAL_APP_BIM_TITLE = 'BIM';
export const EXTERNAL_APP_BIM_DESCRIPTION =
  'Descubra o equipamento TKE que melhor se enquadra no seu projeto técnico. Escolha as configurações ideais para o seu empreendimento, baixe o modelo (Revit) e insira, adapte e analise os detalhes do equipamento.';
export const EXTERNAL_MAX_TITLE = 'Serviços MAX';
export const EXTERNAL_MAX_DESCRIPTION =
  'Com o MAX, nossa tecnologia de monitoramento em tempo real, você acompanha as atividades detalhadas de seus equipamentos, acessando relatórios completos de desempenho e uso, aliado à manutenção inteligente.';

export const NEW_BUILDING_TITLE = 'Para buscar novo contrato, preencha todos os campos: CPF ou CNPJ + Nº Contrato.';
export const NEW_BUILDING_CONTRACT_NUMBER_DISCLAIMER = `Atenção ao inserir número de contrato. Exemplo de formato: Nova instalação (123456/56), Modernização integral (123456/56), Modernização parcial (123456) e Serviço (123456).`;

export const SELECT_DATE_INVOICE_TITLE = 'Configurar data de emissão.';
export const SELECT_DATE_INVOICE_DESCRIPTION = 'Visualize as notas fiscais por data de emissão configurando o período.';
export const SELECT_DATE_BILL_TITLE = 'Configurar data de vencimento.';
export const SELECT_DATE_BILL_DESCRIPTION =
  'Visualize os boletos por data de vencimento original configurando o período.';

export const BILL_DOCUMENT_REDIRECT_TITLE = 'Estamos direcionando você para a visualização da impressão do boleto.';
export const INVOICE_DOCUMENT_REDIRECT_TITLE =
  'Estamos direcionando você para a visualização da impressão da nota fiscal.';
