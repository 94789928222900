import { CustomEvents } from '../../@types/events';
import EventManager from '../../lib/EventManager';
import { DocumentItemType, ToDownloadArray } from './types';

export interface DownloadPayload {
  toDownload: ToDownloadArray;
}

export const downloadEventManager = new EventManager<DownloadPayload>();

export function emitDownloadWithSignalRMessage(toDownload: ToDownloadArray) {
  downloadEventManager.emit(CustomEvents.DOWNLOAD_WITH_SIGNALR, { toDownload });
}

export const mapDownloadArray = (
  arr: Array<{ id: string; description: string; grouper: string }>,
  type: DocumentItemType
) => arr.map((props) => ({ ...props, type }));

export function emitBillDownload(toDownload: string): void;
export function emitBillDownload(toDownload: { id: string; description: string; grouper: string }[]): void;
export function emitBillDownload(toDownload: Array<{ id: string; description: string; grouper: string }> | string) {
  if (Array.isArray(toDownload)) {
    emitDownloadWithSignalRMessage(mapDownloadArray(toDownload, DocumentItemType.BILL));
    return;
  }

  emitDownloadWithSignalRMessage([
    { id: toDownload, type: DocumentItemType.BILL, grouper: undefined, description: undefined },
  ]);
}

export function emitInvoiceDownload(toDownload: string): void;
export function emitInvoiceDownload(toDownload: { id: string; description: string; grouper: string }[]): void;
export function emitInvoiceDownload(toDownload: Array<{ id: string; description: string; grouper: string }> | string) {
  if (Array.isArray(toDownload)) {
    emitDownloadWithSignalRMessage(mapDownloadArray(toDownload, DocumentItemType.INVOICE));
    return;
  }

  emitDownloadWithSignalRMessage([
    { id: toDownload, type: DocumentItemType.INVOICE, grouper: undefined, description: undefined },
  ]);
}
