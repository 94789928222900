import React, { useState } from 'react';

const useToggle = (defaultValue: boolean) => {
  const [state, setState] = useState(defaultValue);

  return [state, () => setState((x) => !x), setState] as [
    boolean,
    VoidFunction,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
};

export default useToggle;
