import { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: translate3d(0,-20px,0);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);

  }
`;

const MAX_Z_INDEX_SIZE = 2147483647;

const theme = {
  color: {
    primary: {
      white: '#FFFFFF',
      black: '#262626',
      highlight: '#F16B08',
      interaction: '#7000BD',
    },
    secondary: {
      purple: {
        1: '#59008D',
        2: '#A31AFF',
        3: '#DAA3FF',
        4: '#EDD1FF',
      },
      orange: {
        1: '#D65200',
        2: '#FF8708',
        3: '#FFBE7A',
        4: '#FFE6CE',
      },
      grey: {
        1: '#262626',
        2: '#434343',
        3: '#9D9D9D',
        4: '#C4C4C4',
        5: '#E9E9E9',
        6: '#f6f6f6',
      },
    },
    background: {
      dark: '#1A1A1A',
    },
    feedback: {
      error: '#DE302D',
      success: '#237C00',
      alert: '#F9CA29',
    },
  },
  font: {
    size: {},
  },
  defaults: {
    headerHeight: 56,
    disabled: css`
      pointer-events: none;
      user-select: none;
    `,
    maxZIndex: MAX_Z_INDEX_SIZE,
    alertZIndex: MAX_Z_INDEX_SIZE - 1,
    asidePortalZIndex: MAX_Z_INDEX_SIZE - 2,
    modalZIndex: MAX_Z_INDEX_SIZE - 3,
    downloadListZIndex: MAX_Z_INDEX_SIZE - 4,
    chatBotZIndex: MAX_Z_INDEX_SIZE - 5,
    floatingZIndex: MAX_Z_INDEX_SIZE - 6,
    tableHeaderZIndex: MAX_Z_INDEX_SIZE - 7,
  },
  animations: {
    fadeIn: css`
      animation: ${fadeIn} 0.2s forwards;
      transition: opacity 0.25s ease-in-out;
      -moz-transition: opacity 0.25s ease-in-out;
      -webkit-transition: opacity 0.25s ease-in-out;
    `,
  },
};

export default theme;
