import React from 'react';
import { Container, DownloadItemBody, DownloadListBody, DownloadListErrorAdvisor, DownloadListHeader } from './style';
import CustomText from '../../components/CustomText';
import ProgressBar from '../../components/ProgressBar';
import { WrapperButton } from '../../styles/components';
import useDownloadList from '../../config/download/hooks/useDownloadList';
import useDisclosure from '../../hooks/useDisclosure';
import Alert from '../../components/Toast/toast';
import { ArrowIcon, ChevronArrowIcon, CloseIcon } from '../../icons';
import { DownloadItemStatus } from '../../config/download/types';
import { compose } from '../../utils/helpers/string';
import CancelDownloadModal from './components/CancelDownloadModal';
import ErrorDownloadModal from './components/ErrorDownloadModal';
import theme from '../../styles/theme';

interface DownloadListItemProps {
  handleCancel: VoidFunction;
  quantity: number;
  status: DownloadItemStatus;
}

const mapLabelByStatus = {
  IN_PROGRESS: 'Compactando $ arquivos',
  CANCELED: 'O download foi cancelado.',
  ERROR: 'Ocorreu um erro no download.',
  SUCCESS: 'O download foi concluído.',
};

const mapColorByStatus = {
  CANCELED: theme.color.feedback.error,
  ERROR: theme.color.feedback.error,
  SUCCESS: theme.color.feedback.success,
};

const DownloadListItem = ({ status, handleCancel, quantity }: DownloadListItemProps) => {
  const { isOpen: cancelModalIsOpen, open: openCancelModal, close: closeCancelModal } = useDisclosure();

  const onSubmitCancel = () => {
    closeCancelModal();
    Alert.SUCCESS('Download cancelado.');
    handleCancel();
  };

  const isError = status !== DownloadItemStatus.IN_PROGRESS;

  return (
    <DownloadItemBody $color={status !== DownloadItemStatus.IN_PROGRESS ? mapColorByStatus[status] : undefined}>
      <CancelDownloadModal visible={cancelModalIsOpen} onClose={closeCancelModal} onSubmit={onSubmitCancel} />
      <div>
        <CustomText>{compose(mapLabelByStatus[status], '$', String(quantity))}</CustomText>
        <WrapperButton onClick={openCancelModal} disabled={isError}>
          <CloseIcon width={12} height={12} />
        </WrapperButton>
      </div>
      <ProgressBar infinite={status === DownloadItemStatus.IN_PROGRESS} />
    </DownloadItemBody>
  );
};

const DownloadList = () => {
  const {
    downloadList,
    cancelAllModalIsOpen,
    handleCloseCancelAllModal,
    handleOpenCancelAllModal,
    onSubmitCancelAll,
    isOpen,
    toggle,
    errorList,
    hasErrors,
    errorModalIsOpen,
    closeErrorModal,
    openErrorModal,
  } = useDownloadList();

  return downloadList.length > 0 ? (
    <Container>
      <CancelDownloadModal
        visible={cancelAllModalIsOpen}
        onClose={handleCloseCancelAllModal}
        onSubmit={onSubmitCancelAll}
      />
      <ErrorDownloadModal visible={errorModalIsOpen} onClose={closeErrorModal} errors={errorList} />
      <DownloadListHeader isOpen={isOpen}>
        <CustomText>Download</CustomText>
        <div>
          <WrapperButton onClick={toggle}>
            <ChevronArrowIcon className="dropdown-icon" />
          </WrapperButton>
          <WrapperButton onClick={handleOpenCancelAllModal}>
            <CloseIcon width={12} height={12} />
          </WrapperButton>
        </div>
      </DownloadListHeader>
      {hasErrors && (
        <DownloadListErrorAdvisor>
          <CustomText>Alguns arquivos não foram baixados verificar o motivo</CustomText>
          <WrapperButton onClick={openErrorModal} cursor="pointer">
            <ArrowIcon className="rotate-180" />
          </WrapperButton>
        </DownloadListErrorAdvisor>
      )}
      {isOpen && (
        <DownloadListBody>
          {downloadList.map(({ id, ...props }) => (
            <DownloadListItem key={`DownloadItem-${id}`} {...props} />
          ))}
        </DownloadListBody>
      )}
    </Container>
  ) : null;
};

export default DownloadList;
