import styled from 'styled-components';
import { boxStyle } from '../../../../../styles/components';
import theme from '../../../../../styles/theme';
import { BREAKPOINTS } from '../../../../../utils/constants/breakpoints';

export const MobileHeaderContainer = styled.header`
  ${boxStyle};

  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  box-shadow: unset;

  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
  }

  gap: 1.2rem;

  .status-badge {
    align-self: flex-end;
  }

  .mobile_header_tooltip {
    width: 100px !important;
    background-color: #4cd964;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  padding: 8px 24px 0 24px;

  .bill_title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  .mobile_header_title {
    color: ${theme.color.primary.interaction};
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const FinancialDetailsMobileMenu = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 16px;
  flex-direction: column;

  p {
    font-size: 1.4rem;
  }
`;
