import * as React from 'react';
import type { SVGProps } from 'react';

const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M6.934 3.672v-.15H1.21v.8H6.934v-.65ZM6.934 12.172v-.15H1.21v.8H6.934v-.65ZM7.01 7.922v-.15H1.21v.8H7.01v-.65ZM15.509 3.672v-.15H9.784v.8H15.509v-.65ZM15.509 12.172v-.15H9.784v.8H15.509v-.65ZM15.51 7.922v-.15H9.71v.8H15.51v-.65Z"
    />
  </svg>
);
export default SvgList;
