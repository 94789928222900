import * as React from 'react';
import type { SVGProps } from 'react';

const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
      d="M.95 4.992v.05h4.1V1.228H.95v3.764Zm.6-3.2h2.9v2.686h-2.9V1.792ZM.95 9.635v.05h4.1V5.871H.95v3.764Zm.6-3.2h2.9v2.686h-2.9V6.435ZM5.95 4.992v.05h4.1V1.228h-4.1v3.764Zm.6-3.2h2.9v2.686h-2.9V1.792ZM5.95 9.635v.05h4.1V5.871h-4.1v3.764Zm.6-3.2h2.9v2.686h-2.9V6.435ZM5.95 14.278v.05h4.1V10.513h-4.1V14.279Zm.6-3.2h2.9v2.686h-2.9v-2.686ZM11 1.228h-.05V5.042h4.1V1.228H11Zm3.45 3.25h-2.9V1.792h2.9v2.686ZM10.95 9.635v.05h4.1V5.871h-4.1v3.764Zm.6-3.2h2.9v2.686h-2.9V6.435ZM10.95 14.278v.05h4.1V10.513h-4.1V14.279Zm.6-3.2h2.9v2.686h-2.9v-2.686ZM.95 12.42v.05H1.55V11.079h2.9v2.686H.95V14.328h4.1V10.513H.95V12.421Z"
    />
  </svg>
);
export default SvgGrid;
