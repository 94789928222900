import React, { useState } from 'react';
import YearHeader from '../YearHeader';
import { Container, YearButton } from './style';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

const paginate = (yearsPerPage: number, startYear: number, endYear: number) => {
  const estimatedTotalPages = Math.ceil((endYear - startYear) / yearsPerPage);
  const yearPages = [];

  for (let i = 0; i < estimatedTotalPages; i++) {
    const start = startYear + i * yearsPerPage;
    const end = start + yearsPerPage - 1;
    const pageYears = [];

    for (let year = start; year <= end && year <= endYear; year++) {
      pageYears.push(year);
    }

    yearPages.push(pageYears);
  }

  return yearPages.filter((yearPage) => yearPage.length === yearsPerPage);
};

interface YearPickerProps {
  activeDate: Date;
  onChangeYear: (newActiveDate: number) => void;
}

const YearPicker = ({ activeDate, onChangeYear }: YearPickerProps) => {
  const { isMobileWidth } = useWindowDimensions();

  const startYear = 1945;
  const endYear = new Date().getFullYear() + 100;
  const yearsPerPage = isMobileWidth ? 18 : 25;
  const yearPages = paginate(yearsPerPage, startYear, endYear);
  const totalPages = yearPages.length;

  const [currentPageIndex, setCurrentPageIndex] = useState(
    yearPages.findIndex((page) => page.includes(new Date().getFullYear()))
  );

  const handleYearChange = (year: number) => {
    onChangeYear(year);
  };

  const hasPreviousPage = currentPageIndex > 0;
  const hasNextPage = currentPageIndex < totalPages - 1;

  const goToPreviousPage = () => {
    if (!hasPreviousPage) return;
    setCurrentPageIndex((prevIndex) => prevIndex - 1);
  };

  const goToNextPage = () => {
    if (!hasNextPage) return;
    setCurrentPageIndex((prevIndex) => prevIndex + 1);
  };

  const currentPage = yearPages[currentPageIndex];

  return (
    <Container>
      <YearHeader
        onPrevious={goToPreviousPage}
        onNext={goToNextPage}
        disableNext={!hasNextPage}
        disablePrevious={!hasPreviousPage}
      />
      <div className="years">
        {currentPage.map((year) => (
          <YearButton
            isActive={year === activeDate.getFullYear()}
            type="button"
            key={year}
            onClick={() => handleYearChange(year)}
          >
            {year}
          </YearButton>
        ))}
      </div>
    </Container>
  );
};

export default YearPicker;
