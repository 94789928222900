import React, { useContext } from 'react';

import FilterButton from './components/FilterButton';
import { Container } from './style';
import FinancialHistoricContext, { FinancialHistoricContextProps } from '../../context';
import { WrapperDiv } from '../../../../../../../styles/components';
import ToggleViewButton from '../../../../../../../components/ToggleViewButton';
import { SearchContainer } from '../../../../../shared/style';
import OrderButton from '../../../../../shared/components/OrderButton';
import { FINANCIAL_HISTORIC_ORDER_OPTIONS } from '../../../../constants';
import Search from '../../../../../../../components/Search';

const DesktopActions = () => {
  const { toggleLayout, layout, showGrid, order, search, handleSearch, handleOrder, actionsIsDisabled } = useContext(
    FinancialHistoricContext
  ) as FinancialHistoricContextProps;

  return (
    <Container>
      <WrapperDiv gap={16}>
        <ToggleViewButton onClick={toggleLayout} layout={layout} />
      </WrapperDiv>
      <SearchContainer>
        {showGrid && (
          <OrderButton
            order={order}
            handleOrder={handleOrder}
            options={FINANCIAL_HISTORIC_ORDER_OPTIONS}
            disabled={actionsIsDisabled}
          />
        )}
        <Search
          disabled={actionsIsDisabled}
          value={search}
          onChange={(event) => handleSearch(event.target.value)}
          placeholder="Buscar"
        >
          <FilterButton />
        </Search>
      </SearchContainer>
    </Container>
  );
};

export default DesktopActions;
