import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';

export const Container = styled.main`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const FormBox = styled.div`
  display: grid;
  grid-template-columns: 432px 432px 220px;
  gap: 24px;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  button {
    width: 160px;
    min-width: 160px;
    padding: 0;
  }

  @media (max-width: 1200px) {
    width: 432px;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    color: #000000;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 16px;
  }
`;
