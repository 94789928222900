import styled from 'styled-components';
import CLIENT_REGISTER_BG_IMAGE from '../../../assets/images/client_register_bg.png';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

export const AuthBackgroundWithImage = styled.div<{ imageUrl?: string }>`
  background-image: ${() => `url(${CLIENT_REGISTER_BG_IMAGE})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`;
