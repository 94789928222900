import React, { useContext } from 'react';
import BillContext, { BillContextProps } from '../../context';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import DisabledBillHeader from '../DisabledBillHeader';
import { ListContainer, ViewContainer, WarningContainer } from '../../../../style';
import CustomText from '../../../../../../../components/CustomText';
import BillGrid from '../BillGrid';
import BillTable from '../BillTable';
import Button from '../../../../../../../components/Button';
import { WrapperDiv } from '../../../../../../../styles/components';

const BillContent = () => {
  const {
    data,
    getSelectionProps,
    requestSort,
    order,
    loading,
    range,
    showGrid,
    signalRConnectionReachedMaxRetries,
    dataIsEmpty,
    filterIsEmpty,
    openSearchAction,
    openSelectDateAction,
  } = useContext(BillContext) as BillContextProps;
  const { isMobileWidth } = useWindowDimensions();

  if (loading) {
    return (
      <>
        {!isMobileWidth && <DisabledBillHeader />}
        <WarningContainer obfuscate>
          <CustomText as="h1">{range ? 'Configurando data, aguarde...' : 'Buscando boletos...'}</CustomText>
        </WarningContainer>
      </>
    );
  }

  if (filterIsEmpty) {
    return (
      <>
        {!isMobileWidth && <DisabledBillHeader />}
        <WarningContainer>
          <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
        </WarningContainer>
      </>
    );
  }

  if (signalRConnectionReachedMaxRetries) {
    return (
      <>
        {!isMobileWidth && <DisabledBillHeader />}
        <WarningContainer>
          <CustomText as="h1">
            Desculpe, este serviço não está disponível para essa rede, tente se conectar por outra rede.
          </CustomText>
        </WarningContainer>
      </>
    );
  }

  if (dataIsEmpty) {
    return (
      <WarningContainer>
        <CustomText as="h1">Você não possui nenhum boleto na sua lista.</CustomText>
        <WrapperDiv gap={24}>
          <Button
            variant="primary"
            size="small"
            onClick={openSelectDateAction}
            disabled={loading || signalRConnectionReachedMaxRetries}
          >
            Configurar data
          </Button>
          <Button variant="primary" size="small" onClick={openSearchAction} disabled={loading}>
            Buscar boletos
          </Button>
        </WrapperDiv>
      </WarningContainer>
    );
  }

  return (
    <ListContainer>
      <ViewContainer>
        {showGrid ? (
          <BillGrid data={data} getSelectionProps={getSelectionProps} />
        ) : (
          <BillTable data={data} getSelectionProps={getSelectionProps} requestSort={requestSort} order={order} />
        )}
      </ViewContainer>
    </ListContainer>
  );
};

export default BillContent;
