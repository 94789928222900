import React, { PropsWithChildren, ReactNode } from 'react';
import useSelectDataOptions from '../../../../../../hooks/useSelectDataOptions';
import useSelectEmail from '../../../../../../hooks/useSelectEmail';
import { useWindowDimensions } from '../../../../../../hooks/useWindowDimensions';
import AsidePortal from '../../../../../../layouts/AsidePortal';
import ModalPortal from '../../../../../../layouts/ModalPortal';
import DesktopSendEmail from '../shared/components/DesktopSendEmail';
import MobileSendEmail from '../shared/components/MobileSendEmail';
import SelectDataType from '../shared/components/SelectDataType';
import { SendEmailDataOnSubmitFn } from './types';

type SendEmailActionProps = {
  visible: boolean;
  onClose: VoidFunction;
  onSubmit: SendEmailDataOnSubmitFn;
  title: string;
  submitButtonLabel: string;
  selectLabel: string;
  description: ReactNode | null | undefined;
  loading: boolean;
};

const SendEmailDataAction = ({
  visible,
  onClose,
  onSubmit,
  submitButtonLabel,
  title,
  children,
  selectLabel,
  description,
  loading,
}: PropsWithChildren<SendEmailActionProps>) => {
  const { isMobileWidth } = useWindowDimensions();
  const selectEmailProps = useSelectEmail();
  const selectDataTypeProps = useSelectDataOptions();

  const handleSubmit = () => {
    if (selectEmailProps.selectedOption === 'custom_email') {
      if (!selectEmailProps.customEmail) {
        selectEmailProps.setCustomEmailError('E-mail inválido');
        return;
      }
    }

    onSubmit({
      selectedOption: selectEmailProps.selectedOption,
      customEmail: selectEmailProps.customEmail,
      dataType: selectDataTypeProps.selectedDataType,
    });
  };

  const handleClose = () => {
    selectEmailProps.clearValues();
    onClose();
  };

  return isMobileWidth ? (
    <AsidePortal visible={visible}>
      <MobileSendEmail
        {...selectEmailProps}
        {...selectDataTypeProps}
        submitButtonLabel={submitButtonLabel}
        onSubmit={handleSubmit}
        onClose={handleClose}
        title={title}
        selectLabel={selectLabel}
        description={description}
        loading={loading}
      >
        <SelectDataType
          selectedDataType={selectDataTypeProps.selectedDataType}
          onChange={selectDataTypeProps.onChangeSelectedDataType}
        />
        {children}
      </MobileSendEmail>
    </AsidePortal>
  ) : (
    <ModalPortal onClose={handleClose} visible={visible}>
      <DesktopSendEmail
        {...selectEmailProps}
        {...selectDataTypeProps}
        selectLabel={selectLabel}
        description={description}
        submitButtonLabel={submitButtonLabel}
        title={title}
        onSubmit={handleSubmit}
        onClose={handleClose}
        loading={loading}
      >
        <SelectDataType
          selectedDataType={selectDataTypeProps.selectedDataType}
          onChange={selectDataTypeProps.onChangeSelectedDataType}
        />
        {children}
      </DesktopSendEmail>
    </ModalPortal>
  );
};

export default SendEmailDataAction;
