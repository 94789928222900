import React, { ReactNode } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import FindContractForm from '../FindContractForm';
import { Content } from '../../../Client/containers/Building/components/AsideActions/style';
import AsideHeader from '../../../../../components/AsideHeader';
import { Container } from './style';
import Button from '../../../../../components/Button';
import { LinkContract } from '../../../../../@types/building';

interface MobileFindContractProps {
  onBack: VoidFunction;
  onSubmit: SubmitHandler<LinkContract>;
  loading: boolean;
  content: ReactNode;
}

const MobileFindContract = ({ onBack, onSubmit, loading, content }: MobileFindContractProps) => {
  const formMethods = useFormContext<LinkContract>();
  const isNotWrappedInFormContext = formMethods === null;

  if (isNotWrappedInFormContext) {
    throw new Error(
      'MobileFindContract: The component is not wrapped within the <MyContext /> context. Ensure that MobileFindContract is within the scope of the provided context.'
    );
  }

  return (
    <>
      <AsideHeader onBack={onBack} />
      <Container>
        <Content>
          {content}
          <FindContractForm loading={loading} mobile />
        </Content>
        <Button
          disabled={loading || !formMethods.formState.isValid}
          loading={loading}
          onClick={formMethods.handleSubmit(onSubmit)}
          type="submit"
          variant="primary"
        >
          Buscar
        </Button>
      </Container>
    </>
  );
};

export default MobileFindContract;
