import React from 'react';
import CustomText from '../../../CustomText';
import { ButtonArrowWrapper, YearHeaderContainer } from '../../style';
import { ChevronArrowIcon } from '../../../../icons';

interface YearHeaderProps {
  onPrevious: VoidFunction;
  onNext: VoidFunction;
  disableNext?: boolean;
  disablePrevious?: boolean;
}

const YearHeader = ({ onPrevious, onNext, disableNext, disablePrevious }: YearHeaderProps) => (
  <YearHeaderContainer>
    <ButtonArrowWrapper onClick={onPrevious} disabled={disablePrevious}>
      <ChevronArrowIcon className="rotate-180" />
    </ButtonArrowWrapper>
    <CustomText>Ano</CustomText>
    <ButtonArrowWrapper onClick={onNext} disabled={disableNext}>
      <ChevronArrowIcon className="arrow arrow-right" />
    </ButtonArrowWrapper>
  </YearHeaderContainer>
);

export default YearHeader;
