import { BillMinimalData, BillOrder } from '../../@types/bill';
import { BILL_STATUS_ORDER } from '../../pages/Dashboard/Client/containers/Bill/constants';

class BillHelper {
  orderMinimalBillData(dataToTransform: BillMinimalData[], order: BillOrder | null) {
    const sortableItems = [...dataToTransform];

    if (!order) {
      return sortableItems.sort((a, b) => {
        if (BILL_STATUS_ORDER[a.status] < BILL_STATUS_ORDER[b.status]) {
          return -1;
        }
        if (BILL_STATUS_ORDER[a.status] > BILL_STATUS_ORDER[b.status]) {
          return 1;
        }

        return 0;
      });
    }

    const isAscendingOrder = order?.direction === 'asc';

    return sortableItems.sort((a, b) => {
      if (order.orderBy === 'status') {
        if (BILL_STATUS_ORDER[a.status] < BILL_STATUS_ORDER[b.status]) {
          return isAscendingOrder ? -1 : 1;
        }
        if (BILL_STATUS_ORDER[a.status] > BILL_STATUS_ORDER[b.status]) {
          return isAscendingOrder ? 1 : -1;
        }

        return 0;
      }
      if (a[order.orderBy] < b[order.orderBy]) {
        return isAscendingOrder ? -1 : 1;
      }
      if (a[order.orderBy] > b[order.orderBy]) {
        return isAscendingOrder ? 1 : -1;
      }

      return 0;
    });
  }
}

export default new BillHelper();
