import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GTMEventName } from '../../../../../../../@types/analytics';
import { BillMinimalData } from '../../../../../../../@types/bill';
import FileActions from '../../../../../../../components/FileActions';
import Alert from '../../../../../../../components/Toast/toast';
import { emitBillDownload } from '../../../../../../../config/download';
import { DocumentItemAction, DocumentItemType } from '../../../../../../../config/download/types';
import useHub from '../../../../../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../../../../../config/signalR/types';
import useDisclosure from '../../../../../../../hooks/useDisclosure';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import documentDialogError from '../../../../../../../layouts/DocumentErrorDialog';
import { sendGTMEvent } from '../../../../../../../lib/DataLayer';
import FinancialService from '../../../../../../../services/Financial/FinancialService';
import BillPolicy from '../../../../../../../strategy/rules/BillPolicy';
import BillDownloadModal from '../../../../../shared/components/BillDownloadModal';
import SendEmailContent from '../../../../../shared/components/BillSendEmailContent';
import SendEmailAction from '../../../../../shared/components/SendEmail/SendEmailAction';
import BillContext, { BillContextProps } from '../../context';

interface BillActionsProps {
  data: BillMinimalData;
  ignoreDropdown?: boolean;
}

const BillActions = ({ data, ignoreDropdown = false }: BillActionsProps) => {
  const { handlePrint } = useContext(BillContext) as BillContextProps;
  const { status, id, hasFullBillAvailable } = data;
  const { isMobileWidth } = useWindowDimensions();
  const navigate = useNavigate();
  const disableActionButtons = !BillPolicy.shouldEnableActionButtons(status, hasFullBillAvailable);
  const disableViewMore = !BillPolicy.shouldEnableAccessToDetails(data);
  const { isOpen: downloadModalIsOpen, close: closeDownloadModal, open: openDownloadModal } = useDisclosure(false);
  const { isOpen: sendEmailIsOpen, close: closeSendEmail, open: openSendEmail } = useDisclosure(false);
  const { singleSend: sendBillMessage } = useHub(SignalRHubName.BILL);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);

  const handleSubmitDownload = () => {
    if (isMobileWidth) {
      sendBillMessage(['RequestDocuments', JSON.stringify([id])]).then(() => {
        emitBillDownload(id);
        sendGTMEvent({ name: GTMEventName.DOWNLOAD_BILL });
      });
      return;
    }

    openDownloadModal();
  };

  const handleSendBillByEmail = async (customEmail: string | null) => {
    setSendEmailIsLoading(true);
    try {
      await FinancialService.sendBillByEmail(id, customEmail);
      Alert.SUCCESS('Boleto enviado');
      sendGTMEvent({ name: GTMEventName.SEND_BILL_BY_EMAIL });
    } catch (e) {
      console.error(e);
      documentDialogError(DocumentItemType.BILL, DocumentItemAction.SENDING_BY_EMAIL);
    } finally {
      setSendEmailIsLoading(false);
      closeSendEmail();
    }
  };

  return (
    <>
      {!disableActionButtons && (
        <SendEmailAction
          loading={sendEmailIsLoading}
          visible={sendEmailIsOpen}
          onClose={closeSendEmail}
          onSubmit={({ customEmail }) => {
            handleSendBillByEmail(customEmail || null);
          }}
          title="Enviar boleto por e-mail."
          submitButtonLabel="Enviar boleto"
          selectLabel="Escolha a melhor forma para lhe enviarmos o boleto e as notas fiscais:"
          description={
            <SendEmailContent
              documentNumber={data.documentNumber}
              expiresAt={data.expiresAt}
              totalAmount={data.totalAmount}
            />
          }
        />
      )}
      {!disableActionButtons && !isMobileWidth && (
        <BillDownloadModal data={[data]} visible={downloadModalIsOpen} onClose={closeDownloadModal} />
      )}
      <FileActions
        dropdownBreakpoint={1600}
        download={{
          disabled: disableActionButtons,
          label: BillPolicy.getFileActionMessageByStatus(status),
          handler: handleSubmitDownload,
        }}
        email={{
          disabled: disableActionButtons,
          label: BillPolicy.getFileActionMessageByStatus(status),
          handler: openSendEmail,
        }}
        print={{
          disabled: disableActionButtons,
          label: BillPolicy.getFileActionMessageByStatus(status),
          handler: () => handlePrint(id),
        }}
        viewButton={{
          label: 'Ver mais',
          handler: () => {
            navigate(`/home/financial-details/${id}`);
            sendGTMEvent({ name: GTMEventName.VIEW_BILL_DETAILS });
          },
          disabled: disableViewMore,
        }}
        ignoreDropdown={ignoreDropdown}
      />
    </>
  );
};

export default BillActions;
