import { isString } from './string';
import { isNumber } from './number';

export const mountMinMaxString = (minWidth: number | undefined, maxWidth: number | undefined) =>
  `minmax(${isNumber(minWidth) ? `${minWidth}px` : 'auto'}, ${
    isNumber(maxWidth) ? `${maxWidth}px` : 'auto'
  })` as CssWidth;

export const mountAutoMinMaxString = (minWidth: number) => mountMinMaxString(minWidth, undefined);

export const mergeClassNames = (...args: unknown[]) =>
  args
    .flat()
    .filter((arg) => isString(arg))
    .join(' ')
    .trim();
