export enum GTMEventName {
  BEGIN_LOGIN = 'begin_login',
  BEGIN_SIGNUP = 'begin_signup',
  LOGIN = 'login',
  SIGNUP = 'signup',
  REDIRECT_TO_FINANCIAL_PORTAL = 'redirect_to_financial_portal',

  VIEW_BILLS = 'view_bills',
  DOWNLOAD_BILL = 'download_bill',
  SEND_BILL_BY_EMAIL = 'send_bill_by_email',
  SEND_BILL_DATA_BY_EMAIL = 'send_bill_data_by_email',
  PRINT_BILL = 'print_bill',
  VIEW_BILL_DETAILS = 'view_bill_details',
  VIEW_BILL_DETAILS_HISTORY = 'view_bill_details_history',
  SEARCH_BILL_BY_DATE = 'search_bill_by_date',
  SEARCH_NEW_BILL = 'search_new_bill',

  VIEW_INVOICES = 'view_invoices',
  DOWNLOAD_INVOICE = 'download_invoice',
  SEND_INVOICE_BY_EMAIL = 'send_invoice_by_email',
  PRINT_INVOICE = 'print_invoice',
  SEARCH_INVOICE_BY_DATE = 'search_invoice_by_date',
  SEARCH_NEW_INVOICE = 'search_new_invoice',

  VIEW_CONTRACTS = 'view_contracts',
  DOWNLOAD_CANCEL = 'download_cancel',
  SEARCH_NEW_CONTRACT = 'search_new_contract',
}

export type GTMEventDataMap = {};

export type GTMEventData = {
  [key in GTMEventName]: key extends keyof GTMEventDataMap ? GTMEventDataMap[key] : undefined;
};

export type GTMEvent<T extends GTMEventName> = GTMEventData[T] extends undefined
  ? { name: T }
  : { name: T; data: GTMEventData[T] };
