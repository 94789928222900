/* eslint-disable @typescript-eslint/no-explicit-any */
export function getProp<T extends object>(object: T, path: PathsOf<T>) {
  return path.split('.').reduce((dive, key) => {
    if (dive && key in dive) {
      return dive[key];
    }
    return undefined;
  }, object as any);
}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
