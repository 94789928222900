import React from 'react';
import CustomText from '../../../../../../../../../components/CustomText';
import DisabledListHeader from '../../../../../../../../../components/TableView/components/DisabledTableHeader';
import { DisabledOrderWrapper } from '../../../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useDisabledTableHeader from '../../../../../../../../../components/TableView/hooks/useDisabledTableHeader';

const DisabledPartnerHeader = () => {
  const props = useDisabledTableHeader([
    {
      width: '120px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Nº Edifício</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '3fr',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Cliente</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '170px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>CNPJ ou CPF</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '1fr',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Localização</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '1fr',
      render: <CustomText>Módulos</CustomText>,
    },
  ]);

  return <DisabledListHeader {...props} />;
};

export default DisabledPartnerHeader;
