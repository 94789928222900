import React from 'react';
import CustomText from '../../../../../../components/CustomText';

const BillErrorMessage = () => (
  <CustomText>
    Falha no download, tente novamente em alguns instantes ou entre em contato pelo chat ou pelo WhatsApp 51 99977 9006
    com o Departamento Financeiro na opção &quot;Demais Solicitações&quot;.
  </CustomText>
);

export default BillErrorMessage;
