import React from 'react';
import AutoGrid from '../../../../../../../layouts/AutoGrid';
import Card from '../../../../../../../components/Card';
import { Icons } from './style';
import BuildingActions from '../BuildingActions';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import { Actions } from '../../style';
import BuildingTooltips from '../../../../../shared/components/BuildingTooltips';
import IntegrationLinks from '../../../../../shared/components/IntegrationLinks';
import { BuildingData } from '../../../../../../../@types/building';
import BuildingPolicy from '../../../../../../../strategy/rules/BuildingPolicy';

interface BuildingGridProps {
  data: BuildingData[];
}

const BuildingGrid = ({ data }: BuildingGridProps) => (
  <AutoGrid>
    {data.map((building) => (
      <Card.Root key={`${building.id}-${building.document}`}>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Nº Edifício</Card.Label>
            <Card.Data>{building.id}</Card.Data>
          </Card.Cell>
          <Icons>
            <BuildingActions data={building} />
          </Icons>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Cliente</Card.Label>
            <Card.Data>{building.clientName}</Card.Data>
          </Card.Cell>
          <Card.Cell>
            <Card.Label>Localização</Card.Label>
            <Card.Data>{`${building.city} - ${building.uf}`}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>CNPJ ou CPF</Card.Label>
            <Card.Data>{maskDocument(building.document)}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell style={{ maxWidth: 'none', gap: '8px' }}>
            <Card.Label>Contratos e telefones úteis</Card.Label>
            <Actions>
              <BuildingTooltips businessContracts={building.contracts} contacts={building.consultants} />
            </Actions>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell style={{ maxWidth: 'none', gap: '8px' }}>
            <Card.Label>Módulos</Card.Label>
            <Actions>
              <IntegrationLinks
                buildingId={building.id}
                contractId={building.contracts[0].id}
                disableBuildings={!BuildingPolicy.clientCanAccessNewBuildings(building)}
              />
            </Actions>
          </Card.Cell>
        </Card.Row>
      </Card.Root>
    ))}
  </AutoGrid>
);

export default BuildingGrid;
