import React, { useContext } from 'react';
import CustomText from '../../../../../components/CustomText';
import FileActions from '../../../../../components/FileActions';
import { BillStatus } from '../../../../../@types/bill';
import BillStatusBadge from '../../../shared/components/BillStatusBadge';
import { DesktopHeaderContainer } from '../../style';
import FinancialDetailsContext, { FinancialDetailsContextProps } from '../../context';
import BillPolicy from '../../../../../strategy/rules/BillPolicy';
import { WrapperButton } from '../../../../../styles/components';
import { ChevronArrowIcon } from '../../../../../icons';

const DesktopHeader = () => {
  const {
    details,
    toggleHistoric,
    backToPanel,
    historicIsOpen,
    openBillSendEmail,
    handleOpenBillDocument,
    handleBillDownload,
  } = useContext(FinancialDetailsContext) as FinancialDetailsContextProps;

  const disableActionButtons = Boolean(details?.status && !BillPolicy.shouldEnableActionButtons(details?.status, true));

  return (
    <DesktopHeaderContainer historicIsOpen={historicIsOpen}>
      <WrapperButton onClick={backToPanel} cursor="pointer">
        <ChevronArrowIcon className="rotate-180" />
      </WrapperButton>
      <CustomText as="h1">
        {details?.client.name} {details?.billNumber}
      </CustomText>
      <FileActions
        download={{
          disabled: disableActionButtons,
          handler: handleBillDownload,
        }}
        email={{
          disabled: disableActionButtons,
          handler: openBillSendEmail,
        }}
        print={{
          disabled: disableActionButtons,
          handler: handleOpenBillDocument,
        }}
        viewButton={{
          label: 'Ver histórico',
          handler: toggleHistoric,
          className: 'historic-button',
        }}
        dropdownBreakpoint={1200}
      />
      {details?.status !== BillStatus.NOT_GENERATED && <BillStatusBadge status={details?.status} />}
    </DesktopHeaderContainer>
  );
};

export default DesktopHeader;
