import React from 'react';
import useTableView from '../../../../../../../components/TableView/hooks/useTableView';
import TableView from '../../../../../../../components/TableView';
import HeaderOrderWrapper from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import CustomText from '../../../../../../../components/CustomText';
import FixedTextCell from '../../../../../../../components/FixedTextCell';
import { InvoiceData, InvoiceOrder, InvoiceOrderBy } from '../../../../../../../@types/invoice';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import { WrapperDiv } from '../../../../../../../styles/components';
import { formatDate } from '../../../../../../../utils/helpers/date';
import { mapInvoiceTypeLabel } from '../../constants';
import InvoiceActions from '../InvoiceActions';
import { GetSelectionPropsFunc } from '../../../../../../../hooks/useDataSelection';
import { mountMinMaxString } from '../../../../../../../utils/helpers/css';

interface InvoiceTableProps {
  data: InvoiceData[];
  requestSort: (key: InvoiceOrderBy, direction?: 'asc' | 'desc') => void;
  order: InvoiceOrder | null;
  style?: React.CSSProperties;
  withSelection?: boolean;
  getSelectionProps?: GetSelectionPropsFunc<InvoiceData>;
}

const InvoiceTable = ({ data, getSelectionProps, requestSort, order, withSelection = true }: InvoiceTableProps) => {
  const { getTableProps } = useTableView<InvoiceData>(
    data,
    [
      {
        key: 'clientName',
        width: '1fr',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientName">
            <CustomText>Cliente</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'clientName',
        render: ({ item }) => <FixedTextCell>{item.clientName || 'Sem nome de cliente'}</FixedTextCell>,
      },
      {
        key: 'clientDocument',
        width: '190px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientDocument">
            <CustomText>CNPJ ou CPF</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'clientDocument',
        render: ({ item }) => (
          <CustomText>{item.clientDocument ? maskDocument(item?.clientDocument) : 'Sem documento'}</CustomText>
        ),
      },
      {
        key: 'contract',
        width: '120px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="contract">
            <CustomText>Contrato</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'contract',
      },
      {
        key: 'invoiceNumber',
        width: mountMinMaxString(200, 220),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="invoiceNumber">
            <CustomText>Nº NF</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'invoiceNumber',
      },
      {
        key: 'type',
        width: '200px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="type">
            <CustomText>Materiais/Serviços</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'type',
        render: ({ item }) => <CustomText>{mapInvoiceTypeLabel[item.type] || 'N/D'}</CustomText>,
      },
      {
        key: 'emittedAt',
        width: mountMinMaxString(130, 150),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="emittedAt">
            <CustomText>Emissão</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'emittedAt',
        render: ({ item: { emittedAt } }) => <CustomText>{formatDate(new Date(emittedAt))}</CustomText>,
      },
      {
        key: 'actions',
        width: 'auto',
        header: (
          <WrapperDiv style={{ width: '100%' }} display="flex" justifyContent="flex-end">
            <CustomText>Ações</CustomText>
          </WrapperDiv>
        ),
        render: ({ item }) => <InvoiceActions data={item} />,
      },
    ],
    {
      withSelection: Boolean(withSelection),
    }
  );

  const tableStyle = getTableProps().style;

  return (
    <TableView
      {...getTableProps()}
      {...(getSelectionProps?.() || { withSelection: false })}
      keyExtractor={(item) => item.id}
      style={tableStyle}
    />
  );
};

export default InvoiceTable;
