import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useQueue from '../../hooks/useQueue';
import ModalPortal from '../../layouts/ModalPortal';
import ModalHeader from '../ModalHeader';
import { dialogEventManager, DialogPayload } from './dialog';
import Button from '../Button';
import { WrapperDiv } from '../../styles/components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import AsidePortal from '../../layouts/AsidePortal';
import AsideHeader from '../AsideHeader';
import CustomText from '../CustomText';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 700px;
  box-sizing: border-box;
  overflow: auto;
  gap: 16px;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  button {
    width: 180px;
  }
`;

const AsideContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
`;

const Dialog = () => {
  const { first, clear, add, remove } = useQueue<DialogPayload>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let id: string | number | NodeJS.Timeout | undefined;

    if (first) {
      id = setTimeout(() => {
        setShow(true);
      }, 300);
    }

    return () => {
      clearTimeout(id);
    };
  }, [first]);

  useEffect(() => {
    async function handleAddDialog(payload: DialogPayload) {
      add(payload);
    }

    dialogEventManager.on('open', handleAddDialog);

    return () => {
      clear();
      dialogEventManager.removeListener('open', handleAddDialog);
    };
  }, []);

  const { isMobileWidth } = useWindowDimensions();

  return isMobileWidth ? (
    <AsidePortal visible={show} onAfterClose={remove}>
      <AsideHeader
        onBack={() => {
          setShow(false);
        }}
      />
      <AsideContainer>
        <WrapperDiv alignItems="initial" flexDirection="column" gap={24}>
          <CustomText style={{ fontSize: '18px' }} bold>
            {first?.title}
          </CustomText>
          {first?.content}
        </WrapperDiv>
        <Button
          size="fit"
          variant="primary"
          onClick={() => {
            setShow(false);
          }}
        >
          Voltar
        </Button>
      </AsideContainer>
    </AsidePortal>
  ) : (
    <ModalPortal visible={show} onAfterClose={remove}>
      <ModalContainer>
        <ModalHeader title={first?.title} />
        {first?.content}
        <WrapperDiv justifyContent="flex-end">
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
            }}
          >
            Voltar
          </Button>
        </WrapperDiv>
      </ModalContainer>
    </ModalPortal>
  );
};

export default Dialog;
