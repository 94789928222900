import styled, { css } from 'styled-components';
import CustomText from '../CustomText';

export const StyledCardRoot = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 1px 1px rgba(228, 228, 228, 0.08);

  ${({ height }) =>
    height
      ? css`
          height: ${height}px;
        `
      : css`
          max-height: 360px;
          min-height: 320px;
        `}
`;

export const StyledCardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  width: 220px;
  gap: 44px;
`;

export const StyledCardLabel = styled(CustomText)`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #262626;
`;

export const StyledCardData = styled(CustomText)<{ bold?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: #262626;

  ${({ bold }) =>
    bold &&
    css`
      font-family: TKEType-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
    `}
`;

export const StyledCardCell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  font-family: 'TKEType-Regular', sans-serif;
  gap: 4px;
`;
