import { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GTMEventName } from '../../../../../@types/analytics';
import {
  INVOICE_ALL_SEARCH_KEYS,
  InvoiceAsideStage,
  InvoiceData,
  InvoiceOrder,
  InvoiceOrderBy,
  InvoiceSearchBy,
} from '../../../../../@types/invoice';
import Alert from '../../../../../components/Toast/toast';
import { emitInvoiceDownload } from '../../../../../config/download';
import useHub from '../../../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../../../config/signalR/types';
import useAsideActions from '../../../../../hooks/useAsideActions';
import useDataSelection from '../../../../../hooks/useDataSelection';
import useDisclosure from '../../../../../hooks/useDisclosure';
import useLayout from '../../../../../hooks/useLayout';
import useSearch from '../../../../../hooks/useSearch';
import useTimedLoading from '../../../../../hooks/useTimedLoading';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { sendGTMEvent } from '../../../../../lib/DataLayer';
import { RootState } from '../../../../../redux/reducers';
import { invoiceActions } from '../../../../../redux/reducers/invoiceSlice';
import InvoiceHelper from '../../../../../strategy/helpers/InvoiceHelper';
import { pipe } from '../../../../../utils/helpers/array';
import { formatDate } from '../../../../../utils/helpers/date';
import getOrderDirection from '../../../../../utils/helpers/getOrderDirection';
import { openBlankPage } from '../../../../../utils/helpers/window';
import { WAIT_TIME_SIGNALR_LOADING } from '../../constants';
import { mapInvoiceTypeLabel } from './constants';

const useInvoice = () => {
  const { layout, toggleLayout, showGrid } = useLayout();
  const { isMobileWidth } = useWindowDimensions();
  const { singleSend: sendInvoiceMessage, errors } = useHub(SignalRHubName.INVOICE);
  const signalRConnectionReachedMaxRetries = errors.connectionReachedMaxRetries;
  const data = useSelector((state: RootState) => state!.invoice.messages);
  const dispatch = useDispatch();
  const [loading, setLoading] = useTimedLoading(WAIT_TIME_SIGNALR_LOADING);
  const [order, setOrder] = useState<InvoiceOrder | null>(null);
  const { isOpen: selectDateIsOpen, close: closeSelectDate, open: openSelectDate } = useDisclosure();
  const { isOpen: searchModalIsOpen, open: openSearchModal, close: closeSearchModal } = useDisclosure();
  const [range, setRange] = useState<DateRange>();
  const { areElementsSelected, selectedData, getSelectionProps, handleSelectAll } = useDataSelection(
    data,
    (item) => item.id
  );
  const [isPending, startTransition] = useTransition();
  const [filteredData, setFilteredData] = useState<Array<InvoiceData>>([]);
  const dataIsEmpty = useMemo(() => data.length < 1 && !loading, [data, loading, range]);
  const filterIsEmpty = useMemo(() => filteredData.length < 1 && !dataIsEmpty, [filteredData, dataIsEmpty]);
  const {
    isOpen: asideActionsIsOpen,
    open: openAsideActions,
    close: closeAsideActions,
    stage: asideStage,
    setStage: setAsideStage,
  } = useAsideActions<InvoiceAsideStage>(InvoiceAsideStage.SELECT_ACTION);

  const actionsIsDisabled = loading || data.length < 1;

  const { applySearch, clearSearch, search, searchKeys, handleSearchKeys, handleSearch } = useSearch({
    data,
    allSearchKeys: INVOICE_ALL_SEARCH_KEYS,
    searchBy: (searchByKeys, searchInvoiceData) => [
      ...searchByKeys.map((key) => {
        if (key === 'emittedAt') {
          return String(formatDate(new Date(searchInvoiceData.emittedAt)));
        }
        if (key === 'type') {
          return String(mapInvoiceTypeLabel[searchInvoiceData.type]);
        }

        return String(searchInvoiceData[key]);
      }),
    ],
  });

  const handleOrder = (newOrder: InvoiceOrder | null) => {
    setOrder(newOrder);
  };

  const requestSearch = useCallback((newSearch: string, newSearchKeys: Array<InvoiceSearchBy>) => {
    handleSearchKeys(newSearchKeys);
    handleSearch(newSearch);
  }, []);

  const applyOrder = (dataToTransform: InvoiceData[]) => InvoiceHelper.orderInvoice(dataToTransform, order);

  const requestSort = useCallback(
    (key: InvoiceOrderBy, direction?: 'asc' | 'desc') =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: direction || getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    [order]
  );

  const requestInvoices = (actualRange: DateRange | undefined) => {
    sendInvoiceMessage([
      'RequestInvoices',
      {
        Clients: [],
        StartDate: actualRange ? actualRange[0] : null,
        FinalDate: actualRange ? actualRange[1] : null,
      },
    ]).then((invoiceId) => {
      dispatch(invoiceActions.registerNewRequest(invoiceId));
      setLoading(true);
    });
  };

  const clearRangeSelect = useCallback(() => {
    setRange(undefined);
    requestInvoices(undefined);
  }, []);

  const onChangeRange = useCallback((newRange: DateRange | undefined) => {
    setRange(newRange);
    requestInvoices(newRange);
    Alert.SUCCESS('Data configurada.');
    sendGTMEvent({ name: GTMEventName.SEARCH_INVOICE_BY_DATE });
  }, []);

  const handleDownloadInvoice = (idToDownload: string) => {
    sendInvoiceMessage(['RequestDocuments', JSON.stringify([idToDownload])]).then(() => {
      emitInvoiceDownload(idToDownload);
      sendGTMEvent({ name: GTMEventName.DOWNLOAD_INVOICE });
    });
  };

  const handleDownloadMultipleInvoices = useCallback(
    (idsToDownload: string[]) => {
      sendInvoiceMessage(['RequestDocuments', JSON.stringify(idsToDownload)]).then(() => {
        const toDownload = [
          ...idsToDownload.map((id) => {
            const searchData = data.find((item) => item.id === id);

            return {
              id,
              description: searchData?.invoiceNumber || 'Desconhecido',
              grouper: searchData?.clientName || 'Desconhecido',
            };
          }),
        ];

        toDownload.forEach(() => {
          sendGTMEvent({ name: GTMEventName.DOWNLOAD_INVOICE });
        });

        emitInvoiceDownload(toDownload);
      });
    },
    [data]
  );

  const handlePrint = (id: InvoiceData['id']) => {
    openBlankPage(`/home/view/invoice/${id}`);
    sendGTMEvent({ name: GTMEventName.PRINT_INVOICE });
  };

  const openSearchAction = () => {
    if (isMobileWidth) {
      openAsideActions();
      setAsideStage(InvoiceAsideStage.SEARCH_NEW_INVOICE);
      return;
    }

    openSearchModal();
  };

  const openSelectDateAction = () => {
    if (isMobileWidth) {
      openAsideActions();
      setAsideStage(InvoiceAsideStage.SELECT_DATE_ACTION);
      return;
    }

    openSelectDate();
  };

  useEffect(() => {
    if (data.length > 0 && loading) {
      setLoading(false);
    }

    startTransition(() => {
      const updatedData = pipe(data, [applySearch, applyOrder]);
      setFilteredData(updatedData);
    });
  }, [data, order, search, searchKeys]);

  // Make new request if redux global invoice data is empty in first render
  useEffect(() => {
    if (data.length <= 0) {
      requestInvoices(range);
    }
  }, []);

  return {
    data: filteredData,
    layout,
    toggleLayout,
    loading,
    filterIsPending: isPending,
    requestSort,
    order,
    setOrder,
    search,
    searchKeys,
    requestSearch,
    handleSearch,
    handleSearchKeys,
    isMobileWidth,
    showGrid,
    openSelectDate,
    closeSelectDate,
    selectDateIsOpen,
    onChangeRange,
    range,
    selectedData,
    getSelectionProps,
    handleSelectAll,
    areElementsSelected,
    handleOrder,
    clearRangeSelect,
    clearSearch,
    handleDownloadInvoice,
    handleDownloadMultipleInvoices,
    actionsIsDisabled,
    handlePrint,
    signalRConnectionReachedMaxRetries,
    dataIsEmpty,
    filterIsEmpty,
    searchModalIsOpen,
    closeSearchModal,
    openSearchAction,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    openSelectDateAction,
  };
};

export default useInvoice;
