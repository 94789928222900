import styled, { css } from 'styled-components';

export const Component = styled.p<BreakpointsProps>`
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: inherit;

  border: 0;
  outline: none;
  margin: 0;
  background: none;

  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  text-decoration: none;
  max-width: 100%;

  ${({ xl }) =>
    css`
      @media (max-width: 1550px) {
        max-width: ${xl ? `${xl}px` : '180px'};
      }
    `}

  ${({ lg }) =>
    css`
      @media (max-width: 1420px) {
        max-width: ${lg ? `${lg}px` : '140px'};
      }
    `}

  ${({ md }) =>
    css`
      @media (max-width: 1320px) {
        max-width: ${md ? `${md}px` : '100px'};
      }
    `}

  ${({ sm }) =>
    css`
      @media (max-width: 1240px) {
        max-width: ${sm ? `${sm}px` : '80px'};
      }
    `}
`;

export const TooltipContainer = styled.div`
  box-sizing: border-box;
  padding: 16px;
`;
