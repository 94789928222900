import React from 'react';
import { BarSize, Progress, ProgressBarContainer } from './style';

const ProgressBar = ({ infinite = true }: { infinite?: boolean }) => (
  <ProgressBarContainer>
    <Progress infinite={infinite} />
    <BarSize />
  </ProgressBarContainer>
);

export default ProgressBar;
