import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 640px;
  box-sizing: border-box;
  overflow: auto;
  gap: 8px;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  button {
    align-self: flex-end;
  }

  .bold {
    font-family: 'TKEType-Medium', sans-serif;
  }
`;
