import { AxiosPromise, AxiosRequestConfig } from 'axios';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface AxiosOptions extends AxiosRequestConfig {
  token?: string;
  isAuthorized?: boolean;
}

export type ApiMethod = <ApiRequest, ApiResponse>(
  url: string,
  data?: ApiRequest,
  options?: AxiosOptions
) => AxiosPromise<ApiResponse>;
