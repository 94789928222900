export type RefreshToken = Brand<string, 'REFRESH_TOKEN'>;
export type JwtToken = Brand<string, 'JWT_TOKEN'>;
export type ExternalToken = Brand<string, 'EXTERNAL_TOKEN'>;
export type ConfirmationToken = Brand<string, 'CONFIRMATION_TOKEN'>;
export type ConfirmationCode = Brand<string, 'CONFIRMATION_CODE'>;

export type UserType = 'CUSTOMER' | 'COLLABORATOR';
export enum UserRoles {
  CUSTOMER = 1,
  COLLABORATOR = 2,
  ADMIN = 3,
  EXTERNAL = 4,
}

export interface User {
  id: string;
  login: string;
  name: string;
  phone: string;
  email: string;
  roles: Array<UserType>;
}

export interface MaskedUserData {
  name: string;
  email: string;
  phone: string;
  activated: boolean;
}

export const CodeProvider = {
  SMS: 1,
  EMAIL: 2,
} as const;

export type ContactType = typeof CodeProvider[keyof typeof CodeProvider];

export enum LoginFormStage {
  USER_TYPE_SELECT = 'USER_TYPE_SELECT',
  CREDENTIALS_CLIENT = 'CREDENTIALS_CLIENT',
  CAPTCHA = 'CAPTCHA',
  REQUEST_CODE = 'REQUEST_CODE',
  SUBMIT_CODE = 'SUBMIT_CODE',
  FEEDBACK = 'FEEDBACK',
}
