import React, { useMemo } from 'react';
import BillContext from './context';
import useBill from './useBill';
import AsideActions from './components/AsideActions';
import DesktopActions from './components/DesktopActions';
import BillContent from './components/BillContent';

const BillContainer = () => {
  const {
    data,
    dataToDownload,
    loading,
    toggleLayout,
    layout,
    order,
    handleOrder,
    search,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    showGrid,
    openSelectDate,
    closeSelectDate,
    selectDateIsOpen,
    onChangeRange,
    range,
    requestSearch,
    areElementsSelected,
    selectedData,
    handleSelectAll,
    clearSearch,
    clearRangeSelect,
    handleFilterByStatus,
    statusFilter,
    clearFilterByStatus,
    actionsIsDisabled,
    multipleDownloadModalIsOpen,
    openMultipleDownloadModal,
    closeMultipleDownloadModal,
    handlePrint,
    getSelectionProps,
    signalRConnectionReachedMaxRetries,
    dataIsEmpty,
    filterIsEmpty,
    requestSort,
    closeSearchModal,
    searchModalIsOpen,
    openSearchAction,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    isMobileWidth,
    openSelectDateAction,
  } = useBill();

  const context = useMemo(
    () => ({
      data,
      openSelectDate,
      closeSelectDate,
      selectDateIsOpen,
      range,
      onChangeRange,
      requestSearch,
      search,
      areElementsSelected,
      selectedData,
      handleSelectAll,
      toggleLayout,
      layout,
      handleSearch,
      handleSearchKeys,
      handleOrder,
      loading,
      showGrid,
      order,
      searchKeys,
      clearSearch,
      clearRangeSelect,
      handleFilterByStatus,
      statusFilter,
      clearFilterByStatus,
      actionsIsDisabled,
      openMultipleDownloadModal,
      handlePrint,
      signalRConnectionReachedMaxRetries,
      dataIsEmpty,
      getSelectionProps,
      requestSort,
      dataToDownload,
      multipleDownloadModalIsOpen,
      closeMultipleDownloadModal,
      closeSearchModal,
      searchModalIsOpen,
      openSearchAction,
      filterIsEmpty,
      asideActionsIsOpen,
      asideStage,
      setAsideStage,
      openAsideActions,
      closeAsideActions,
      openSelectDateAction,
    }),
    [
      data,
      actionsIsDisabled,
      selectDateIsOpen,
      range,
      search,
      areElementsSelected,
      selectedData,
      layout,
      loading,
      showGrid,
      order,
      searchKeys,
      statusFilter,
      signalRConnectionReachedMaxRetries,
      dataIsEmpty,
      dataToDownload,
      multipleDownloadModalIsOpen,
      searchModalIsOpen,
      filterIsEmpty,
      asideActionsIsOpen,
      asideStage,
    ]
  );

  return (
    <BillContext.Provider value={context}>
      {isMobileWidth ? <AsideActions /> : <DesktopActions />}
      <BillContent />
    </BillContext.Provider>
  );
};

export default BillContainer;
