import React from 'react';
import {
  FinancialHistoricData,
  FinancialHistoricOrder,
  FinancialHistoricOrderBy,
} from '../../../../../../../@types/bill';
import HeaderOrderWrapper from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useTableView from '../../../../../../../components/TableView/hooks/useTableView';
import CustomText from '../../../../../../../components/CustomText';
import FixedTextCell from '../../../../../../../components/FixedTextCell';
import TableView from '../../../../../../../components/TableView';
import {
  mapFinancialHistoricOperationLabel,
  mapFinancialHistoricOperationToDescription,
  mapHistoricUserTypeLabel,
} from '../../../../constants';
import { formatDate, formatHour } from '../../../../../../../utils/helpers/date';
import { compose } from '../../../../../../../utils/helpers/string';
import { mountAutoMinMaxString } from '../../../../../../../utils/helpers/css';

interface FinancialHistoricTableProps {
  data: FinancialHistoricData[];
  requestSort: (key: FinancialHistoricOrderBy, direction?: 'asc' | 'desc') => void;
  order: FinancialHistoricOrder | null;
  style?: React.CSSProperties;
}

const FinancialHistoricTable = ({ data, requestSort, order, style }: FinancialHistoricTableProps) => {
  const { getTableProps } = useTableView<FinancialHistoricData>(data, [
    {
      key: 'operation',
      width: '150px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="type">
          <CustomText>Operação</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'type',
      render: ({ item }) => <CustomText>{mapFinancialHistoricOperationLabel[item.type]}</CustomText>,
    },
    {
      key: 'name',
      width: mountAutoMinMaxString(200),
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="userName">
          <CustomText>Nome do usuário</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'userName',
      render: ({ item }) => <FixedTextCell>{item?.userName || 'Anônimo'}</FixedTextCell>,
    },
    {
      key: 'description',
      width: '1fr',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="information">
          <CustomText>Descrição</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => (
        <FixedTextCell sm={200} md={260} lg={320} xl={400}>
          {compose(mapFinancialHistoricOperationToDescription[item.type], '$', item.information)}
        </FixedTextCell>
      ),
    },
    {
      key: 'userType',
      width: '180px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="userType">
          <CustomText>Tipo de usuário</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => <CustomText>{mapHistoricUserTypeLabel[item.userType] || 'Anônimo'}</CustomText>,
    },
    {
      key: 'date',
      width: '120px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="date">
          <CustomText>Data</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => <CustomText>{formatDate(new Date(item.createdAt))}</CustomText>,
    },
    {
      key: 'hour',
      width: '100px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="hour">
          <CustomText>Hora</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => <CustomText>{formatHour(new Date(item.createdAt))}</CustomText>,
    },
  ]);

  const tableStyle = style || getTableProps().style;

  return <TableView {...getTableProps()} keyExtractor={(item) => item.id} style={tableStyle} />;
};

export default FinancialHistoricTable;
