import React, { useContext } from 'react';
import BillContext, { BillContextProps } from '../../../../context';
import DesktopSelectDate from '../../../../../../../../../layouts/DesktopSelectDate';
import { SELECT_DATE_BILL_DESCRIPTION, SELECT_DATE_BILL_TITLE } from '../../../../../../../../../utils/constants/text';

const SelectDateModal = () => {
  const { selectDateIsOpen, closeSelectDate, range, onChangeRange } = useContext(BillContext) as BillContextProps;

  return (
    <DesktopSelectDate
      title={SELECT_DATE_BILL_TITLE}
      description={SELECT_DATE_BILL_DESCRIPTION}
      isOpen={selectDateIsOpen}
      onClose={closeSelectDate}
      range={range}
      onChangeRange={onChangeRange}
    />
  );
};

export default SelectDateModal;
