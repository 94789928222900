import { BuildingData, ContractLink, ContractType } from '../../@types/building';

class BuildingPolicy {
  clientCanAccessNewBuildings(data: BuildingData) {
    return data.contracts.some(
      (c) =>
        c.type !== ContractType.VS &&
        c.type !== ContractType.MP &&
        (c.link === ContractLink.FATHER || c.link === ContractLink.CHILD)
    );
  }

  partnerCanAccessNewBuildings(data: BuildingData) {
    return data.contracts.some((c) => c.type !== ContractType.VS && c.type !== ContractType.MP);
  }

  contractsAreLinked(data: BuildingData) {
    return data.contracts.some((contract) => contract.link);
  }

  contractsAreLinkedInternally(data: BuildingData) {
    return data.contracts.every(({ link }) => link === ContractLink.TK);
  }
}

export default new BuildingPolicy();
