import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Actions, Container, Content, Description, DesktopDescription, MobileDescription, Title } from './style';
import Button from '../../components/Button';
import BuildingService from '../../services/Buildings/BuildingService';
import getEnvironment from '../../utils/helpers/getEnvironment';
import { ReactComponent as Logo } from '../../assets/svg/logo_without_label.svg';
import FullScrenPageLayout from '../../layouts/FullScreenPageLayout';

interface RedirectLocationState {
  type: 'building' | 'financial';
  id: string;
}

interface RedirectHandlerItem {
  title: string;
  errorDescription: string;
  buttonText: string;
  baseUrl: string;
  fetchToken: (buildingId: string) => Promise<string>;
}

const redirectHandler = {
  building: {
    title: 'Acesso à Instalações',
    errorDescription:
      'Não foi possível validar seu acesso ao portal de instalações. Estamos redirecionando você para a tela inicial.',
    buttonText: 'Ir para Instalações',
    baseUrl: getEnvironment().BUILDING_PORTAL_AUTH_URI ?? '',
    fetchToken: async (contractId: string) => BuildingService.getBuildingPortalToken(contractId),
  },
  financial: {
    title: 'Acesso ao Financeiro',
    errorDescription:
      'Não foi possível validar seu acesso ao portal de financeiro. Estamos redirecionando você para a tela inicial.',
    buttonText: 'Ir para Financeiro',
    baseUrl: getEnvironment().FINANCIAL_PORTAL_AUTH_URI ?? '',
    fetchToken: async (contractId: string) => BuildingService.getBuildingFinancesPortalToken(contractId),
  },
} as const;

const RedirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as RedirectLocationState;
  const { type, id } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [isBadRequest, setIsBadRequest] = useState(false);
  const item: RedirectHandlerItem = redirectHandler[type];

  const backToDashboard = () => {
    navigate('/home');
  };

  const handleRedirect = () => {
    setIsLoading(true);

    item
      .fetchToken(id)
      .then((externalToken) => {
        window.location.replace(`${item.baseUrl}?externalToken=${externalToken}`);
      })
      .catch(() => {
        setIsLoading(false);
        setIsBadRequest(true);
      });
  };

  useEffect(() => {
    if (isBadRequest) {
      setTimeout(() => {
        navigate('/home');
      }, 5 * 1000);
    }
  }, [isBadRequest]);

  return isBadRequest ? (
    <FullScrenPageLayout title="Ops.. Acesso não validado" description={item.errorDescription} />
  ) : (
    <Container>
      <Logo className="tke_logo" />
      <Content>
        <Title>{item.title}</Title>
        <DesktopDescription>
          <Description>Esta área ainda apresenta a antiga versão.</Description>
          <Description>Estamos trabalhando para em breve lhe proporcionar uma melhor experiência.</Description>
        </DesktopDescription>
        <MobileDescription>
          <Description>
            Esta área ainda apresenta a antiga versão, estamos trabalhando para em breve lhe proporcionar uma melhor
            experiência.
          </Description>
        </MobileDescription>
      </Content>
      <Actions>
        <Button variant="secondary" disabled={isLoading} size="small" onClick={backToDashboard}>
          Voltar para o meu painel
        </Button>
        <Button variant="primary" loading={isLoading} disabled={isLoading} size="small" onClick={handleRedirect}>
          {item.buttonText}
        </Button>
      </Actions>
    </Container>
  );
};

export default RedirectPage;
