import React, { FunctionComponent } from 'react';
import { BusinessContract } from '../../../../../../@types/building';
import CustomText from '../../../../../../components/CustomText';
import { Container, ContractWrapper } from './style';
import { MAP_BUSINESS_CONTRACT_SLUG } from '../../../../Client/containers/Building/constants';

interface BusinessContractsTooltipProps {
  businessContracts: Array<BusinessContract>;
}

const BusinessContractsTooltip: FunctionComponent<BusinessContractsTooltipProps> = ({ businessContracts }) => (
  <Container>
    <CustomText className="header">Contratos</CustomText>
    {businessContracts.map((contract) => (
      <ContractWrapper key={`ContactWrapper-${contract.id}-${contract.contractCode}`}>
        <CustomText className="label">{MAP_BUSINESS_CONTRACT_SLUG[contract.type]}</CustomText>
        <CustomText className="content">Nº {contract.contractCode}</CustomText>
      </ContractWrapper>
    ))}
  </Container>
);

export default BusinessContractsTooltip;
