import React, { useContext } from 'react';
import { FilterButtonContainer, FilterButtonTooltipContent } from '../../../../../../../shared/style';
import UnstyledButtonWithTooltip from '../../../../../../../../../components/Floating/components/UnstyledButtonWithFloating';
import { isNotEmptyArray } from '../../../../../../../../../utils/helpers/array';
import FinancialHistoricContext, { FinancialHistoricContextProps } from '../../../../context';
import CustomText from '../../../../../../../../../components/CustomText';
import CheckboxGroup from '../../../../../../../../../components/SelectableButton/Checkbox/Group';
import { FINANCIAL_HISTORIC_FILTER_OPTIONS } from '../../../../../../constants';
import { ChevronArrowIcon } from '../../../../../../../../../icons';

const FilterButton = () => {
  const { searchKeys, handleSearchKeys } = useContext(FinancialHistoricContext) as FinancialHistoricContextProps;
  const isSelected = isNotEmptyArray(searchKeys);

  return (
    <FilterButtonContainer isSelected={isSelected}>
      <UnstyledButtonWithTooltip
        placement="bottom-end"
        content={
          <>
            <CustomText>{isSelected ? 'Filtro ativado' : 'Filtro'}</CustomText>
            <ChevronArrowIcon className="rotate-90" />
          </>
        }
        floating={
          <FilterButtonTooltipContent>
            <CheckboxGroup
              align="column"
              options={FINANCIAL_HISTORIC_FILTER_OPTIONS}
              selectedOptions={searchKeys}
              onChange={handleSearchKeys}
            />
          </FilterButtonTooltipContent>
        }
      />
    </FilterButtonContainer>
  );
};

export default FilterButton;
