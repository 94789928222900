import React, { PropsWithChildren } from 'react';
import { WrapperDiv } from '../../styles/components';
import { BannerContainer } from './style';

interface BannerProps {
  imagePath: string;
  onClick?: VoidFunction;
}

const Banner = ({ imagePath, children, onClick }: PropsWithChildren<BannerProps>) => (
  <BannerContainer imagePath={imagePath} onClick={onClick} role="button">
    <img src={imagePath} alt="Imagem do banner" />
    <WrapperDiv flexDirection="column" gap={12} justifyContent="center" height="fit-content">
      {children}
    </WrapperDiv>
  </BannerContainer>
);

export default Banner;
