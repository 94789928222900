import React from 'react';
import { AppLink, Cards, Container, StoreButtons } from './style';
import ExternalAppCard from './components/ExternalAppCard';
import {
  EXTERNAL_APP_BIM_DESCRIPTION,
  EXTERNAL_APP_BIM_TITLE,
  EXTERNAL_APP_TKE_DIGITAL_APP_DESCRIPTION,
  EXTERNAL_APP_TKE_DIGITAL_APP_TITLE,
  EXTERNAL_MAX_DESCRIPTION,
  EXTERNAL_MAX_TITLE,
} from '../../utils/constants/text';
import CustomText from '../../components/CustomText';
import Button from '../../components/Button';
import {
  APP_BIM_LINK,
  APP_MAX_LINK,
  APP_TKE_DIGITAL_APPLE_LINK,
  APP_TKE_DIGITAL_GOOGLE_LINK,
} from '../../utils/constants/links';

import TKE_DIGITAL_WEB_BG from '../../assets/images/tke_digital_web_bg.png';
import TKE_MAX_BG from '../../assets/images/tke_max_bg.png';
import TKE_BIM_BG from '../../assets/images/tke_bim_bg.png';
import PageLayout from '../../layouts/PageLayout';
import { AppleIcon, GoogleIcon } from '../../icons';

const ExternalApplications = () => (
  <PageLayout boxStyle>
    <Container>
      <CustomText as="h1" className="title">
        Aplicações TKE
      </CustomText>
      <Cards>
        <ExternalAppCard
          background={TKE_DIGITAL_WEB_BG}
          title={EXTERNAL_APP_TKE_DIGITAL_APP_TITLE}
          description={EXTERNAL_APP_TKE_DIGITAL_APP_DESCRIPTION}
        >
          <StoreButtons>
            <Button
              size="fit"
              as="a"
              variant="secondary"
              href={APP_TKE_DIGITAL_APPLE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              App Store
              <AppleIcon />
            </Button>
            <Button
              size="fit"
              as="a"
              variant="secondary"
              href={APP_TKE_DIGITAL_GOOGLE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google play
              <GoogleIcon />
            </Button>
          </StoreButtons>
        </ExternalAppCard>
        <ExternalAppCard
          background={TKE_BIM_BG}
          title={EXTERNAL_APP_BIM_TITLE}
          description={EXTERNAL_APP_BIM_DESCRIPTION}
        >
          <AppLink text="Acessar" href={APP_BIM_LINK} />
        </ExternalAppCard>
        <ExternalAppCard background={TKE_MAX_BG} title={EXTERNAL_MAX_TITLE} description={EXTERNAL_MAX_DESCRIPTION}>
          <AppLink text="Acessar" href={APP_MAX_LINK} />
        </ExternalAppCard>
      </Cards>
    </Container>
  </PageLayout>
);

export default ExternalApplications;
