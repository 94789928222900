import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import { StyledButtonProps } from './types';

const PRIMARY_DISABLED_STYLE = css`
  background: ${theme.color.secondary.grey[4]};
  border: 1px solid ${theme.color.secondary.grey[4]};
  ${theme.defaults.disabled}
`;

const DEFAULT_DISABLED_STYLE = css`
  color: ${theme.color.secondary.grey[4]};
  border-color: ${theme.color.secondary.grey[4]};

  .spinner_path {
    stroke: ${theme.color.secondary.grey[4]};
  }

  ${theme.defaults.disabled}
`;

const PRIMARY_HIGHLIGHT_STYLE = css`
  background: ${theme.color.secondary.purple[2]};
`;

const DEFAULT_HIGHLIGHT_STYLE = css`
  color: ${theme.color.secondary.purple[2]};
  border-color: ${theme.color.secondary.purple[2]};

  .spinner_path {
    stroke: ${theme.color.secondary.purple[2]};
  }
`;

const FIT_SIZE_STYLE = css`
  width: 100%;
`;

const MEDIUM_SIZE_STYLE = css`
  min-width: 195px;
`;

const SMALL_SIZE_STYLE = css`
  min-width: 180px;
  width: auto;
  font-size: 1.4rem;
  padding: 9px 24px;
  height: 36px;
  white-space: nowrap;

  .spinner {
    width: 16px;
    height: 14px;
  }

  .spinner_path {
    stroke-width: 8px;
  }
`;

const mapSize = {
  fit: FIT_SIZE_STYLE,
  medium: MEDIUM_SIZE_STYLE,
  small: SMALL_SIZE_STYLE,
};

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  border: none;
  height: 46px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.6rem;
  box-sizing: border-box;

  .spinner {
    width: 22px;
    height: 20px;
  }

  .spinner_path {
    stroke-width: 5px;
  }

  ${({ size }) => (size ? mapSize[size] : mapSize.medium)};
`;

export const PrimaryButton = styled(StyledButton)`
  color: ${theme.color.primary.white};
  background: ${theme.color.primary.interaction};
  border: 1px solid ${theme.color.primary.interaction};

  .spinner_path {
    stroke: #fff;
  }

  &:active {
    background: ${theme.color.secondary.purple[1]};
  }

  &:hover {
    ${PRIMARY_HIGHLIGHT_STYLE};
  }

  ${({ highlight }) => highlight && PRIMARY_HIGHLIGHT_STYLE}
  ${({ disabled }) => disabled && PRIMARY_DISABLED_STYLE}
`;

export const SecondaryButton = styled(StyledButton)`
  background: ${theme.color.primary.white};
  color: ${theme.color.primary.interaction};
  border: 1.5px solid ${theme.color.primary.interaction};

  .spinner_path {
    stroke: ${theme.color.primary.interaction};
  }

  &:hover {
    ${DEFAULT_HIGHLIGHT_STYLE};
  }

  &:active {
    color: ${theme.color.secondary.purple[1]};
    border-color: ${theme.color.secondary.purple[1]};

    .spinner_path {
      stroke: ${theme.color.secondary.purple[1]};
    }
  }

  ${({ highlight }) => highlight && DEFAULT_HIGHLIGHT_STYLE}
  ${({ disabled }) => disabled && DEFAULT_DISABLED_STYLE}
`;

export const TertiaryButton = styled(StyledButton)`
  background: ${theme.color.primary.white};
  color: ${theme.color.primary.black};
  border: 1.5px solid ${theme.color.secondary.grey[5]};

  .spinner_path {
    stroke: ${theme.color.secondary.grey[5]};
  }

  &:hover {
    ${DEFAULT_HIGHLIGHT_STYLE};
  }

  &:active {
    color: ${theme.color.secondary.purple[1]};
    border-color: ${theme.color.secondary.purple[1]};

    .spinner_path {
      stroke: ${theme.color.secondary.purple[1]};
    }
  }

  ${({ highlight }) => highlight && DEFAULT_HIGHLIGHT_STYLE}
  ${({ disabled }) => disabled && DEFAULT_DISABLED_STYLE}
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  box-sizing: border-box;

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  text-align: center;
`;
