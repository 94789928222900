import React, { useContext } from 'react';
import UnstyledButtonWithTooltip from '../../../../../../../../../components/Floating/components/UnstyledButtonWithFloating';
import CheckboxGroup from '../../../../../../../../../components/SelectableButton/Checkbox/Group';
import CustomText from '../../../../../../../../../components/CustomText';
import { BILL_FILTER_OPTIONS, BILL_STATUS_FILTER_OPTIONS } from '../../../../constants';
import { isNotEmptyArray } from '../../../../../../../../../utils/helpers/array';
import BillContext, { BillContextProps } from '../../../../context';
import { FilterButtonContainer, FilterButtonTooltipContent } from '../../../../../../../shared/style';
import { ChevronArrowIcon } from '../../../../../../../../../icons';

const FilterButton = () => {
  const { searchKeys, handleSearchKeys, handleFilterByStatus, statusFilter } = useContext(
    BillContext
  ) as BillContextProps;
  const isSelected = isNotEmptyArray(searchKeys) || isNotEmptyArray(statusFilter);

  return (
    <FilterButtonContainer isSelected={isSelected}>
      <UnstyledButtonWithTooltip
        placement="bottom-end"
        content={
          <>
            <CustomText>{isSelected ? 'Filtro ativado' : 'Filtro'}</CustomText>
            <ChevronArrowIcon className="rotate-90" />
          </>
        }
        floating={
          <FilterButtonTooltipContent>
            <CheckboxGroup
              align="column"
              options={BILL_FILTER_OPTIONS}
              selectedOptions={searchKeys}
              onChange={handleSearchKeys}
            />
            <CustomText className="status_title">Status</CustomText>
            <CheckboxGroup
              align="column"
              options={BILL_STATUS_FILTER_OPTIONS}
              selectedOptions={statusFilter}
              onChange={handleFilterByStatus}
            />
          </FilterButtonTooltipContent>
        }
      />
    </FilterButtonContainer>
  );
};

export default FilterButton;
