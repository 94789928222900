import React, { Suspense, useMemo, useState } from 'react';
import CustomText from '../../../../../../components/CustomText';
import PhoneActionContext from './context';
import { FormStage } from '../../../../type';
import Form from './Steps/Form';
import Confirm from './Steps/Confirm';
import Feedback from './Steps/Feedback';

const formMapping = {
  [FormStage.FORM]: Form,
  [FormStage.CONFIRM]: Confirm,
  [FormStage.FEEDBACK]: Feedback,
} as const;

const PhoneAction = () => {
  const [stage, setStage] = useState(FormStage.FORM);
  const [selectedPhone, setSelectedPhone] = useState('');
  const Step = formMapping[stage];

  const context = useMemo(() => ({ stage, setStage, selectedPhone, setSelectedPhone }), [stage, selectedPhone]);

  return (
    <PhoneActionContext.Provider value={context}>
      <Suspense fallback={<CustomText as="h1">Loading...</CustomText>}>
        <Step />
      </Suspense>
    </PhoneActionContext.Provider>
  );
};

export default PhoneAction;
