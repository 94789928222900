import styled from 'styled-components';
import ExternalLink from '../../components/Link/ExternalLink';
import theme from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  .title {
    font-weight: 500;
    font-size: 30px;
    color: #262626;
  }
`;

export const Content = styled.div``;

export const Cards = styled.div`
  display: grid;
  --grid-layout-gap: 16px;
  --grid-column-count: 3;
  --grid-item--min-width: 320px;

  @media (max-width: 520px) {
    --grid-item--min-width: 100%;
  }

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  grid-gap: var(--grid-layout-gap);
`;

export const StoreButtons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  a {
    word-wrap: break-word;
    box-sizing: border-box;
    height: 36px;
    padding: 0;
  }
`;

export const AppLink = styled(ExternalLink).attrs({
  hoverColor: theme.color.secondary.purple[2],
  color: theme.color.primary.interaction,
  target: '_blank',
  rel: 'noopener noreferrer',
})``;
