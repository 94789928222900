import React, { useContext } from 'react';
import { LoginFormStage } from '../../../../../../@types/auth';
import THUMB from '../../../../../../assets/images/intro_video_thumb.jpeg';
import LOGO_APP from '../../../../../../assets/images/logo-app-tke-digital.png';
import Button from '../../../../../../components/Button';
import ExternalLink from '../../../../../../components/Link/ExternalLink';
import VideoPlayer from '../../../../../../components/VideoPlayer';
import { useWindowDimensions } from '../../../../../../hooks/useWindowDimensions';
import { AppleIcon, GoogleIcon } from '../../../../../../icons';
import {
  APP_BIM_LINK,
  APP_MAX_LINK,
  APP_TKE_DIGITAL_APPLE_LINK,
  APP_TKE_DIGITAL_GOOGLE_LINK,
  APP_TKE_ELEVATOR_LINK,
} from '../../../../../../utils/constants/links';
import LoginContext, { LoginContextProps } from '../../context';
import MobileStoreButton from './components/MobileStoreButton';
import {
  BracketBox,
  Container,
  Footer,
  FooterLinks,
  FooterTitle,
  Greetings,
  IntegrationLinks,
  MobileDownloads,
  MobileInfo,
  MobileLinks,
  ResponsiveMobileDownloads,
} from './style';

const stagesWithoutBackgroundResponsive = [
  LoginFormStage.CAPTCHA,
  LoginFormStage.REQUEST_CODE,
  LoginFormStage.SUBMIT_CODE,
  LoginFormStage.FEEDBACK,
];

const Background = () => {
  const { isMobileWidth } = useWindowDimensions();

  const { stage } = useContext(LoginContext) as LoginContextProps;

  const hideOnSmallerScreens = stagesWithoutBackgroundResponsive.includes(stage);

  return (
    <Container hide={hideOnSmallerScreens}>
      <BracketBox className="bracket_box">
        <Greetings>
          Bem vindo ao novo portal <span>TKE DIGITAL</span>
        </Greetings>
      </BracketBox>
      {!isMobileWidth && (
        <VideoPlayer
          thumbPath={THUMB}
          videoId="PtTLAATMnxs"
          title="Assista o vídeo e entenda como usar todos os recursos do TKE Digital."
        />
      )}
      <Footer>
        <FooterTitle>Outros canais TKE</FooterTitle>
        <FooterLinks>
          <MobileInfo>
            <img src={LOGO_APP} alt="TKE application logo" width={64} height={64} />
            <MobileLinks>
              <div>
                <p className="mobile-app-name">TKE Digital App</p>
                <p className="mobile-download-info">Faça o download</p>
              </div>
              <MobileDownloads>
                <MobileStoreButton store="android" url={APP_TKE_DIGITAL_GOOGLE_LINK} />
                <MobileStoreButton store="ios" url={APP_TKE_DIGITAL_APPLE_LINK} />
              </MobileDownloads>
              <ResponsiveMobileDownloads>
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  as="a"
                  size="small"
                  variant="secondary"
                  href={APP_TKE_DIGITAL_APPLE_LINK}
                >
                  Apple App Store
                  <AppleIcon />
                </Button>
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  as="a"
                  size="small"
                  variant="secondary"
                  href={APP_TKE_DIGITAL_GOOGLE_LINK}
                >
                  Google App Store
                  <GoogleIcon />
                </Button>
              </ResponsiveMobileDownloads>
            </MobileLinks>
          </MobileInfo>
          <IntegrationLinks>
            <ExternalLink color="inherit" text="TK Elevator Brasil" href={APP_TKE_ELEVATOR_LINK} />
            <ExternalLink color="inherit" text="BIM" href={APP_BIM_LINK} />
            <ExternalLink color="inherit" text="Serviços MAX" href={APP_MAX_LINK} />
          </IntegrationLinks>
        </FooterLinks>
      </Footer>
    </Container>
  );
};

export default Background;
