import api from '../../config/http/api';
import {
  ConfirmationCode,
  ConfirmationToken,
  ContactType,
  ExternalToken,
  JwtToken,
  MaskedUserData,
  RefreshToken,
  User,
} from '../../@types/auth';
import { RegisterPayload } from '../../pages/Auth/Client/Register/type';

interface RequestConfirmation {
  login: string;
  password: string;
}

interface ResponseConfirmation {
  userInfo: MaskedUserData;
  confirmationToken: ConfirmationToken;
  expiresIn: number;
}

interface Confirmation {
  ConfirmationToken: ConfirmationToken;
  ConfirmationCode: ConfirmationCode;
}

interface ResponseLogin {
  accessToken: JwtToken;
  accessTokenExpirationInSeconds: number;
  refreshToken: RefreshToken;
  refreshTokenExpirationInSeconds: number;
  user: User;
}

interface RequestRecovery {
  login: string;
}

interface ResponseRecovery {
  confirmationToken: ConfirmationToken;
  expiresIn: number;
  customerInfo: MaskedUserData;
}

interface RequestRecoveryConfirmation {
  confirmationToken: ConfirmationToken;
  confirmationCode: ConfirmationCode;
  newPassword: string;
}

interface ResponseRegister {
  customerInfo: MaskedUserData;
  confirmationToken: ConfirmationToken;
}

interface PartnerLoginRequest {
  IdToken: JwtToken;
}

interface PartnerLoginResponse {
  accessToken: JwtToken;
}

const getConfirmationToken = async (login: string, password: string, captchaToken: string) =>
  api.post<RequestConfirmation, ResponseConfirmation>(
    '/api/v1/auth',
    {
      login,
      password,
    },
    {
      headers: {
        captcha_response: captchaToken,
      },
    }
  );

const confirmationAuth = async (confirmationToken: ConfirmationToken, confirmationCode: ConfirmationCode) =>
  api.post<Confirmation, ResponseLogin>('/api/v1/auth/confirmation', {
    ConfirmationToken: confirmationToken,
    ConfirmationCode: confirmationCode,
  });

const requestAuthCodeService = async (
  confirmationToken: ConfirmationToken,
  contactType: ContactType,
  signal?: AbortSignal
) =>
  api.post<{ confirmationToken: ConfirmationToken; contactType: number }, void>(
    '/api/v1/auth/send-confirmation-code',
    {
      confirmationToken,
      contactType: Number(contactType),
    },
    {
      signal,
    }
  );

const requestCodeService = async (
  confirmationToken: ConfirmationToken,
  contactType: ContactType,
  signal?: AbortSignal
) =>
  api.post<{ confirmationToken: ConfirmationToken; contactType: number }, void>(
    '/api/v1/customers/confirmation-code/send',
    {
      confirmationToken,
      contactType: Number(contactType),
    },
    {
      signal,
    }
  );

const getUserData = async (token: string) =>
  api.get<null, User>('/api/v1/auth/me', null, { isAuthorized: true, token });

const checkUserData = async (login: string) =>
  api.post<{ login: string }, MaskedUserData>('/api/v1/customers/check', {
    login,
  });

const requestRecoveryService = async (login: string, captchaToken: string) =>
  api.post<RequestRecovery, ResponseRecovery>(
    '/api/v1/customers/recovery',
    {
      login,
    },
    {
      headers: {
        captcha_response: captchaToken,
      },
    }
  );

const requestRecoveryConfirmationService = async (
  confirmationToken: ConfirmationToken,
  confirmationCode: ConfirmationCode,
  newPassword: string
) =>
  api.post<RequestRecoveryConfirmation, void>('/api/v1/customers/recovery/confirmation', {
    confirmationToken,
    confirmationCode,
    newPassword,
  });

const registerUser = async (user: RegisterPayload, captchaToken: string) =>
  api.post<RegisterPayload, ResponseRegister>('/api/v1/customers/registration', user, {
    headers: {
      captcha_response: captchaToken,
    },
  });

const updateRegisterUser = async (user: Pick<RegisterPayload, 'login' | 'password'>, captchaToken: string) =>
  api.put<Pick<RegisterPayload, 'login' | 'password'>, ResponseRegister>('/api/v1/customers/registration', user, {
    headers: {
      captcha_response: captchaToken,
    },
  });

const confirmationRegisterUser = async (confirmationToken: ConfirmationToken, confirmationCode: ConfirmationCode) =>
  api.post<Confirmation, void>('/api/v1/customers/registration/confirmation', {
    ConfirmationToken: confirmationToken,
    ConfirmationCode: confirmationCode,
  });

const signInPartner = async (IdToken: JwtToken) =>
  api.post<PartnerLoginRequest, PartnerLoginResponse>('/api/v1/auth/collaborator', {
    IdToken,
  });

export interface ExternalLoginResponse {
  user: User;
  accessToken: JwtToken;
  refreshToken: JwtToken;
  externalInfo: {
    destination: 'payments' | 'invoices';
    contract: string;
  };
}

const signInExternal = async (externalToken: ExternalToken, clientId: string) =>
  api.post<{ externalToken: ExternalToken; clientId: string }, ExternalLoginResponse>('/api/v1/auth/external', {
    externalToken,
    clientId,
  });

export default {
  getConfirmationToken,
  confirmationAuth,
  requestAuthCodeService,
  requestCodeService,
  checkUserData,
  requestRecoveryService,
  requestRecoveryConfirmationService,
  registerUser,
  updateRegisterUser,
  confirmationRegisterUser,
  getUserData,
  signInPartner,
  signInExternal,
};
