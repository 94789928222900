import React, { useEffect } from 'react';
import ModalPortal from '../../../ModalPortal';
import { ModalContainer } from '../../style';
import ModalHeader from '../../../../components/ModalHeader';
import { WrapperDiv } from '../../../../styles/components';
import CustomText from '../../../../components/CustomText';
import Button from '../../../../components/Button';

interface CancelDownloadModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
}

const CancelDownloadModal = ({ visible, onClose, onSubmit }: CancelDownloadModalProps) => {
  useEffect(
    () => () => {
      onClose();
    },
    []
  );

  return (
    <ModalPortal onClose={onClose} visible={visible}>
      <ModalContainer>
        <ModalHeader title="Cancelar os downloads em andamento?" onClose={onClose} />
        <WrapperDiv mt={8}>
          <CustomText>
            Se você realmente deseja cancelar o download dos arquivos, clique no botão cancelar, caso tenha clicado por
            engano ou tenha mudado de ideia, basta fechar esta janela ou voltar à página anterior para continuar o
            processo de download.
          </CustomText>
        </WrapperDiv>
        <WrapperDiv justifyContent="flex-end" gap={8}>
          <Button onClick={onClose} variant="secondary">
            Voltar
          </Button>
          <Button onClick={onSubmit} variant="primary">
            Cancelar
          </Button>
        </WrapperDiv>
      </ModalContainer>
    </ModalPortal>
  );
};

export default CancelDownloadModal;
