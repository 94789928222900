import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import RequestCode from '../../../common/RequestCode';
import RadioGroup from '../../../../../../../../components/SelectableButton/Radio/Group';
import { CodeProvider } from '../../../../../../../../@types/auth';
import MessageLabel from '../../../../../../../../components/Label/components/MessageLabel';
import PhoneLabel from '../../../../../../../../components/Label/components/PhoneLabel';
import { useAuth } from '../../../../../../../../context/AuthContext';
import { maskPhone, obfuscatePhone } from '../../../../../../../../utils/helpers/mask';
import CustomText from '../../../../../../../../components/CustomText';
import { RequestCodeData, RequestFormProps } from '../../../../../../type';
import { Container } from './style';

const RequestForm = ({ onSubmit, loading }: RequestFormProps) => {
  const { control, handleSubmit } = useForm<RequestCodeData>({
    mode: 'onChange',
  });

  const { user } = useAuth();
  const phone = user?.phone!;
  const email = user?.email!;

  return (
    <Container>
      <RequestCode onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <CustomText as="p">Escolha a melhor forma para lhe enviarmos o código:</CustomText>
        <Controller
          name="contactType"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field: { value, ...otherFieldProps } }) => (
            <RadioGroup
              options={[
                {
                  label: <PhoneLabel label={maskPhone(obfuscatePhone(phone))} />,
                  option: CodeProvider.SMS,
                },
                {
                  label: <MessageLabel label={email} />,
                  option: CodeProvider.EMAIL,
                },
              ]}
              align="column"
              selectedOption={value}
              {...otherFieldProps}
            />
          )}
        />
      </RequestCode>
    </Container>
  );
};

export default RequestForm;
