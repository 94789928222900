import styled, { css, keyframes } from 'styled-components';
import theme from '../../styles/theme';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const StyledAccordionContent = styled.div<{ isLeaving: boolean }>`
  background-color: white;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    overflow-y: auto;
  }

  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms ease-in-out, margin-top 500ms ease-in-out;

  &[aria-hidden='false'] {
    grid-template-rows: 1fr;
    margin-top: 16px;
  }

  & > * {
    overflow: hidden;
  }

  ${({ isLeaving }) =>
    isLeaving &&
    css`
      & > * {
        animation: ${fadeOut} 0.4s forwards;
      }
    `}
`;

export const StyledAccordionRoot = styled.div<{ gap?: number }>`
  .__styled-accordion-item + .__styled-accordion-item {
    margin-top: ${({ gap }) => (gap ? `${gap}px` : 0)};
  }

  > .__styled-accordion-item:not(:last-child) {
    border-bottom: 1px solid ${theme.color.secondary.grey[4]};
  }
`;

export const StyledAccordionItem = styled.div.attrs({
  className: '__styled-accordion-item',
})`
  border: 1px solid ${theme.color.secondary.grey[4]};
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  box-sizing: border-box;
`;

export const StyledAccordionHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  min-width: 200px;

  .children-container {
    width: 100%;
  }

  .accordion-title {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  /* Style the accordion chevron icon */
  .accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
    transform: rotate(90deg);
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(-90deg);
  }
`;
