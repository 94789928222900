import axios from 'axios';
import getEnvironment from '../../utils/helpers/getEnvironment';
import { buildUrl } from '../../utils/helpers/string';
import { getFromLocalStorage, LocalStorageKey, saveToLocalStorage } from '../../utils/helpers/storage';

export interface RefreshTokenResponse {
  accessToken: string;
  accessTokenExpirationInSeconds: number;
  refreshToken: string;
  refreshTokenExpirationInSeconds: number;
  user: unknown;
}

const { BASE_URL, CLIENT_ID } = getEnvironment();

const refreshTokenApi = axios.create({
  baseURL: buildUrl(BASE_URL!, '/api/v1/auth/refresh-token'),
  headers: {
    client_id: CLIENT_ID,
    'Content-Type': 'application/json',
  },
});

export const executeRefreshToken = () => {
  const refreshToken = getFromLocalStorage(LocalStorageKey.REFRESH_TOKEN);

  return new Promise<RefreshTokenResponse>((resolve, reject) => {
    if (refreshToken || refreshToken !== '') {
      refreshTokenApi
        .post('', { token: refreshToken })
        .then(({ data }) => {
          saveToLocalStorage(LocalStorageKey.TOKEN, data.accessToken);
          saveToLocalStorage(LocalStorageKey.REFRESH_TOKEN, data.refreshToken);
          saveToLocalStorage(LocalStorageKey.USER, JSON.stringify(data.user));

          resolve(data);
        })
        .catch((reason) => reject(reason));
    }
  });
};
