import React from 'react';
import { Container } from './style';
import { ListHead } from '../../styles';
import Checkbox from '../../../SelectableButton/Checkbox';

export interface DisabledHeader {
  width: CssWidth;
  render: JSX.Element;
}

interface DisabledTableHeaderProps {
  headers: DisabledHeader[];
  withSelection?: boolean;
  style?: React.CSSProperties;
}

const DisabledListHeader = ({ headers, withSelection, ...props }: DisabledTableHeaderProps) => (
  <Container {...props}>
    {withSelection && (
      <ListHead disabled>
        <Checkbox checked={false} disabled />
      </ListHead>
    )}
    {headers.map(({ render }, index) => (
      <ListHead key={`disabled_list_head_${index}`} disabled>
        {render}
      </ListHead>
    ))}
  </Container>
);

export default DisabledListHeader;
