import React, { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react';
import { Addon, IconWrapper, InputControl, SearchBox, SearchInput } from './style';
import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '../../icons';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: Children;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Search = forwardRef<HTMLInputElement, SearchProps>(
  ({ disabled = false, id, placeholder = 'Digite aqui', children, value, onChange, ...props }: SearchProps, ref) => {
    const [displayValue, setDisplayValue] = useState(value);
    const debouncedOnChange = useDebounce(onChange, 350);
    const showAddon = Boolean(children) && !disabled;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setDisplayValue(event.target.value);
      debouncedOnChange(event);
    };

    return (
      <InputControl>
        <SearchBox disabled={disabled}>
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
          <SearchInput
            placeholder={placeholder}
            ref={ref}
            disabled={disabled}
            id={id}
            value={displayValue}
            onChange={handleChange}
            {...props}
          />
          <Addon>{showAddon && children}</Addon>
        </SearchBox>
      </InputControl>
    );
  }
);

export default Search;
