import React, { useMemo } from 'react';
import InvoiceContext from './context';
import useInvoice from './useInvoice';
import AsideActions from './components/AsideActions';
import DesktopActions from './components/DesktopActions';
import InvoiceContent from './components/InvoiceContent';

const InvoiceContainer = () => {
  const {
    data,
    getSelectionProps,
    requestSort,
    isMobileWidth,
    loading,
    showGrid,
    order,
    searchKeys,
    openSelectDate,
    closeSelectDate,
    selectDateIsOpen,
    range,
    onChangeRange,
    requestSearch,
    search,
    areElementsSelected,
    selectedData,
    handleSelectAll,
    toggleLayout,
    layout,
    handleSearch,
    handleSearchKeys,
    handleOrder,
    clearSearch,
    clearRangeSelect,
    handleDownloadMultipleInvoices,
    handleDownloadInvoice,
    actionsIsDisabled,
    handlePrint,
    signalRConnectionReachedMaxRetries,
    dataIsEmpty,
    filterIsEmpty,
    filterIsPending,
    searchModalIsOpen,
    closeSearchModal,
    openSearchAction,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    openSelectDateAction,
  } = useInvoice();

  const context = useMemo(
    () => ({
      filterIsPending,
      openSelectDate,
      closeSelectDate,
      selectDateIsOpen,
      range,
      onChangeRange,
      requestSearch,
      search,
      areElementsSelected,
      selectedData,
      handleSelectAll,
      toggleLayout,
      layout,
      handleSearch,
      handleSearchKeys,
      handleOrder,
      loading,
      showGrid,
      order,
      searchKeys,
      clearSearch,
      clearRangeSelect,
      handleDownloadMultipleInvoices,
      handleDownloadInvoice,
      actionsIsDisabled,
      handlePrint,
      signalRConnectionReachedMaxRetries,
      dataIsEmpty,
      filterIsEmpty,
      data,
      getSelectionProps,
      requestSort,
      searchModalIsOpen,
      closeSearchModal,
      openSearchAction,
      asideActionsIsOpen,
      asideStage,
      setAsideStage,
      openAsideActions,
      closeAsideActions,
      openSelectDateAction,
    }),
    [
      filterIsPending,
      actionsIsDisabled,
      selectDateIsOpen,
      range,
      search,
      areElementsSelected,
      selectedData,
      layout,
      loading,
      showGrid,
      order,
      searchKeys,
      signalRConnectionReachedMaxRetries,
      dataIsEmpty,
      filterIsEmpty,
      data,
      getSelectionProps,
      requestSort,
      searchModalIsOpen,
      asideActionsIsOpen,
      asideStage,
    ]
  );

  return (
    <InvoiceContext.Provider value={context}>
      {isMobileWidth ? <AsideActions /> : <DesktopActions />}
      <InvoiceContent />
    </InvoiceContext.Provider>
  );
};

export default InvoiceContainer;
