import React, { useMemo } from 'react';
import usePartner from './usePartner';
import HomeContext from './context';
import PartnerDesktopView from './containers/Building/Desktop';
import PartnerMobileView from './containers/Building/Mobile';

const PartnerDashboard = () => {
  const { buildings, fetchBuildings, isMobileWidth, requestSort, order, status, setStatus } = usePartner();

  const context = useMemo(
    () => ({
      buildings,
      fetchBuildings,
      isMobileWidth,
      requestSort,
      order,
      status,
      setStatus,
    }),
    [buildings, order, status]
  );

  return (
    <HomeContext.Provider value={context}>
      {isMobileWidth ? <PartnerMobileView /> : <PartnerDesktopView />}
    </HomeContext.Provider>
  );
};

export default PartnerDashboard;
