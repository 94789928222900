import React, { PropsWithChildren } from 'react';
import { Placement } from '@floating-ui/core/src/types';
import Floating from '../../index';
import CustomText from '../../../CustomText';
import { TooltipContent } from './style';
import { StyledReferenceWrapper } from '../../style';

interface TooltipProps {
  info: string;
  placement?: Placement;
}

const ReferenceWrapper = React.forwardRef<HTMLDivElement, PropsWithChildren<{ open?: boolean }>>(
  ({ children, ...props }, ref) => (
    <StyledReferenceWrapper ref={ref} className="tooltip_button" {...props}>
      {children}
    </StyledReferenceWrapper>
  )
);

const Tooltip = ({ children, placement, info }: PropsWithChildren<TooltipProps>) => (
  <Floating placement={placement} button={<ReferenceWrapper>{children}</ReferenceWrapper>} usingSafePolygon={false}>
    <TooltipContent>
      <CustomText>{info}</CustomText>
    </TooltipContent>
  </Floating>
);

export default Tooltip;
