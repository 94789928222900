import React from 'react';
import { StyledToast } from './style';
import 'react-toastify/dist/ReactToastify.css';

const TEN_SECONDS = 10000; // ms

const Toast = () => (
  <StyledToast
    autoClose={TEN_SECONDS}
    hideProgressBar
    closeOnClick
    pauseOnHover
    draggable={false}
    closeButton={false}
  />
);

export default Toast;
