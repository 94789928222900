import React, { createContext, useMemo, useState, useCallback, useEffect, useRef, useContext } from 'react';
import generateUUID, { UUID } from '../utils/helpers/uuid';

export interface ScrollLockContextProps {
  registerComponent: (id: UUID) => void;
  unregisterComponent: (id: UUID) => void;
}

export const ScrollLockContext = createContext<ScrollLockContextProps | null>(null);

export const useScrollLock = (shouldLock: boolean | undefined) => {
  const { registerComponent, unregisterComponent } = useContext(ScrollLockContext) as ScrollLockContextProps;
  const idRef = useRef(generateUUID());
  useEffect(() => {
    if (shouldLock) {
      registerComponent(idRef.current);
    } else {
      unregisterComponent(idRef.current);
    }

    return () => unregisterComponent(idRef.current);
  }, [shouldLock]);
};

const ScrollLockProvider = ({ children }: ContextProvider) => {
  const [registeredComponents, setRegisteredComponents] = useState<UUID[]>([]);

  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.dataset.scrollLock = 'true';
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = 'unset';
    document.body.dataset.scrollLock = 'false';
  }, []);

  const registerComponent = useCallback((id: UUID) => {
    setRegisteredComponents((prevCount) => [...prevCount, id]);
  }, []);

  const unregisterComponent = useCallback((id: UUID) => {
    setRegisteredComponents((prevCount) => prevCount.filter((prevId) => prevId !== id));
  }, []);

  const shouldLockScroll = useMemo(() => registeredComponents.length > 0, [registeredComponents]);

  useEffect(() => {
    if (shouldLockScroll) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [shouldLockScroll]);

  const context = useMemo(
    () => ({
      registerComponent,
      unregisterComponent,
      shouldLockScroll,
    }),
    [registerComponent, unregisterComponent, shouldLockScroll]
  );

  return <ScrollLockContext.Provider value={context}>{children}</ScrollLockContext.Provider>;
};

export default ScrollLockProvider;
