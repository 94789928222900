import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Floating, { FloatingParentRef } from '../Floating';
import CustomText from '../CustomText';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Component, TooltipContainer } from './style';

interface ClassNameProps {
  className?: string;
  tooltipStyle?: React.CSSProperties | undefined;
}

const FixedTextCell = ({ children, tooltipStyle, ...props }: PropsWithChildren<BreakpointsProps & ClassNameProps>) => {
  const parentRef = useRef<FloatingParentRef>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (parentRef.current) {
      setIsOverflowing(parentRef?.current.buttonElementIsOverflowing());
    }
  }, [width]);

  return (
    <Floating ref={parentRef} disabled={!isOverflowing} button={<Component {...props}>{children}</Component>}>
      <TooltipContainer style={tooltipStyle}>
        <CustomText>{children}</CustomText>
      </TooltipContainer>
    </Floating>
  );
};

export default FixedTextCell;
