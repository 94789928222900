import React, { FunctionComponent } from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo_without_label.svg';
import CustomText from '../../components/CustomText';
import { Container, Content } from './style';
import ProgressBar from '../../components/ProgressBar';

interface FullScreenPageLayoutProps {
  title: string;
  description: string;
  loading?: boolean;
}

const FullScreenPageLayout: FunctionComponent<FullScreenPageLayoutProps> = ({ title, description, loading }) => (
  <Container>
    <Logo />
    <Content>
      <CustomText as="h1">{title}</CustomText>
      <CustomText as="p">{description}</CustomText>
    </Content>
    {loading && <ProgressBar />}
  </Container>
);

export default FullScreenPageLayout;
