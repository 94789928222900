import React, { ChangeEvent, forwardRef, Ref } from 'react';
import { AlignContainer, GroupContainer, GroupTitle } from '../../style';
import CheckboxComponent from '../index';
import { OptionValue, SelectableGroupProps } from '../../types';
import { CheckboxContainer } from './style';
import { addOrRemove } from '../../../../utils/helpers/array';

interface CheckboxGroupProps<T extends OptionValue> extends SelectableGroupProps<T> {
  selectedOptions: T[];
  onChange: (value: T[]) => void;
}

const Component = <T extends OptionValue>(
  { align, options, title, disabled, selectedOptions, onChange, ...props }: CheckboxGroupProps<T>,
  ref: Ref<HTMLDivElement>
) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const choice = e.target.value as T;

    if (Array.isArray(selectedOptions)) {
      return onChange(addOrRemove(selectedOptions, choice));
    }

    return onChange([choice]);
  };

  return (
    <GroupContainer ref={ref}>
      {title && <GroupTitle>{title}</GroupTitle>}
      <AlignContainer className="Align_Container" align={align}>
        {options.map(({ label, option, readOnly }) => (
          <CheckboxContainer key={`CheckboxGroup-${label}-${option}`}>
            <CheckboxComponent
              id={`id-${label}-${option}`}
              label={label}
              value={String(option)}
              checked={selectedOptions.map(String).includes(String(option))}
              onChange={handleChange}
              disabled={disabled || readOnly}
              {...props}
            />
          </CheckboxContainer>
        ))}
      </AlignContainer>
    </GroupContainer>
  );
};

const CheckboxGroup = forwardRef(Component) as <T extends OptionValue>(
  props: CheckboxGroupProps<T> & { ref?: Ref<HTMLDivElement> }
) => JSX.Element;

export default CheckboxGroup;
