import { usePatternFormat } from 'react-number-format';

const CPF_PATTERN = '###.###.###-##';
const CNPJ_PATTERN = '##.###.###/####-##';

export const maskString = (str: string, pattern: string) => {
  const letters = str.split('');
  return letters.reduce((p, c) => p.replace('#', c), pattern);
};

export const obfuscatePhone = (str: string) => {
  const phone = str.slice(-11);
  return `${phone.substring(0, 4)}*****${phone.substring(9)}`;
};

export const maskPhone = (str: string) => maskString(str.slice(-11), '(##) #####-####');

export const maskDocument = (str: string) => {
  if (str.length === 11) {
    return maskString(str, CPF_PATTERN);
  }

  if (str.length === 14) {
    return maskString(str, CNPJ_PATTERN);
  }

  return str;
};

export const maskCPForCNPJ = (unmaskedDocument: string) => {
  if (unmaskedDocument.length < 12) {
    const { format } = usePatternFormat({
      format: CPF_PATTERN,
      mask: '_',
    });

    return format(unmaskedDocument);
  }

  const { format } = usePatternFormat({ format: CNPJ_PATTERN, mask: '_' });
  return format(unmaskedDocument);
};
