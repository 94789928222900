import React, { useCallback } from 'react';
import useTableView from '../../../../../../../components/TableView/hooks/useTableView';
import HeaderOrderWrapper from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import CustomText from '../../../../../../../components/CustomText';
import { BillMinimalData, BillOrder, BillOrderBy } from '../../../../../../../@types/bill';
import FixedTextCell from '../../../../../../../components/FixedTextCell';
import { WrapperDiv } from '../../../../../../../styles/components';
import { BillBadge } from '../BillBadge';
import BillActions from '../BillActions';
import { formatDate } from '../../../../../../../utils/helpers/date';
import { formatNumberToBRL } from '../../../../../../../utils/helpers/money';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import { GetSelectionPropsFunc } from '../../../../../../../hooks/useDataSelection';
import InvoicesTooltip from '../InvoicesTooltip';
import { BillTooltipContainer } from '../../style';
import { mountAutoMinMaxString, mountMinMaxString } from '../../../../../../../utils/helpers/css';
import TableView from '../../../../../../../components/TableView';
import BillCheckbox from '../BillCheckbox';
import { HTMLCheckboxProps } from '../../../../../../../@types/html';

interface BillTableProps {
  data: BillMinimalData[];
  requestSort: (key: BillOrderBy, direction?: 'asc' | 'desc') => void;
  order: BillOrder | null;
  style?: React.CSSProperties;
  withSelection?: boolean;
  getSelectionProps?: GetSelectionPropsFunc<BillMinimalData>;
}

const BillTable = ({ data, getSelectionProps, requestSort, order, withSelection = true }: BillTableProps) => {
  const { width, isMobileWidth } = useWindowDimensions();

  const { getTableProps } = useTableView<BillMinimalData>(
    data,
    [
      {
        width: mountAutoMinMaxString(160),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientName">
            <CustomText>Cliente</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'clientName',
        render: ({ item }) => <FixedTextCell>{item.clientName || 'Sem nome de cliente'}</FixedTextCell>,
      },
      {
        width: '190px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientDocument">
            <CustomText>CNPJ ou CPF</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'clientDocument',
        render: ({ item }) => (
          <CustomText>{item.clientDocument ? maskDocument(item?.clientDocument) : 'Sem documento'}</CustomText>
        ),
      },
      {
        width: '120px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="contract">
            <CustomText>Contrato</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'contract',
      },
      {
        width: width <= 1366 ? '250px' : '280px',
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="documentNumber">
            <CustomText>Nº Boleto</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'documentNumber',
        render: ({ item }) => (
          <BillTooltipContainer disabled={item.composition.length < 1}>
            <CustomText>{item.documentNumber || 'Sem Nº Boleto'}</CustomText>
            <InvoicesTooltip composition={item.composition} />
          </BillTooltipContainer>
        ),
      },
      {
        width: mountMinMaxString(135, 150),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="expiresAt">
            <CustomText>Vencimento</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'expiresAt',
        render: ({ item: { expiresAt } }) => <CustomText>{formatDate(new Date(expiresAt))}</CustomText>,
      },
      {
        width: mountMinMaxString(135, 150),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="totalAmount">
            <CustomText>Total a pagar</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'totalAmount',
        render: ({ item: { totalAmount } }) => <CustomText>{formatNumberToBRL(totalAmount)}</CustomText>,
      },
      {
        width: mountMinMaxString(140, 170),
        header: (
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="status">
            <CustomText>Status</CustomText>
          </HeaderOrderWrapper>
        ),
        accessor: 'status',
        render: ({ item: { status } }) => <BillBadge status={status} />,
      },
      {
        key: 'actions',
        width: isMobileWidth ? '65px' : 'auto',
        header: (
          <WrapperDiv style={{ width: '100%' }} justifyContent="flex-end">
            <CustomText>Ações</CustomText>
          </WrapperDiv>
        ),
        render: ({ item }) => (
          <WrapperDiv justifyContent="flex-end" style={{ width: '100%' }}>
            <BillActions data={item} />
          </WrapperDiv>
        ),
      },
    ],
    {
      withSelection: Boolean(withSelection),
    }
  );

  const tableStyle = getTableProps().style;

  const createCustomCheckbox = useCallback(
    (item: BillMinimalData, checkboxProps: HTMLCheckboxProps) => (
      <BillCheckbox status={item.status} {...checkboxProps} />
    ),
    []
  );

  return (
    <TableView
      {...getTableProps()}
      {...(getSelectionProps?.() || { withSelection: false })}
      keyExtractor={(item) => item.id}
      style={tableStyle}
      customCheckbox={createCustomCheckbox}
    />
  );
};

export default BillTable;
