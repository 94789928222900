import React, { Suspense, useMemo, useState } from 'react';
import CustomText from '../../../../../../components/CustomText';
import { FormStage } from '../../../../type';
import EmailActionContext from './context';
import Form from './Steps/Form';
import Confirm from './Steps/Confirm';
import Feedback from './Steps/Feedback';

const formMapping = {
  [FormStage.FORM]: Form,
  [FormStage.CONFIRM]: Confirm,
  [FormStage.FEEDBACK]: Feedback,
} as const;

const EmailAction = () => {
  const [stage, setStage] = useState(FormStage.FORM);
  const [selectedEmail, setSelectedEmail] = useState('');
  const Step = formMapping[stage];

  const context = useMemo(() => ({ stage, setStage, selectedEmail, setSelectedEmail }), [stage, selectedEmail]);

  return (
    <EmailActionContext.Provider value={context}>
      <Suspense fallback={<CustomText as="h1">Loading...</CustomText>}>
        <Step />
      </Suspense>
    </EmailActionContext.Provider>
  );
};

export default EmailAction;
