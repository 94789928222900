import React, { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import { RadioInput, RadioLabel } from './style';
import { GroupLabel } from '../style';
import { InputRadioWrapper } from './Group/style';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  handleChange?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const RadioButton: FunctionComponent<RadioProps> = ({ checked, disabled, handleChange, ...props }) => (
  <RadioLabel disabled={disabled} checked={checked}>
    <RadioInput disabled={disabled} type="radio" onClick={handleChange} {...props} />
  </RadioLabel>
);

interface RadioComponentProps extends RadioProps {
  label: ReactNode;
}

const RadioComponent: FunctionComponent<RadioComponentProps> = ({
  checked,
  name,
  disabled,
  label,
  value,
  handleChange,
  id,
  ...props
}) => (
  <InputRadioWrapper key={`RadioGroup-${label}-${value}`}>
    <RadioButton
      id={id}
      name={name}
      value={value}
      checked={checked}
      handleChange={handleChange}
      disabled={disabled}
      {...props}
    />
    <GroupLabel checked={checked} htmlFor={id}>
      {label}
    </GroupLabel>
  </InputRadioWrapper>
);

export default RadioComponent;
