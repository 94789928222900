import styled from 'styled-components';

export const ActionsHead = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
`;

export const NameAndTooltipButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
`;

export const Links = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;
