import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  .__modal-title {
    font-size: 18px;
  }

  .__modal-close {
    cursor: pointer;
  }

  button {
    width: fit-content !important;
  }
`;
