import getGridTemplateColumns from '../helpers/getGridTemplateColumns';
import { DisabledHeader } from '../components/DisabledTableHeader';

const useDisabledTableHeader = (headers: Array<DisabledHeader>, config?: { withSelection: boolean }) => {
  const gridTemplateColumns = getGridTemplateColumns(headers, config?.withSelection);

  return {
    style: {
      display: 'grid',
      gridTemplateColumns,
    },
    headers,
    withSelection: Boolean(config?.withSelection),
  };
};

export default useDisabledTableHeader;
