import styled, { css } from 'styled-components';
import theme from '../../../styles/theme';

export const RadioInput = styled.input`
  display: none;
`;

const CHECKED_STYLE = css`
  border: 4px solid ${theme.color.primary.interaction};
`;

const DISABLED_STYLE = css`
  border-color: ${theme.color.secondary.grey[4]};
  ${theme.defaults.disabled}
`;

interface SelectableLabelProps {
  checked: boolean;
  disabled?: boolean;
}

export const RadioLabel = styled.label<SelectableLabelProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;

  position: relative;
  border: 1px solid ${theme.color.secondary.grey[1]};
  border-radius: 50%;

  &:active {
    border-width: 2px;
  }

  ${({ checked }) => checked && CHECKED_STYLE};

  ${({ disabled }) => disabled && DISABLED_STYLE};

  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    css`
      border: 1px solid ${theme.color.secondary.grey[4]};
      &:after {
        content: '';
        position: absolute;
        background-color: ${theme.color.secondary.grey[4]};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
      ${DISABLED_STYLE}
    `};
`;
