import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabAccessor } from '../../../@types/dashboard';
import useHub from '../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../config/signalR/types';
import useQueryParam from '../../../hooks/useQueryParam';
import { useScrollToRef } from '../../../hooks/useScrollToRef';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { sendGTMEvent } from '../../../lib/DataLayer';
import { billActions } from '../../../redux/reducers/billSlice';
import { invoiceActions } from '../../../redux/reducers/invoiceSlice';
import { mapTabToGTMEvent } from './constants';

const useClient = () => {
  const tab = useQueryParam('tab');
  const currentTab: TabAccessor = Object.values(TabAccessor).includes(tab as TabAccessor)
    ? (tab as TabAccessor)
    : TabAccessor.BUILDING;
  const [selectedTab, setSelectedTab] = useState<TabAccessor>(currentTab);
  const { isMobileWidth } = useWindowDimensions();
  const { scrollRef, scrollToRef } = useScrollToRef<HTMLDivElement>();
  const { singleSend: sendBillMessage } = useHub(SignalRHubName.BILL);
  const { singleSend: sendInvoiceMessage } = useHub(SignalRHubName.INVOICE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTab) {
      sendGTMEvent({ name: mapTabToGTMEvent[selectedTab] });
    }
  }, [selectedTab]);

  const updateFinancialData = () => {
    Promise.all([
      sendBillMessage([
        'RequestBills',
        {
          Clients: [],
          StartDate: null,
          FinalDate: null,
        },
      ]),
      sendInvoiceMessage([
        'RequestInvoices',
        {
          Clients: [],
          StartDate: null,
          FinalDate: null,
        },
      ]),
    ]).then(([billRequestId, invoiceRequestId]) => {
      dispatch(billActions.registerNewRequest(billRequestId));
      dispatch(invoiceActions.registerNewRequest(invoiceRequestId));
    });
  };

  const handleTabChange = (accessor: TabAccessor) => {
    setSelectedTab(accessor);
  };

  return {
    selectedTab,
    setSelectedTab,
    scrollToRef,
    handleTabChange,
    updateFinancialData,
    isMobileWidth,
    scrollRef,
  };
};

export default useClient;
