import { useCallback, useMemo, useState } from 'react';
import { BuildingData, BuildingOrder, BuildingOrderBy, LinkContract } from '../../../@types/building';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import BuildingService from '../../../services/Buildings/BuildingService';
import { isNotEmptyArray } from '../../../utils/helpers/array';
import getOrderDirection from '../../../utils/helpers/getOrderDirection';
import Alert from '../../../components/Toast/toast';

export enum PartnerSearchStatus {
  NOT_COMPLETED,
  HAS_RESULT,
  EMPTY_RESULT,
}

const usePartner = () => {
  const [searchedBuildings, setSearchedBuildings] = useState<BuildingData[]>([]);
  const [order, setOrder] = useState<BuildingOrder | null>(null);
  const [status, setStatus] = useState<PartnerSearchStatus>(PartnerSearchStatus.NOT_COMPLETED);

  const sortedData = useMemo(() => {
    const sortableItems = [...searchedBuildings];
    if (!order) return sortableItems;

    const isAscendingOrder = order.direction === 'asc';

    return sortableItems.sort((a, b) => {
      if (order.orderBy !== 'vinculated') {
        if (order.orderBy === 'document' || order.orderBy === 'id') {
          const aNumeric = Number(a[order.orderBy]);
          const bNumeric = Number(b[order.orderBy]);

          if (aNumeric < bNumeric) {
            return isAscendingOrder ? -1 : 1;
          }
          if (aNumeric > bNumeric) {
            return isAscendingOrder ? 1 : -1;
          }
        } else {
          if (a[order.orderBy] < b[order.orderBy]) {
            return isAscendingOrder ? -1 : 1;
          }
          if (a[order.orderBy] > b[order.orderBy]) {
            return isAscendingOrder ? 1 : -1;
          }
        }
      }
      return 0;
    });
  }, [order, searchedBuildings]);

  const requestSort = useCallback(
    (key: BuildingOrderBy) =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    []
  );

  const fetchBuildings = async (newContract: LinkContract, signal?: AbortSignal) => {
    const buildings = await BuildingService.partnerGetBuildingsByContract(newContract, signal);
    setSearchedBuildings(buildings);
    try {
      if (isNotEmptyArray(buildings)) {
        setStatus(PartnerSearchStatus.HAS_RESULT);
      } else {
        setStatus(PartnerSearchStatus.EMPTY_RESULT);
      }
    } catch (e) {
      console.error(e);
      Alert.ERROR('Ocorreu um erro ao buscar novo contrato. Tente novamente mais tarde.');
      setStatus(PartnerSearchStatus.EMPTY_RESULT);
    }
  };

  const { isMobileWidth } = useWindowDimensions();

  return {
    buildings: sortedData,
    fetchBuildings,
    isMobileWidth,
    requestSort,
    order,
    status,
    setStatus,
  };
};

export default usePartner;
