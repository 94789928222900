import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const FloatingButton = styled.button<{ open?: boolean }>`
  display: flex;
  cursor: pointer;
  gap: 8px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  // Reset button styles
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;

  &[disabled] {
    ${theme.defaults.disabled}
  }
`;
