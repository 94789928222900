import { createSlice } from '@reduxjs/toolkit';
import { InvoiceData } from '../../@types/invoice';
import { RequestID } from '../../config/signalR/types';

export interface InvoiceState {
  messages: InvoiceData[];
  currentRequestId: null | RequestID;
}

const initialState: InvoiceState = {
  messages: [],
  currentRequestId: null,
};

export const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    registerNewRequest(state, { payload }) {
      return { ...state, currentRequestId: payload, messages: [] };
    },
    receiveData(state, { payload }) {
      if (!payload || !payload.id) {
        console.error('Receive invalid payload.');
        return state;
      }

      if (payload.requestId !== state.currentRequestId) {
        return state;
      }

      const { id } = payload;

      const existingItemIndex = state.messages.findIndex((dataItem) => dataItem.id === id);

      if (existingItemIndex === -1) {
        return { ...state, messages: [...state.messages, payload] };
      }

      const updatedMessages = [...state.messages];
      updatedMessages[existingItemIndex] = payload;

      return { ...state, messages: updatedMessages };
    },
  },
});
export const invoiceActions = slice.actions;
export default slice.reducer;
