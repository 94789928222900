import React from 'react';
import { SkeletonBox } from '../../style';
import { WrapperDiv } from '../../../../styles/components';

const InputSkeleton = () => (
  <WrapperDiv alignItems="left" flexDirection="column" gap={8}>
    <SkeletonBox width={115} height={12} />
    <SkeletonBox height={45} />
  </WrapperDiv>
);

export default InputSkeleton;
