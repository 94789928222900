import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import MobileSearch from '../../../../../../../shared/components/MobileSearch';
import { FINANCIAL_HISTORIC_FILTER_OPTIONS } from '../../../../../../constants';
import { FinancialHistoricStage } from '../../../../../../../../../@types/bill';
import FinancialHistoricContext, { FinancialHistoricContextProps } from '../../../../context';

const SearchStep = () => {
  const { requestSearch, search } = useContext(FinancialHistoricContext) as FinancialHistoricContextProps;
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <MobileSearch
      title="Buscar histórico"
      defaultValue={search}
      filterOptions={FINANCIAL_HISTORIC_FILTER_OPTIONS}
      onSubmit={requestSearch}
      onBack={() => changeStage(FinancialHistoricStage.SELECT_ACTION)}
      onClose={closeHandler}
    />
  );
};

export default SearchStep;
