import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { BillStatus } from '../../../../../../../@types/bill';
import CustomText from '../../../../../../../components/CustomText';
import theme from '../../../../../../../styles/theme';
import getFeedbackIcon from '../../../../../../../utils/jsx/getFeedbackIcon';
import { MAP_BILL_STATUS } from '../../constants';
import { DocumentIcon, ExpiredIcon } from '../../../../../../../icons';

type MapFinancial = Record<
  Exclude<BillStatus, BillStatus.CANCELED>,
  { Icon: ReactNode; label: string; iconColor: string; textColor?: string }
>;

const mapFinancialStatus: MapFinancial = {
  [BillStatus.PAID]: {
    Icon: getFeedbackIcon('success'),
    label: MAP_BILL_STATUS[BillStatus.PAID],
    iconColor: theme.color.feedback.success,
  },
  [BillStatus.OVERDUE]: {
    Icon: getFeedbackIcon('error'),
    label: MAP_BILL_STATUS[BillStatus.OVERDUE],
    iconColor: theme.color.feedback.error,
  },
  [BillStatus.PENDING]: {
    Icon: getFeedbackIcon('warning'),
    label: MAP_BILL_STATUS[BillStatus.PENDING],
    iconColor: theme.color.feedback.alert,
  },
  [BillStatus.NOT_GENERATED]: {
    Icon: <DocumentIcon />,
    label: MAP_BILL_STATUS[BillStatus.NOT_GENERATED],
    iconColor: theme.color.secondary.grey[3],
    textColor: theme.color.secondary.grey[3],
  },
  [BillStatus.EXPIRED]: {
    Icon: <ExpiredIcon />,
    label: MAP_BILL_STATUS[BillStatus.EXPIRED],
    iconColor: theme.color.feedback.error,
  },
};

interface FinancialStatusProps {
  status: Exclude<BillStatus, BillStatus.CANCELED>;
}

const StyledStatus = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: space-between;
`;

export const BillBadge = ({ status }: FinancialStatusProps) => {
  const { Icon, label, textColor, iconColor } = mapFinancialStatus[status];

  return (
    <StyledStatus style={{ color: iconColor }}>
      {Icon}
      <CustomText style={{ color: textColor || theme.color.primary.black }} crop>
        {label}
      </CustomText>
    </StyledStatus>
  );
};
