import * as React from 'react';
import type { SVGProps } from 'react';

const SvgClient = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="profile-icon-black-0053" clipPath="url(#clip0_1568_6183)">
      <path
        id="Vector"
        d="M25.7969 27.8449H6.36993L9.76209 19.8921L9.7625 19.8912C9.96044 19.4203 10.301 19.0233 10.7362 18.756C11.1714 18.4886 11.6794 18.3644 12.1889 18.4007L12.196 18.4012H12.2031H18.7656V18.4016L18.7794 18.4007C19.2934 18.3652 19.8056 18.4922 20.2435 18.7637C20.6809 19.0348 21.0221 19.4364 21.2191 19.9117L21.8606 21.5551L21.9102 21.6824H22.0469H23.0219H23.3088L23.2095 21.4132L22.558 19.646L22.5574 19.6445C22.2345 18.7899 21.7609 18.1399 21.1216 17.7053C20.4827 17.271 19.6955 17.0637 18.7656 17.0637H12.2031C11.2687 17.0637 10.4792 17.2709 9.83898 17.7059C9.1995 18.1403 8.72654 18.79 8.40368 19.6447L4.98847 27.4964L4.97187 27.5346V27.5762V28.9824V29.1824H5.17188H25.7969H25.9969V28.9824V28.0449V27.8449H25.7969Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
      <path
        id="Vector_2"
        d="M15.4844 14.1824C16.6365 14.1824 17.7627 13.8408 18.7206 13.2007C19.6785 12.5607 20.4251 11.6509 20.866 10.5866C21.3069 9.52218 21.4222 8.35096 21.1975 7.22102C20.9727 6.09108 20.4179 5.05317 19.6033 4.23853C18.7886 3.42389 17.7507 2.86911 16.6208 2.64435C15.4908 2.41959 14.3196 2.53495 13.2552 2.97583C12.1909 3.41671 11.2811 4.16331 10.6411 5.12123C10.001 6.07914 9.65937 7.20535 9.65937 8.35742C9.65937 9.90231 10.2731 11.3839 11.3655 12.4763C12.4579 13.5687 13.9395 14.1824 15.4844 14.1824ZM15.4844 3.86992C16.3719 3.86992 17.2395 4.13311 17.9775 4.6262C18.7155 5.1193 19.2906 5.82015 19.6303 6.64013C19.9699 7.46012 20.0588 8.3624 19.8856 9.23289C19.7125 10.1034 19.2851 10.903 18.6575 11.5306C18.0299 12.1582 17.2303 12.5855 16.3598 12.7587C15.4894 12.9319 14.5871 12.843 13.7671 12.5033C12.9471 12.1637 12.2462 11.5885 11.7532 10.8505C11.2601 10.1126 10.9969 9.24497 10.9969 8.35742C10.9969 7.16727 11.4697 6.02585 12.3112 5.18428C13.1528 4.34271 14.2942 3.86992 15.4844 3.86992Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1568_6183">
        <rect width="30" height="30" fill="currentColor" transform="translate(0.484375 0.857422)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClient;
