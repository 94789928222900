import { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GTMEventName } from '../../../../../@types/analytics';
import {
  ALL_SEARCH_KEYS,
  BillAsideStage,
  BillMinimalData,
  BillOrder,
  BillOrderBy,
  BillSearchBy,
  BillStatus,
} from '../../../../../@types/bill';
import Alert from '../../../../../components/Toast/toast';
import useHub from '../../../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../../../config/signalR/types';
import useAsideActions from '../../../../../hooks/useAsideActions';
import useDataSelection from '../../../../../hooks/useDataSelection';
import useDisclosure from '../../../../../hooks/useDisclosure';
import useLayout from '../../../../../hooks/useLayout';
import useSearch from '../../../../../hooks/useSearch';
import useTimedLoading from '../../../../../hooks/useTimedLoading';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { sendGTMEvent } from '../../../../../lib/DataLayer';
import { RootState } from '../../../../../redux/reducers';
import { billActions } from '../../../../../redux/reducers/billSlice';
import BillHelper from '../../../../../strategy/helpers/BillHelper';
import BillPolicy from '../../../../../strategy/rules/BillPolicy';
import { isEmptyArray, pipe } from '../../../../../utils/helpers/array';
import { formatDate } from '../../../../../utils/helpers/date';
import getOrderDirection from '../../../../../utils/helpers/getOrderDirection';
import { formatNumberToBRL } from '../../../../../utils/helpers/money';
import { openBlankPage } from '../../../../../utils/helpers/window';
import { WAIT_TIME_SIGNALR_LOADING } from '../../constants';

const useBill = () => {
  const { singleSend: sendBillMessage, errors } = useHub(SignalRHubName.BILL);
  const signalRConnectionReachedMaxRetries = errors.connectionReachedMaxRetries;
  const { layout, toggleLayout, showGrid } = useLayout();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state!.bill.messages);
  const {
    isOpen: multipleDownloadModalIsOpen,
    open: openMultipleDownloadModal,
    close: closeMultipleDownloadModal,
  } = useDisclosure();
  const { isOpen: searchModalIsOpen, open: openSearchModal, close: closeSearchModal } = useDisclosure();
  const { isOpen: selectDateIsOpen, open: openSelectDate, close: closeSelectDate } = useDisclosure();
  const [loading, setLoading] = useTimedLoading(WAIT_TIME_SIGNALR_LOADING);
  const [order, setOrder] = useState<BillOrder | null>(null);
  const [range, setRange] = useState<DateRange>();
  const [statusFilter, setStatusFilter] = useState<Array<BillStatus>>([]);
  const { areElementsSelected, selectedData, getSelectionProps, handleSelectAll } = useDataSelection(
    data,
    (item) => item.id,
    (item) => !BillPolicy.shouldEnableActionButtons(item.status, item.hasFullBillAvailable)
  );
  const [isPending, startTransition] = useTransition();
  const [filteredData, setFilteredData] = useState<Array<BillMinimalData>>([]);
  const dataIsEmpty = useMemo(() => data.length < 1 && !loading, [data, loading]);
  const filterIsEmpty = useMemo(() => filteredData.length < 1 && !dataIsEmpty, [filteredData, dataIsEmpty]);
  const { isMobileWidth } = useWindowDimensions();
  const {
    isOpen: asideActionsIsOpen,
    open: openAsideActions,
    close: closeAsideActions,
    stage: asideStage,
    setStage: setAsideStage,
  } = useAsideActions<BillAsideStage>(BillAsideStage.SELECT_ACTION);

  const dataToDownload = useMemo(
    () => data.filter((current) => selectedData.includes(current.id)),
    [selectedData, data]
  );

  const actionsIsDisabled = loading || dataIsEmpty || signalRConnectionReachedMaxRetries;

  const { applySearch, search, searchKeys, handleSearchKeys, handleSearch, clearSearch } = useSearch({
    data,
    allSearchKeys: ALL_SEARCH_KEYS,
    searchBy: (searchByKeys, searchBuildingData) => [
      ...searchByKeys
        .filter((key) => key !== 'invoice')
        .map((key) => {
          if (key === 'expiresAt') {
            return String(formatDate(new Date(searchBuildingData[key])));
          }

          if (key === 'totalAmount') {
            return String(formatNumberToBRL(searchBuildingData[key]));
          }

          return String(searchBuildingData[key as Exclude<BillSearchBy, 'invoice'>]);
        }),
      ...(searchByKeys.includes('invoice')
        ? [...searchBuildingData.composition.map((c) => String(c.invoiceNumber))]
        : []),
    ],
  });

  const applyFilterByStatus = (dataToTransform: BillMinimalData[]) => {
    const filterableItems = [...dataToTransform];
    if (isEmptyArray(statusFilter)) return filterableItems;

    return filterableItems.filter((item) => statusFilter.map(String).includes(String(item.status)));
  };

  const applyOrder = (dataToTransform: BillMinimalData[]) => BillHelper.orderMinimalBillData(dataToTransform, order);

  const handleOrder = (newOrder: BillOrder | null) => {
    setOrder(newOrder);
  };

  const requestSearch = useCallback((newSearch: string, newSearchKeys: Array<BillSearchBy>) => {
    handleSearchKeys(newSearchKeys);
    handleSearch(newSearch);
  }, []);

  const handleFilterByStatus = (newFilterStatus: Array<BillStatus>) => {
    setStatusFilter(newFilterStatus);
  };

  const clearFilterByStatus = useCallback(() => {
    setStatusFilter([]);
  }, []);

  const requestSort = useCallback(
    (key: BillOrderBy, direction?: 'asc' | 'desc') =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: direction || getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    [order]
  );

  const requestBills = (actualRange: DateRange | undefined) => {
    sendBillMessage([
      'RequestBills',
      {
        Clients: [],
        StartDate: actualRange ? actualRange[0] : null,
        FinalDate: actualRange ? actualRange[1] : null,
      },
    ]).then((billId) => {
      setLoading(true);
      dispatch(billActions.registerNewRequest(billId));
    });
  };

  const clearRangeSelect = useCallback(() => {
    setRange(undefined);
    requestBills(undefined);
  }, []);

  const onChangeRange = useCallback(async (newRange: DateRange | undefined) => {
    setRange(newRange);
    requestBills(newRange);
    Alert.SUCCESS('Data configurada.');
    sendGTMEvent({ name: GTMEventName.SEARCH_BILL_BY_DATE });
  }, []);

  const handlePrint = (id: BillMinimalData['id']) => {
    openBlankPage(`/home/view/bill/${id}`);
    sendGTMEvent({ name: GTMEventName.PRINT_BILL });
  };

  const openSearchAction = () => {
    if (isMobileWidth) {
      openAsideActions();
      setAsideStage(BillAsideStage.SEARCH_NEW_BILL);
      return;
    }

    openSearchModal();
  };

  const openSelectDateAction = () => {
    if (isMobileWidth) {
      openAsideActions();
      setAsideStage(BillAsideStage.SELECT_DATE_ACTION);
      return;
    }

    openSelectDate();
  };

  useEffect(() => {
    if (data.length > 0 && loading) {
      setLoading(false);
    }

    startTransition(() => {
      const updatedData = pipe(data, [applySearch, applyFilterByStatus, applyOrder]);
      setFilteredData(updatedData);
    });
  }, [data, order, statusFilter, search, searchKeys]);

  // Make new request if redux global bill data is empty in first render
  useEffect(() => {
    if (data.length <= 0) {
      requestBills(range);
    }
  }, []);

  return {
    data: filteredData,
    dataToDownload,
    layout,
    toggleLayout,
    loading,
    filterIsPending: isPending,
    requestSort,
    order,
    setOrder,
    search,
    clearSearch,
    searchKeys,
    requestSearch,
    handleSearch,
    handleSearchKeys,
    showGrid,
    openSelectDate,
    closeSelectDate,
    selectDateIsOpen,
    onChangeRange,
    range,
    selectedData,
    getSelectionProps,
    handleSelectAll,
    areElementsSelected,
    handleOrder,
    clearRangeSelect,
    statusFilter,
    handleFilterByStatus,
    clearFilterByStatus,
    actionsIsDisabled,
    multipleDownloadModalIsOpen,
    openMultipleDownloadModal,
    closeMultipleDownloadModal,
    handlePrint,
    signalRConnectionReachedMaxRetries,
    dataIsEmpty,
    filterIsEmpty,
    searchModalIsOpen,
    closeSearchModal,
    openSearchAction,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    isMobileWidth,
    openSelectDateAction,
  };
};

export default useBill;
