import React from 'react';
import Header from '../../common/Header';
import CredentialsForm from '../components/CredentialsForm';
import CustomText from '../../../../../../../components/CustomText';

const HeaderDescription = () => (
  <CustomText as="p">
    Para editar seu número de celular você receberá um código de validação, digite o código e o novo número de celular.
  </CustomText>
);

const FormStep = () => (
  <>
    <Header title="Editar número de celular" description={<HeaderDescription />} />
    <CredentialsForm />
  </>
);

export default FormStep;
