import styled from 'styled-components';

export const Anchor = styled.a`
  color: inherit;
  text-decoration: none;

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
`;
