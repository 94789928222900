import { BillDetails, BillMinimalComposition, BillMinimalData } from '../../@types/bill';

class FinancialMapper {
  detailsToMinimalData(billDetails: BillDetails, billId?: string): BillMinimalData {
    return {
      id: billId ?? billDetails.id,
      clientName: billDetails.client.name,
      clientDocument: billDetails.client.document,
      contract: billDetails.contract,
      documentNumber: billDetails.documentNumber,
      expiresAt: billDetails.expiresAt,
      totalAmount: billDetails.totalAmount,
      status: billDetails.status,
      hasFullBillAvailable: billDetails.hasFullBillAvailable,
      composition: billDetails.composition.reduce((acc, currentValue) => {
        if (currentValue.hasInvoice) {
          acc.push(currentValue);
        }

        return acc;
      }, [] as Array<BillMinimalComposition>),
    };
  }
}

export default new FinancialMapper();
