import React, { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CalloutContainer, Container, MobileEmptyResult } from './style';
import CustomText from '../../../../../../../../../components/CustomText';
import Button from '../../../../../../../../../components/Button';
import { Content } from '../../style';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import AsideHeader from '../../../../../../../../../components/AsideHeader';
import { BuildingAsideStage, BuildingData, LinkContract } from '../../../../../../../../../@types/building';
import { isNotEmptyArray } from '../../../../../../../../../utils/helpers/array';
import Alert from '../../../../../../../../../components/Toast/toast';
import Callout from '../../../../../../../../../components/Callout';
import {
  NEW_BUILDING_CONTRACT_NUMBER_DISCLAIMER,
  NEW_BUILDING_TITLE,
} from '../../../../../../../../../utils/constants/text';
import BuildingContext, { BuildingContextProps } from '../../../../context';
import BuildingGrid from '../../../BuildingGrid';
import MobileFindContract from '../../../../../../../shared/components/MobileFindContract';

enum SearchStatus {
  NOT_FINISHED = 'NOT_FINISHED',
  FINISHED_AND_HAS_RESULT = 'FINISHED_AND_HAS_RESULT',
  NO_RESULT = 'NO_RESULT',
}

interface SearchNewBuildingStepProps {
  data: BuildingData[];
  onBack: VoidFunction;
  onSubmit: VoidFunction;
  isAlreadyRegistered: boolean;
  loading: boolean;
  status: SearchStatus;
}

const SearchNewBuildingStep = ({
  data,
  onBack,
  onSubmit,
  isAlreadyRegistered,
  loading,
  status,
}: SearchNewBuildingStepProps) => (
  <>
    <AsideHeader onBack={onBack} />
    <Container>
      <Content>
        <CustomText as="h1" className="title">
          Resultado
        </CustomText>
        {status === SearchStatus.NO_RESULT && (
          <MobileEmptyResult>
            <CustomText as="h1">Nenhum resultado encontrado</CustomText>
          </MobileEmptyResult>
        )}
        {status === SearchStatus.FINISHED_AND_HAS_RESULT && (
          <>
            {isAlreadyRegistered && (
              <CalloutContainer>
                <Callout>
                  <CustomText>
                    {data.length > 1
                      ? 'Estes contratos já estão fixados no seu painel.'
                      : 'Este contrato já está fixado no seu painel.'}
                  </CustomText>
                </Callout>
              </CalloutContainer>
            )}
            <BuildingGrid data={data} />
          </>
        )}
      </Content>
      <Button
        type="submit"
        variant="primary"
        onClick={onSubmit}
        disabled={loading || isAlreadyRegistered || status === SearchStatus.NO_RESULT}
        loading={loading}
      >
        Fixar no meu painel
      </Button>
    </Container>
  </>
);

const NewContractStep = () => {
  const { changeStage, closeHandler } = useContext(AsideActionsContext) as AsideActionsContextProps;
  const { fetchNewContract, linkNewContract, surveyedContractIsAlreadyOnList } = useContext(
    BuildingContext
  ) as BuildingContextProps;
  const [loading, setLoading] = useState(false);
  const [newBuildings, setNewBuildings] = useState<BuildingData[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState<boolean>(false);
  const [status, setStatus] = useState<SearchStatus>(SearchStatus.NOT_FINISHED);

  const formMethods = useForm<LinkContract>({
    mode: 'onChange',
    defaultValues: {
      document: '',
      contract: '',
    },
  });

  const fetchData = async (data: LinkContract) => {
    setLoading(true);
    setStatus(SearchStatus.NOT_FINISHED);
    const newContract = await fetchNewContract(data);
    setNewBuildings(newContract);

    if (isNotEmptyArray(newContract)) {
      setStatus(SearchStatus.FINISHED_AND_HAS_RESULT);
    } else {
      setStatus(SearchStatus.NO_RESULT);
    }

    setIsAlreadyRegistered(surveyedContractIsAlreadyOnList(newContract));
    setLoading(false);
  };

  const onSubmit: SubmitHandler<LinkContract> = (data) => {
    fetchData(data);
  };

  const submitDialogHandler = async () => {
    if (!Array.isArray(newBuildings) || newBuildings.length === 0) {
      Alert.ERROR('Não foi possível vincular o novo contrato!');
      return;
    }

    setLoadingSubmit(true);

    const formValues = formMethods.getValues();
    const data = {
      contract: formValues.contract,
      document: formValues.document,
    } as LinkContract;

    try {
      await linkNewContract(data);
      const message =
        newBuildings.length > 1
          ? 'Contratos fixados no seu painel com sucesso!'
          : 'Contrato fixado na lista com sucesso!';
      Alert.SUCCESS(message);
      closeHandler();
    } catch (err) {
      closeHandler();
      const message =
        newBuildings.length > 1
          ? 'Não foi possível fixar os novos contratos na sua lista. Tente novamente em alguns instantes.'
          : 'Não foi possível fixar o novo contrato na sua lista. Tente novamente em alguns instantes.';
      Alert.ERROR(message);
      console.error(err);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onBackResult = () => {
    setStatus(SearchStatus.NOT_FINISHED);
  };

  const showResult = status === SearchStatus.FINISHED_AND_HAS_RESULT || status === SearchStatus.NO_RESULT;

  return showResult ? (
    <SearchNewBuildingStep
      status={status}
      data={newBuildings}
      onSubmit={submitDialogHandler}
      loading={loadingSubmit}
      onBack={onBackResult}
      isAlreadyRegistered={isAlreadyRegistered}
    />
  ) : (
    <FormProvider {...formMethods}>
      <MobileFindContract
        onBack={() => changeStage(BuildingAsideStage.SELECT_ACTION)}
        onSubmit={onSubmit}
        loading={loading}
        content={
          <>
            <CustomText as="h1" className="title">
              Buscar novo contrato
            </CustomText>
            <CustomText as="p" className="disclaimer">
              {NEW_BUILDING_TITLE} {NEW_BUILDING_CONTRACT_NUMBER_DISCLAIMER}
            </CustomText>
          </>
        }
      />
    </FormProvider>
  );
};

export default NewContractStep;
