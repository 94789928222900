import styled from 'styled-components';

export const Cover = styled.div<{ url: string }>`
  background: ${({ url }) => url && `url(${url})`} no-repeat;
  background-size: 100%;
  object-fit: contain;
  min-height: 140px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  margin-top: 16px;
  padding: 0 16px 16px 16px;

  .title {
    font-weight: 500;
    font-size: 2.4rem;
    color: #434343;
  }

  .description {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 19px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  height: 450px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 1px 1px rgba(228, 228, 228, 0.08);

  @media (max-width: 1366px) {
    height: 350px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Links = styled.div`
  display: flex;
  gap: 16px;
`;
