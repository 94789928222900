import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: inherit;

  p {
    color: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }
`;
