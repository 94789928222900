import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import YouTube from 'react-youtube';
import { VideoPlayIcon } from '../../icons';
import { WrapperDiv } from '../../styles/components';
import Banner from '../Banner';
import Button from '../Button';
import Loader from '../Loader';
import { FrameContainer, VideoPlayerContainer, VideoPlayerTitle } from './style';

const VIDEO_PLAYER_OPTIONS = {
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel: 0,
    showinfo: 0,
    modestbranding: 1,
  },
};

interface VideoPlayerProps {
  thumbPath: string;
  videoId: string;
  title: string;
}

const VideoPlayer = ({ thumbPath, videoId, title }: VideoPlayerProps) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const openVideoPlayer = () => {
    flushSync(() => {
      setLoadingVideo(true);
    });
    setShowVideoPlayer(true);
  };

  if (showVideoPlayer) {
    return (
      <VideoPlayerContainer>
        {loadingVideo && <Loader center width={64} height={64} />}
        <FrameContainer>
          <YouTube
            className="container-player"
            iframeClassName="iframe-player"
            videoId={videoId}
            id={videoId}
            opts={VIDEO_PLAYER_OPTIONS}
            onReady={() => {
              flushSync(() => {
                setLoadingVideo(false);
              });
            }}
          />
        </FrameContainer>
      </VideoPlayerContainer>
    );
  }

  return (
    <VideoPlayerContainer>
      <WrapperDiv className="banner-container">
        <Banner imagePath={thumbPath} onClick={openVideoPlayer}>
          <VideoPlayIcon />
        </Banner>
      </WrapperDiv>
      <WrapperDiv flexDirection="column" justifyContent="space-between" className="title-container">
        <VideoPlayerTitle>{title}</VideoPlayerTitle>
        <Button variant="primary" size="fit" onClick={openVideoPlayer}>
          Assista ao vídeo
        </Button>
      </WrapperDiv>
    </VideoPlayerContainer>
  );
};

export default VideoPlayer;
