import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M21.04 12.678v-.15H6.46l4.891-4.888.106-.106-.106-.106-.4-.4-.105-.105-.106.106-5.822 5.824-.106.107.106.105 5.822 5.825.106.106.106-.106.4-.4.105-.105-.106-.106-4.89-4.888H21.039v-.713Z"
    />
  </svg>
);
export default SvgArrow;
