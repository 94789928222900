import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import ExternalLink from '../../../../../components/Link/ExternalLink';
import theme from '../../../../../styles/theme';

interface IntegrationLinksProps {
  buildingId: string | number;
  contractId: string | number;
  disableBuildings: boolean;
  showFinancialLink?: boolean;
}

const IntegrationLinks: FunctionComponent<IntegrationLinksProps> = ({
  buildingId,
  contractId,
  showFinancialLink = false,
  disableBuildings,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {showFinancialLink && (
        <ExternalLink
          hoverColor={theme.color.secondary.orange[1]}
          color={theme.color.primary.interaction}
          onClick={() => navigate('/redirect', { state: { id: contractId, type: 'financial' } })}
          text="Financeiro"
        />
      )}
      <ExternalLink
        hoverColor={theme.color.secondary.orange[1]}
        color={theme.color.primary.interaction}
        disabled={disableBuildings}
        onClick={() => navigate('/redirect', { state: { id: buildingId, type: 'building' } })}
        text="Instalações"
      />
    </>
  );
};

export default IntegrationLinks;
