import React, { ReactNode } from 'react';
import { TitleBox } from './style';
import CustomText from '../../../../../components/CustomText';
import ModalHeader from '../../../../../components/ModalHeader';

interface FindContractHeaderProps {
  content: ReactNode;
  title: string;
  onClose: VoidFunction;
}

const FindContractHeader = ({ content, title, onClose }: FindContractHeaderProps) => (
  <>
    <ModalHeader onClose={onClose} />
    <TitleBox>
      <CustomText as="h1">{title}</CustomText>
      {content}
    </TitleBox>
  </>
);

export default FindContractHeader;
