import { ContactType } from '../../@types/auth';

export enum ActionsType {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  PHONE = 'PHONE',
}

export enum FormStage {
  FORM = 'FORM',
  CONFIRM = 'CONFIRM',
  FEEDBACK = 'FEEDBACK',
}

export enum PasswordFormStage {
  FORM = 'FORM',
  FEEDBACK = 'FEEDBACK',
}

export interface RequestCodeData {
  contactType: ContactType;
}

export interface RequestFormProps {
  onSubmit: (data: RequestCodeData) => void;
  loading: boolean;
}
