import React from 'react';
import Header from '../../common/Header';
import CredentialsForm from '../components/CredentialsForm';
import CustomText from '../../../../../../../components/CustomText';

const HeaderDescription = () => (
  <CustomText as="p">
    Para editar seu e-mail você receberá um código de validação, digite o código e seu novo e-mail.
  </CustomText>
);

const FormStep = () => (
  <>
    <Header title="Editar e-mail" description={<HeaderDescription />} />
    <CredentialsForm />
  </>
);

export default FormStep;
