import styled from 'styled-components';

export const StyledGrid = styled.div`
  padding: 1px 0;
  display: grid;
  --grid-layout-gap: 16px;
  --grid-column-count: 4;
  --grid-item--min-width: 320px;

  @media (max-width: 520px) {
    --grid-item--min-width: 100%;
  }

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  width: 100%;
  flex-grow: 1;
  grid-gap: var(--grid-layout-gap);
`;
