import React, { useContext, useState } from 'react';
import { BillComposition } from '../../../../../@types/bill';
import Accordion from '../../../../../components/Accordion';
import CustomText from '../../../../../components/CustomText';
import FileActions from '../../../../../components/FileActions';
import Alert from '../../../../../components/Toast/toast';
import { DocumentItemAction, DocumentItemType } from '../../../../../config/download/types';
import useDisclosure from '../../../../../hooks/useDisclosure';
import documentDialogError from '../../../../../layouts/DocumentErrorDialog';
import FinancialService from '../../../../../services/Financial/FinancialService';
import { formatDate } from '../../../../../utils/helpers/date';
import { formatNumberToBRL } from '../../../../../utils/helpers/money';
import InvoiceSendEmailContent from '../../../shared/components/InvoiceSendEmailContent';
import SendEmailAction from '../../../shared/components/SendEmail/SendEmailAction';
import FinancialDetailsContext, { FinancialDetailsContextProps } from '../../context';
import { DetailsInput, FormComposition, FormCompositionHeader } from '../../style';
import { GTMEventName } from '../../../../../@types/analytics';
import { sendGTMEvent } from '../../../../../lib/DataLayer';

interface InvoiceAccordionProps {
  composition: BillComposition;
}

const InvoiceAccordion = ({ composition }: InvoiceAccordionProps) => {
  const { handleOpenInvoiceDocument, handleInvoiceDownload } = useContext(
    FinancialDetailsContext
  ) as FinancialDetailsContextProps;
  const { isOpen: sendEmailIsOpen, close: closeSendEmail, open: openSendEmail } = useDisclosure(false);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);

  const handleSendInvoiceByEmail = async (invoiceId: string, customEmail: string | null) => {
    setSendEmailIsLoading(true);

    try {
      await FinancialService.sendInvoiceByEmail(invoiceId, customEmail);
      Alert.SUCCESS('Nota fiscal enviada');
      sendGTMEvent({ name: GTMEventName.SEND_INVOICE_BY_EMAIL });
    } catch (e) {
      console.error(e);
      documentDialogError(DocumentItemType.INVOICE, DocumentItemAction.SENDING_BY_EMAIL);
    } finally {
      setSendEmailIsLoading(false);
      closeSendEmail();
    }
  };

  return (
    <>
      {composition.hasInvoice && (
        <SendEmailAction
          loading={sendEmailIsLoading}
          visible={sendEmailIsOpen}
          onClose={closeSendEmail}
          onSubmit={({ customEmail }) => {
            if (composition.id) {
              handleSendInvoiceByEmail(composition.id, customEmail || null);
            }
          }}
          title="Enviar nota fiscal por e-mail."
          submitButtonLabel="Enviar nota fiscal"
          selectLabel="Escolha a melhor forma para lhe enviarmos a nota fiscal:"
          description={
            <InvoiceSendEmailContent invoiceNumber={composition.invoiceNumber} emittedAt={composition.emittedAt} />
          }
        />
      )}
      <Accordion.Item>
        {({ onToggleAccordion, isActive }) => (
          <>
            <Accordion.Header active={isActive} onToggleAccordion={onToggleAccordion}>
              <FormCompositionHeader>
                <CustomText>{composition.description}</CustomText>
                {composition.hasInvoice && (
                  <FileActions
                    ignoreDropdown
                    download={{
                      handler: () => handleInvoiceDownload(composition.id),
                    }}
                    email={{
                      handler: openSendEmail,
                    }}
                    print={{
                      handler: () => handleOpenInvoiceDocument(composition.id),
                    }}
                  />
                )}
              </FormCompositionHeader>
            </Accordion.Header>
            <Accordion.Content active={isActive}>
              <FormComposition>
                {composition.hasInvoice && <DetailsInput label="NºNF" readOnly value={composition.invoiceNumber} />}
                <DetailsInput label="Título" readOnly value={composition.invoiceNumber} />
                <DetailsInput label="Valor" readOnly value={formatNumberToBRL(composition.paymentAmount)} />
                <DetailsInput label="Data Emissão" readOnly value={formatDate(new Date(composition.emittedAt))} />
              </FormComposition>
            </Accordion.Content>
          </>
        )}
      </Accordion.Item>
    </>
  );
};

export default InvoiceAccordion;
