import { useEffect, useMemo, useState } from 'react';
import Alert, { AlertTypes } from '../../../components/Toast/toast';
import fetchDownload from '../helpers/fetchDownload';
import {
  AppendToDownloadListFunc,
  AppendToErrorListFunc,
  DocumentItemType,
  DownloadItem,
  DownloadItemStatus,
  ErrorItem,
  HandleDownloadFunc,
  PackageDownloadID,
  RemoveToDownloadListFunc,
  ToDownloadArray,
} from '../types';
import { saveFile, saveZip } from '../helpers/save';
import createCancellablePromisePool from '../helpers/createPromisePool';
import generateDownloadID from '../helpers/generateDownloadID';
import { findAndUpdate, isEmptyArray, isNotEmptyArray } from '../../../utils/helpers/array';
import CanceledDownloadError from '../../../exceptions/CanceledDownload';
import useToggle from '../../../hooks/useToggle';
import { CustomEvents } from '../../../@types/events';
import useSignalRDownloadQueue from './useSignalRDownloadQueue';
import { sendGTMEvent } from '../../../lib/DataLayer';
import { GTMEventName } from '../../../@types/analytics';
import useDisclosure from '../../../hooks/useDisclosure';
import { downloadEventManager, DownloadPayload } from '../index';

const downloadByGroup = (toDownload: ToDownloadArray, files_per_group = 4) =>
  createCancellablePromisePool(toDownload, async ({ id, type }, signal) => fetchDownload(id, type, signal), {
    concurrency: files_per_group,
  });

const useDownloadList = () => {
  const [isOpen, toggle] = useToggle(true);
  const { isOpen: cancelAllModalIsOpen, open: openCancelAllModal, close: handleCloseCancelAllModal } = useDisclosure();
  const { isOpen: errorModalIsOpen, open: openErrorModal, close: closeErrorModal } = useDisclosure();
  const [downloadList, setDownloadList] = useState<Array<DownloadItem>>([]);
  const [errorList, setErrorList] = useState<Map<string, Map<DocumentItemType, ErrorItem[]>>>(new Map());

  const hasErrors = useMemo(
    () => Array.from(errorList).some(([, errorMap]) => Array.from(errorMap.values()).length >= 1),
    [errorList]
  );

  const allDownloadAreFinished = useMemo(
    () => isNotEmptyArray(downloadList) && downloadList.every((item) => item.status !== DownloadItemStatus.IN_PROGRESS),
    [downloadList]
  );

  const appendToErrorList: AppendToErrorListFunc = (id, errorItem, documentType) => {
    setErrorList((map) => {
      if (!id) {
        return map;
      }

      let errors = map.get(id);

      if (!errors) {
        errors = new Map<DocumentItemType, ErrorItem[]>();
        map.set(id, errors);
      }

      let errorItems = errors.get(documentType);

      if (!errorItems) {
        errorItems = [];
        errors.set(documentType, errorItems);
      }

      const hasErrorItem = errorItems.some((nestedError) => nestedError.id === errorItem.id);

      if (!hasErrorItem) {
        errorItems.push(errorItem);
        return new Map(map.set(id, errors));
      }

      return map;
    });
  };

  const clearErrors = () => {
    setErrorList(new Map());
  };

  const clearDownloadList = () => {
    setDownloadList([]);
  };

  const alertSuccess = () => {
    Alert.SUCCESS('Download concluído.');
  };

  const alertError = () => {
    Alert.ERROR('Ocorreu um erro inesperado durante o download.');
  };

  const appendStatus = (id: PackageDownloadID, newStatus: DownloadItemStatus) => {
    setDownloadList(findAndUpdate('id', id, (curr) => ({ ...curr, status: newStatus })));
  };

  const removeToDownloadList: RemoveToDownloadListFunc = (id, newStatus) => {
    if (!newStatus) {
      setDownloadList((prevState) => prevState.filter((item) => id !== item.id));
      return;
    }

    appendStatus(id, newStatus);
    setTimeout(() => {
      setDownloadList((prevState) => prevState.filter((item) => id !== item.id));
    }, 5000);
  };

  const appendToDownloadList: AppendToDownloadListFunc = (toDownload, id, cancel, status) => {
    if (downloadList.findIndex((item) => item.id === id) !== -1) {
      return;
    }

    const downloadItem = {
      id,
      quantity: toDownload.length,
      handleCancel: cancel,
      status,
    };

    setDownloadList((prevState) => [...prevState, downloadItem]);
  };

  const handleCancelDownloadList = () => {
    downloadList.forEach(({ handleCancel, status }) => {
      if (status === DownloadItemStatus.SUCCESS) {
        return;
      }

      handleCancel();
    });
    sendGTMEvent({ name: GTMEventName.DOWNLOAD_CANCEL });
  };

  useEffect(() => {
    if (isEmptyArray(downloadList) && hasErrors) {
      clearErrors();
    }

    if (allDownloadAreFinished && !hasErrors) {
      setTimeout(() => {
        clearDownloadList();
      }, 5000);
    }
  }, [downloadList, errorList]);

  const handleDownload: HandleDownloadFunc = async (toDownload, quantity, downloadId) => {
    if (Array.isArray(toDownload)) {
      const { promise, cancel } = downloadByGroup(toDownload, 2);
      const uniqueId = downloadId || generateDownloadID();

      const handleIndividualCancel = () => {
        cancel();
        removeToDownloadList(uniqueId, DownloadItemStatus.CANCELED);
      };

      const downloadItem: DownloadItem = {
        id: uniqueId,
        quantity,
        handleCancel: handleIndividualCancel,
        status: DownloadItemStatus.IN_PROGRESS,
      };

      if (!downloadId) {
        setDownloadList((prevState) => [...prevState, downloadItem]);
      } else {
        setDownloadList(findAndUpdate('id', downloadId, downloadItem));
      }

      const handleDownloadErrors = (e: Error) => {
        if (e instanceof CanceledDownloadError) return;

        console.error(e);
        appendStatus(uniqueId, DownloadItemStatus.ERROR);
        alertError();
      };

      promise
        .then(saveZip)
        .then(() => {
          appendStatus(uniqueId, DownloadItemStatus.SUCCESS);
        })
        .then(alertSuccess)
        .catch(handleDownloadErrors);

      return;
    }

    fetchDownload(toDownload.id, toDownload.type)
      .then(({ blob, fileName, fileType }) => {
        saveFile(blob, fileName, fileType);
      })
      .then(() => {
        appendStatus(downloadId, DownloadItemStatus.SUCCESS);
        Alert.UPDATE(downloadId, AlertTypes.SUCCESS, 'Download concluído.');
      })
      .catch(() => Alert.UPDATE(downloadId!, AlertTypes.ERROR, 'Ocorreu um erro inesperado durante o download.'));
  };

  const handleOpenCancelAllModal = () => {
    if (downloadList.every(({ status }) => status === DownloadItemStatus.ERROR)) {
      return;
    }

    if (hasErrors && allDownloadAreFinished) {
      clearDownloadList();
      clearErrors();
      return;
    }

    openCancelAllModal();
  };

  const onSubmitCancelAll = () => {
    handleCloseCancelAllModal();
    handleCancelDownloadList();
  };

  const { createSignalRDownloadPackage } = useSignalRDownloadQueue({
    appendToDownloadList,
    handleDownload,
    appendToErrorList,
    appendStatus,
  });

  useEffect(() => {
    const requestSignalRDownload = ({ toDownload }: DownloadPayload) => {
      createSignalRDownloadPackage([...toDownload]);
    };

    downloadEventManager.on(CustomEvents.DOWNLOAD_WITH_SIGNALR, requestSignalRDownload);

    return () => {
      downloadEventManager.removeListener(CustomEvents.DOWNLOAD_WITH_SIGNALR, requestSignalRDownload);
    };
  });

  return {
    isOpen,
    toggle,
    onSubmitCancelAll,
    handleDownload,
    downloadList,
    errorList,
    hasErrors,
    clearErrors,
    clearDownloadList,
    appendToDownloadList,
    handleOpenCancelAllModal,
    handleCloseCancelAllModal,
    cancelAllModalIsOpen,
    errorModalIsOpen,
    closeErrorModal,
    openErrorModal,
  };
};

export default useDownloadList;
