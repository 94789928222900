import * as React from 'react';
import type { SVGProps } from 'react';

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m1.72 15.993 6.3-6.4 6.4 6.4 1.4-1.5-6.4-6.3 6.4-6.4-1.4-1.4-6.4 6.4-6.3-6.4-1.4 1.4 6.3 6.4-6.3 6.3 1.4 1.5Z"
    />
  </svg>
);
export default SvgClose;
