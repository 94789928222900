import styled from 'styled-components';

export const ButtonLink = styled.button`
  color: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;

  cursor: pointer;
  border: none;
  outline: none;
  background: none;

  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
`;
