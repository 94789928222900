import React, { useContext, useMemo } from 'react';
import { Container } from './style';
import AsidePortal from '../../../../../../../layouts/AsidePortal';
import AsideActionsContext from './context';
import { BillAsideStage } from '../../../../../../../@types/bill';
import SelectStep from './components/SelectStep';
import FilterStep from './components/FilterStep';
import SearchNewBillStep from './components/SearchNewBillStep';
import BillContext, { BillContextProps } from '../../context';
import SelectDateStep from './components/SelectDateStep';
import TextIconButton from '../../../../../../../components/TextIconButton';
import { CloseIcon } from '../../../../../../../icons';

const stepMapping = {
  [BillAsideStage.SELECT_ACTION]: SelectStep,
  [BillAsideStage.FILTER_ACTION]: FilterStep,
  [BillAsideStage.SELECT_DATE_ACTION]: SelectDateStep,
  [BillAsideStage.SEARCH_NEW_BILL]: SearchNewBillStep,
} as const;

const AsideActions = () => {
  const {
    dataIsEmpty,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    search,
    clearSearch,
    clearFilterByStatus,
    range,
    clearRangeSelect,
  } = useContext(BillContext) as BillContextProps;

  const openHandler = () => {
    openAsideActions();
  };

  const closeHandler = () => {
    closeAsideActions();
    setAsideStage(BillAsideStage.SELECT_ACTION);
  };

  const clearHandler = () => {
    clearSearch();
    clearFilterByStatus();
  };

  const changeStage = (newStage: BillAsideStage) => {
    setAsideStage(newStage);
  };

  const context = useMemo(
    () => ({
      changeStage,
      closeHandler,
    }),
    []
  );

  const Step = stepMapping[asideStage];

  const showButtons = !dataIsEmpty;

  return (
    <AsideActionsContext.Provider value={context}>
      <AsidePortal visible={asideActionsIsOpen}>
        <Step />
      </AsidePortal>
      {showButtons && (
        <Container>
          <input readOnly placeholder="Buscar" onClick={openHandler} />
          {search && (
            <TextIconButton onClick={clearHandler} label="Busca ativada" icon={<CloseIcon width={12} height={12} />} />
          )}
          {range && (
            <TextIconButton
              onClick={clearRangeSelect}
              label="Configurar data ativado"
              icon={<CloseIcon width={12} height={12} />}
            />
          )}
        </Container>
      )}
    </AsideActionsContext.Provider>
  );
};

export default AsideActions;
