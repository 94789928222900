import styled from 'styled-components';
import theme from '../../../styles/theme';
import { StyledText } from '../../../components/CustomText';
import { boxStyle } from '../../../styles/components';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

export const Main = styled.main`
  ${boxStyle};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex: 1;
  max-height: calc(100vh - 168px);

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    max-height: unset;
    height: unset;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
`;

export const ViewContainer = styled.div`
  overflow: auto;

  padding-right: 12px;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding-right: 0;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const WarningContainer = styled.div<{ obfuscate?: boolean }>`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  & > h1 {
    color: ${({ obfuscate }) => (obfuscate ? theme.color.secondary.grey[3] : theme.color.primary.black)};
    font-weight: 500;
    font-size: 16px;
  }

  div {
    @media (max-width: ${BREAKPOINTS.mobile}px) {
      flex-direction: column;
    }
  }

  button {
    width: 230px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${boxStyle};

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled(StyledText)`
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  color: #262626;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
`;

export const TabTitle = styled(StyledText)`
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  color: ${theme.color.primary.interaction};
  line-height: 40px;
`;
