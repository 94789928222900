import React, { FunctionComponent } from 'react';
import { Placement } from '@floating-ui/core/src/types';
import Floating from '../../index';
import Button from '../../../Button';
import { ButtonProps } from '../../../Button/types';
import { ChevronArrowIcon } from '../../../../icons';

interface ButtonFloatingProps {
  floating: JSX.Element;
  placement?: Placement;
  tooltipBoxStyleClass?: string;
  usePortal?: boolean;
  disabled?: boolean;
  usingSafePolygon?: boolean;
}

type ButtonDropdownProps = ButtonFloatingProps & ButtonProps;

const ReferenceButton = React.forwardRef<
  HTMLButtonElement,
  Pick<ButtonFloatingProps, 'disabled'> & ButtonProps & { open?: boolean }
>(({ children, open, disabled, ...props }, ref) => (
  <Button highlight={open} ref={ref} disabled={disabled} {...props}>
    {children}
    <ChevronArrowIcon className="rotate-90" />
  </Button>
));

const ButtonDropdown: FunctionComponent<ButtonDropdownProps> = ({
  placement,
  floating,
  children,
  tooltipBoxStyleClass,
  usePortal,
  disabled = false,
  usingSafePolygon,
  ...props
}) => (
  <Floating
    usePortal={usePortal}
    placement={placement}
    button={
      <ReferenceButton disabled={disabled} {...props}>
        {children}
      </ReferenceButton>
    }
    customClassname={tooltipBoxStyleClass}
    disabled={disabled}
    usingSafePolygon={usingSafePolygon}
  >
    {floating}
  </Floating>
);

export default ButtonDropdown;
