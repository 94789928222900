import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import { StyledLink } from '../style';
import { ButtonLink } from './style';
import ArrowSlim from '../../../icons/ArrowSlim';

interface InternalLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: 'forward' | 'backward';
  disabled?: boolean;
  onClick: VoidFunction;
  hoverColor?: string;
}

const InternalLink: FunctionComponent<InternalLinkProps> = ({
  text,
  variant = 'backward',
  disabled,
  onClick,
  hoverColor,
  color,
  ...props
}: InternalLinkProps) => (
  <StyledLink
    className="Internal_Link"
    role="link"
    $color={color}
    $hoverColor={hoverColor}
    disabled={disabled}
    onClick={onClick}
  >
    {variant === 'backward' && <ArrowSlim />}
    <ButtonLink {...props}>{text}</ButtonLink>
    {variant === 'forward' && <ArrowSlim className="forward_icon" />}
  </StyledLink>
);

export default InternalLink;
