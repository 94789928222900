import React, { useContext, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import CustomText from '../../../../../../../../../components/CustomText';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import { LinkContract } from '../../../../../../../../../@types/building';
import MobileFindContract from '../../../../../../../shared/components/MobileFindContract';
import AsideHeader from '../../../../../../../../../components/AsideHeader';
import { Container, Content, MobileEmptyResult } from './style';
import { InvoiceAsideStage, InvoiceData } from '../../../../../../../../../@types/invoice';
import InvoiceGrid from '../../../InvoiceGrid';
import useSearchNewInvoice from '../../../../hooks/useSearchNewInvoice';

interface NewContractResultProps {
  data: InvoiceData[];
  onBack: VoidFunction;
}

const NewContractResult = ({ data, onBack }: NewContractResultProps) => (
  <>
    <AsideHeader onBack={onBack} />
    <Container>
      <Content>
        <CustomText as="h1" className="title">
          Resultado
        </CustomText>
        {data.length < 1 && (
          <MobileEmptyResult>
            <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
          </MobileEmptyResult>
        )}
        {data && <InvoiceGrid data={data} mobile />}
      </Content>
    </Container>
  </>
);

const SearchNewInvoiceStep = () => {
  const { onSubmit, formMethods, data, clearData, loading } = useSearchNewInvoice();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  const handleSubmit: SubmitHandler<LinkContract> = (searchData) => {
    onSubmit(searchData);
    setIsSubmitted(true);
  };

  const onBackResult = () => {
    clearData();
    setIsSubmitted(false);
  };

  const showResult = isSubmitted && !loading;

  return (
    <FormProvider {...formMethods}>
      {showResult && <NewContractResult data={data} onBack={onBackResult} />}
      {!showResult && (
        <MobileFindContract
          onBack={() => changeStage(InvoiceAsideStage.SELECT_ACTION)}
          onSubmit={handleSubmit}
          loading={loading}
          content={
            <>
              <CustomText as="h1" className="title">
                Buscar nova nota fiscal
              </CustomText>
              <CustomText as="p" className="disclaimer">
                Para buscar novas notas fiscais, preencha todos os campos: CPF ou CNPJ + Nº Contrato.
              </CustomText>
            </>
          }
        />
      )}
    </FormProvider>
  );
};

export default SearchNewInvoiceStep;
