import React from 'react';
import Card from '../../../../../../../components/Card';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import { BillBadge } from '../BillBadge';
import BillActions from '../BillActions';
import AutoGrid from '../../../../../../../layouts/AutoGrid';
import { WrapperDiv } from '../../../../../../../styles/components';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import { formatDate } from '../../../../../../../utils/helpers/date';
import { formatNumberToBRL } from '../../../../../../../utils/helpers/money';
import { BillMinimalData } from '../../../../../../../@types/bill';
import { GetSelectionPropsFunc } from '../../../../../../../hooks/useDataSelection';
import InvoicesTooltip from '../InvoicesTooltip';
import BillCheckbox from '../BillCheckbox';

type BillGridProps =
  | {
      data: BillMinimalData[];
      getSelectionProps: GetSelectionPropsFunc<BillMinimalData>;
      mobile?: false;
    }
  | {
      data: BillMinimalData[];
      getSelectionProps?: GetSelectionPropsFunc<BillMinimalData>;
      mobile: true;
    };

const BillGrid = ({ data, getSelectionProps, mobile = false }: BillGridProps) => {
  const selectionProps = getSelectionProps?.();
  const { isMobileWidth } = useWindowDimensions();
  const showCheckbox = !isMobileWidth && !mobile;

  return (
    <AutoGrid>
      {data.map((bill) => (
        <Card.Root key={bill.id} className="__financial-card">
          <Card.Row>
            <Card.Cell>
              <Card.Label>CNPJ/CPF</Card.Label>
              <Card.Data>{bill.clientDocument ? maskDocument(bill?.clientDocument) : 'Sem documento'}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <WrapperDiv gap={16}>
                <BillBadge status={bill.status} />
                {showCheckbox && (
                  <BillCheckbox
                    onChange={() => selectionProps!.handleSelect(bill)}
                    checked={selectionProps!.selectedDataHasElement(bill)}
                    disabled={selectionProps!.disableSelectionPerRowWhen?.(bill)}
                    status={bill.status}
                  />
                )}
              </WrapperDiv>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell>
              <Card.Label>Cliente</Card.Label>
              <Card.Data>{bill.clientName || 'Sem nome de cliente'}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Contrato</Card.Label>
              <Card.Data>{bill.contract}</Card.Data>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell>
              <Card.Label>Vencimento</Card.Label>
              <Card.Data>{formatDate(new Date(bill.expiresAt))}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Total a pagar</Card.Label>
              <Card.Data>{formatNumberToBRL(bill.totalAmount)}</Card.Data>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell>
              <Card.Label>NFs</Card.Label>
              <InvoicesTooltip composition={bill.composition} />
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Nº do boleto</Card.Label>
              <Card.Data>{bill.documentNumber || 'Sem Nº Boleto'}</Card.Data>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell style={{ maxWidth: 'none', gap: '8px' }}>
              <Card.Label>Ações</Card.Label>
              <BillActions data={bill} ignoreDropdown />
            </Card.Cell>
          </Card.Row>
        </Card.Root>
      ))}
    </AutoGrid>
  );
};

export default BillGrid;
