import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { BREAKPOINTS } from '../../../../../../../utils/constants/breakpoints';

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
`;

export const BillTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #434343;
  box-shadow: 0 0 14px ${theme.color.secondary.grey[5]};

  @media screen and (max-width: ${BREAKPOINTS.mobile}px) {
    border: none !important;
    box-shadow: unset;
    gap: 24px;

    .title {
      font-weight: 500;
      font-size: 18px;
    }

    .invoice_text {
      font-size: 16px;
    }
  }
`;
