import React, { FunctionComponent } from 'react';
import { Container } from './style';

import ExternalLink from '../../../../../../../../components/Link/ExternalLink';
import { AppleIcon, GoogleIcon } from '../../../../../../../../icons';

const mapStore = {
  ios: {
    Icon: AppleIcon,
    title: 'App Store',
  },
  android: {
    Icon: GoogleIcon,
    title: 'Google Play',
  },
} as const;

interface MobileStoreButtonProps {
  store: 'ios' | 'android';
  url: string;
}

const MobileStoreButton: FunctionComponent<MobileStoreButtonProps> = ({ url, store }: MobileStoreButtonProps) => {
  const { Icon, title } = mapStore[store];

  return (
    <Container>
      <Icon />
      <ExternalLink color="#FFF" text={title} href={url} target="_blank" rel="noopener noreferrer" />
    </Container>
  );
};

export default MobileStoreButton;
