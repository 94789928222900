import React, { FunctionComponent } from 'react';
import { Placement } from '@floating-ui/core/src/types';
import { Container, FloatingButton } from './style';

import Floating from '../../index';

export interface UnstyledButtonWithFloatingProps {
  content: JSX.Element;
  floating: JSX.Element;
  placement?: Placement;
  tooltipBoxStyleClass?: string;
  usePortal?: boolean;
  disabled?: boolean;
  usingSafePolygon?: boolean;
}

const ReferenceButton = React.forwardRef<
  HTMLButtonElement,
  Pick<UnstyledButtonWithFloatingProps, 'content' | 'disabled'> & { open?: boolean }
>(({ content, open, disabled, ...props }, ref) => (
  <FloatingButton
    className={open ? 'tooltip_button tooltip_button_open' : 'tooltip_button'}
    ref={ref}
    disabled={disabled}
    {...props}
  >
    {content}
  </FloatingButton>
));

const UnstyledButtonWithFloating: FunctionComponent<UnstyledButtonWithFloatingProps> = ({
  placement,
  floating,
  content,
  tooltipBoxStyleClass,
  usePortal,
  disabled = false,
  usingSafePolygon,
}) => (
  <Container className="tooltip_container">
    <Floating
      usePortal={usePortal}
      placement={placement}
      button={<ReferenceButton content={content} disabled={disabled} />}
      customClassname={tooltipBoxStyleClass}
      disabled={disabled}
      usingSafePolygon={usingSafePolygon}
    >
      {floating}
    </Floating>
  </Container>
);

export default UnstyledButtonWithFloating;
