import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOpenEye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} viewBox="0 0 20 21" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M13.75 10.857A3.753 3.753 0 0 0 10 7.107a3.753 3.753 0 0 0-3.75 3.75 3.753 3.753 0 0 0 3.75 3.75 3.753 3.753 0 0 0 3.75-3.75Zm-6.875 0A3.13 3.13 0 0 1 10 7.732a3.13 3.13 0 0 1 3.125 3.125A3.13 3.13 0 0 1 10 13.982a3.13 3.13 0 0 1-3.125-3.125Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M11.563 10.857a1.563 1.563 0 1 0-3.127.002 1.563 1.563 0 0 0 3.127-.002Zm-2.5 0A.94.94 0 0 1 10 9.92a.94.94 0 0 1 .938.937.94.94 0 0 1-.938.938.94.94 0 0 1-.938-.938Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M18.703 10.692C16.793 7.664 13.54 5.857 10 5.857c-3.541 0-6.794 1.807-8.704 4.835l-.103.165h.741C3.74 8.114 6.74 6.482 10 6.482c3.259 0 6.259 1.632 8.065 4.375-1.806 2.744-4.806 4.375-8.065 4.375a9.57 9.57 0 0 1-5.72-1.89l-.35.522A10.196 10.196 0 0 0 10 15.857c3.54 0 6.793-1.806 8.703-4.834l.103-.166-.103-.165Z"
    />
  </svg>
);
export default SvgOpenEye;
