import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

export const Container = styled.div<{ fit?: boolean }>`
  box-sizing: border-box;
  display: flex;
  padding: 24px;
  background: rgba(249, 202, 41, 0.1);
  border: 1px solid ${theme.color.secondary.orange[3]};
  width: fit-content;
  ${({ fit }) =>
    fit &&
    css`
      width: 100%;
    `};
`;
