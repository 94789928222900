import { InvoiceData, InvoiceOrder } from '../../@types/invoice';

class InvoiceHelper {
  orderInvoice(dataToTransform: InvoiceData[], order: InvoiceOrder | null) {
    const sortableItems = [...dataToTransform];
    if (!order) return sortableItems;

    const isAscendingOrder = order.direction === 'asc';

    return sortableItems.sort((a, b) => {
      if (a[order.orderBy] < b[order.orderBy]) {
        return isAscendingOrder ? -1 : 1;
      }
      if (a[order.orderBy] > b[order.orderBy]) {
        return isAscendingOrder ? 1 : -1;
      }

      return 0;
    });
  }
}

export default new InvoiceHelper();
