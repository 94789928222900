import React, { ReactNode } from 'react';
import AsideHeader from '../../components/AsideHeader';
import { WrapperDiv } from '../../styles/components';
import NavButton from '../Topbar/components/NavButton';
import { ChevronArrowIcon } from '../../icons';

interface AsideTabAction<T extends string> {
  accessor: T;
  label: ReactNode;
  disabled?: boolean;
}

interface ActionSelectProps<T extends string> {
  onClose: VoidFunction;
  onChange: (accessor: T) => void;
  actions: Array<AsideTabAction<T>>;
}

const AsideTabs = <T extends string>({ actions, onChange, onClose }: ActionSelectProps<T>) => (
  <>
    <AsideHeader onBack={onClose} />
    <WrapperDiv flexDirection="column">
      {actions.map((action, index) => (
        <NavButton
          key={`${action.label}-${action.accessor}-${index}`}
          onClick={() => onChange(action.accessor)}
          disabled={action.disabled}
        >
          <WrapperDiv>
            {action.label}
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
      ))}
    </WrapperDiv>
  </>
);

export default AsideTabs;
