import { useState } from 'react';
import { FileDataType } from '../@types/file';

export interface UseSelectDataTypeProps {
  selectedDataType: FileDataType;
  onChangeSelectedDataType: (newSelectedDataType: FileDataType) => void;
}

const useSelectDataType = () => {
  const [selectedDataType, setSelectedDataType] = useState<FileDataType>(FileDataType.CSV);

  const onChangeSelectedDataType = (newSelectedDataType: FileDataType) => {
    setSelectedDataType(newSelectedDataType);
  };

  return {
    selectedDataType,
    onChangeSelectedDataType,
  } as UseSelectDataTypeProps;
};

export default useSelectDataType;
