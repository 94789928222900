import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingContainer from '../../layouts/LoadingContainer';
import { SignalRHubName } from '../../config/signalR/types';
import useHandleOpenDocument from '../../hooks/useHandleOpenDocument';
import { Container } from './style';
import { DocumentItemType } from '../../config/download/types';

interface DocumentRedirectProps {
  title: string;
  hubName: SignalRHubName;
  documentType: DocumentItemType;
}

const DocumentRedirect = ({ title, hubName, documentType }: DocumentRedirectProps) => {
  const { id } = useParams();
  const { openDocument } = useHandleOpenDocument(hubName, documentType);

  useEffect(() => {
    if (id) {
      openDocument(id);
    }
  }, [id]);

  return (
    <Container>
      <LoadingContainer title={title} />
    </Container>
  );
};

export default DocumentRedirect;
