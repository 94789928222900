import React from 'react';
import { SkeletonBox } from '../../style';
import { WrapperDiv } from '../../../../styles/components';

const FormHeaderSkeleton = () => (
  <>
    <SkeletonBox width={102} height={20} mb={62} />
    <SkeletonBox width={250} height={45} mb={32} />
    <WrapperDiv alignItems="left" flexDirection="column" gap={8}>
      <SkeletonBox width={400} height={45} />
      <SkeletonBox width={215} height={30} />
    </WrapperDiv>
  </>
);

export default FormHeaderSkeleton;
