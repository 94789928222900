import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { boxStyle as __boxStyle } from '../../styles/components';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

interface PageLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: Children;
  boxStyle?: boolean;
}

export const OverlayBox = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  background: #f6f6f6;
  box-sizing: border-box;
  min-height: calc(100vh - 56px);

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding: 0;
  }
`;

export const Container = styled.div<{ boxStyle?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  min-height: calc(100vh - 88px);
  width: 100%;
  box-sizing: border-box;

  ${({ boxStyle }) => boxStyle && __boxStyle};

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    gap: 0;
  }
`;

const PageLayout: FunctionComponent<PageLayoutProps> = ({ children, boxStyle, ...props }) => (
  <OverlayBox>
    <Container boxStyle={boxStyle} {...props}>
      {children}
    </Container>
  </OverlayBox>
);

export default PageLayout;
