import { createContext } from 'react';
import { FinancialHistoricOrder, FinancialHistoricSearchBy } from '../../../../../@types/bill';
import { Layout } from '../../../../../hooks/useLayout';

export interface FinancialHistoricContextProps {
  toggleLayout: VoidFunction;
  clearSearch: VoidFunction;
  actionsIsDisabled: boolean;
  requestSearch: (newSearch: string, newSearchKeys: Array<FinancialHistoricSearchBy>) => void;
  search: string;
  loading: boolean;
  layout: Layout;
  showGrid: boolean;
  order: FinancialHistoricOrder | null;
  handleOrder: (newOrder: FinancialHistoricOrder | null) => void;
  handleSearch: (newSearch: string) => void;
  searchKeys: Array<FinancialHistoricSearchBy>;
  handleSearchKeys: (newSearchKeys: Array<FinancialHistoricSearchBy>) => void;
}

const FinancialHistoricContext = createContext<FinancialHistoricContextProps | null>(null);

export default FinancialHistoricContext;
