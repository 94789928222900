import { REGEX_EMAIL_PATTERN } from '../constants/regex';

const invalidCpfFormats = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

const unmaskValue = (value: string) => value.replace(/\D/g, '');

const validateCpf = (value: string) => {
  const unmaskedCpf = unmaskValue(value);
  if (unmaskedCpf.length !== 11 || invalidCpfFormats.includes(unmaskedCpf)) return false;
  let sum = 0;
  let rev;
  for (let i = 1; i <= 9; i++) sum += parseInt(unmaskedCpf.substring(i - 1, i), 10) * (11 - i);
  rev = (sum * 10) % 11;
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(unmaskedCpf.substring(9, 10), 10)) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++) sum += parseInt(unmaskedCpf.substring(i - 1, i), 10) * (12 - i);
  rev = (sum * 10) % 11;
  if (rev === 10 || rev === 11) rev = 0;
  return rev === parseInt(unmaskedCpf.substring(10, 11), 10);
};

const isCPFOrCNPJ = (maskedValue: string) => {
  const unmaskedValue = unmaskValue(maskedValue);

  return unmaskedValue.length === 11 || unmaskedValue.length === 14;
};

const emailRegex = new RegExp(REGEX_EMAIL_PATTERN);
const isValidEmail = (email: string) => Boolean(email) && emailRegex.test(email);

export { validateCpf, isValidEmail, isCPFOrCNPJ, unmaskValue };
