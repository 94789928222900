import React, { HTMLAttributes } from 'react';
import { Container } from './style';

interface CalloutProps extends HTMLAttributes<HTMLDivElement> {
  children: Children;
  fit?: boolean;
}

const Callout = ({ children, ...props }: CalloutProps) => <Container {...props}>{children}</Container>;

export default Callout;
