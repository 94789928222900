import React, { FunctionComponent, useContext, useState } from 'react';
import axios from 'axios';
import UnstyledButtonWithTooltip from '../../../../../../../components/Floating/components/UnstyledButtonWithFloating';
import { WrapperDiv, WrapperButton } from '../../../../../../../styles/components';
import CustomText from '../../../../../../../components/CustomText';
import { BuildingData } from '../../../../../../../@types/building';
import Alert from '../../../../../../../components/Toast/toast';
import useAbortController from '../../../../../../../hooks/useAbortController';
import UnlinkModal from './components/UnlinkModal';
import BuildingContext, { BuildingContextProps } from '../../context';
import { DocumentIcon } from '../../../../../shared/style';
import { StarIcon, TrashIcon } from '../../../../../../../icons';
import BuildingPolicy from '../../../../../../../strategy/rules/BuildingPolicy';
import theme from '../../../../../../../styles/theme';
import useDebounce from '../../../../../../../hooks/useDebounce';

interface IconActionsProps {
  data: BuildingData;
}

const BuildingActions: FunctionComponent<IconActionsProps> = ({ data }) => {
  const { unlinkNewContract, favoriteBuilding } = useContext(BuildingContext) as BuildingContextProps;
  const internallyLinked = BuildingPolicy.contractsAreLinkedInternally(data);
  const contractsLinked = internallyLinked ? true : BuildingPolicy.contractsAreLinked(data);
  const isVisibleTrashIcon = internallyLinked;
  const { abort, getSignal } = useAbortController();
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [loadingUnlink, setLoadingUnlink] = useState(false);

  const handleToggleFavorite = async () => {
    if (!contractsLinked) return;
    try {
      abort();
      const signal = getSignal();
      favoriteBuilding(data, signal);
    } catch (e) {
      if (axios.isCancel(e)) {
        console.error('Request canceled');
        return;
      }

      console.error(e);
    }
  };

  const debounceHandleFavorite = useDebounce(handleToggleFavorite, 300);

  const handleUnlinkContract = async () => {
    setLoadingUnlink(true);

    try {
      await unlinkNewContract(data.contracts.map((contract) => contract.id));
      Alert.SUCCESS('Contrato foi desafixado do seu painel!');
      setShowUnlinkModal(true);
    } catch (e) {
      setShowUnlinkModal(false);
      Alert.ERROR('Não foi possível desafixar este contrato. Tente novamente mais tarde.');
      console.error(e);
    } finally {
      setLoadingUnlink(false);
    }
  };

  return (
    <>
      <UnlinkModal
        visible={showUnlinkModal}
        onClose={() => setShowUnlinkModal(false)}
        onSubmit={handleUnlinkContract}
        buildingContract={data.contracts[0].contractCode}
        loading={loadingUnlink}
      />
      <WrapperButton onClick={debounceHandleFavorite} disabled={!contractsLinked} cursor="pointer">
        <StarIcon color={data.favorite ? theme.color.secondary.orange[2] : theme.color.secondary.grey[4]} />
      </WrapperButton>
      {contractsLinked ? (
        <UnstyledButtonWithTooltip
          floating={
            <WrapperDiv padding={16}>
              {internallyLinked ? (
                <CustomText>Contrato adicionado manualmente no TKE Digital.</CustomText>
              ) : (
                <CustomText>Contrato vinculado ao usuário por via contratual pelo representante legal.</CustomText>
              )}
            </WrapperDiv>
          }
          content={<DocumentIcon link={internallyLinked ? 'unlinked' : 'linked'} />}
        />
      ) : (
        <DocumentIcon link={internallyLinked ? 'unlinked' : 'linked'} $disabled={!contractsLinked} />
      )}
      {isVisibleTrashIcon && (
        <WrapperButton onClick={() => setShowUnlinkModal(true)} cursor="pointer">
          <TrashIcon />
        </WrapperButton>
      )}
    </>
  );
};

export default BuildingActions;
