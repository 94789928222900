import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SignalRSignInMethod } from '../types';

const createSignalRConnection = async (authMethod: SignalRSignInMethod) => {
  const authData = await authMethod();

  return new HubConnectionBuilder()
    .withUrl(authData.serviceUrl, {
      accessTokenFactory: () => authData.accessToken,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Debug)
    .build();
};

export default createSignalRConnection;
