import React, { ButtonHTMLAttributes, SyntheticEvent } from 'react';

import { AddonButton, Container, StyledFileActionButton } from './style';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import CustomText from '../CustomText';
import UnstyledButtonWithFloating from '../Floating/components/UnstyledButtonWithFloating';
import { DownloadIcon, EmailIcon, MoreIcon, OpenEyeIcon, PrinterIcon } from '../../icons';
import Tooltip from '../Floating/components/Tooltip';
import ButtonStack from '../ButtonStack';

interface ButtonWithTooltipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  info: string;
  children: Children;
  handler: VoidFunction;
}

interface ModularFileButton {
  handler: VoidFunction;
  label?: string;
  disabled?: boolean;
  className?: string;
}

const stopPropagationEvent = <T extends SyntheticEvent>(event: T, cb: () => void | undefined) => {
  event.stopPropagation();
  cb();
};

const FileActionButton = ({ info, children, handler, ...props }: ButtonWithTooltipProps) => (
  <Tooltip info={info} placement="bottom-end">
    <StyledFileActionButton onClick={(event) => stopPropagationEvent(event, handler)} {...props}>
      {children}
    </StyledFileActionButton>
  </Tooltip>
);

interface FileActionsProps {
  ignoreDropdown?: boolean;
  download?: ModularFileButton;
  print?: ModularFileButton;
  email?: ModularFileButton;
  viewButton?: ModularFileButton;
  dropdownBreakpoint?: number;
}

const FileActions = ({
  ignoreDropdown = false,
  dropdownBreakpoint = 1440,
  download,
  print,
  email,
  viewButton,
}: FileActionsProps) => {
  const { width } = useWindowDimensions();
  const isDropdownActions = width <= dropdownBreakpoint && !ignoreDropdown;

  const stack = [
    {
      ...download,
      render: (
        <>
          <DownloadIcon />
          <CustomText>{download?.label || 'Download'}</CustomText>
        </>
      ),
    },
    {
      ...email,
      render: (
        <>
          <EmailIcon />
          <CustomText>{email?.label || 'Enviar por e-mail'}</CustomText>
        </>
      ),
    },
    {
      ...print,
      render: (
        <>
          <PrinterIcon />
          <CustomText>{print?.label || 'Imprimir'}</CustomText>
        </>
      ),
    },
    {
      ...viewButton,
      render: (
        <>
          <OpenEyeIcon />
          <CustomText>{viewButton?.label || 'Ver mais'}</CustomText>
        </>
      ),
    },
  ].filter((i) => !!i) as ModularFileButton[];

  return isDropdownActions ? (
    <Container>
      <UnstyledButtonWithFloating
        placement="bottom-end"
        content={<MoreIcon />}
        floating={<ButtonStack stack={stack} />}
      />
    </Container>
  ) : (
    <Container>
      {download && (
        <FileActionButton handler={download.handler} info={download.label || 'Download'} disabled={download.disabled}>
          <DownloadIcon />
        </FileActionButton>
      )}
      {email && (
        <FileActionButton handler={email.handler} info={email.label || 'Enviar por e-mail'} disabled={email.disabled}>
          <EmailIcon />
        </FileActionButton>
      )}
      {print && (
        <FileActionButton handler={print.handler} info={print.label || 'Imprimir'} disabled={print.disabled}>
          <PrinterIcon />
        </FileActionButton>
      )}
      {viewButton && (
        <AddonButton
          className={viewButton.className}
          onClick={(event) => stopPropagationEvent(event, viewButton.handler)}
          disabled={viewButton?.disabled}
        >
          <CustomText>{viewButton.label || 'Ver mais'}</CustomText>
        </AddonButton>
      )}
    </Container>
  );
};

export default FileActions;
