import React, { PropsWithChildren } from 'react';
import { OrderArrow, OrderContainer } from '../../styles';

export const DisabledOrderWrapper = ({ children, align = 'end' }: PropsWithChildren<{ align?: 'end' | 'initial' }>) => (
  <OrderContainer $active={false}>
    <div>{children}</div>
    <OrderArrow $isAscending={false} $align={align} />
  </OrderContainer>
);

interface HeaderOrderWrapperInterface<T extends string> {
  children: Children;
  orderBy: T;
  currentOrder: {
    orderBy: T;
    direction: 'asc' | 'desc';
  } | null;
  requestSort: (key: T) => void;
  align?: 'end' | 'initial';
}

const HeaderOrderWrapper = <T extends string>({
  children,
  orderBy,
  currentOrder,
  requestSort,
  align = 'end',
}: HeaderOrderWrapperInterface<T>) => {
  const isActive = currentOrder?.orderBy === orderBy;
  const isAscending = isActive && currentOrder?.direction === 'asc';

  return (
    <OrderContainer onClick={() => requestSort(orderBy)} $active={isActive}>
      <div>{children}</div>
      <OrderArrow $isAscending={isAscending} $align={align} />
    </OrderContainer>
  );
};

export default HeaderOrderWrapper;
