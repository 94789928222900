import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { GTMEventName } from '../../../../../../@types/analytics';
import { BillMinimalData, BillOrder, BillOrderBy } from '../../../../../../@types/bill';
import { LinkContract } from '../../../../../../@types/building';
import useHub from '../../../../../../config/signalR/hooks/useHub';
import { RequestID, SignalRHubName } from '../../../../../../config/signalR/types';
import useTimedLoading from '../../../../../../hooks/useTimedLoading';
import { sendGTMEvent } from '../../../../../../lib/DataLayer';
import BillHelper from '../../../../../../strategy/helpers/BillHelper';
import getOrderDirection from '../../../../../../utils/helpers/getOrderDirection';
import { unmaskValue } from '../../../../../../utils/helpers/validate';
import { WAIT_TIME_SIGNALR_LOADING } from '../../../constants';

const useSearchNewBill = () => {
  const { singleSend: sendBillMessage, registerListener } = useHub(SignalRHubName.BILL);

  const [loading, setLoading] = useTimedLoading(WAIT_TIME_SIGNALR_LOADING, false);
  const [order, setOrder] = useState<BillOrder | null>(null);
  const [data, setData] = useState<BillMinimalData[]>([]);
  const [requestId, setRequestId] = useState<RequestID | null>(null);

  const formMethods = useForm<LinkContract>({
    mode: 'onChange',
    defaultValues: {
      document: '',
      contract: '',
    },
  });

  useEffect(() => {
    registerListener('ReceiveBillByClient', (item) => {
      if (requestId !== item.requestId) return;

      setData((prevData) => [...prevData, item]);
      setLoading(false);
    });
  }, [requestId]);

  const onSubmit: SubmitHandler<LinkContract> = (searchData) => {
    setLoading(true);

    sendGTMEvent({ name: GTMEventName.SEARCH_NEW_BILL });

    sendBillMessage([
      'RequestBillsByClient',
      {
        document: unmaskValue(searchData.document),
        contract: unmaskValue(searchData.contract),
      },
    ])
      .then((newRequestId) => {
        setData([]);
        setRequestId(newRequestId);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const requestSort = useCallback(
    (key: BillOrderBy) =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    []
  );

  const applyOrder = (dataToTransform: BillMinimalData[]) => BillHelper.orderMinimalBillData(dataToTransform, order);

  const filteredData = useMemo(() => applyOrder(data), [data, order]);

  const clearData = useCallback(() => setData([]), []);

  const alreadySearched = Boolean(requestId);

  return {
    data: filteredData,
    requestSort,
    order,
    formMethods,
    onSubmit,
    loading,
    clearData,
    alreadySearched,
  };
};

export default useSearchNewBill;
