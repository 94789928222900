import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none" viewBox="0 0 15 17" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M5.636 1.98H3.223a2.14 2.14 0 0 0-2.139 2.139V5.12h1.137v7.386h.868V5.121h8.79v10.495H9.624v-9.09h-.868v9.09H6.214v-9.09h-.868v9.09H2.22v.868H12.748V5.121h1.136V4.119a2.14 2.14 0 0 0-2.138-2.14H9.333A1.856 1.856 0 0 0 7.484.276c-.972 0-1.772.752-1.848 1.705Zm1.848-.836c.494 0 .903.362.975.836H6.51a.986.986 0 0 1 .975-.836ZM1.953 4.253v-.134c0-.702.569-1.27 1.27-1.27h8.523c.701 0 1.27.568 1.27 1.27v.134z"
    />
  </svg>
);
export default SvgTrash;
