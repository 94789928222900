import React, { FunctionComponent, useContext, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Container } from './style';
import Background from './components/Background';
import Form from './components/Form';
import LoginContext from './context';
import { GeneralError, LoginPayload } from './type';
import {
  ConfirmationCode,
  ConfirmationToken,
  ContactType,
  LoginFormStage,
  MaskedUserData,
} from '../../../../@types/auth';
import { CustomErrorType, errorHandler } from '../../../../exceptions/errorHandler';
import Alert from '../../../../components/Toast/toast';
import AuthService from '../../../../services/Auth/AuthService';
import { LoginStateContext, LoginStateProps } from '../../../../context/LoginStateContext';
import useAbortController from '../../../../hooks/useAbortController';
import { EXCEPTIONS_MESSAGE } from '../../../../utils/constants/exceptions';
import FullScreenPageLayout from '../../../../layouts/FullScreenPageLayout';

type LoginForm = LoginPayload & { confirmationCode: ConfirmationCode | null };

const Login: FunctionComponent = () => {
  const { loginStage: stage, setLoginStage: setStage } = useContext(LoginStateContext) as LoginStateProps;
  const [generalError, setGeneralError] = useState<GeneralError | null>(null);
  const [selectedContactType, setSelectedContactType] = useState<ContactType | null>(null);
  const [credentialsInvalid, setCredentialsInvalid] = useState<boolean>(false);
  const [user, setUser] = useState<MaskedUserData | null>(null);
  const [confirmationToken, setConfirmationToken] = useState<ConfirmationToken | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const wrongSubmit = useRef<number>(0);
  const { abort, getSignal } = useAbortController();

  const methods = useForm<LoginForm>({
    defaultValues: {
      login: '',
      password: '',
      confirmationCode: null,
    },
  });

  const handleContactTypeChange = (contactType: ContactType) => {
    setSelectedContactType(contactType);
  };

  const resetForm = () => {
    methods.reset();
    methods.clearErrors();
    wrongSubmit.current = 0;
    setStage(LoginFormStage.USER_TYPE_SELECT);
    setCredentialsInvalid(false);
  };

  const requestCode = async (callback?: VoidFunction) => {
    if (!confirmationToken || !selectedContactType) return;
    try {
      abort();
      const signal = getSignal();
      await AuthService.requestAuthCodeService(confirmationToken, selectedContactType, signal);
      setStage(LoginFormStage.SUBMIT_CODE);
      callback?.();
    } catch (e) {
      const handledError = errorHandler(e);

      if (handledError.type === CustomErrorType.AXIOS_ERROR) {
        const { status } = handledError;

        if (status === 401 || status === 403) {
          Alert.WARNING(EXCEPTIONS_MESSAGE.LOGIN_EXPIRED);
          resetForm();
          return;
        }

        if (status === 500) {
          Alert.ERROR(EXCEPTIONS_MESSAGE.HTTP_500);
          return;
        }

        Alert.ERROR('Ocorreu um erro ao solicitar seu código, tente novamente!');
      }

      console.error(e);
    }
  };

  const context = useMemo(
    () => ({
      stage,
      setStage,
      confirmationToken,
      setConfirmationToken,
      captchaToken,
      setCaptchaToken,
      user,
      setUser,
      wrongSubmit,
      handleContactTypeChange,
      requestCode,
      resetForm,
      credentialsInvalid,
      setCredentialsInvalid,
      generalError,
      setGeneralError,
    }),
    [stage, confirmationToken, captchaToken, wrongSubmit, selectedContactType, generalError]
  );

  return (
    <FormProvider {...methods}>
      <LoginContext.Provider value={context}>
        {generalError ? (
          <FullScreenPageLayout title={generalError.title} description={generalError.description} />
        ) : (
          <Container>
            <Background />
            <Form stage={stage} />
          </Container>
        )}
      </LoginContext.Provider>
    </FormProvider>
  );
};

export default Login;
