import React from 'react';
import {
  AmountInfo,
  ButtonArrowWrapper,
  Container,
  NumberButton,
  PageContainer,
  PageList,
  PaginationInfo,
  StyledDots,
} from './style';
import TextSelect from '../TextSelect';
import { ChevronArrowIcon } from '../../icons';

interface PaginationProps {
  initialLimit: number;
  limit: number;
  offset: number;
  amount: number;
  setOffset: SetState<number>;
  setLimit: SetState<number>;
}

interface LimitOption {
  value: number;
  label: string;
}

const Dots = () => <StyledDots>...</StyledDots>;

const Pagination = ({ limit, amount, initialLimit, setLimit, offset, setOffset }: PaginationProps) => {
  const pageCount = Math.ceil(amount / limit);
  const pageArray = [-1, 0, 1].map((idx) => offset + idx).filter((page) => page > 0 && page <= pageCount);

  const limitOptions = [initialLimit, 15, 30, 40, 50].map((value) => ({
    value,
    label: String(value),
  }));

  const changeLimit = (option: LimitOption | null) => {
    setLimit(option?.value || initialLimit);
    setOffset(1);
  };

  const isVisible = amount > 0 && pageCount > 1;

  return (
    <Container>
      <PageContainer>
        {isVisible && (
          <>
            <ButtonArrowWrapper disabled={offset === 1} onClick={() => setOffset((prevOffset) => prevOffset - 1)}>
              <ChevronArrowIcon className="rotate-180" />
            </ButtonArrowWrapper>
            <PageList>
              {!pageArray.includes(1) && (
                <>
                  <NumberButton
                    isActive={offset === 1}
                    onClick={() => {
                      setOffset(1);
                    }}
                  >
                    1
                  </NumberButton>
                  <Dots />
                </>
              )}
              {pageArray.map((page) => (
                <NumberButton
                  key={page}
                  isActive={page === offset}
                  onClick={() => {
                    setOffset(page);
                  }}
                >
                  {page}
                </NumberButton>
              ))}
              {!pageArray.includes(pageCount) && (
                <>
                  <Dots />
                  <NumberButton
                    isActive={offset === pageCount}
                    onClick={() => {
                      setOffset(pageCount);
                    }}
                  >
                    {pageCount}
                  </NumberButton>
                </>
              )}
            </PageList>
            <ButtonArrowWrapper
              disabled={offset === pageCount}
              onClick={() => setOffset((prevOffset) => prevOffset + 1)}
            >
              <ChevronArrowIcon />
            </ButtonArrowWrapper>
          </>
        )}
      </PageContainer>
      <PaginationInfo>
        <TextSelect
          menuPlacement="auto"
          options={limitOptions}
          value={limitOptions.find((v) => v.value === limit) || limitOptions[0]}
          onChange={changeLimit}
        />
        <AmountInfo>
          Itens visualizados / 1 - {limit} de {amount === 1 ? `1 item` : `${amount} itens`}
        </AmountInfo>
      </PaginationInfo>
    </Container>
  );
};

export default Pagination;
