export enum TabAccessor {
  BUILDING = 'buildings',
  FINANCIAL = 'payments',
  INVOICE = 'invoices',
}

export interface Tab<T extends string> {
  label: string;
  accessor: T;
}
