import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import { ChevronArrowIcon } from '../../icons';

export const Table = styled.div`
  .dataheader {
    border-bottom: 1px solid ${theme.color.secondary.grey[3]};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 54px;
  }

  .datacell {
    border-bottom: ${theme.color.secondary.grey[5]} 1px solid;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 54px;
  }
`;

export const HoverDiv = styled.div`
  display: contents;
  width: 100%;

  &:hover > .datacell {
    background: #ffe6ce;
  }
`;

export const ListHead = styled.div.attrs({
  className: 'dataheader',
})<{ disabled?: boolean }>`
  background-color: #fff;
  z-index: ${theme.defaults.tableHeaderZIndex};
  position: sticky;
  top: 0;
  white-space: nowrap;

  ${({ disabled }) =>
    disabled &&
    css`
      ${theme.defaults.disabled}
      p,
    path {
        color: ${theme.color.secondary.grey[3]};
        stroke: ${theme.color.secondary.grey[3]};
        fill: ${theme.color.secondary.grey[3]};
      }
    `}
`;

const ALIGN_INITIAL_ARROW_CSS = css`
  position: absolute;
  left: calc(100% + 6px);
`;

const ALIGN_END_ARROW_CSS = css`
  position: absolute;
  right: 0;
`;

export const OrderArrow = styled(ChevronArrowIcon)<{ $isAscending: boolean; $align: 'initial' | 'end' }>`
  ${({ $align }) => ($align === 'initial' ? ALIGN_INITIAL_ARROW_CSS : ALIGN_END_ARROW_CSS)}
  ${({ $isAscending }) =>
    $isAscending
      ? css`
          transform: rotate(-90deg);
        `
      : css`
          transform: rotate(90deg);
        `}
`;

export const OrderContainer = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100%;

  ${({ $active }) =>
    $active &&
    css`
      p {
        color: ${theme.color.primary.interaction};
      }

      svg > path {
        fill: ${theme.color.primary.interaction};
        stroke: ${theme.color.primary.interaction};
      }
    `}
`;

export const ListData = styled.div.attrs({
  className: 'datacell',
})`
  box-sizing: border-box;
  display: flex;

  p {
    white-space: nowrap;
  }
`;
