import FileSaver from 'file-saver';
import JsZip from 'jszip';
import { FileContent, FileType } from '../types';
import { FileMimeType } from '../../../utils/constants/mime';

const mountFallbackFileName = (fileType: FileType) => {
  const currentDate = new Date().getTime();
  return `tkedigital-download-${currentDate}.${FileMimeType[fileType]}`;
};

const saveFile = (data: Blob | string, filename: string | null, fileType: FileType) => {
  FileSaver.saveAs(data, filename ?? mountFallbackFileName(fileType));
};

const saveZip = (files: FileContent[]) => {
  const zip = JsZip();
  files.forEach(({ blob, fileName, fileType }) => {
    zip.file(fileName ?? mountFallbackFileName(fileType), blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const currentDate = new Date().getTime();
    const fileName = `tkedigital-download-${currentDate}.zip`;
    saveFile(zipFile, fileName, 'application/zip');
  });
};

export { saveZip, saveFile };
