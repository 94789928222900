import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import ModalPortal from '../../../../../../../../../layouts/ModalPortal';
import { Container, ModalWarning, ResultContainer } from './style';
import CustomText from '../../../../../../../../../components/CustomText';
import FindContractForm from '../../../../../../../shared/components/FindContractForm';
import FindContractHeader from '../../../../../../../shared/components/FindContractHeader';
import BillTable from '../../../BillTable';
import useSearchNewBill from '../../../../hooks/useSearchNewBill';
import BillContext, { BillContextProps } from '../../../../context';

const ModalContainer = () => {
  const { closeSearchModal } = useContext(BillContext) as BillContextProps;
  const { formMethods, onSubmit, loading, data, requestSort, order, alreadySearched } = useSearchNewBill();

  return (
    <Container>
      <FindContractHeader
        title="Buscar boletos"
        onClose={closeSearchModal}
        content={
          <CustomText as="p">
            Para buscar novos boletos, preencha todos os campos: CPF ou CNPJ + Nº Contrato.
          </CustomText>
        }
      />
      <FormProvider {...formMethods}>
        <FindContractForm onSubmit={onSubmit} loading={loading} />
      </FormProvider>
      <ResultContainer>
        {!loading && !data.length && !alreadySearched && (
          <ModalWarning obfuscate>
            <CustomText as="h1">Utilize a busca para encontrar um novo boleto.</CustomText>
          </ModalWarning>
        )}
        {loading && (
          <ModalWarning obfuscate>
            <CustomText as="h1">Buscando boletos...</CustomText>
          </ModalWarning>
        )}
        {!loading && !data.length && alreadySearched && (
          <ModalWarning obfuscate>
            <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
          </ModalWarning>
        )}
        {!loading && Boolean(data.length) && (
          <BillTable data={data} requestSort={requestSort} order={order} withSelection={false} />
        )}
      </ResultContainer>
    </Container>
  );
};

const SearchBillModal = () => {
  const { searchModalIsOpen, closeSearchModal } = useContext(BillContext) as BillContextProps;

  return (
    <ModalPortal onClose={closeSearchModal} visible={searchModalIsOpen} fullHeight fullWidth>
      <ModalContainer />
    </ModalPortal>
  );
};

export default SearchBillModal;
