import React from 'react';
import DisabledListHeader from '../../../../../../../components/TableView/components/DisabledTableHeader';
import CustomText from '../../../../../../../components/CustomText';
import { DisabledOrderWrapper } from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useDisabledTableHeader from '../../../../../../../components/TableView/hooks/useDisabledTableHeader';
import { ActionsHead } from '../../style';
import { DocumentIcon, StarIcon } from '../../../../../../../icons';

const DisabledBuildingHeader = () => {
  const props = useDisabledTableHeader([
    {
      width: '140px',
      render: (
        <ActionsHead>
          <DisabledOrderWrapper>
            <StarIcon />
          </DisabledOrderWrapper>
          <DisabledOrderWrapper>
            <DocumentIcon />
          </DisabledOrderWrapper>
        </ActionsHead>
      ),
    },
    {
      width: '140px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Nº Edifício</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '3fr',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Nome</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '190px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>CNPJ ou CPF</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '1fr',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Localização</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '1fr',
      render: <CustomText>Módulos</CustomText>,
    },
  ]);

  return <DisabledListHeader {...props} />;
};

export default DisabledBuildingHeader;
