import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 641px;
  height: fit-content;
  padding: 32px 24px;
  flex-direction: column;
  background: #fff;
  gap: 24px;

  button {
    align-self: flex-end;
    width: fit-content;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h1 {
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
