import { InvoiceOrderBy, InvoiceSearchBy, InvoiceType } from '../../../../../@types/invoice';

export const mapInvoiceTypeLabel = {
  [InvoiceType.MATERIAL]: 'Material',
  [InvoiceType.TASK]: 'Serviço',
};

export const INVOICE_ORDER_OPTIONS: Array<{ label: string; option: InvoiceOrderBy }> = [
  {
    option: 'clientName',
    label: 'Cliente',
  },
  {
    option: 'clientDocument',
    label: 'CNPJ ou CPF',
  },
  {
    option: 'contract',
    label: 'Contrato',
  },
  {
    option: 'invoiceNumber',
    label: 'Nº NF',
  },
  {
    option: 'type',
    label: 'Materiais/Serviços',
  },
  {
    option: 'emittedAt',
    label: 'Emissão',
  },
];

export const INVOICE_FILTER_OPTIONS: Array<{ label: string; option: InvoiceSearchBy }> = [
  {
    option: 'clientName',
    label: 'Cliente',
  },
  {
    option: 'clientDocument',
    label: 'CNPJ ou CPF',
  },
  {
    option: 'contract',
    label: 'Contrato',
  },
  {
    option: 'invoiceNumber',
    label: 'Nº NF',
  },
  {
    option: 'type',
    label: 'Materiais/Serviços',
  },
  {
    option: 'emittedAt',
    label: 'Emissão',
  },
];
