import React from 'react';
import DisabledListHeader from '../../../../../../../components/TableView/components/DisabledTableHeader';
import CustomText from '../../../../../../../components/CustomText';
import { DisabledOrderWrapper } from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useDisabledTableHeader from '../../../../../../../components/TableView/hooks/useDisabledTableHeader';
import { WrapperDiv } from '../../../../../../../styles/components';
import { mountAutoMinMaxString, mountMinMaxString } from '../../../../../../../utils/helpers/css';

const DisabledBillHeader = () => {
  const props = useDisabledTableHeader(
    [
      {
        width: mountAutoMinMaxString(160),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Cliente</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '190px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>CNPJ ou CPF</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '120px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>Contrato</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '250px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>Nº Boleto</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: mountMinMaxString(135, 150),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Vencimento</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: mountMinMaxString(135, 150),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Total a pagar</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: mountMinMaxString(140, 170),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Status</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: 'auto',
        render: (
          <WrapperDiv
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomText>Ações</CustomText>
          </WrapperDiv>
        ),
      },
    ],

    { withSelection: true }
  );

  return <DisabledListHeader {...props} />;
};

export default DisabledBillHeader;
