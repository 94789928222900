export enum ExceptionSlug {
  CONFIRMATION_TOKEN_EXPIRED = 'CONFIRMATION_TOKEN_EXPIRED',
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  CUSTOMER_ALREADY_ACTIVATED = 'CUSTOMER_ALREADY_ACTIVATED',
  CAPTCHA_RESPONSE_INVALID = 'CAPTCHA_RESPONSE_INVALID',
  CONFIRMATION_CODE_INVALID = 'CONFIRMATION_CODE_INVALID',
  CONFIRMATION_CODE_EXPIRED = 'CONFIRMATION_CODE_EXPIRED',
  CUSTOMER_NOT_ENABLED = 'CUSTOMER_NOT_ENABLED',
  PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}

const EXCEPTIONS_MESSAGE = {
  CUSTOMER_NOT_FOUND: 'Este usuário já existe!',
  CUSTOMER_ALREADY_ACTIVATED: 'Este usuário já existe!',
  CAPTCHA_RESPONSE_INVALID: 'Ocorreu um erro no captcha, tente novamente!',
  CONFIRMATION_CODE_INVALID: 'Código incorreto ou expirado. Digite novamente ou solicite novo código',
  CONFIRMATION_CODE_EXPIRED: 'Código incorreto ou expirado. Digite novamente ou solicite novo código',
  CUSTOMER_NOT_ENABLED: 'Seu usuário não está ativo!',
  LOGIN_EXPIRED: 'Tempo de acesso expirado. Preencha suas informações e tente novamente.',
  HTTP_500: 'Erro 500. Estamos trabalhando para resolver o problema. Por favor, tente novamente em alguns instantes.',
  DEFAULT: 'Ocorreu um erro inesperado, tente novamente!',
};

export { EXCEPTIONS_MESSAGE };
