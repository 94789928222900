import React from 'react';
import DisabledListHeader from '../../../../../../../components/TableView/components/DisabledTableHeader';
import CustomText from '../../../../../../../components/CustomText';
import { DisabledOrderWrapper } from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useDisabledTableHeader from '../../../../../../../components/TableView/hooks/useDisabledTableHeader';
import { WrapperDiv } from '../../../../../../../styles/components';
import { mountMinMaxString } from '../../../../../../../utils/helpers/css';

const DisabledInvoiceHeader = () => {
  const props = useDisabledTableHeader(
    [
      {
        width: '1fr',
        render: (
          <DisabledOrderWrapper>
            <CustomText>Cliente</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '190px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>CNPJ ou CPF</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '120px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>Contrato</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: mountMinMaxString(200, 220),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Nº NF</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: '200px',
        render: (
          <DisabledOrderWrapper>
            <CustomText>Materiais/Serviços</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: mountMinMaxString(130, 150),
        render: (
          <DisabledOrderWrapper>
            <CustomText>Emissão</CustomText>
          </DisabledOrderWrapper>
        ),
      },
      {
        width: 'auto',
        render: (
          <WrapperDiv
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomText>Ações</CustomText>
          </WrapperDiv>
        ),
      },
    ],
    { withSelection: true }
  );

  return <DisabledListHeader {...props} />;
};

export default DisabledInvoiceHeader;
