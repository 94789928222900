import styled, { css } from 'styled-components';
import CustomText from '../CustomText';
import theme from '../../styles/theme';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import { WrapperButton } from '../../styles/components';

export const ButtonArrowWrapper = styled(WrapperButton)<{ disabled: boolean }>`
  padding: 4px;
  cursor: pointer;

  &:hover {
    color: ${theme.color.primary.interaction};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.color.secondary.grey[4]};
      ${theme.defaults.disabled};
    `}
`;

export const StyledDots = styled(CustomText)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
  color: #262626;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    justify-content: start;
    gap: 8px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: fit-content;
  max-width: 320px;
`;

export const AmountInfo = styled(CustomText)`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  @media (max-width: 520px) {
    display: none;
  }

  &:before {
    content: '';
    display: inline-block;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background: #e9e9e9;
  }
`;

export const PageList = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  gap: 8px;
`;

export const NumberButton = styled.button<{ isActive?: boolean }>`
  user-select: none;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0;
  width: 36px;
  height: 36px;
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #262626;

  &:hover {
    background-color: ${theme.color.secondary.purple[4]};
  }

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${theme.color.secondary.purple[2]} !important;
          color: #fff;
        `
      : css`
          background-color: transparent;
        `};
`;

export const PaginationInfo = styled.div`
  display: flex;
  align-items: center;
`;
