import React, { PropsWithChildren } from 'react';
import Button from '../../../../../../../../components/Button';
import ModalHeader from '../../../../../../../../components/ModalHeader';
import { DesktopContainer } from '../../../style';
import { SendEmailProps } from '../../../types';
import SelectEmail from '../SelectEmail';

const DesktopSendEmail = ({
  children,
  selectedOption,
  onChangeSelectedOption,
  onChangeCustomEmail,
  customEmail,
  onClose,
  onSubmit,
  title,
  submitButtonLabel,
  description,
  selectLabel,
  customEmailError,
  loading,
}: PropsWithChildren<SendEmailProps>) => (
  <DesktopContainer>
    <ModalHeader title={title} onClose={onClose} />
    {description}
    <SelectEmail
      {...{
        selectedOption,
        onChangeSelectedOption,
        onChangeCustomEmail,
        customEmail,
        description: selectLabel,
        customEmailError,
      }}
    />
    {children}
    <Button disabled={!selectedOption || loading} loading={loading} onClick={onSubmit} variant="primary">
      {submitButtonLabel}
    </Button>
  </DesktopContainer>
);

export default DesktopSendEmail;
