import styled from 'styled-components';
import theme from '../../styles/theme';
import CustomText from '../../components/CustomText';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: fixed;
  gap: 32px;
`;

export const Title = styled(CustomText)`
  font-weight: 500;
  color: ${theme.color.primary.black};
  text-align: center;
  font-size: 32px;
  line-height: 50px;
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 16px;
  justify-content: center;

  button {
    width: 240px;
    max-width: 100%;
    padding: 0 16px;
  }
`;

export const Content = styled.div`
  gap: 4px;
`;

export const Description = styled(CustomText)`
  color: ${theme.color.primary.black};
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  line-height: 26px;
  white-space: break-spaces;
`;

export const DesktopDescription = styled.div`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  } ;
`;

export const MobileDescription = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: block;
  } ;
`;

export const Link = styled.a`
  text-decoration: none;
`;
