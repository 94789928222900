import React, { useState } from 'react';
import ModalPortal from '../ModalPortal';
import { Actions, Container, Divider } from './style';
import ModalHeader from '../../components/ModalHeader';
import Calendar from '../../components/DateRangeSelector';
import Button from '../../components/Button';
import CustomText from '../../components/CustomText';
import { WrapperDiv } from '../../styles/components';

interface SelectDateProps {
  onClose: VoidFunction;
  isOpen: boolean;
  range: DateRange | undefined;
  onChangeRange: (newRange: DateRange | undefined) => void;
  title: string;
  description: string;
}

const SelectDate = ({ range, onChangeRange, onClose, title, description }: Omit<SelectDateProps, 'isOpen'>) => {
  const [internalRange, setInternalRange] = useState<DateRange | undefined>(range);

  const onConfirm = () => {
    if (!internalRange) return;
    onChangeRange(internalRange);
    onClose();
  };

  const onClearRange = () => {
    if (!internalRange) return;
    onChangeRange(undefined);
    onClose();
  };

  return (
    <Container>
      <WrapperDiv gap={8} flexDirection="column" fit alignItems="initial">
        <ModalHeader title={title} onClose={onClose} />
        <CustomText>{description}</CustomText>
      </WrapperDiv>
      <Divider />
      <Calendar value={internalRange} onChange={(newRange) => setInternalRange(newRange)} />
      <Actions>
        <Button variant="tertiary" onClick={onClearRange}>
          Limpar
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={!internalRange}>
          Aplicar
        </Button>
      </Actions>
    </Container>
  );
};

const DesktopSelectDate = ({ onClose, isOpen, range, onChangeRange, title, description }: SelectDateProps) => (
  <ModalPortal onClose={onClose} visible={isOpen}>
    <SelectDate {...{ onClose, range, onChangeRange, title, description }} />
  </ModalPortal>
);

export default DesktopSelectDate;
