import React, { useContext, useState } from 'react';
import { FileDataType } from '../../../../../../../@types/file';
import Button from '../../../../../../../components/Button';
import ButtonStack from '../../../../../../../components/ButtonStack';
import CustomText from '../../../../../../../components/CustomText';
import ButtonDropdown from '../../../../../../../components/Floating/components/ButtonDropdown';
import Tooltip from '../../../../../../../components/Floating/components/Tooltip';
import Search from '../../../../../../../components/Search';
import Alert from '../../../../../../../components/Toast/toast';
import ToggleViewButton from '../../../../../../../components/ToggleViewButton';
import { DocumentItemAction, DocumentItemType } from '../../../../../../../config/download/types';
import useDisclosure from '../../../../../../../hooks/useDisclosure';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import { CalendarIcon, PageIcon } from '../../../../../../../icons';
import documentDialogError from '../../../../../../../layouts/DocumentErrorDialog';
import FinancialService from '../../../../../../../services/Financial/FinancialService';
import { WrapperDiv } from '../../../../../../../styles/components';
import { BREAKPOINTS } from '../../../../../../../utils/constants/breakpoints';
import MultipleDownloadModal from '../../../../../shared/components/BillDownloadModal';
import GridOrderButton from '../../../../../shared/components/OrderButton';
import SendEmailDataAction from '../../../../../shared/components/SendEmail/SendEmailDataAction';
import { SearchContainer } from '../../../../../shared/style';
import { BILL_ORDER_OPTIONS } from '../../constants';
import BillContext, { BillContextProps } from '../../context';
import FilterButton from './components/FilterButton';
import SearchBillModal from './components/SearchBillModal';
import SelectDateModal from './components/SelectDateModal';
import { Container } from './style';
import { GTMEventName } from '../../../../../../../@types/analytics';
import { sendGTMEvent } from '../../../../../../../lib/DataLayer';

const DesktopActions = () => {
  const {
    dataIsEmpty,
    toggleLayout,
    layout,
    openSelectDateAction,
    handleSelectAll,
    showGrid,
    areElementsSelected,
    order,
    search,
    handleSearch,
    handleOrder,
    actionsIsDisabled,
    loading,
    openMultipleDownloadModal,
    range,
    signalRConnectionReachedMaxRetries,
    dataToDownload,
    multipleDownloadModalIsOpen,
    closeMultipleDownloadModal,
    openSearchAction,
  } = useContext(BillContext) as BillContextProps;

  const dateConfigIsActive = range && !loading;
  const showButtons = !dataIsEmpty;
  const { width } = useWindowDimensions();
  const showActionsInDropdown = width < BREAKPOINTS.fullHd && showGrid;
  const { isOpen: sendEmailIsOpen, close: closeSendEmail, open: openSendEmail } = useDisclosure(false);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);

  const handleSendDataByEmail = async (customEmail: string | null, dataType: FileDataType) => {
    setSendEmailIsLoading(true);
    try {
      await FinancialService.sendDataByEmail(customEmail, dataType);
      Alert.SUCCESS('Sua solicitação foi realizada com sucesso. Aguarde o e-mail com os dados dos boletos.');
      sendGTMEvent({ name: GTMEventName.SEND_BILL_DATA_BY_EMAIL });
    } catch (e) {
      console.error(e);
      documentDialogError(DocumentItemType.BILL, DocumentItemAction.SENDING_BY_EMAIL);
    } finally {
      setSendEmailIsLoading(false);
      closeSendEmail();
    }
  };

  return (
    <>
      <SearchBillModal />
      <MultipleDownloadModal
        visible={multipleDownloadModalIsOpen}
        data={dataToDownload}
        onClose={closeMultipleDownloadModal}
      />
      <SelectDateModal />
      <SendEmailDataAction
        loading={sendEmailIsLoading}
        visible={sendEmailIsOpen}
        onClose={closeSendEmail}
        onSubmit={({ customEmail, dataType }) => {
          handleSendDataByEmail(customEmail || null, dataType);
        }}
        title="Enviar dados dos boletos"
        submitButtonLabel="Enviar"
        selectLabel="Escolha para quem deseja enviar o arquivo:"
        description={
          <CustomText>
            Será gerado um arquivo de dados e assim que estiver disponível será enviado por e-mail.
          </CustomText>
        }
      />

      {showButtons && (
        <Container>
          <WrapperDiv gap={16}>
            <ToggleViewButton onClick={toggleLayout} layout={layout} />
            {showActionsInDropdown ? (
              <ButtonDropdown
                variant="tertiary"
                size="small"
                disabled={loading || signalRConnectionReachedMaxRetries}
                placement="bottom-start"
                floating={
                  <ButtonStack
                    stack={[
                      {
                        handler: openSelectDateAction,
                        render: (
                          <>
                            <CalendarIcon />
                            <CustomText>Configurar data</CustomText>
                          </>
                        ),
                      },
                      {
                        handler: openSearchAction,
                        render: (
                          <>
                            <PageIcon width={24} height={24} />
                            <CustomText>Buscar novo boleto</CustomText>
                          </>
                        ),
                      },
                    ]}
                  />
                }
              >
                Ações
              </ButtonDropdown>
            ) : (
              <>
                <Tooltip info="Enviar por e-mail um arquivo com os dados dos boletos." placement="bottom-start">
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={openSendEmail}
                    disabled={signalRConnectionReachedMaxRetries}
                  >
                    Enviar dados dos boletos
                  </Button>
                </Tooltip>
                <Button
                  variant={dateConfigIsActive ? 'secondary' : 'tertiary'}
                  size="small"
                  onClick={openSelectDateAction}
                  disabled={loading || signalRConnectionReachedMaxRetries}
                >
                  {dateConfigIsActive ? 'Configurar data ativado' : 'Configurar data'}
                </Button>
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={openSearchAction}
                  disabled={loading || signalRConnectionReachedMaxRetries}
                >
                  Buscar novo boleto
                </Button>
              </>
            )}
            {showGrid && (
              <Button variant="tertiary" size="small" onClick={handleSelectAll} disabled={actionsIsDisabled}>
                Selecionar todos
              </Button>
            )}
            <Button
              variant="tertiary"
              size="small"
              disabled={!areElementsSelected || actionsIsDisabled}
              onClick={openMultipleDownloadModal}
            >
              Baixar selecionados
            </Button>
          </WrapperDiv>
          <SearchContainer>
            {showGrid && (
              <GridOrderButton
                order={order}
                handleOrder={handleOrder}
                options={BILL_ORDER_OPTIONS}
                disabled={actionsIsDisabled}
              />
            )}
            <Search
              disabled={actionsIsDisabled}
              value={search}
              onChange={(event) => handleSearch(event.target.value)}
              placeholder="Buscar"
            >
              <FilterButton />
            </Search>
          </SearchContainer>
        </Container>
      )}
    </>
  );
};

export default DesktopActions;
