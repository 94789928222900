import { PersistenceNewContract } from '../../services/Buildings/type';
import { unmaskValue } from '../../utils/helpers/validate';

class ContractMapper {
  toPersistence(domainNewContract: { contractCode: string; document: string }) {
    return {
      contractCode: domainNewContract.contractCode,
      document: unmaskValue(domainNewContract.document),
    };
  }

  toDomain(persistenceNewContract: PersistenceNewContract) {
    return {
      name: persistenceNewContract.name,
      clientName: persistenceNewContract.customer.name,
      id: persistenceNewContract.id,
      uf: persistenceNewContract.address.uf,
      city: persistenceNewContract.address.city,
      cep: persistenceNewContract.address.cep,
      contracts: persistenceNewContract.contracts,
      consultants: persistenceNewContract.consultants.filter(({ phone }) => Boolean(phone)),
      document: persistenceNewContract.customer.document,
      favorite: false,
    };
  }
}

export default new ContractMapper();
