import * as React from 'react';
import type { SVGProps } from 'react';

const SvgExpired = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#expired_svg__a)">
      <path
        stroke="currentColor"
        strokeWidth={0.3}
        d="m10.163 5.985-.107-.11-.107.11-1.646 1.68-1.646-1.68-.107-.11-.107.11-.39.398-.103.105.103.105 1.65 1.685-1.65 1.685-.103.105.103.104.39.398.107.11.107-.11 1.646-1.68 1.646 1.68.107.11.107-.11.39-.398.103-.104-.103-.105-1.65-1.685 1.65-1.685.103-.105-.103-.105z"
      />
      <mask
        id="expired_svg__b"
        width={5}
        height={5}
        x={6}
        y={6}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m10.056 6.09.39.398-1.753 1.79 1.753 1.79-.39.397-1.753-1.79-1.753 1.79-.39-.397 1.753-1.79-1.753-1.79.39-.398 1.753 1.79z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#expired_svg__b)">
        <path fill="currentColor" d="M4.785 4.684h7.04v7.187h-7.04z" />
      </g>
    </g>
    <rect width={12.03} height={12.03} x={2.291} y={2.263} stroke="currentColor" strokeWidth={1.3} rx={6.015} />
    <defs>
      <clipPath id="expired_svg__a">
        <path fill="currentColor" d="M5.805 5.361h5v5.833h-5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExpired;
