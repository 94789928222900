import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${theme.defaults.headerHeight}px;
  width: 100%;
  background-color: ${theme.color.primary.white};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  padding: 0 40px;
  box-sizing: border-box;
  position: sticky;

  @media (max-width: 1200px) {
    padding: 0 24px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  position: relative;
`;

export const UserProfileWrapper = styled(HeaderWrapper)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;
