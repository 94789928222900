import styled from 'styled-components';

export const SkeletonForm = styled.div`
  margin-top: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
