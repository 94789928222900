import React from 'react';
import Header from '../../common/Header';
import Credentials from '../components/CredentialsForm';
import CustomText from '../../../../../../../components/CustomText';

const HeaderDescription = () => (
  <CustomText as="p">
    Para editar a senha você receberá um código de validação, digite o código, sua senha atual e a nova senha.
  </CustomText>
);

const PasswordFormStep = () => (
  <>
    <Header title="Editar senha" description={<HeaderDescription />} />
    <Credentials />
  </>
);

export default PasswordFormStep;
