import React, { useContext, useState } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import BillContext, { BillContextProps } from '../../../../context';
import { isNotEmptyArray } from '../../../../../../../../../utils/helpers/array';
import AsideHeader from '../../../../../../../../../components/AsideHeader';
import { Form } from '../../style';
import CustomText from '../../../../../../../../../components/CustomText';
import CheckboxGroup from '../../../../../../../../../components/SelectableButton/Checkbox/Group';
import Button from '../../../../../../../../../components/Button';
import { BillAsideStage, BillSearchBy, BillStatus } from '../../../../../../../../../@types/bill';
import { BILL_FILTER_OPTIONS, BILL_STATUS_FILTER_OPTIONS } from '../../../../constants';
import { MobileSearchForm } from '../../../../../../../shared/components/MobileSearch';
import { FilterContainer } from '../../../../../../../shared/components/MobileSearch/style';

const FilterStep = () => {
  const { requestSearch, search, searchKeys, statusFilter, handleFilterByStatus } = useContext(
    BillContext
  ) as BillContextProps;
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState<string>(search);
  const [localSearchKeys, setLocalSearchKeys] = useState<Array<BillSearchBy>>(searchKeys || []);
  const [localStatusFilter, setLocalStatusFilter] = useState<Array<BillStatus>>(statusFilter || []);

  const hasFilter = isNotEmptyArray(localSearchKeys);

  const submitSearch = () => {
    requestSearch(localSearch, localSearchKeys);
    handleFilterByStatus(localStatusFilter);
    closeHandler();
  };

  const onSubmitFilter = () => {
    setFilterIsOpen(false);
  };

  const onBackFilter = () => {
    setFilterIsOpen(false);
    setLocalSearchKeys([]);
  };

  return filterIsOpen ? (
    <>
      <AsideHeader onBack={onBackFilter} />
      <FilterContainer>
        <Form>
          <CustomText as="h1">Selecionar filtros</CustomText>
          <CheckboxGroup
            options={BILL_FILTER_OPTIONS}
            align="column"
            selectedOptions={localSearchKeys}
            onChange={(newValue) => setLocalSearchKeys(newValue)}
          />
          <CustomText>Status</CustomText>
          <CheckboxGroup
            options={BILL_STATUS_FILTER_OPTIONS}
            align="column"
            selectedOptions={localStatusFilter}
            onChange={(newValue) => setLocalStatusFilter(newValue)}
          />
        </Form>
        <Button size="fit" variant="primary" onClick={onSubmitFilter}>
          Aplicar filtro
        </Button>
      </FilterContainer>
    </>
  ) : (
    <MobileSearchForm
      title="Buscar boletos"
      onBack={() => changeStage(BillAsideStage.SELECT_ACTION)}
      onSubmit={submitSearch}
      value={localSearch}
      onChange={(newValue) => setLocalSearch(newValue)}
      filterIsOpen={hasFilter}
      onOpenFilter={() => {
        setFilterIsOpen(true);
      }}
    />
  );
};

export default FilterStep;
