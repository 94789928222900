import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'TKEType-Regular', sans-serif;
`;

export const AlignContainer = styled.div<{ align: 'row' | 'column' }>`
  display: flex;
  ${({ align }) =>
    align === 'row' &&
    css`
      justify-content: space-between;
    `};

  flex-direction: ${({ align }) => align};
`;

export const GroupTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
`;

export const GroupLabel = styled.label<{ checked: boolean }>`
  cursor: pointer;
  color: ${theme.color.secondary.grey[1]};
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;

  ${({ checked }) =>
    checked &&
    css`
      color: ${theme.color.primary.interaction};
    `};
`;
