import React, { FunctionComponent, Suspense } from 'react';
import SkeletonLogin from '../../../../../../layouts/Skeleton/components/Login';
import { LoginFormStage } from '../../../../../../@types/auth';

interface FormProps {
  stage: LoginFormStage;
}

const formMapping = {
  [LoginFormStage.USER_TYPE_SELECT]: React.lazy(() => import('../Steps/UserType')),
  [LoginFormStage.CREDENTIALS_CLIENT]: React.lazy(() => import('../Steps/CredentialsClient')),
  [LoginFormStage.CAPTCHA]: React.lazy(() => import('../Steps/Captcha')),
  [LoginFormStage.SUBMIT_CODE]: React.lazy(() => import('../Steps/SubmitCode')),
  [LoginFormStage.REQUEST_CODE]: React.lazy(() => import('../Steps/RequestCode')),
  [LoginFormStage.FEEDBACK]: React.lazy(() => import('../Steps/Feedback')),
} as const;

const Form: FunctionComponent<FormProps> = ({ stage }) => {
  const Step = formMapping[stage];

  return (
    <Suspense fallback={<SkeletonLogin />}>
      <Step />
    </Suspense>
  );
};

export default Form;
