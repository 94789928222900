import React, { useState } from 'react';
import { Container, FilterContainer, Form, OpenFilter } from './style';
import CustomText from '../../../../../components/CustomText';
import AsideHeader from '../../../../../components/AsideHeader';
import { isNotEmptyArray } from '../../../../../utils/helpers/array';
import Button from '../../../../../components/Button';
import { WrapperDiv } from '../../../../../styles/components';
import CheckboxGroup from '../../../../../components/SelectableButton/Checkbox/Group';
import Input from '../../../../../components/Input';
import { ChevronArrowIcon } from '../../../../../icons';

interface SearchActionProps<SK extends string> {
  title: string;
  defaultValue: string;
  onSubmit: (search: string, searchKeys: Array<SK>) => void;
  onClose: VoidFunction;
  onBack: VoidFunction;
  filterOptions: Array<{ label: string; option: SK }>;
}

interface MobileSearchFormProps {
  title: string;
  onBack: VoidFunction;
  onSubmit: VoidFunction;
  onChange: (newValue: string) => void;
  value: string;
  onOpenFilter: VoidFunction;
  filterIsOpen: boolean;
}

export const MobileSearchForm = ({
  title,
  value,
  onChange,
  onBack,
  onSubmit,
  onOpenFilter,
  filterIsOpen,
}: MobileSearchFormProps) => (
  <>
    <AsideHeader onBack={onBack} />
    <Container>
      <Form>
        <CustomText as="h1">{title}</CustomText>
        <Input.Form
          type="text"
          placeholder="Buscar"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          useHelper={false}
        />
        <OpenFilter onClick={onOpenFilter} isActive={filterIsOpen}>
          <WrapperDiv>
            <WrapperDiv gap={6}>
              <CustomText>{filterIsOpen ? 'Filtro ativado' : 'Filtro'}</CustomText>
            </WrapperDiv>
            <ChevronArrowIcon />
          </WrapperDiv>
        </OpenFilter>
      </Form>
      <Button type="submit" variant="primary" disabled={!value} onClick={onSubmit}>
        Buscar
      </Button>
    </Container>
  </>
);

const MobileSearch = <SK extends string>({
  title,
  defaultValue = '',
  onSubmit,
  onClose,
  onBack,
  filterOptions,
}: SearchActionProps<SK>) => {
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState<string>(defaultValue);
  const [localSearchKeys, setLocalSearchKeys] = useState<Array<SK>>([]);

  const hasFilter = isNotEmptyArray(localSearchKeys);

  const submitSearch = () => {
    onSubmit(localSearch, localSearchKeys);
    onClose();
  };

  const onSubmitFilter = () => {
    setFilterIsOpen(false);
  };

  const onBackFilter = () => {
    setFilterIsOpen(false);
    setLocalSearchKeys([]);
  };

  return filterIsOpen ? (
    <>
      <AsideHeader onBack={onBackFilter} />
      <FilterContainer>
        <Form>
          <CustomText as="h1">Selecionar filtros</CustomText>
          <CheckboxGroup
            options={filterOptions}
            align="column"
            selectedOptions={localSearchKeys}
            onChange={(newValue) => setLocalSearchKeys(newValue)}
          />
        </Form>
        <Button size="fit" variant="primary" onClick={onSubmitFilter}>
          Aplicar filtro
        </Button>
      </FilterContainer>
    </>
  ) : (
    <MobileSearchForm
      title={title}
      onBack={onBack}
      onSubmit={submitSearch}
      value={localSearch}
      onChange={(newValue) => setLocalSearch(newValue)}
      filterIsOpen={hasFilter}
      onOpenFilter={() => {
        setFilterIsOpen(true);
      }}
    />
  );
};

export default MobileSearch;
