import { PersistenceBuildingData } from '../../services/Buildings/type';

class BuildingMapper {
  toDomain(persistenceBuilding: PersistenceBuildingData) {
    return {
      name: persistenceBuilding.name,
      clientName: persistenceBuilding.clientName,
      id: persistenceBuilding.id,
      uf: persistenceBuilding.address.uf,
      city: persistenceBuilding.address.city,
      cep: persistenceBuilding.address.cep,
      contracts: persistenceBuilding.contracts,
      consultants: persistenceBuilding.consultants.filter(({ phone }) => Boolean(phone)),
      document: persistenceBuilding.clientDocument,
      favorite: persistenceBuilding.favorite,
    };
  }
}

export default new BuildingMapper();
