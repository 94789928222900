import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    gap: 10px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #9d9d9d;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export const StepComponentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  .find_contract_form {
    flex-direction: column;
  }
`;
