import { ApiMethod, AxiosOptions, HttpMethod } from './types';
import { getFromLocalStorage, LocalStorageKey } from '../../utils/helpers/storage';
import AxiosInstance from './config';
import './responseInterceptor';
import './requestInterceptor';

function getToken(token?: string) {
  const savedToken = getFromLocalStorage(LocalStorageKey.TOKEN);
  return token ?? savedToken;
}

const executeAuthorizedRequest = <T>(method: HttpMethod, url: string, options: AxiosOptions, data?: T) => {
  const token = options.token || getToken();
  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return AxiosInstance({
    url,
    method,
    data,
    ...options,
  });
};

const executeNotAuthorizedRequest = <T>(method: HttpMethod, url: string, data?: T, options?: AxiosOptions) =>
  AxiosInstance({
    url,
    method,
    data,
    ...options,
  });

const executeRequest = <T>(method: HttpMethod, url: string, data?: T, options?: AxiosOptions) =>
  options?.isAuthorized
    ? executeAuthorizedRequest(method, url, options!, data)
    : executeNotAuthorizedRequest(method, url, data, options);

type Api = Record<string, ApiMethod>;

const api: Api = {
  get: (url, _, options) => executeRequest(HttpMethod.GET, url, undefined, options),
  post: (url, data, options) => executeRequest(HttpMethod.POST, url, data, options),
  put: (url, data, options) => executeRequest(HttpMethod.PUT, url, data, options),
  patch: (url, data, options) => executeRequest(HttpMethod.PATCH, url, JSON.stringify(data), options),
  delete: (url, data, options) => executeRequest(HttpMethod.DELETE, url, JSON.stringify(data), options),
};

export default api;
