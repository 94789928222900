import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrowSlim = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={13} viewBox="0 0 24 13" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.537 5.744 8.73 1.325 7.512.288.806 5.994l-.61.519.61.518 6.706 5.706L8.73 11.7 3.61 7.345h20.33v-1.6H3.537Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowSlim;
