import styled from 'styled-components';
import Select from 'react-select';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import theme from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledSelect = styled(Select)`
  .Select__control {
    height: 40px;
    cursor: pointer;

    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    border: none;
    box-shadow: none;

    &:hover {
      border-color: #605b5b;
    }

    &--is-focused {
      box-shadow: none;
      outline: none;
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
    }
  }

  .--is-empty {
    .Select__single-value,
    .Select__placeholder {
      color: ${theme.color.secondary.grey[4]} !important;
    }
  }

  .Select__single-value,
  .Select__placeholder {
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    color: ${theme.color.primary.black};
    margin: 0;
  }

  .Select__value-container {
    padding: 0;
    margin: 0;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__clear-indicator ~ .Select__indicator-separator {
    display: block;
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__menu {
    color: #3c3d3e;
    padding: 8px;
    z-index: 999;
    position: absolute;
    width: 180px;

    @media (min-width: ${BREAKPOINTS.mobile}px) {
      right: 0;
    }

    @media (max-width: ${BREAKPOINTS.mobile}px) {
      left: 0;
    }
  }

  .Select__option {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.color.primary.black};
    cursor: pointer;

    &--is-selected {
      background: #e8effa;
      color: #20337d;
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
      border: 1px solid #cdc7c2;
    }
  }
` as typeof Select;
