import CanceledDownloadError from '../../../exceptions/CanceledDownload';

type PromisePoolReturn<R extends unknown> = { promise: Promise<R[]>; cancel: VoidFunction };

export default function createCancellablePromisePool<T extends unknown, R extends unknown>(
  arr: T[],
  fn: (item: T, signal: AbortSignal) => Promise<R>,
  { concurrency = Infinity } = {}
): PromisePoolReturn<R> {
  const abortController = new AbortController();

  const duct: PromisePoolReturn<R> = {
    cancel(): void {},
    promise: Promise.resolve([]),
  };

  const results: R[] = [];
  const pool = Array(concurrency).fill(null);
  let isCanceled = false;
  let completedTasks = 0;

  duct.promise = new Promise((resolve, reject) => {
    duct.cancel = () => {
      abortController?.abort();
      reject(new CanceledDownloadError());
      isCanceled = true;
    };

    let currentIndex = 0;

    function executeNextTask(poolIndex: number) {
      if (isCanceled) return;

      if (currentIndex >= arr.length && completedTasks === arr.length) {
        resolve(results);
        return;
      }

      if (currentIndex >= arr.length) {
        return;
      }

      const currentIndexCopy = currentIndex;
      currentIndex += 1;

      const currentTask = fn(arr[currentIndexCopy], abortController.signal);

      currentTask
        .then((result) => {
          results[currentIndexCopy] = result;
          completedTasks += 1;
          pool[poolIndex] = null;
          executeNextTask(poolIndex);
        })
        .catch((error) => {
          reject(error);
        });

      pool[poolIndex] = currentTask;
    }

    for (let i = 0; i < Math.min(concurrency, arr.length); i++) {
      executeNextTask(i);
    }
  });

  return duct;
}
