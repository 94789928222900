import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import useHub from '../../signalR/hooks/useHub';
import { SignalRHubName } from '../../signalR/types';
import {
  AppendStatusFunc,
  AppendToDownloadListFunc,
  AppendToErrorListFunc,
  DocumentItemAction,
  DocumentItemType,
  DownloadItemStatus,
  HandleDownloadFunc,
  PackageDownloadID,
  SignalRDocumentResponse,
  ToDownloadArray,
} from '../types';
import generateDownloadID from '../helpers/generateDownloadID';
import Alert from '../../../components/Toast/toast';
import { isNotEmptyArray, removeFirstMatchingElement } from '../../../utils/helpers/array';
import documentDialogError from '../../../layouts/DocumentErrorDialog';

interface PackageToBeProcessed {
  packageId: PackageDownloadID;
  downloadDataOfPackage: {
    id: string | undefined;
    success: boolean | undefined;
    type: DocumentItemType;
    grouper: string;
    description: string;
  }[];
}

class SignalRDownloadManager {
  private readonly downloadPackages: Record<PackageDownloadID, { downloads: ToDownloadArray }>;

  private queue: Array<{ id: string; success: boolean }>;

  constructor() {
    this.downloadPackages = {};
    this.queue = [];
  }

  createPackage(packageId: PackageDownloadID, toDownload: ToDownloadArray) {
    this.downloadPackages[packageId] = {
      downloads: toDownload,
    };
  }

  getDownloadDataById(downloadId: string) {
    return Object.entries(this.downloadPackages)
      .find(([, value]) => value.downloads.some((toDownload) => toDownload.id === downloadId))?.[1]
      .downloads.find((item) => item.id === downloadId);
  }

  getDownloadDataOfPackage(packageId: PackageDownloadID) {
    return this.downloadPackages[packageId].downloads
      .map(({ id, type }) => {
        const relatedItem = this.queue.find((queuedItem) => queuedItem.id === id);
        return { id: relatedItem?.id, success: relatedItem?.success, type };
      })
      .filter(({ id }) => Boolean(id));
  }

  finishPackage(packageId: PackageDownloadID) {
    const downloadPackage = this.downloadPackages[packageId];

    if (downloadPackage && downloadPackage.downloads) {
      const { downloads } = downloadPackage;

      downloads.forEach(({ id }) => {
        this.queue = removeFirstMatchingElement(this.queue, (queuedItem) => queuedItem.id === id);
      });

      delete this.downloadPackages[packageId];
    }
  }

  processPackages() {
    const packagesToBeProcessed: Array<PackageToBeProcessed> = [];

    Object.entries(this.downloadPackages).forEach(([packageId, { downloads }]) => {
      const downloadDataOfPackage = this.getDownloadDataOfPackage(packageId as PackageDownloadID);

      if (downloadDataOfPackage.length === downloads.length) {
        packagesToBeProcessed.push({ packageId, downloadDataOfPackage } as PackageToBeProcessed);
        this.finishPackage(packageId as PackageDownloadID);
      }
    });

    return packagesToBeProcessed;
  }

  getPackages() {
    return this.downloadPackages;
  }

  receiveDownloadResponse(id: string, success: boolean) {
    const alreadyHaveLinkCached = this.queue.some((storedItem) => id === storedItem.id);

    if (!alreadyHaveLinkCached) {
      this.queue.push({ id, success });
    }
  }
}

interface DownloadMethods {
  appendToDownloadList: AppendToDownloadListFunc;
  appendToErrorList: AppendToErrorListFunc;
  handleDownload: HandleDownloadFunc;
  appendStatus: AppendStatusFunc;
}

const useSignalRDownloadQueue = ({
  appendToDownloadList,
  handleDownload,
  appendToErrorList,
  appendStatus,
}: DownloadMethods) => {
  const signalRDownloadManagerRef = useRef<SignalRDownloadManager>(new SignalRDownloadManager());

  const createSignalRDownloadPackage = (toDownload: ToDownloadArray) => {
    const signalRDownloadManager = signalRDownloadManagerRef.current;
    const packageId = generateDownloadID();
    signalRDownloadManager.createPackage(packageId, toDownload);
    if (toDownload.length > 1) {
      appendToDownloadList(
        toDownload,
        packageId,
        () => {
          appendStatus(packageId, DownloadItemStatus.CANCELED);
          signalRDownloadManager.finishPackage(packageId);
        },
        DownloadItemStatus.IN_PROGRESS
      );
    } else {
      Alert.LOADING('Aguarde o download...', { toastId: packageId });
    }
    return packageId;
  };

  const processPackages = () => {
    const signalRDownloadManager = signalRDownloadManagerRef.current;
    const packagesToBeProcessed = signalRDownloadManager.processPackages();

    packagesToBeProcessed.forEach(({ packageId, downloadDataOfPackage }) => {
      const toDownload = downloadDataOfPackage.filter(({ success }) => success) as ToDownloadArray;

      if (isNotEmptyArray(toDownload)) {
        handleDownload(
          toDownload.length === 1 ? toDownload[0] : toDownload,
          downloadDataOfPackage.length,
          packageId as PackageDownloadID
        );
      } else if (downloadDataOfPackage.length > 1) {
        appendStatus(packageId, DownloadItemStatus.ERROR);
      } else {
        documentDialogError(downloadDataOfPackage[0].type, DocumentItemAction.DOWNLOAD);
      }

      toast.dismiss(packageId);
      signalRDownloadManager.finishPackage(packageId);
    });
  };

  const receiveSignalRDownloadResponse = (response: SignalRDocumentResponse, documentType: DocumentItemType) => {
    const signalRDownloadManager = signalRDownloadManagerRef.current;
    const data = signalRDownloadManager.getDownloadDataById(response.id);

    if (!response.success) {
      appendToErrorList(
        data?.grouper || undefined,
        {
          id: response.id,
          description: data?.description || 'Sem descrição',
        },
        documentType
      );
    }

    signalRDownloadManager.receiveDownloadResponse(response.id, response.success);
    processPackages();
  };

  const { registerListener: registerBillListener, removeListener: removeBillListener } = useHub(SignalRHubName.BILL);
  const { registerListener: registerInvoiceListener, removeListener: removeInvoiceListener } = useHub(
    SignalRHubName.INVOICE
  );

  useEffect(() => {
    const receiveBill = (response: SignalRDocumentResponse) => {
      receiveSignalRDownloadResponse(response, DocumentItemType.BILL);
    };

    const receiveInvoice = (response: SignalRDocumentResponse) => {
      receiveSignalRDownloadResponse(response, DocumentItemType.INVOICE);
    };

    registerBillListener('ReceiveBillDocument', receiveBill);
    registerInvoiceListener('ReceiveInvoiceDocument', receiveInvoice);

    return () => {
      removeBillListener('ReceiveBillDocument', receiveBill);
      removeInvoiceListener('ReceiveInvoiceDocument', receiveInvoice);
    };
  }, []);

  return {
    createSignalRDownloadPackage,
  };
};

export default useSignalRDownloadQueue;
