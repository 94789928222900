import { createContext } from 'react';
import { FormStage } from './components/Form/type';
import { ConfirmationToken, ContactType, MaskedUserData } from '../../../../@types/auth';

export interface ForgotPasswordContextProps {
  stage: FormStage;
  setStage: SetState<FormStage>;
  captchaToken: string | null;
  setCaptchaToken: SetState<string | null>;
  confirmationToken: ConfirmationToken | null;
  setConfirmationToken: SetState<ConfirmationToken | null>;
  userData: MaskedUserData | null;
  setUserData: SetState<MaskedUserData | null>;
  requestCode: (callback?: VoidFunction) => Promise<void>;
  handleContactTypeChange: (contactType: ContactType) => void;
  resetForm: VoidFunction;
}

const ForgotPasswordContext = createContext<ForgotPasswordContextProps | null>(null);

export default ForgotPasswordContext;
