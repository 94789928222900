import React from 'react';
import { FeedbackErrorIcon, FeedbackSuccessIcon, FeedbackWarnIcon } from '../../icons';

const iconMapping = {
  error: FeedbackErrorIcon,
  warning: FeedbackWarnIcon,
  success: FeedbackSuccessIcon,
};

const getFeedbackIcon = (state: 'warning' | 'success' | 'error') => {
  if (!state) return null;
  const Icon = iconMapping[state];
  return <Icon />;
};

export default getFeedbackIcon;
