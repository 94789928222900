import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ConfirmForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .LabelWithIcon_Container {
    color: ${theme.color.primary.interaction};
    p {
      display: inline-block;
    }
  }
`;
