import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 120px);
  height: calc(100vh - 120px);
  padding: 24px 40px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  button[type='submit'] {
    width: 120px;
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
