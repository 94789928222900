import React, { FunctionComponent } from 'react';
import { Container } from './style';
import CustomText from '../CustomText';

interface LabelWithIconProps {
  icon: JSX.Element;
  label: string;
}

const LabelWithIcon: FunctionComponent<LabelWithIconProps> = ({ icon, label }) => (
  <Container className="LabelWithIcon_Container">
    {icon}
    <CustomText as="p" data-clarity-mask="True">
      {label}
    </CustomText>
  </Container>
);

export default LabelWithIcon;
