import styled, { css } from 'styled-components';
import theme from '../../../styles/theme';

import SvgDocument from '../../../icons/Document';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

export const StateWarning = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 800px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    width: 100%;
  }

  @media (max-width: 1600px) {
    width: 500px;
  }

  gap: 16px;
`;

export const FilterButtonContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  .__button-icon {
    transform: rotate(90deg);
  }

  .tooltip_button_open {
    color: ${theme.color.primary.interaction};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      .tooltip_button {
        color: ${theme.color.primary.interaction};
      }
    `}
`;

export const FilterButtonTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #fff;
  width: 205px;
  box-sizing: border-box;
  box-shadow: 0 3px 8px rgba(38, 35, 33, 0.16);

  max-height: 268px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  .status_title {
    margin: 32px 0;
  }

  .Align_Container {
    gap: 32px;
  }

  .CheckboxGroup-Label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
  }
`;

export const PaginateFooter = styled.footer`
  display: flex;
  bottom: 0;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  background: #fff;
  padding-top: 20px;
  padding-right: 48px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding-top: 32px;
  }
`;

const mapLinkStyle = {
  unlinked: css`
    color: ${theme.color.secondary.grey[4]};
  `,
  linked: css`
    color: ${theme.color.secondary.purple[2]};
  `,
};

export const DocumentIcon = styled(SvgDocument)<{ link?: 'linked' | 'unlinked'; $disabled?: boolean }>`
  color: red;

  ${({ link }) => link && mapLinkStyle[link]};

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${theme.color.secondary.grey[4]};
    `}
`;
