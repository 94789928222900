import React, { useContext } from 'react';
import { BillStatus } from '../../../../../@types/bill';
import AsideHeader from '../../../../../components/AsideHeader';
import CustomText from '../../../../../components/CustomText';
import FixedTextCell from '../../../../../components/FixedTextCell';
import InternalLink from '../../../../../components/Link/InternalLink';
import useDisclosure from '../../../../../hooks/useDisclosure';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { ChevronArrowIcon, DownloadIcon, EmailIcon, PrinterIcon } from '../../../../../icons';
import AsidePortal from '../../../../../layouts/AsidePortal';
import NavButton from '../../../../../layouts/Topbar/components/NavButton';
import { WrapperDiv } from '../../../../../styles/components';
import theme from '../../../../../styles/theme';
import BillStatusBadge from '../../../shared/components/BillStatusBadge';
import FinancialDetailsContext, { FinancialDetailsContextProps } from '../../context';
import { DetailsMenuButton } from '../../style';
import { FinancialDetailsMobileMenu, MobileHeaderContainer } from './style';

interface MobileAsideMenuProps {
  onClose: VoidFunction;
  isOpen: boolean;
}

const MobileAsideMenu = ({ onClose, isOpen }: MobileAsideMenuProps) => {
  const { closeHistoric, openHistoric, openBillSendEmail, handleBillDownload, handleOpenBillDocument } = useContext(
    FinancialDetailsContext
  ) as FinancialDetailsContextProps;

  return (
    <AsidePortal visible={isOpen}>
      <AsideHeader onBack={onClose} />
      <FinancialDetailsMobileMenu>
        <NavButton
          onClick={() => {
            onClose();
            closeHistoric();
          }}
        >
          <WrapperDiv>
            <CustomText>Informações</CustomText>
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
        <NavButton
          onClick={() => {
            onClose();
            openHistoric();
          }}
        >
          <WrapperDiv>
            <CustomText>Histórico</CustomText>
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
        <NavButton
          onClick={() => {
            onClose();
            handleBillDownload();
          }}
        >
          <WrapperDiv>
            <WrapperDiv alignItems="center" gap={8}>
              <DownloadIcon />
              <CustomText>Baixar</CustomText>
            </WrapperDiv>
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
        <NavButton onClick={openBillSendEmail}>
          <WrapperDiv>
            <WrapperDiv alignItems="center" gap={8}>
              <EmailIcon />
              <CustomText>Enviar por e-mail</CustomText>
            </WrapperDiv>
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
        <NavButton onClick={handleOpenBillDocument}>
          <WrapperDiv>
            <WrapperDiv alignItems="center" gap={8}>
              <PrinterIcon />
              <CustomText>Imprimir</CustomText>
            </WrapperDiv>
            <ChevronArrowIcon />
          </WrapperDiv>
        </NavButton>
      </FinancialDetailsMobileMenu>
    </AsidePortal>
  );
};

const MobileHeader = () => {
  const { isOpen, close: closeMenu, open: openMenu } = useDisclosure();
  const { details, backToPanel } = useContext(FinancialDetailsContext) as FinancialDetailsContextProps;
  const title = `${details?.client.name} ${details?.billNumber}`;
  const { width } = useWindowDimensions();

  return (
    <MobileHeaderContainer>
      <MobileAsideMenu onClose={closeMenu} isOpen={isOpen} />
      <InternalLink
        onClick={backToPanel}
        text="Voltar a lista de boletos"
        variant="backward"
        color={theme.color.primary.interaction}
        hoverColor={theme.color.primary.interaction}
      />
      <WrapperDiv justifyContent="space-between" fit>
        <FixedTextCell sm={250} md={300} className="mobile_header_title" tooltipStyle={{ width: width - 48 }}>
          {title}
        </FixedTextCell>
        <DetailsMenuButton onClick={openMenu}>
          <CustomText>Mais</CustomText>
          <ChevronArrowIcon />
        </DetailsMenuButton>
      </WrapperDiv>
      {details?.status !== BillStatus.NOT_GENERATED && <BillStatusBadge status={details?.status} />}
    </MobileHeaderContainer>
  );
};

export default MobileHeader;
