import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="m12.688 9.729.05.081.085-.047.018-.011.012-.007.01-.01.372-.371.056-.056-.042-.068-1.345-2.167-.012-.02-.02-.012L9.908 5.8l-.068-.043-.056.056-.389.389-.088.088.105.067 1.936 1.223 1.341 2.15Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="m14.049 11.93-.106-.107-.106.106-1.474 1.475-3.256-3.253L9 10.044l-.107.107-.393.396-.106.106.106.106 3.755 3.756.107.106.106-.106 1.474-1.475 2.545 2.545a13.24 13.24 0 0 1-1.875 1.285c-.803.438-1.498.654-2.071.654-.528 0-.927-.121-1.263-.455L3.77 9.56c-.383-.384-.553-.808-.55-1.359.003-.735.351-1.529.788-2.235.429-.693.932-1.283 1.237-1.622L7.8 6.898 6.326 8.372l-.106.106.106.106.397.397.106.106.106-.106L8.91 7.004l.107-.106-.107-.106-3.557-3.557-.106-.106-.106.106-.197.197c-.059.057-.698.68-1.326 1.566-.628.884-1.263 2.058-1.269 3.206-.003.778.268 1.416.816 1.96l7.51 7.511c.518.518 1.11.69 1.834.69 1.138 0 2.342-.602 3.26-1.198a12.51 12.51 0 0 0 1.64-1.269l.001-.002.196-.198.104-.106-.105-.106-3.557-3.557Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="m14.082 8.415.067.107.089-.088.377-.375.056-.056-.04-.068-1.297-2.12-.012-.02-.02-.013-2.087-1.318-.067-.043-.057.057-.388.388-.089.089.106.066 2.057 1.298 1.305 2.096Z"
    />
  </svg>
);
export default SvgPhone;
