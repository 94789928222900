import { buildUrl, compose } from '../../utils/helpers/string';
import getEnvironment from '../../utils/helpers/getEnvironment';
import { DocumentItemType } from './types';
import { SignalRHubName } from '../signalR/types';

const { BASE_URL, CLIENT_ID } = getEnvironment();

export const downloadMethodsByHub = {
  [SignalRHubName.BILL]: { on: 'ReceiveBillDocument', send: 'RequestDocuments' },
  [SignalRHubName.INVOICE]: { on: 'ReceiveInvoiceDocument', send: 'RequestDocuments' },
};

export const downloadEndpointsByHub = {
  [SignalRHubName.BILL]: '/api/v1/finances/bills/$/download',
  [SignalRHubName.INVOICE]: '/api/v1/finances/invoices/$/download',
};

export const downloadEndpointsByDocumentType = {
  [DocumentItemType.BILL]: '/api/v1/finances/bills/$/download',
  [DocumentItemType.INVOICE]: '/api/v1/finances/invoices/$/download',
};

export function createDownloadEndpoint(type: DocumentItemType, id: string) {
  return `${compose(downloadEndpointsByDocumentType[type], '$', id)}?download=true`;
}

export function createOpenDocumentEndpoint(type: SignalRHubName, id: string) {
  return buildUrl(BASE_URL!, `${compose(downloadEndpointsByHub[type], '$', id)}?download=false&client_id=${CLIENT_ID}`);
}
