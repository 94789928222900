import DownloadService from '../../../services/Download/DownloadService';
import { createDownloadEndpoint } from '../constants';
import { DocumentItemType, FileContent } from '../types';

export default function fetchDownload(id: string, type: DocumentItemType, signal?: AbortSignal) {
  return DownloadService.requestDownload(createDownloadEndpoint(type, id), signal).then((response) => {
    const fileName = response.headers['content-disposition']?.split('filename=')[1].split(';')[0] || null;
    const contentType = response.headers['content-type'] || 'application/pdf';
    return { fileType: contentType, blob: response.data, fileName } as FileContent;
  });
}
