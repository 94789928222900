import React, { useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CustomText from '../../../../../../components/CustomText';
import PageLayout from '../../../../../../layouts/PageLayout';
import {
  ModalWarning,
  ResultContainer,
} from '../../../../Client/containers/Building/components/DesktopActions/components/NewContractModal/style';
import List from './components/Table';
import DisabledListHeader from './components/Table/DisabledPartnerHeader';
import { PartnerSearchStatus } from '../../../usePartner';
import { LinkContract } from '../../../../../../@types/building';
import PartnerContext, { PartnerContextProps } from '../../../context';
import { Form } from '../../../../shared/components/FindContractForm/style';
import { NEW_BUILDING_CONTRACT_NUMBER_DISCLAIMER } from '../../../../../../utils/constants/text';
import Input from '../../../../../../components/Input';
import HookFeedbackError from '../../../../../../components/HookFeedbackError';
import { maskCPForCNPJ } from '../../../../../../utils/helpers/mask';
import Button from '../../../../../../components/Button';
import { isCPFOrCNPJ } from '../../../../../../utils/helpers/validate';
import { REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS } from '../../../../../../utils/constants/regex';
import { TitleBox } from '../../../../shared/components/FindContractHeader/style';
import { SearchIcon } from '../../../../../../icons';

const PartnerDesktopView = () => {
  const { buildings, status, fetchBuildings } = useContext(PartnerContext) as PartnerContextProps;
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LinkContract>({
    mode: 'onChange',
    defaultValues: {
      document: '',
      contract: '',
    },
  });

  const fetchData = async (data: LinkContract) => {
    setLoading(true);
    await fetchBuildings(data);
    setLoading(false);
  };

  const onSubmit: SubmitHandler<LinkContract> = (data) => {
    fetchData(data);
  };

  const watchDocument = watch('document');
  const watchContract = watch('contract');
  const formIsValid = (watchDocument.length > 0 && isCPFOrCNPJ(watchDocument)) || watchContract.length > 0;

  return (
    <PageLayout boxStyle>
      <TitleBox>
        <CustomText as="h1">Buscar novo contrato</CustomText>
        <CustomText as="p">
          Para buscar novo edifício, preencha pelo menos um dos campos: CPF ou CNPJ ou Nº Contrato.
        </CustomText>
        <CustomText as="p">{NEW_BUILDING_CONTRACT_NUMBER_DISCLAIMER}</CustomText>
      </TitleBox>
      <Form>
        <Controller
          name="document"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.BaseNumberMasked
              {...field}
              inputMode="numeric"
              customInput={Input.Form}
              variant="small"
              type="text"
              label="CPF ou CNPJ"
              placeholder="Buscar CPF ou CNPJ"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              error={Boolean(error)}
              format={maskCPForCNPJ}
            />
          )}
        />
        <Controller
          name="contract"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Regex
              {...field}
              handleChange={field.onChange}
              allowCharacters={REGEX_ALLOW_CONTRACT_NUMBER_CHARACTERS}
              variant="small"
              type="text"
              label="Número do contrato"
              placeholder="Buscar número do contrato"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              error={Boolean(error)}
            />
          )}
        />
        <Button disabled={loading || !formIsValid} variant="primary" size="small" onClick={handleSubmit(onSubmit)}>
          Buscar
          <SearchIcon />
        </Button>
      </Form>
      <ResultContainer>
        {status === PartnerSearchStatus.HAS_RESULT && !loading && <List data={buildings} />}

        {status === PartnerSearchStatus.NOT_COMPLETED && !loading && (
          <>
            <DisabledListHeader />
            <ModalWarning obfuscate>
              <CustomText as="h1">Utilize a busca para encontrar um novo edifício.</CustomText>
            </ModalWarning>
          </>
        )}

        {status === PartnerSearchStatus.EMPTY_RESULT && !loading && (
          <>
            <DisabledListHeader />
            <ModalWarning obfuscate>
              <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
            </ModalWarning>
          </>
        )}

        {loading && (
          <>
            <DisabledListHeader />
            <ModalWarning obfuscate>
              <CustomText as="h1">Buscando contrato...</CustomText>
            </ModalWarning>
          </>
        )}
      </ResultContainer>
    </PageLayout>
  );
};

export default PartnerDesktopView;
