import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ConfirmCode from '../../common/ConfirmCode';
import MessageLabel from '../../../../../../../components/Label/components/MessageLabel';
import { FormStage } from '../../../../../type';
import PhoneActionContext, { PhoneActionContextProps } from '../context';
import { CustomErrorType, errorHandler } from '../../../../../../../exceptions/errorHandler';
import { EXCEPTIONS_MESSAGE, ExceptionSlug } from '../../../../../../../utils/constants/exceptions';
import Alert from '../../../../../../../components/Toast/toast';
import AccountService from '../../../../../../../services/Account/AccountService';
import useAbortController from '../../../../../../../hooks/useAbortController';
import AccountContext, { AccountContextProps } from '../../../../../context';
import HookFeedbackError from '../../../../../../../components/HookFeedbackError';
import Button from '../../../../../../../components/Button';
import { ConfirmForm } from '../../common/ConfirmCode/style';
import { useAuth } from '../../../../../../../context/AuthContext';
import { maskPhone, obfuscatePhone } from '../../../../../../../utils/helpers/mask';
import Input from '../../../../../../../components/Input';

const ConfirmStep = () => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
  } = useForm<{ confirmationCode: string }>({
    mode: 'onChange',
  });

  const watchConfirmationCode = watch('confirmationCode');
  const formIsValid = isValid && watchConfirmationCode.length === 6;

  const { setStage, selectedPhone } = useContext(PhoneActionContext) as PhoneActionContextProps;
  const { closeAction } = useContext(AccountContext) as AccountContextProps;
  const [loading, setLoading] = useState(false);
  const { abort, getSignal } = useAbortController();
  const { updateUserPhone } = useAuth();

  const onError = (e: unknown) => {
    const handledError = errorHandler(e);
    if (handledError.type === CustomErrorType.AXIOS_ERROR) {
      const { firstSlug } = handledError;
      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_EXPIRED) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_EXPIRED,
        });
        return;
      }

      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_INVALID) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_INVALID,
        });
        return;
      }

      closeAction();
      Alert.ERROR('Ocorreu um erro ao tentar trocar sua senha. Por favor, tente novamente em alguns instantes.');
    }

    if (handledError.type === CustomErrorType.STOCK_ERROR) {
      console.error(e);
    }
  };

  const onSubmit = async (data: { confirmationCode: string }) => {
    setLoading(true);
    abort();
    const signal = getSignal();

    try {
      await AccountService.confirmChangePhone(
        {
          confirmationCode: data.confirmationCode,
          newPhone: selectedPhone,
        },
        signal
      );
      updateUserPhone(selectedPhone);
      setStage(FormStage.FEEDBACK);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmCode
      title="Editar número de celular"
      description="Enviamos um código de confirmação para o novo número de celular."
      highlight={<MessageLabel label={maskPhone(obfuscatePhone(selectedPhone))} />}
    >
      <ConfirmForm onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="confirmationCode"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Form
              {...field}
              label="Código"
              placeholder="000000"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              error={Boolean(error)}
              maxLength={6}
            />
          )}
        />
        <Button disabled={!formIsValid || loading} loading={loading} variant="primary" type="submit">
          Finalizar alteração
        </Button>
      </ConfirmForm>
    </ConfirmCode>
  );
};

export default ConfirmStep;
