import { useMemo, useState } from 'react';
import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { Placement } from '@floating-ui/core/src/types';

const useCustomFloating = ({ placement, usingSafePolygon }: { placement: Placement; usingSafePolygon: boolean }) => {
  const [open, setOpen] = useState(false);

  const data = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
    strategy: 'fixed',
  });
  const { context } = data;

  const dismiss = useDismiss(context, { ancestorScroll: true });
  const role = useRole(context, { role: 'tooltip' });
  const hover = useHover(context, {
    move: false,
    delay: {
      open: 20,
      close: 0,
    },
    ...(usingSafePolygon
      ? {
          handleClose: safePolygon({
            blockPointerEvents: false,
          }),
        }
      : {}),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role, hover]);

  return useMemo(
    () => ({
      data,
      open,
      setOpen,
      getReferenceProps,
      getFloatingProps,
    }),
    [data, open, setOpen, getReferenceProps, getFloatingProps]
  );
};

export default useCustomFloating;
