import axios from 'axios';
import { clearLocalStorage } from '../../utils/helpers/storage';
import AxiosInstance from './config';
import { executeRefreshToken } from './refreshToken';

let isRefreshing = false;
let failedQueue: { reject: (reason?: unknown) => void; resolve: (value: unknown) => void }[] = [];

const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const redirectToLogin = () => {
  clearLocalStorage();
  window.location.href = '/';
};

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (rejectedRequest) => {
    if (!rejectedRequest.config.headers.Authorization || axios.isCancel(rejectedRequest))
      return Promise.reject(rejectedRequest);

    const originalRequest = rejectedRequest.config;

    if (rejectedRequest.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return await AxiosInstance(originalRequest);
        } catch (error) {
          return await Promise.reject(error);
        }
      }

      originalRequest._retry = true;

      return new Promise((resolve, reject) => {
        isRefreshing = true;

        executeRefreshToken()
          .then((data) => {
            axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);

            resolve(AxiosInstance(originalRequest));
          })
          .catch((error: unknown) => {
            processQueue(error, null);
            redirectToLogin();
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(rejectedRequest);
  }
);
