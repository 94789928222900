import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GTMEventName } from '../../../@types/analytics';
import { BillDetails } from '../../../@types/bill';
import { TabAccessor } from '../../../@types/dashboard';
import Alert from '../../../components/Toast/toast';
import { emitBillDownload, emitInvoiceDownload } from '../../../config/download';
import { DocumentItemAction, DocumentItemType } from '../../../config/download/types';
import useHub from '../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../config/signalR/types';
import useDisclosure from '../../../hooks/useDisclosure';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import documentDialogError from '../../../layouts/DocumentErrorDialog';
import { sendGTMEvent } from '../../../lib/DataLayer';
import FinancialService from '../../../services/Financial/FinancialService';
import { openBlankPage } from '../../../utils/helpers/window';

const useFinancialDetails = () => {
  const { id } = useParams();
  const { registerListener: registerBillListener, singleSend: sendBillMessage } = useHub(SignalRHubName.BILL);
  const { singleSend: sendInvoiceMessage } = useHub(SignalRHubName.INVOICE);
  const [details, setDetails] = useState<BillDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);
  const [historicIsOpen, setHistoricIsOpen] = useState(false);
  const { isMobileWidth } = useWindowDimensions();
  const navigate = useNavigate();
  const { isOpen: billSendEmailIsOpen, close: closeBillSendEmail, open: openBillSendEmail } = useDisclosure(false);
  const { isOpen: billDownloadIsOpen, close: closeBillDownload, open: openBillDownload } = useDisclosure(false);

  const openHistoric = () => {
    setHistoricIsOpen(true);
    sendGTMEvent({ name: GTMEventName.VIEW_BILL_DETAILS_HISTORY });
  };

  const closeHistoric = () => {
    setHistoricIsOpen(false);
  };

  const toggleHistoric = () => {
    if (historicIsOpen) {
      closeHistoric();
      return;
    }

    openHistoric();
  };

  const handleBillDownload = () => {
    if (isMobileWidth && id) {
      sendBillMessage(['RequestDocuments', JSON.stringify([id])]).then(() => {
        emitBillDownload(id);
        sendGTMEvent({ name: GTMEventName.DOWNLOAD_BILL });
      });
      return;
    }

    openBillDownload();
  };

  const handleInvoiceDownload = (invoiceId: string) => {
    if (invoiceId) {
      sendInvoiceMessage(['RequestDocuments', JSON.stringify([invoiceId])]).then(() => {
        emitInvoiceDownload(invoiceId);
        sendGTMEvent({ name: GTMEventName.DOWNLOAD_INVOICE });
      });
    }
  };

  const backToPanel = () => {
    navigate(`/home?tab=${TabAccessor.FINANCIAL}`);
  };

  useEffect(() => {
    sendBillMessage(['RequestFullBill', id])
      .then(() => {
        setDetails(null);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  useEffect(() => {
    registerBillListener('ReceiveFullBill', (item) => {
      setLoading(false);
      setDetails(item);
    });
  }, []);

  const handleSendBillByEmail = async (customEmail: string | null) => {
    if (!id) {
      return;
    }

    setSendEmailIsLoading(true);

    try {
      await FinancialService.sendBillByEmail(id, customEmail);
      Alert.SUCCESS('Boleto enviado');
      sendGTMEvent({ name: GTMEventName.SEND_BILL_BY_EMAIL });
    } catch (e) {
      console.error(e);
      documentDialogError(DocumentItemType.BILL, DocumentItemAction.SENDING_BY_EMAIL);
    } finally {
      setSendEmailIsLoading(false);
      closeBillSendEmail();
    }
  };

  const handleOpenBillDocument = () => {
    if (id) {
      openBlankPage(`/home/view/bill/${id}`);
      sendGTMEvent({ name: GTMEventName.PRINT_BILL });
    }
  };

  const handleOpenInvoiceDocument = (invoiceId: string) => {
    openBlankPage(`/home/view/invoice/${invoiceId}`);
    sendGTMEvent({ name: GTMEventName.PRINT_INVOICE });
  };

  return {
    id,
    details,
    sendEmailIsLoading,
    historicIsOpen,
    closeHistoric,
    openHistoric,
    toggleHistoric,
    backToPanel,
    handleBillDownload,
    handleOpenBillDocument,
    handleOpenInvoiceDocument,
    handleSendBillByEmail,
    loading,
    billDownloadIsOpen,
    closeBillDownload,
    openBillDownload,
    billSendEmailIsOpen,
    closeBillSendEmail,
    openBillSendEmail,
    isMobileWidth,
    handleInvoiceDownload,
  };
};

export default useFinancialDetails;
