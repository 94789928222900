import { useState } from 'react';
import { isValidEmail } from '../utils/helpers/validate';

type SelectedOption = 'email' | 'custom_email';

export interface UseSelectEmailProps {
  selectedOption: SelectedOption | null;
  customEmail: string;
  onChangeCustomEmail: (newCustomEmail: string) => void;
  onChangeSelectedOption: (newSelectedOption: SelectedOption) => void;
  customEmailError: string | null;
  setCustomEmailError: (error: string | null) => void;
  clearValues: VoidFunction;
}

const useSelectEmail = () => {
  const [selectedOption, setSelectedOption] = useState<'email' | 'custom_email' | null>(null);
  const [customEmail, setCustomEmail] = useState<string>('');
  const [customEmailError, setCustomEmailError] = useState<string | null>(null);

  const onChangeCustomEmail = (newCustomEmail: string) => {
    if (!isValidEmail(newCustomEmail)) {
      setCustomEmailError('E-mail inválido');
    } else {
      setCustomEmailError(null);
    }

    setCustomEmail(newCustomEmail);
  };
  const onChangeSelectedOption = (newSelectedOption: 'email' | 'custom_email') => {
    if (newSelectedOption === 'email') {
      setCustomEmail('');
      setCustomEmailError(null);
    }
    setSelectedOption(newSelectedOption);
  };

  const clearValues = () => {
    setCustomEmailError(null);
    setSelectedOption(null);
    setCustomEmail('');
  };

  return {
    selectedOption,
    customEmail,
    onChangeCustomEmail,
    onChangeSelectedOption,
    customEmailError,
    setCustomEmailError,
    clearValues,
  } as UseSelectEmailProps;
};

export default useSelectEmail;
