import React from 'react';
import ModalPortal from '../../../../../../../../../layouts/ModalPortal';
import Button from '../../../../../../../../../components/Button';
import { Actions, Container } from './style';
import ModalHeader from '../../../../../../../../../components/ModalHeader';

interface UnlinkModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  loading: boolean;
  buildingContract: string;
}

const UnlinkModal = ({ buildingContract, visible, onClose, onSubmit, loading }: UnlinkModalProps) => (
  <ModalPortal visible={visible} onClose={onClose}>
    <Container>
      <ModalHeader title={`Deseja desafixar o contrato ${buildingContract} do seu painel?`} onClose={onClose} />
      <Actions>
        <Button onClick={onClose} variant="secondary" disabled={loading}>
          Voltar
        </Button>
        <Button onClick={onSubmit} variant="primary" disabled={loading} loading={loading}>
          Desafixar
        </Button>
      </Actions>
    </Container>
  </ModalPortal>
);

export default UnlinkModal;
