import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

const DISABLED_BUTTONS_STYLE = css`
  & > p {
    color: ${theme.color.secondary.grey[3]};
    fill: ${theme.color.secondary.grey[3]};
  }

  & > .download-icon path {
    fill: ${theme.color.secondary.grey[3]};
  }

  & > .email-icon path {
    fill: ${theme.color.secondary.grey[3]};
    stroke: ${theme.color.secondary.grey[3]};
  }

  & > .print-icon path {
    fill: ${theme.color.secondary.grey[3]};
    stroke: ${theme.color.secondary.grey[3]};
  }

  & > .eye-icon path {
    fill: ${theme.color.secondary.grey[3]};
    stroke: ${theme.color.secondary.grey[3]};
  }
`;

export const StyledFileActionButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 32px;
  width: 32px;
  background: transparent;
  outline: none;
  border: 1px solid ${theme.color.secondary.grey[5]};
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  padding: 0;

  &:hover {
    border: 2px solid ${theme.color.secondary.purple[2]} !important;
    color: ${theme.color.secondary.purple[2]};

    & > .download-icon path {
      fill: ${theme.color.secondary.purple[2]};
    }

    & > .email-icon path {
      fill: ${theme.color.secondary.purple[2]};
      stroke: ${theme.color.secondary.purple[2]};
    }

    & > .print-icon path {
      fill: ${theme.color.secondary.purple[2]};
      stroke: ${theme.color.secondary.purple[2]};
    }
  }

  &[disabled] {
    ${DISABLED_BUTTONS_STYLE};

    &:hover {
      border: 2px solid ${theme.color.secondary.grey[3]} !important;
      color: ${theme.color.secondary.grey[3]};
    }
  }
`;

export const DropdownActions = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px;
`;

export const DropdownActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  min-width: 190px;
  width: 100%;
  height: 48px;

  &:hover {
    background: ${theme.color.secondary.purple[4]};
  }

  &[disabled] {
    ${DISABLED_BUTTONS_STYLE};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .tooltip_button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 32px;
    width: 32px;
    background: transparent;
    border: 1px solid ${theme.color.secondary.grey[5]};
    color: ${theme.color.primary.black};
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
  }
  .tooltip_button_open {
    border: 1px solid ${theme.color.secondary.purple[2]};
    color: ${theme.color.primary.interaction};
  }
`;

export const AddonButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 32px;
  background: transparent;
  border: 2px solid transparent;
  outline: 1px solid #e9e9e9;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  width: fit-content;
  p {
    font-size: 14px;
  }

  &:hover {
    border: 2px solid ${theme.color.secondary.purple[2]};
    color: ${theme.color.secondary.purple[2]};
    outline: none !important;
  }

  &[disabled] {
    color: ${theme.color.secondary.grey[4]} !important;

    &:hover {
      border: 2px solid ${theme.color.secondary.grey[4]};
      outline: none !important;
    }
  }
`;
