import { BillOrderBy, BillSearchBy, BillStatus } from '../../../../../@types/bill';

export const MAP_BILL_STATUS = {
  [BillStatus.NOT_GENERATED]: 'Sem boleto',
  [BillStatus.PENDING]: 'À vencer',
  [BillStatus.PAID]: 'Compensado',
  [BillStatus.OVERDUE]: 'Vencido',
  [BillStatus.EXPIRED]: 'Expirado',
  [BillStatus.CANCELED]: 'Cancelado',
};

export const BILL_ORDER_OPTIONS: Array<{ label: string; option: BillOrderBy }> = [
  {
    option: 'clientName',
    label: 'Cliente',
  },
  {
    option: 'clientDocument',
    label: 'CNPJ ou CPF',
  },
  {
    option: 'contract',
    label: 'Contrato',
  },
  {
    option: 'documentNumber',
    label: 'Nº Boleto',
  },
  {
    option: 'expiresAt',
    label: 'Vencimento',
  },
  {
    option: 'totalAmount',
    label: 'Total a pagar',
  },
  {
    option: 'status',
    label: 'Status',
  },
];

export const BILL_FILTER_OPTIONS: Array<{ label: string; option: BillSearchBy }> = [
  {
    label: 'Cliente',
    option: 'clientName',
  },
  {
    label: 'CNPJ ou CPF',
    option: 'clientDocument',
  },
  {
    label: 'Contrato',
    option: 'contract',
  },
  {
    label: 'Nº Boleto',
    option: 'documentNumber',
  },
  {
    label: 'NF',
    option: 'invoice',
  },
  {
    label: 'Vencimento',
    option: 'expiresAt',
  },
];

export const BILL_STATUS_FILTER_OPTIONS: Array<{ label: string; option: BillStatus }> = [
  {
    label: MAP_BILL_STATUS[BillStatus.EXPIRED],
    option: BillStatus.EXPIRED,
  },
  {
    label: MAP_BILL_STATUS[BillStatus.OVERDUE],
    option: BillStatus.OVERDUE,
  },
  {
    label: MAP_BILL_STATUS[BillStatus.PENDING],
    option: BillStatus.PENDING,
  },
  {
    label: MAP_BILL_STATUS[BillStatus.NOT_GENERATED],
    option: BillStatus.NOT_GENERATED,
  },
  {
    label: MAP_BILL_STATUS[BillStatus.PAID],
    option: BillStatus.PAID,
  },
];

export const BILL_STATUS_ORDER = {
  [BillStatus.EXPIRED]: 1,
  [BillStatus.OVERDUE]: 2,
  [BillStatus.PENDING]: 3,
  [BillStatus.NOT_GENERATED]: 4,
  [BillStatus.PAID]: 5,
  [BillStatus.CANCELED]: 6,
};
