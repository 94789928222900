import api from '../../config/http/api';
import { LinkContract } from '../../@types/building';
import BuildingMapper from '../../strategy/mappers/BuildingMapper';
import { ExternalTokenData, PersistenceBuildingData, PersistenceNewContract } from './type';
import ContractMapper from '../../strategy/mappers/ContractMapper';
import { unmaskValue } from '../../utils/helpers/validate';

const removeContractRelation = async (data: Array<string>) => {
  await api.post<Array<String>, void>('/api/v1/customers/me/contracts/unlink', data, {
    isAuthorized: true,
  });
};

const addContractRelation = async (data: LinkContract) => {
  await api.post<{ contractCode: string; document: string }, void>(
    '/api/v1/customers/me/contracts',
    ContractMapper.toPersistence({
      contractCode: data.contract,
      document: data.document,
    }),
    { isAuthorized: true }
  );
};

const partnerGetBuildingsByContract = async (data: LinkContract, signal?: AbortSignal) => {
  const { data: buildings } = await api.post<Partial<LinkContract>, PersistenceNewContract[]>(
    '/api/v1/buildings/filter',
    {
      ...(data.document && { document: unmaskValue(data.document) }),
      ...(data.contract && { contractCode: data.contract }),
    },
    {
      signal,
      isAuthorized: true,
    }
  );

  return buildings.map(ContractMapper.toDomain);
};

const getBuildingsByContract = async (data: LinkContract, signal?: AbortSignal) => {
  const { data: buildings } = await api.post<{ contractCode: string; document: string }, PersistenceNewContract[]>(
    '/api/v1/buildings/filter',
    ContractMapper.toPersistence({
      contractCode: data.contract,
      document: data.document,
    }),
    {
      signal,
      isAuthorized: true,
    }
  );

  return buildings.map(ContractMapper.toDomain);
};

const getBuildingsByDocument = async (data: Partial<LinkContract>, signal?: AbortSignal) => {
  const { data: buildings } = await api.post<{ contractCode?: string; document?: string }, PersistenceNewContract[]>(
    '/api/v1/buildings/filter',
    {
      ...(data.document && { document: unmaskValue(data.document) }),
      ...(data.contract && { contractCode: data.contract }),
    },
    {
      signal,
      isAuthorized: true,
    }
  );

  return buildings.map(ContractMapper.toDomain);
};

const getBuildings = async () => {
  const { data: buildings } = await api.get<null, PersistenceBuildingData[]>('/api/v1/customers/me/buildings', null, {
    isAuthorized: true,
  });

  return buildings.map(BuildingMapper.toDomain);
};

const getBuildingPortalToken = async (buildingId: string) => {
  const { data: externalTokenData } = await api.get<null, ExternalTokenData>(
    `/api/v1/buildings/${buildingId}/building-token`,
    null,
    {
      isAuthorized: true,
    }
  );

  return externalTokenData.accessToken;
};

const getBuildingFinancesPortalToken = async (contractId: string) => {
  const { data: externalTokenData } = await api.post<{ contractId: string }, ExternalTokenData>(
    `/api/v1/contracts/finances-token`,
    { contractId },
    {
      isAuthorized: true,
    }
  );
  return externalTokenData.accessToken;
};

const toggleFavorite = async ({ favorite, id }: { favorite: boolean; id: string | number }, signal?: AbortSignal) =>
  api.put<{ favorite: boolean }, null>(
    `/api/v1/customers/me/buildings/${id}`,
    {
      favorite,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

export default {
  partnerGetBuildingsByContract,
  removeContractRelation,
  addContractRelation,
  getBuildingsByContract,
  getBuildingsByDocument,
  getBuildings,
  getBuildingPortalToken,
  getBuildingFinancesPortalToken,
  toggleFavorite,
};
