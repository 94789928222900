import api from '../../config/http/api';
import { SignalRSignInReturn } from '../../config/signalR/types';

const signinBillHub = async () => {
  const { data } = await api.get<null, SignalRSignInReturn>('/api/v1/finances/bills/signal-signin', null, {
    isAuthorized: true,
  });

  return data;
};

const signinInvoiceHub = async () => {
  const { data } = await api.get<null, SignalRSignInReturn>('/api/v1/finances/invoices/signal-signin', null, {
    isAuthorized: true,
  });

  return data;
};

export default {
  signinBillHub,
  signinInvoiceHub,
};
