import React from 'react';
import UnstyledButtonWithTooltip from '../../../../../../../../../components/Floating/components/UnstyledButtonWithFloating';
import CheckboxGroup from '../../../../../../../../../components/SelectableButton/Checkbox/Group';
import CustomText from '../../../../../../../../../components/CustomText';
import { BUILDING_FILTER_OPTIONS } from '../../../../constants';
import { BuildingSearchBy } from '../../../../../../../../../@types/building';
import { FilterButtonContainer, FilterButtonTooltipContent } from '../../../../../../../shared/style';
import { ChevronArrowIcon } from '../../../../../../../../../icons';

interface FilterButtonProps {
  isSelected: boolean;
  value: Array<BuildingSearchBy>;
  onChange: (newValue: Array<BuildingSearchBy>) => void;
}

const FilterButton = ({ value, onChange, isSelected }: FilterButtonProps) => (
  <FilterButtonContainer isSelected={isSelected}>
    <UnstyledButtonWithTooltip
      placement="bottom-end"
      content={
        <>
          <CustomText>{isSelected ? 'Filtro ativado' : 'Filtro'}</CustomText>
          <ChevronArrowIcon className="rotate-90" />
        </>
      }
      floating={
        <FilterButtonTooltipContent>
          <CheckboxGroup options={BUILDING_FILTER_OPTIONS} onChange={onChange} align="column" selectedOptions={value} />
        </FilterButtonTooltipContent>
      }
    />
  </FilterButtonContainer>
);

export default FilterButton;
