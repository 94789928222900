import * as React from 'react';
import type { SVGProps } from 'react';

const SvgApple = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#fff"
      d="M13.999 8.83a2.912 2.912 0 0 1 1.394-2.45 2.993 2.993 0 0 0-2.361-1.272c-.993-.104-1.956.593-2.463.593-.515 0-1.295-.582-2.134-.565A3.15 3.15 0 0 0 5.79 6.745c-1.144 1.975-.29 4.878.805 6.474.549.782 1.19 1.655 2.028 1.624.82-.034 1.127-.522 2.117-.522.981 0 1.269.522 2.124.502.88-.014 1.435-.785 1.964-1.574a6.457 6.457 0 0 0 .899-1.824 2.826 2.826 0 0 1-1.727-2.595ZM12.383 4.059c.48-.575.717-1.313.66-2.059a2.935 2.935 0 0 0-1.898.979 2.731 2.731 0 0 0-.676 1.983 2.43 2.43 0 0 0 1.914-.903Z"
    />
  </svg>
);
export default SvgApple;
