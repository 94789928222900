import React, { useContext, useMemo, useState } from 'react';
import { Container } from './style';
import SelectStep from './components/SelectStep';
import SearchStep from './components/SearchStep';

import AsideActionsContext from './context';
import AsidePortal from '../../../../../../../layouts/AsidePortal';
import { FinancialHistoricStage } from '../../../../../../../@types/bill';
import FinancialHistoricContext, { FinancialHistoricContextProps } from '../../context';
import TextIconButton from '../../../../../../../components/TextIconButton';
import { CloseIcon } from '../../../../../../../icons';

const stepMapping = {
  [FinancialHistoricStage.SELECT_ACTION]: SelectStep,
  [FinancialHistoricStage.FILTER_ACTION]: SearchStep,
} as const;

const AsideActions = () => {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(FinancialHistoricStage.SELECT_ACTION);
  const { search, clearSearch, actionsIsDisabled } = useContext(
    FinancialHistoricContext
  ) as FinancialHistoricContextProps;

  const openHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
    setStage(FinancialHistoricStage.SELECT_ACTION);
  };

  const clearHandler = () => {
    clearSearch();
  };

  const changeStage = (newStage: FinancialHistoricStage) => {
    setStage(newStage);
  };

  const context = useMemo(
    () => ({
      changeStage,
      closeHandler,
    }),
    []
  );

  const Step = stepMapping[stage];

  return (
    <AsideActionsContext.Provider value={context}>
      <Container>
        <input readOnly placeholder="Buscar" onClick={openHandler} disabled={actionsIsDisabled} />
        <AsidePortal visible={open}>
          <Step />
        </AsidePortal>
        {search && (
          <TextIconButton onClick={clearHandler} label="Busca ativada" icon={<CloseIcon width={12} height={12} />} />
        )}
      </Container>
    </AsideActionsContext.Provider>
  );
};

export default AsideActions;
