import React from 'react';
import { useAuth } from '../../../../../../../../context/AuthContext';
import { ErrorMessage, SelectEmailContainer } from '../../../style';
import CustomText from '../../../../../../../../components/CustomText';
import RadioGroup from '../../../../../../../../components/SelectableButton/Radio/Group';
import Input from '../../../../../../../../components/Input';

interface SelectEmailProps {
  selectedOption: 'email' | 'custom_email' | null;
  onChangeSelectedOption: (newSelectedOption: 'email' | 'custom_email') => void;
  onChangeCustomEmail: (newCustomEmail: string) => void;
  customEmail: string;
  description: string;
  customEmailError: string | null;
}

const SelectEmail = ({
  selectedOption,
  onChangeSelectedOption,
  onChangeCustomEmail,
  customEmail,
  description,
  customEmailError,
}: SelectEmailProps) => {
  const { user } = useAuth();

  return (
    <SelectEmailContainer>
      <CustomText as="p" className="__modal_select_title">
        {description}
      </CustomText>
      <RadioGroup
        name="modal_select_email"
        options={[
          {
            label: (
              <CustomText>
                Enviar para{' '}
                <CustomText as="span" className="highlight_email" data-clarity-mask="True">
                  {user?.email}
                </CustomText>
              </CustomText>
            ),
            option: 'email',
          },
          {
            label: <CustomText>Enviar para o e-mail:</CustomText>,
            option: 'custom_email',
          },
        ]}
        align="column"
        selectedOption={selectedOption}
        onChange={onChangeSelectedOption}
      />
      <div className="__align_input">
        <Input.Form
          helper={<ErrorMessage>{customEmailError}</ErrorMessage>}
          error={Boolean(customEmailError)}
          disabled={selectedOption !== 'custom_email'}
          value={customEmail}
          onChange={(event) => onChangeCustomEmail(event.target.value)}
        />
      </div>
    </SelectEmailContainer>
  );
};

export default SelectEmail;
