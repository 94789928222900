import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.063 11.978v3.2h12.8v-3.2h1.6v4.8h-16v-4.8h1.6Zm7.2-11.2v9.793l3.725-3.725 1.132 1.132-5.657 5.657-5.657-5.657 1.131-1.132 3.726 3.727V.778h1.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDownload;
