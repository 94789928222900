import styled, { css } from 'styled-components';
import theme from '../../../../styles/theme';
import { fadeIn, fadeOut, slideToLeft, slideToRight } from '../../../../styles/keyframes';

interface AnimatedProps {
  isLeaving: boolean;
}

export const Overlay = styled.div<AnimatedProps>`
  position: fixed;
  z-index: ${theme.defaults.asidePortalZIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: ${fadeIn} 0.35s forwards;
  z-index: ${theme.defaults.asidePortalZIndex};

  ${({ isLeaving }) =>
    isLeaving &&
    css`
      animation: ${fadeOut} 0.35s ease-in-out forwards;
    `};
`;

export const DesktopSidebar = styled.div<AnimatedProps>`
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  position: fixed;
  height: 100vh;
  flex-direction: column;
  z-index: ${theme.defaults.asidePortalZIndex};
  transition: 850ms;
  width: 255px;
  overflow: hidden;
  animation: ${slideToRight} 0.35s forwards;

  ${({ isLeaving }) =>
    isLeaving &&
    css`
      animation: ${slideToLeft} 0.35s ease-in-out forwards;
    `};
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 20px;
  border-bottom: 1px solid ${theme.color.secondary.grey[5]};
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  a {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 10px;
    width: 100%;
    height: 67px;
    background: #ffffff;
    position: relative;

    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #262626;
    text-decoration: none;
  }
`;
