import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { NumberFormatBase, NumberFormatBaseProps, PatternFormat, PatternFormatProps } from 'react-number-format';
import { Helper, Icon, InputBox, InputControl, Label, StyledInput } from './style';
import { InputProps } from './types';
import getFeedbackIcon from '../../utils/jsx/getFeedbackIcon';
import { caretUnknownFormatBoundary, removeFormatting } from '../../utils/helpers/number';
import OpenEye from '../../icons/OpenEye';
import CloseEye from '../../icons/CloseEye';
import { WrapperButton } from '../../styles/components';

type InputType = InputProps & InputHTMLAttributes<HTMLInputElement>;

const FormInput = forwardRef<HTMLInputElement, InputType>(
  (
    {
      readOnly,
      error,
      feedback,
      addon,
      useHelper = true,
      helper,
      disabled = false,
      variant = 'normal',
      id,
      label,
      placeholder = 'Digite aqui',
      boxClassName,
      ...props
    },
    ref
  ) => {
    const currentState = error ? 'error' : feedback;
    const shouldShowFeedbackIcon = currentState && !disabled && !addon;
    const shouldShowAddon = addon && !disabled;

    return (
      <InputControl className="Input_Control">
        {label && (
          <Label error={error} disabled={disabled} htmlFor={id}>
            {label}
          </Label>
        )}
        <InputBox className="input_box" state={currentState} disabled={disabled} readOnly={readOnly}>
          <StyledInput
            placeholder={placeholder}
            ref={ref}
            disabled={disabled}
            id={id}
            variant={variant}
            readOnly={readOnly}
            {...props}
          />
          {shouldShowFeedbackIcon && <Icon variant={variant}>{getFeedbackIcon(currentState)}</Icon>}
          {shouldShowAddon && <Icon cursor="pointer">{addon}</Icon>}
        </InputBox>
        {useHelper && <Helper disabled={disabled}>{helper}</Helper>}
      </InputControl>
    );
  }
);

const PasswordInput = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  const [show, setShow] = useState(false);

  const handlePasswordVisibility = () => {
    setShow((prevState) => !prevState);
  };

  const currentIcon = (
    <WrapperButton onClick={handlePasswordVisibility} cursor="pointer">
      {show ? <OpenEye /> : <CloseEye />}
    </WrapperButton>
  );

  return <FormInput ref={ref} type={show ? 'text' : 'password'} addon={currentIcon} {...props} />;
});

const MaskedInput = forwardRef<HTMLInputElement, PatternFormatProps & InputProps>((props, ref) => (
  <PatternFormat {...props} getInputRef={ref} customInput={FormInput} patternChar="9" mask="_" />
));

const BaseNumberMaskedInput = forwardRef<
  HTMLInputElement,
  Omit<NumberFormatBaseProps, 'removeFormatting' | 'getCaretBoundary'> & InputProps
>((props, ref) => (
  <NumberFormatBase
    {...props}
    getInputRef={ref}
    customInput={FormInput}
    removeFormatting={removeFormatting}
    getCaretBoundary={caretUnknownFormatBoundary}
  />
));

const RegexInput = forwardRef<
  HTMLInputElement,
  InputType & { handleChange: (value: string) => void; allowCharacters: RegExp }
>(({ handleChange, allowCharacters, ...props }, ref) => (
  <FormInput
    {...props}
    ref={ref}
    onChange={(event) => {
      const inputValue = event.target.value;
      const allowedValue = inputValue.match(allowCharacters);
      handleChange(allowedValue ? allowedValue[0] : '');
    }}
  />
));

export default {
  Form: FormInput,
  Password: PasswordInput,
  Masked: MaskedInput,
  BaseNumberMasked: BaseNumberMaskedInput,
  Regex: RegexInput,
};
