import React, { useCallback } from 'react';
import { Content } from './style';
import RadioGroup from '../../../../../components/SelectableButton/Radio/Group';
import ButtonDropdown from '../../../../../components/Floating/components/ButtonDropdown';

interface OrderRadioOption<K extends string> {
  option: K;
  label: string;
}

interface OrderButtonProps<K extends string> {
  order: {
    orderBy: K;
    direction: 'asc' | 'desc';
  } | null;
  handleOrder: (newOrder: { orderBy: K; direction: 'asc' | 'desc' } | null) => void;
  options: OrderRadioOption<K>[];
  disabled?: boolean;
}

const OrderButton = <K extends string>({ order, handleOrder, options, disabled = false }: OrderButtonProps<K>) => {
  const handleChange = useCallback(
    (value: K) => {
      if (String(value) === order?.orderBy) {
        handleOrder(null);
        return;
      }

      handleOrder({
        orderBy: value,
        direction: 'asc',
      });
    },
    [order]
  );

  return (
    <ButtonDropdown
      disabled={disabled}
      placement="bottom-end"
      floating={
        <Content>
          <RadioGroup
            align="column"
            name="GridOrder"
            selectedOption={order?.orderBy}
            onChange={handleChange}
            options={options}
          />
        </Content>
      }
      variant="tertiary"
      size="small"
    >
      {order ? 'Ordenação ativada' : 'Ordenação'}
    </ButtonDropdown>
  );
};

export default OrderButton;
