import React, { FunctionComponent } from 'react';
import { Layout } from '../../hooks/useLayout';
import Button from '../Button';
import { GridIcon, ListIcon } from '../../icons';

interface ToggleViewButtonProps {
  onClick: VoidFunction;
  layout: Layout;
}

const ToggleViewButton: FunctionComponent<ToggleViewButtonProps> = ({ onClick, layout }) => (
  <Button size="small" variant="tertiary" onClick={onClick} style={{ width: '100px', minWidth: 'initial' }}>
    {layout !== Layout.LIST ? <ListIcon /> : <GridIcon />}
    {layout !== Layout.LIST ? 'Lista' : 'Grid'}
  </Button>
);

export default ToggleViewButton;
