import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CustomText from '../../../../../../../components/CustomText';
import Header from '../Header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface FeedbackProps {
  title: string;
  description: string;
}

const Feedback: FunctionComponent<FeedbackProps> = ({ title, description }) => (
  <Container>
    <Header title={title} />
    <CustomText as="h1">{description}</CustomText>
  </Container>
);

export default Feedback;
