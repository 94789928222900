import type { SVGProps } from 'react';
import React from 'react';

const SvgVideoPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={58} height={58} fill="none" {...props}>
    <circle cx={29.034} cy={29.257} r={27.576} stroke="#fff" strokeWidth={1.5} />
    <path
      fill="#fff"
      d="M50.478 27.41c1.594.7 1.594 2.961 0 3.662l-32.25 14.173a2 2 0 0 1-2.804-1.831V15.067a2 2 0 0 1 2.805-1.831L50.478 27.41Z"
    />
  </svg>
);

export default SvgVideoPlay;
