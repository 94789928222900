import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BuildingData, BuildingOrder, BuildingOrderBy, LinkContract } from '../../../../../../../../../@types/building';
import CustomText from '../../../../../../../../../components/CustomText';
import Alert from '../../../../../../../../../components/Toast/toast';
import useAbortController from '../../../../../../../../../hooks/useAbortController';
import ModalPortal from '../../../../../../../../../layouts/ModalPortal';
import { NEW_BUILDING_TITLE } from '../../../../../../../../../utils/constants/text';
import { isEmptyArray } from '../../../../../../../../../utils/helpers/array';
import getOrderDirection from '../../../../../../../../../utils/helpers/getOrderDirection';
import FindContractDialog from '../../../../../../../shared/components/FindContractDialog';
import FindContractForm from '../../../../../../../shared/components/FindContractForm';
import FindContractHeader from '../../../../../../../shared/components/FindContractHeader';
import BuildingContext, { BuildingContextProps } from '../../../../context';
import BuildingTable from '../../../BuildingTable';
import { Container, ListContainer, ModalWarning, ResultContainer } from './style';
import { GTMEventName } from '../../../../../../../../../@types/analytics';
import { sendGTMEvent } from '../../../../../../../../../lib/DataLayer';

enum SearchStatus {
  NOT_FINISHED = 'NOT_FINISHED',
  FINISHED_AND_HAS_RESULT = 'FINISHED_AND_HAS_RESULT',
  NO_RESULT = 'NO_RESULT',
}

const NewContractModal = () => {
  const { fetchNewContract, surveyedContractIsAlreadyOnList, linkNewContract, searchModalIsOpen, closeSearchModal } =
    useContext(BuildingContext) as BuildingContextProps;

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<SearchStatus>(SearchStatus.NOT_FINISHED);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState<boolean>(false);
  const [newBuildings, setNewBuildings] = useState<BuildingData[]>([]);
  const { getSignal, abort } = useAbortController();
  const [order, setOrder] = useState<BuildingOrder | null>(null);

  const fetchData = async (data: LinkContract) => {
    setNewBuildings([]);
    setLoading(true);
    abort();
    setStatus(SearchStatus.NOT_FINISHED);
    const signal = getSignal();
    try {
      sendGTMEvent({ name: GTMEventName.SEARCH_NEW_CONTRACT });
      const newContract = await fetchNewContract(data, signal);
      setNewBuildings(newContract);
      setIsAlreadyRegistered(surveyedContractIsAlreadyOnList(newContract));
      if (isEmptyArray(newContract)) {
        setStatus(SearchStatus.NO_RESULT);
      } else {
        setStatus(SearchStatus.FINISHED_AND_HAS_RESULT);
      }
    } catch (e) {
      console.error(e);
      setStatus(SearchStatus.NO_RESULT);
      setIsAlreadyRegistered(false);
    } finally {
      setLoading(false);
    }
  };

  const formMethods = useForm<LinkContract>({
    mode: 'onChange',
    defaultValues: {
      document: '',
      contract: '',
    },
  });

  const onSubmit: SubmitHandler<LinkContract> = (data) => {
    fetchData(data);
  };

  const cancelRequest = () => {
    abort();
    setLoading(false);
  };

  const resetFields = () => {
    formMethods.reset();
    setNewBuildings([]);
    setIsAlreadyRegistered(false);
    setStatus(SearchStatus.NOT_FINISHED);
  };

  const closeHandler = () => {
    closeSearchModal();
    cancelRequest();
    resetFields();
  };

  const hasResult = status === SearchStatus.FINISHED_AND_HAS_RESULT;

  const submitDialogHandler = async () => {
    if (!Array.isArray(newBuildings) || newBuildings.length === 0) {
      Alert.ERROR('Não foi possível vincular o novo contrato!');
      return;
    }
    setLoadingSubmit(true);

    const formValues = formMethods.getValues();
    const data = {
      contract: formValues.contract,
      document: formValues.document,
    } as LinkContract;

    try {
      await linkNewContract(data);
      closeSearchModal();
      resetFields();
      const message =
        newBuildings.length > 1
          ? 'Contratos fixados no seu painel com sucesso!'
          : 'Contrato fixado no seu painel com sucesso!';
      Alert.SUCCESS(message);
    } catch (err) {
      const message =
        newBuildings.length > 1
          ? 'Não foi possível fixar os novos contratos no seu painel!'
          : 'Não foi possível fixar o novo contrato no seu painel!';
      Alert.ERROR(message);
      console.error(err);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const sortedData = useMemo(() => {
    if (!order) return newBuildings;

    const isAscendingOrder = order.direction === 'asc';

    return newBuildings.sort((a, b) => {
      if (order.orderBy !== 'vinculated') {
        if (a[order.orderBy] < b[order.orderBy]) {
          return isAscendingOrder ? -1 : 1;
        }
        if (a[order.orderBy] > b[order.orderBy]) {
          return isAscendingOrder ? 1 : -1;
        }
      }
      return 0;
    });
  }, [order, newBuildings]);

  const requestSort = useCallback(
    (key: BuildingOrderBy) =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    []
  );

  return (
    <ModalPortal onClose={closeHandler} visible={searchModalIsOpen} fullHeight fullWidth>
      <Container>
        <FindContractHeader
          title="Buscar novo contrato"
          onClose={closeHandler}
          content={<CustomText as="p">{NEW_BUILDING_TITLE}</CustomText>}
        />
        <FormProvider {...formMethods}>
          <FindContractForm onSubmit={onSubmit} loading={loading} />
        </FormProvider>
        <ResultContainer>
          {status === SearchStatus.NOT_FINISHED && !loading && (
            <ModalWarning obfuscate>
              <CustomText as="h1">Utilize a busca para encontrar um novo contrato.</CustomText>
            </ModalWarning>
          )}
          {status === SearchStatus.NO_RESULT && !loading && (
            <ModalWarning obfuscate>
              <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
            </ModalWarning>
          )}
          {loading && (
            <ModalWarning obfuscate>
              <CustomText as="h1">Buscando contrato...</CustomText>
            </ModalWarning>
          )}
          {hasResult && !loading && (
            <ListContainer>
              <BuildingTable
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'minmax(100px, 120px) minmax(100px, 120px) 3fr minmax(170px, 190px) 1fr 1fr',
                }}
                requestSort={requestSort}
                order={order}
                data={sortedData}
              />
            </ListContainer>
          )}
          {hasResult && (
            <FindContractDialog
              resultLength={newBuildings.length}
              loading={loadingSubmit}
              onCancel={closeHandler}
              contractIsAlreadyOnList={isAlreadyRegistered}
              onSubmit={submitDialogHandler}
            />
          )}
        </ResultContainer>
      </Container>
    </ModalPortal>
  );
};

export default NewContractModal;
