import React, { useContext, useState } from 'react';
import { GTMEventName } from '../../../../../../../@types/analytics';
import { InvoiceData } from '../../../../../../../@types/invoice';
import FileActions from '../../../../../../../components/FileActions';
import Alert from '../../../../../../../components/Toast/toast';
import { DocumentItemAction, DocumentItemType } from '../../../../../../../config/download/types';
import useDebounce from '../../../../../../../hooks/useDebounce';
import useDisclosure from '../../../../../../../hooks/useDisclosure';
import documentDialogError from '../../../../../../../layouts/DocumentErrorDialog';
import { sendGTMEvent } from '../../../../../../../lib/DataLayer';
import FinancialService from '../../../../../../../services/Financial/FinancialService';
import InvoiceSendEmailContent from '../../../../../shared/components/InvoiceSendEmailContent';
import SendEmailAction from '../../../../../shared/components/SendEmail/SendEmailAction';
import InvoiceContext, { InvoiceContextProps } from '../../context';

interface InvoiceActionsProps {
  data: InvoiceData;
  ignoreDropdown?: boolean;
}

const InvoiceActions = ({ data, ignoreDropdown = false }: InvoiceActionsProps) => {
  const { id, emittedAt, invoiceNumber } = data;
  const { handleDownloadInvoice, handlePrint } = useContext(InvoiceContext) as InvoiceContextProps;
  const { isOpen: sendEmailIsOpen, close: closeSendEmail, open: openSendEmail } = useDisclosure(false);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);

  const handleDownload = () => {
    handleDownloadInvoice(id);
  };

  const debounceHandleDownload = useDebounce(handleDownload, 300);

  const handleSendInvoiceByEmail = async (customEmail: string | null) => {
    setSendEmailIsLoading(true);
    try {
      await FinancialService.sendInvoiceByEmail(id, customEmail);
      Alert.SUCCESS('Nota fiscal enviada');
      sendGTMEvent({ name: GTMEventName.SEND_INVOICE_BY_EMAIL });
    } catch (e) {
      console.error(e);
      documentDialogError(DocumentItemType.INVOICE, DocumentItemAction.SENDING_BY_EMAIL);
    } finally {
      setSendEmailIsLoading(false);
      closeSendEmail();
    }
  };

  return (
    <>
      <SendEmailAction
        loading={sendEmailIsLoading}
        visible={sendEmailIsOpen}
        onClose={closeSendEmail}
        onSubmit={({ customEmail }) => {
          handleSendInvoiceByEmail(customEmail || null);
        }}
        title="Enviar nota fiscal por e-mail."
        submitButtonLabel="Enviar nota fiscal"
        selectLabel="Escolha a melhor forma para lhe enviarmos a nota fiscal:"
        description={<InvoiceSendEmailContent invoiceNumber={invoiceNumber} emittedAt={emittedAt} />}
      />
      <FileActions
        ignoreDropdown={ignoreDropdown}
        download={{
          handler: debounceHandleDownload,
        }}
        email={{
          handler: openSendEmail,
        }}
        print={{
          handler: () => handlePrint(id),
        }}
      />
    </>
  );
};

export default InvoiceActions;
