import styled, { css } from 'styled-components';
import theme from '../../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  flex: 1;
  justify-content: space-between;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;

export const OpenFilter = styled.button<{ isActive?: boolean }>`
  width: 100%;
  padding: 8px 24px;
  gap: 8px;
  height: 35px;
  cursor: pointer;

  background: #ffffff;
  border: 0.5px solid #e9e9e9;
  box-shadow: 0 1px 1px rgba(50, 43, 80, 0.08);

  ${({ isActive }) =>
    isActive &&
    css`
      border: 0.5px solid ${theme.color.secondary.purple['2']};
      color: ${theme.color.primary.interaction};
    `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0;
  padding: 24px 16px;
  height: 100%;
  justify-content: space-between;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .buttons {
    display: flex;
    gap: 8px;

    @media (max-width: 520px) {
      flex-direction: column;
    }
  }

  .Align_Container {
    gap: 24px;
  }
`;
