import { AxiosRequestConfig } from 'axios';
import AxiosInstance from './config';
import { executeRefreshToken } from './refreshToken';

let isRefreshing = false;
let sessionStarted: boolean;

const requestConfig = (
  config: AxiosRequestConfig<unknown>
): AxiosRequestConfig<unknown> | Promise<AxiosRequestConfig<unknown>> => {
  if (sessionStarted || isRefreshing) {
    return config;
  }
  isRefreshing = true;

  return new Promise((resolve) => {
    executeRefreshToken().finally(() => {
      sessionStarted = true;
      isRefreshing = false;
      resolve(config);
    });
  });
};

AxiosInstance.interceptors.request.use(requestConfig);
