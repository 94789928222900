export type RegisterPayload = {
  login: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

export enum RegisterStatus {
  USER_INCOMPLETE_DATA = 'USER_INCOMPLETE_DATA',
  USER_ALREADY_ACTIVATED_AND_REGISTERED = 'USER_ALREADY_ACTIVATED_AND_REGISTERED',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  NEW_USER = 'NEW_USER',
}
