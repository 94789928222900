import React, { FunctionComponent } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { PasswordTips } from './style';

export const PasswordSecurityWarning = () => (
  <>
    <PasswordTips>Mínimo 8 caracteres, contendo:</PasswordTips>
    <PasswordTips>
      1 letra maiúscula / 1 letra minúsculas / 1 número / 1 caractere especial (!, @, #, &, ...).
    </PasswordTips>
  </>
);

export const PasswordErrorHelper: FunctionComponent<{ error: FieldError }> = ({ error }) => (
  <>
    {error?.type === 'newPasswordSameAsCurrent' && (
      <PasswordTips error>A nova senha não pode ser igual a atual.</PasswordTips>
    )}
    {error?.type === 'required' && <PasswordTips error>Campo obrigatório.</PasswordTips>}
    {error?.type === 'pattern' && (
      <>
        <PasswordTips error>Senha insegura, por favor digite:</PasswordTips>
        <PasswordTips error>
          8 caracteres / 1 letra maiúscula / 1 letra minúscula / 1 número / 1 caractere especial (!, @, #, &, ...).
        </PasswordTips>
      </>
    )}
  </>
);
