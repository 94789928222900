import styled, { css } from 'styled-components';
import theme from '../../../../styles/theme';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .years {
    display: grid;
    grid-template-columns: repeat(5, 70px);
    width: 100%;
    justify-content: space-between;
    grid-row-gap: 12px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    .years {
      grid-template-columns: repeat(3, 70px);
      grid-row-gap: 2px;
    }
  }
`;

export const YearButton = styled.button<{ isActive: boolean }>`
  border: none;
  background: none;
  outline: none;
  padding: 10px;
  cursor: pointer;

  font-size: 14px;
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.color.primary.interaction};
      color: #fff;
    `}
`;
