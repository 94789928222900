import React, { PropsWithChildren, ReactNode } from 'react';

import useSelectEmail from '../../../../../../hooks/useSelectEmail';
import { useWindowDimensions } from '../../../../../../hooks/useWindowDimensions';
import AsidePortal from '../../../../../../layouts/AsidePortal';
import ModalPortal from '../../../../../../layouts/ModalPortal';
import DesktopSendEmail from '../shared/components/DesktopSendEmail';
import MobileSendEmail from '../shared/components/MobileSendEmail';
import { SendEmailOnSubmitFn } from './types';

type SendEmailActionProps = {
  visible: boolean;
  onClose: VoidFunction;
  onSubmit: SendEmailOnSubmitFn;
  title: string;
  submitButtonLabel: string;
  selectLabel: string;
  description: ReactNode | null | undefined;
  loading: boolean;
};

const SendEmailAction = ({
  visible,
  onClose,
  onSubmit,
  submitButtonLabel,
  title,
  children,
  selectLabel,
  description,
  loading,
}: PropsWithChildren<SendEmailActionProps>) => {
  const { isMobileWidth } = useWindowDimensions();
  const selectEmailProps = useSelectEmail();

  const handleSubmit = () => {
    if (selectEmailProps.selectedOption === 'custom_email') {
      if (!selectEmailProps.customEmail) {
        selectEmailProps.setCustomEmailError('E-mail inválido');
        return;
      }
    }

    onSubmit({
      selectedOption: selectEmailProps.selectedOption,
      customEmail: selectEmailProps.customEmail,
    });
  };

  const handleClose = () => {
    selectEmailProps.clearValues();
    onClose();
  };

  return isMobileWidth ? (
    <AsidePortal visible={visible}>
      <MobileSendEmail
        {...selectEmailProps}
        submitButtonLabel={submitButtonLabel}
        onSubmit={handleSubmit}
        onClose={handleClose}
        title={title}
        description={description}
        selectLabel={selectLabel}
        loading={loading}
      >
        {children}
      </MobileSendEmail>
    </AsidePortal>
  ) : (
    <ModalPortal onClose={handleClose} visible={visible}>
      <DesktopSendEmail
        {...selectEmailProps}
        description={description}
        selectLabel={selectLabel}
        submitButtonLabel={submitButtonLabel}
        title={title}
        onSubmit={handleSubmit}
        onClose={handleClose}
        loading={loading}
      >
        {children}
      </DesktopSendEmail>
    </ModalPortal>
  );
};

export default SendEmailAction;
