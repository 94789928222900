import styled from 'styled-components';
import theme from '../../../../../../styles/theme';
import { BREAKPOINTS } from '../../../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #434343;
  box-shadow: 0 0 14px ${theme.color.secondary.grey[5]};
  min-width: 175px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}px) {
    border: none !important;
    box-shadow: unset;
    gap: 24px;

    .header {
      font-weight: 500;
      font-size: 18px;
    }

    .type {
      font-size: 16px !important;
    }

    .name {
      font-size: 12px !important;
    }

    .External_Link {
      & > a {
        font-size: 14px !important;
      }
    }
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0;
  color: ${theme.color.primary.interaction};

  .type {
    font-style: normal;
    font-weight: 500;
    color: #262626;
  }

  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    color: #262626;
  }

  .External_Link {
    width: fit-content;
    justify-content: space-between;
    align-items: center;

    .external_icon {
      width: 10px;
      height: 10px;
    }

    & > a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      text-decoration-line: underline;
    }
  }
`;

export const PhoneWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
