import React from 'react';
import Checkbox from '../../../../../../../components/SelectableButton/Checkbox';
import Tooltip from '../../../../../../../components/Floating/components/Tooltip';
import { HTMLCheckboxProps } from '../../../../../../../@types/html';
import { BillStatus, DisabledBillStatus } from '../../../../../../../@types/bill';

const mapInfo = {
  [BillStatus.PAID]: 'Pagamento já compensado',
  [BillStatus.NOT_GENERATED]: 'Pagamento sem boleto',
  [BillStatus.EXPIRED]: 'Pagamento expirado',
};

const BillCheckbox = ({ disabled, onChange, checked, status }: HTMLCheckboxProps & { status: BillStatus }) => {
  if (disabled) {
    return (
      <Tooltip info={mapInfo[status as unknown as DisabledBillStatus]}>
        <Checkbox checked={false} disabled />
      </Tooltip>
    );
  }

  return <Checkbox onChange={onChange} checked={checked} />;
};

export default BillCheckbox;
