import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import theme from '../../styles/theme';

export const ToasterContentWrapper = styled.div<{ $color: string }>`
  display: flex;
  width: 100%;
  padding: 24px 0 24px 16px;
  align-items: center;
  gap: 16px;

  border-left: 5px solid ${({ $color }) => $color};
  color: ${({ $color }) => $color};

  p {
    max-width: 245px;
    font-family: 'TKEType-Medium', sans-serif;
    font-size: 14px;
    color: ${theme.color.primary.black} !important;
  }

  .spinner {
    width: 16px;
    height: 16px;
  }

  .spinner_path {
    stroke-width: 5px;
  }
`;

export const StyledToast = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
  id: 'toast',
})`
  z-index: ${theme.defaults.alertZIndex};
  padding-top: 21px;
  padding-right: 19px;

  .toast {
    background: #ffffff;
    box-shadow: 0 0 14px rgba(207, 207, 207, 0.68);
    padding: 0;
    border-radius: 0;
  }

  .body {
    padding: 0;
  }
`;
