import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFeedbackError = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M7.797 10.278H9.13v1.333H7.797v-1.333Zm0-5.333H9.13v4H7.797v-4Zm.66-3.334a6.663 6.663 0 0 0-6.66 6.667c0 3.68 2.98 6.666 6.66 6.666a6.67 6.67 0 0 0 6.673-6.666A6.67 6.67 0 0 0 8.457 1.61Zm.007 12A5.332 5.332 0 0 1 3.13 8.278a5.332 5.332 0 0 1 5.334-5.333 5.332 5.332 0 0 1 5.333 5.333 5.332 5.332 0 0 1-5.333 5.333Z"
    />
  </svg>
);
export default SvgFeedbackError;
