import React, { ReactNode } from 'react';
import { WrapperButton } from '../../styles/components';
import CustomText from '../CustomText';
import { HTMLButtonProps } from '../../@types/html';

interface TextIconButtonProps extends HTMLButtonProps {
  label: string;
  icon: ReactNode;
}

const TextIconButton = ({ label, icon, type, ...props }: TextIconButtonProps) => (
  <WrapperButton
    {...props}
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    }}
    cursor="pointer"
  >
    <CustomText>{label}</CustomText>
    {icon}
  </WrapperButton>
);

export default TextIconButton;
