import React from 'react';
import { Helmet } from 'react-helmet-async';

const script = `(function(I,n,f,o,b,i,p){ I[b]=I[b]||function(){(I[b].q=I[b].q||[]).push(arguments)}; I[b].t=1*new Date();i=n.createElement(f);i.async=1;i.src=o; p=n.getElementsByTagName(f)[0];p.parentNode.insertBefore(i,p)}) (window,document,'script','https://livechat.infobip.com/widget.js','liveChat'); liveChat('init', '98492b0a-038e-494a-970e-e478cf5baf2b');`;

const Chatbot = () => (
  <Helmet>
    <script type="text/javascript">{script}</script>
  </Helmet>
);

export default Chatbot;

// const tagManagerScript = ``;
//
// const TagManager = () => {
//   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     return null;
//   }
//
//   return (
//     <Helmet>
//       <script type="text/javascript">{script}</script>
//     </Helmet>
//   );
// };
