import { useCallback, useState } from 'react';
import { isEmptyArray } from '../utils/helpers/array';
import { normalizeString, removeSpecialCharacters } from '../utils/helpers/string';

export const filterTermsOnArray = (searchTerm: string, termsToFind: Array<string>) =>
  termsToFind.some(
    (word) => removeSpecialCharacters(word).includes(searchTerm) || searchTerm === removeSpecialCharacters(word)
  );

interface UseSearchProps<T extends unknown, SK extends string> {
  data: Array<T>;
  allSearchKeys: Array<SK>;
  searchBy: (searchByKeys: Array<SK>, searchBuildingData: T) => string[];
}

const useSearch = <T extends unknown, SK extends string>({ allSearchKeys, searchBy }: UseSearchProps<T, SK>) => {
  const [search, setSearch] = useState<string>('');
  const [searchKeys, setSearchKeys] = useState<Array<SK>>([]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const applySearch = (dataToTransform: T[]) => {
    if (!search) return dataToTransform;

    const searchFilter = search.split(' ').map(normalizeString);
    const actualSearchKeys = isEmptyArray(searchKeys) ? allSearchKeys : searchKeys;
    return dataToTransform.filter((buildingData) => {
      const allTermsToFind = searchBy(actualSearchKeys, buildingData).map(normalizeString);
      return searchFilter.every((searchTerm) => filterTermsOnArray(searchTerm, allTermsToFind));
    });
  };

  const handleSearch = useCallback(
    (newSearch: string) => {
      setSearch(removeSpecialCharacters(newSearch));
    },
    [search, searchKeys]
  );

  const handleSearchKeys = useCallback(
    (newSearchKeys: Array<SK>) => {
      setSearchKeys(newSearchKeys);
    },
    [search, searchKeys]
  );

  return {
    search,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    clearSearch,
    applySearch,
  };
};
export default useSearch;
