import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { StyledTabs } from './style';
import CustomText from '../CustomText';
import { Tab } from '../../@types/dashboard';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const transitionTime = 200;
const transitionStyle = `left ${transitionTime}ms, right ${transitionTime}ms`;

interface TabsProps<T extends string> {
  selectedTab: T;
  tabs: Array<Tab<T>>;
  onChange: (value: T) => void;
}

type Sizes = Record<string, { left: number; right: number }>;

const Tabs = <T extends string>({ selectedTab, children, onChange, tabs }: PropsWithChildren<TabsProps<T>>) => {
  const [sizes, setSizes] = useState<Sizes>({});
  const rootRef = useRef<HTMLDivElement>(null);
  const elsRef = useRef<Record<string, HTMLDivElement>>({});
  const { width, height } = useWindowDimensions();

  const getSizes = () => {
    const rootBounds = rootRef?.current?.getBoundingClientRect();
    const newSizes: Sizes = {};

    if (rootBounds) {
      Object.keys(elsRef.current).forEach((key) => {
        const el = elsRef.current[key as T];
        const bounds = el.getBoundingClientRect();

        const left = bounds.left - rootBounds.left;
        const right = rootBounds.right - bounds.right;

        newSizes[key] = { left, right };
      });
    }

    setSizes(newSizes);
    return newSizes;
  };

  useEffect(() => {
    getSizes();
  }, [children, selectedTab, width, height]);

  const getGliderStyle = () => {
    if (selectedTab == null || Object.keys(sizes).length === 0) {
      return { left: '0', right: '100%' };
    }

    const size = sizes[selectedTab];

    return {
      left: `${size.left}px`,
      right: `${size.right}px`,
      transition: transitionStyle,
    };
  };

  return (
    <StyledTabs className="Tabs" ref={rootRef}>
      {tabs.map(({ accessor, label }, index) => {
        let className = `Tabs__Tab`;
        const isActive = accessor === selectedTab;

        if (isActive) {
          className = `${className} Tabs__Tab--active`;
        }

        return (
          <div
            key={`Tab_${index}_${accessor}`}
            className={className}
            onClick={() => {
              onChange(accessor);
            }}
            onKeyDown={() => {
              onChange(accessor);
            }}
            role="tab"
            aria-selected={isActive}
            aria-controls={`tab-panel-${accessor}`}
            tabIndex={0}
            ref={(el) => {
              if (el) {
                elsRef.current[accessor] = el;
              }
            }}
          >
            <CustomText key={accessor}>{label}</CustomText>
          </div>
        );
      })}
      <div className="pre_glider" />
      <div className="glider" style={getGliderStyle()} />
    </StyledTabs>
  );
};

export default Tabs;
