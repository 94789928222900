import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 6px;
  gap: 8px;
  height: 30px;
  box-sizing: border-box;
  min-width: 230px;

  p {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    width: 100%;
  }
`;
