import styled from 'styled-components';
import theme from '../../../../../styles/theme';
import CustomText from '../../../../../components/CustomText';

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  flex: 1;
  justify-content: space-between;

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 640px;
  box-sizing: border-box;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  button {
    width: fit-content;
  }
`;

export const SelectEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  margin-top: 24px;

  .__modal_select_title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }

  .Align_Container {
    gap: 16px;
  }

  .__align_input {
    margin-left: 34px;
  }

  .highlight_email {
    color: ${theme.color.secondary.purple[1]};
  }
`;

export const SelectDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 32px;

  .__modal_select_title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }

  .Align_Container {
    gap: 16px;
  }

  .__align_input {
    margin-left: 34px;
  }

  .highlight_email {
    color: ${theme.color.secondary.purple[1]};
  }
`;

export const ErrorMessage = styled(CustomText)`
  color: ${theme.color.feedback.error};
`;
