import styled from 'styled-components';
import { UserIcon } from '../../style';

export const Content = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .name {
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #262626;
  }

  .email {
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
  }
`;

export const ContentUserIcon = styled(UserIcon)`
  width: 64px;
  height: 64px;
  cursor: initial;

  font-style: normal;
  font-weight: 400;
  font-size: 31px;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;
