import React, { ReactElement, Ref } from 'react';
import { GroupBase, Props } from 'react-select';
import { SelectInstance } from 'react-select/dist/declarations/src';
import { StyledSelect } from './style';
import CustomText from '../CustomText';
import { isEmptyArray } from '../../utils/helpers/array';

const NoOptionsMessage = () => <CustomText style={{ fontSize: '14px' }}>Sem opções</CustomText>;
function TextSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group>,
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
) {
  return (
    <StyledSelect
      {...props}
      classNames={{ control: (state) => (isEmptyArray([...state.options]) ? '--is-empty' : '') }}
      placeholder="Selecione..."
      classNamePrefix="Select"
      noOptionsMessage={NoOptionsMessage}
      isSearchable={false}
      ref={ref}
      menuPosition="fixed"
    />
  );
}

export default React.forwardRef(TextSelect) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  p: Props<Option, IsMulti, Group> & { ref?: Ref<SelectInstance<Option, IsMulti, Group>> }
) => ReactElement;
