import styled from 'styled-components';
import { WarningContainer } from '../../../../../../style';

export const ListContainer = styled.div`
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 48px);
  padding: 24px 40px;

  @media (max-width: 1400px) {
    padding: 24px 32px;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

export const CalloutContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ModalWarning = styled(WarningContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
`;

export const DialogActions = styled.div`
  display: flex;
  gap: 16px;
`;
