import { ReactNode } from 'react';
import EventManager from '../../lib/EventManager';

export interface DialogPayload {
  title: string;
  content: ReactNode;
}

export const dialogEventManager = new EventManager<DialogPayload>();

export default function dialog(payload: DialogPayload) {
  dialogEventManager.emit('open', payload);
}
