import React, { useEffect } from 'react';
import ModalPortal from '../../../ModalPortal';
import ModalHeader from '../../../../components/ModalHeader';
import CustomText from '../../../../components/CustomText';
import { ModalContainer } from '../../style';
import { Divisor, WrapperDiv } from '../../../../styles/components';
import { DocumentItemType, ErrorItem } from '../../../../config/download/types';
import Accordion from '../../../../components/Accordion';
import UnstyledWrapperWithFloating from '../../../../components/Floating/components/UnstyledWrapperWithFloating';
import { FeedbackErrorIcon } from '../../../../icons';
import { ErrorItemFloating, ErrorItemLabel } from './style';
import Button from '../../../../components/Button';
import BillErrorMessage from './components/BillErrorMessage';
import InvoiceErrorMessage from './components/InvoiceErrorMessage';

interface ErrorDownloadModalProps {
  visible: boolean;
  onClose: VoidFunction;
  errors: Map<string, Map<DocumentItemType, ErrorItem[]>>;
}

const mapDocumentTypeToTitle = {
  [DocumentItemType.BILL]: 'Boleto',
  [DocumentItemType.INVOICE]: 'Nota fiscal',
};

export const mapDocumentTypeToDescription = {
  [DocumentItemType.BILL]: <BillErrorMessage />,
  [DocumentItemType.INVOICE]: <InvoiceErrorMessage />,
};

const ErrorDownloadModal = ({ visible, onClose, errors }: ErrorDownloadModalProps) => {
  useEffect(
    () => () => {
      onClose();
    },
    []
  );

  return (
    <ModalPortal onClose={onClose} visible={visible}>
      <ModalContainer>
        <WrapperDiv gap={8} flexDirection="column">
          <ModalHeader title="Falha em alguns arquivos download" onClose={onClose} />
          <CustomText style={{ alignSelf: 'stretch' }}>
            Verifique o motivo abaixo ou entre em contato pelo chat ou pelo{' '}
            <CustomText as="span" bold>
              WhatsApp
              <br />
              (51) 99977-9006
            </CustomText>{' '}
            com o{' '}
            <CustomText as="span" bold>
              Departamento Financeiro na opção &quot;Demais Solicitações&quot;.
            </CustomText>{' '}
          </CustomText>
        </WrapperDiv>
        <Accordion.Root gap={16}>
          {Array.from(errors).map(([key, errorMap]) => (
            <Accordion.Item key={`download-accordion-${key}`}>
              {({ onToggleAccordion, isActive }) => (
                <>
                  <Accordion.Header active={isActive} onToggleAccordion={onToggleAccordion}>
                    <CustomText style={{ fontSize: 18 }} medium>
                      {key}
                    </CustomText>
                  </Accordion.Header>
                  <Accordion.Content active={isActive}>
                    <WrapperDiv gap={16} flexDirection="column" alignItems="left">
                      <Divisor />
                      {Array.from(errorMap).map(([documentType, errorItems]) => (
                        <React.Fragment key={`download-item-${documentType}`}>
                          <CustomText style={{ fontSize: 18 }} medium>
                            {mapDocumentTypeToTitle[documentType]}
                          </CustomText>
                          <WrapperDiv style={{ width: 'fit-content' }}>
                            {errorItems.map((error) => (
                              <UnstyledWrapperWithFloating
                                key={`download-item-info-${error.id}-${documentType}`}
                                content={
                                  <ErrorItemLabel>
                                    <FeedbackErrorIcon />
                                    <CustomText crop>{error.description}</CustomText>
                                  </ErrorItemLabel>
                                }
                                floating={
                                  <ErrorItemFloating>{mapDocumentTypeToDescription[documentType]}</ErrorItemFloating>
                                }
                              />
                            ))}
                          </WrapperDiv>
                        </React.Fragment>
                      ))}
                    </WrapperDiv>
                  </Accordion.Content>
                </>
              )}
            </Accordion.Item>
          ))}
        </Accordion.Root>
        <WrapperDiv mt={140} justifyContent="flex-end">
          <Button variant="primary" onClick={onClose}>
            Voltar
          </Button>
        </WrapperDiv>
      </ModalContainer>
    </ModalPortal>
  );
};

export default ErrorDownloadModal;
