import React from 'react';
import dialog from '../../components/Dialog/dialog';
import { DocumentItemAction, DocumentItemType } from '../../config/download/types';
import CustomText from '../../components/CustomText';

const BillErrorMessage = () => (
  <CustomText>
    Falha no download, tente novamente em alguns instantes ou entre em contato pelo chat ou pelo WhatsApp 51 99977 9006
    com o Departamento Financeiro na opção &quot;Demais Solicitações&quot;.
  </CustomText>
);

const InvoiceErrorMessage = () => (
  <CustomText>
    Isso pode ocorrer caso ela tenha sido emitida recentemente. Tente novamente em alguns instantes ou se for o caso,
    pedimos que aguarde o prazo de 3 dias para processamento.
    <br />
    <br />
    Caso contrário entre em contato pelo chat ou pelo WhatsApp 51 99977 9006 com o Departamento Financeiro na opção
    &quot;Demais Solicitações&quot;.
  </CustomText>
);

const mapDocumentTypeToTitle = {
  [DocumentItemType.BILL]: {
    [DocumentItemAction.DOWNLOAD]: 'Não conseguimos disponibilizar seu boleto',
    [DocumentItemAction.SENDING_BY_EMAIL]: 'Não conseguimos enviar o boleto por e-mail.',
    [DocumentItemAction.PRINT]: 'Não conseguimos carregar a visualização da impressão do boleto.',
  },
  [DocumentItemType.INVOICE]: {
    [DocumentItemAction.DOWNLOAD]: 'Não conseguimos disponibilizar sua nota fiscal',
    [DocumentItemAction.SENDING_BY_EMAIL]: 'Não conseguimos enviar a nota fiscal por e-mail.',
    [DocumentItemAction.PRINT]: 'Não conseguimos carregar a visualização da impressão da nota fiscal.',
  },
};

export const mapDocumentTypeToDescription = {
  [DocumentItemType.BILL]: <BillErrorMessage />,
  [DocumentItemType.INVOICE]: <InvoiceErrorMessage />,
};

export default function documentDialogError(type: DocumentItemType, action: DocumentItemAction) {
  dialog({
    title: mapDocumentTypeToTitle[type][action],
    content: mapDocumentTypeToDescription[type],
  });
}
