import React, { FunctionComponent } from 'react';
import { Header } from './style';
import { ArrowIcon, LogoWithoutLabelIcon } from '../../icons';
import { WrapperButton } from '../../styles/components';
import theme from '../../styles/theme';

interface AsideHeaderProps {
  onBack: VoidFunction;
}

const AsideHeader: FunctionComponent<AsideHeaderProps> = ({ onBack }) => (
  <Header>
    <LogoWithoutLabelIcon width={67} height={28} />
    <WrapperButton onClick={onBack} cursor="pointer">
      <ArrowIcon color={theme.color.primary.interaction} />
    </WrapperButton>
  </Header>
);

export default AsideHeader;
