import { createContext } from 'react';
import { ActionsType } from './type';

export interface AccountContextProps {
  open: boolean;
  closeAction: VoidFunction;
  openAction: (newType: ActionsType) => void;
  type?: ActionsType;
  isMobileWidth: boolean;
}

const AccountContext = createContext<AccountContextProps | null>(null);

export default AccountContext;
