// Checks if the current time is within daylight saving time (DST - Daylight Saving Time)
function isDST() {
  const now = new Date();
  const january = new Date(now.getFullYear(), 0, 1);
  const july = new Date(now.getFullYear(), 6, 1);
  return now.getTimezoneOffset() < Math.max(january.getTimezoneOffset(), july.getTimezoneOffset());
}

const normalizeTimeZone = (date: Date) => {
  const userOffset = new Date().getTimezoneOffset();
  const adjustedOffset = userOffset + (isDST() ? 60 : 0); // Adjust for DST if needed
  const localTimeInMs = date.getTime() - adjustedOffset * 60 * 1000;
  return new Date(localTimeInMs);
};

export const formatDate = (date: Date, locale = 'pt') =>
  new Intl.DateTimeFormat(locale, { timeZone: 'UTC' }).format(normalizeTimeZone(date));

export const formatHour = (date: Date, locale = 'pt') =>
  new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(normalizeTimeZone(date));

export const getMonthName = (date: Date) => {
  const DateFormat = new Intl.DateTimeFormat('pt', {
    timeZone: 'UTC',
    month: 'long',
  });

  return DateFormat.format(normalizeTimeZone(date));
};

export const getWeekday = (date: Date) => {
  const DateFormat = new Intl.DateTimeFormat('pt', {
    timeZone: 'UTC',
    weekday: 'short',
  });

  const formattedDate = DateFormat.formatToParts(normalizeTimeZone(date))[0].value;

  return formattedDate.charAt(0);
};

export const addMonths = (date: Date, months: number) => {
  const copyDate = new Date(date);
  const targetMonth = date.getMonth() + months;
  copyDate.setMonth(targetMonth);
  return copyDate;
};

export const subMonths = (date: Date, months: number) => {
  const copyDate = new Date(date);
  const targetMonth = date.getMonth() - months;
  copyDate.setMonth(targetMonth);
  return copyDate;
};
