import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../../../../utils/constants/breakpoints';

export const Container = styled.div<{ hide: boolean }>`
  color: #ffffff;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 64px;
  background: linear-gradient(
    45deg,
    #7000bd 18.55%,
    #7a09ae 21.25%,
    #9a2382 30.24%,
    #b5395d 39.23%,
    #cb4c3e 49.12%,
    #dc5a26 59.01%,
    #e96415 68.9%,
    #f06a0b 80.58%,
    #f26c08 94.97%
  );

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
    padding: 60px 40px 24px 40px;
    background: none;
  }
`;

export const BracketBox = styled.div`
  position: relative;
  width: fit-content;
  box-sizing: border-box;
  padding: 44px 44px 52px 50px;
  display: flex;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: none;
  }

  &:after {
    z-index: 999;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    border: 4px white solid;
    border-right: none;
    border-bottom: none;
  }

  &:before {
    z-index: 999;
    content: '';
    position: absolute;
    bottom: 24px;
    right: 0;
    width: 36px;
    height: 36px;
    border: 4px white solid;
    border-left: none;
    border-top: none;
  }
`;

export const Greetings = styled.h1`
  font-family: 'TKEMono-Book', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 2.2rem;
  text-transform: uppercase;

  & > span {
    display: block;
    font-size: 6.8rem;
    margin-top: 8px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    gap: 40px;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    text-align: center;
  }
`;

export const FooterTitle = styled.p`
  font-family: 'TKEType-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  color: #ffffff;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    font-size: 32px;
    line-height: 1;
    color: #262626;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 10%;

  .External_Link > a {
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
    gap: 64px;
  }
`;

export const MobileInfo = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    align-items: center;
  }
`;

export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  font-family: 'TKEType-Regular', sans-serif;
  color: #ffffff;

  .External_Link > a {
    font-family: 'TKEType-Medium', sans-serif;
  }

  .mobile-app-name {
    font-family: 'TKEType-Medium', sans-serif;
    font-size: 1.6rem;
    line-height: 21px;
  }

  .mobile-download-info {
    margin-top: 2px;
    font-family: 'TKEType-Medium', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    color: #121212;
    gap: 16px;

    .mobile-app-name {
      font-size: 20px;
      line-height: 1.3;
      color: #262626;
    }

    .mobile-download-info {
      display: none;
    }
  }
`;

export const ResponsiveMobileDownloads = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const MobileDownloads = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: none;
  }
`;

export const IntegrationLinks = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  width: 350px;
  justify-content: space-between;
  color: #ffffff;

  .External_Link {
    height: fit-content;
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #121212;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    align-items: center;
  }
`;
