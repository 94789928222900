import { useState } from 'react';
import { getFromLocalStorage, LocalStorageKey, saveToLocalStorage } from '../utils/helpers/storage';

const useLocalStorage = <T>(storageKey: LocalStorageKey, initialState: T): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = getFromLocalStorage(storageKey);
      if (value) {
        return JSON.parse(value);
      }
      saveToLocalStorage(storageKey, JSON.stringify(initialState));
      return initialState;
    } catch (err) {
      console.warn(`Error reading localStorage key “${storageKey}”:`, err);
      return initialState;
    }
  });

  const setValue = (value: T) => {
    try {
      saveToLocalStorage(storageKey, JSON.stringify(value));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(value);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
