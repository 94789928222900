import styled, { css } from 'styled-components';
import theme from '../../../../styles/theme';

export const MobileTooltipButton = styled.button`
  display: flex;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  white-space: nowrap;

  // Reset button styles
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;

  &[disabled] {
    ${theme.defaults.disabled}
  }
`;

export const Container = styled.div<{ disabled?: boolean }>`
  .tooltip_button {
    color: ${theme.color.primary.interaction};
  }

  .tooltip_button_open {
    color: ${theme.color.secondary.orange[1]};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      .tooltip_button {
        color: ${theme.color.secondary.grey[3]};
      }
    `}

  .__chevron-icon {
    transform: rotate(90deg);
  }
`;
