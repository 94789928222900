import { GTMEvent, GTMEventData, GTMEventName } from '../@types/analytics';
import { User } from '../@types/auth';
import { repeat } from '../utils/helpers/array';
import { LocalStorageKey, getFromLocalStorage } from '../utils/helpers/storage';

type SignedEventData<T extends GTMEventName> = {
  userId: string;
} & GTMEventData[T];

const getUserData = () => {
  try {
    const value = getFromLocalStorage(LocalStorageKey.USER);
    if (value) {
      return JSON.parse(value) as User;
    }

    return null;
  } catch (err) {
    console.warn(`Error reading localStorage key “${LocalStorageKey.USER}”:`, err);
    return null;
  }
};

const handleSendEvent = <T extends GTMEventName>(event: GTMEvent<T>) => {
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }

  const userData = getUserData();

  let eventData = 'data' in event ? event.data : {};
  const eventName = event.name;

  if (userData) {
    eventData = {
      ...eventData,
      userId: userData?.id,
    } as SignedEventData<T>;
  }

  window.dataLayer.push({
    event: eventName,
    ...eventData,
  });
};

function sendMultipleGTMEvents<T extends GTMEventName>(event: GTMEvent<T>, amount: number) {
  repeat(handleSendEvent, amount, event);
}

function sendGTMEvent<T extends GTMEventName>(event: GTMEvent<T>) {
  handleSendEvent(event);
}

export { sendGTMEvent, sendMultipleGTMEvents };
