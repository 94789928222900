import React from 'react';
import { Container, SkeletonBox } from '../../style';
import FormHeaderSkeleton from '../FormHeaderSkeleton';
import InputSkeleton from '../InputSkeleton';
import { WrapperDiv } from '../../../../styles/components';
import { SkeletonForm } from './style';

const SkeletonLogin = () => (
  <Container>
    <FormHeaderSkeleton />
    <SkeletonForm>
      <InputSkeleton />
      <InputSkeleton />
      <WrapperDiv gap={16}>
        <SkeletonBox height={44} />
        <SkeletonBox height={44} />
      </WrapperDiv>
    </SkeletonForm>
  </Container>
);

export default SkeletonLogin;
