import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignalRHubName } from '../config/signalR/types';
import useHub from '../config/signalR/hooks/useHub';
import { createOpenDocumentEndpoint, downloadMethodsByHub } from '../config/download/constants';
import { dashboardRoutesByUserType } from '../utils/helpers/permissions';
import { useAuth } from '../context/AuthContext';
import documentDialogError from '../layouts/DocumentErrorDialog';
import { DocumentItemAction, DocumentItemType, SignalRDocumentResponse } from '../config/download/types';

const useHandleOpenDocument = (hubName: SignalRHubName, documentType: DocumentItemType) => {
  const { registerListener, removeListener, singleSend } = useHub(hubName);
  const navigate = useNavigate();
  const { getUserRole } = useAuth();

  const openDocument = (id: string) => {
    singleSend([downloadMethodsByHub[hubName].send, JSON.stringify([id])]);
  };

  useEffect(() => {
    function receiveDocumentToPrint(item: SignalRDocumentResponse) {
      if (item.success) {
        window.location.replace(createOpenDocumentEndpoint(hubName, item.id));
      } else {
        navigate(dashboardRoutesByUserType[getUserRole()]);
        documentDialogError(documentType, DocumentItemAction.PRINT);
      }
    }

    registerListener(downloadMethodsByHub[hubName].on, receiveDocumentToPrint);

    return () => {
      removeListener(downloadMethodsByHub[hubName].on, receiveDocumentToPrint);
    };
  }, []);

  return {
    openDocument,
  };
};

export default useHandleOpenDocument;
