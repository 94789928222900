import axios from 'axios';
import getEnvironment from '../../utils/helpers/getEnvironment';

const { BASE_URL, CLIENT_ID } = getEnvironment();

const buildUrl = () => {
  let baseUrl = BASE_URL;

  if (baseUrl!.endsWith('/')) {
    baseUrl = baseUrl!.slice(0, -1);
  }

  return baseUrl;
};

const AxiosInstance = axios.create({
  baseURL: buildUrl(),
  headers: { client_id: CLIENT_ID },
});

export default AxiosInstance;
