import { createContext } from 'react';
import { FormStage } from '../../../../type';

export interface PhoneActionContextProps {
  setStage: SetState<FormStage>;
  stage: FormStage;
  selectedPhone: string;
  setSelectedPhone: SetState<string>;
}

const PhoneActionContext = createContext<PhoneActionContextProps | null>(null);

export default PhoneActionContext;
