import { unmaskValue } from './validate';

export function caretUnknownFormatBoundary(formattedValue: string) {
  return Array.from({ length: formattedValue.length + 1 }).map(() => true);
}

export function removeFormatting(formattedValue: string) {
  return unmaskValue(formattedValue);
}

export function isNumber(value: unknown): value is number {
  if (value === null || value === undefined) return false;
  return typeof value === 'number';
}
