import * as React from 'react';
import type { SVGProps } from 'react';

const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M11.236 7.769v2.313h3.212c-.299 1.47-1.55 2.315-3.212 2.315a3.54 3.54 0 0 1 0-7.079 3.463 3.463 0 0 1 2.205.79l1.743-1.743a5.986 5.986 0 1 0-3.948 10.481c2.994 0 5.717-2.178 5.717-5.989a5.009 5.009 0 0 0-.134-1.09l-5.583.002Z"
    />
  </svg>
);
export default SvgGoogle;
