import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as TKELogo } from '../../../../assets/svg/logo_without_label.svg';
import UserShortProfile from '../UserShortProfile';
import TopbarContext, { TopbarContextProps } from '../../context';
import Floating from '../../../../components/Floating';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import UserProfile from '../UserProfile';
import { UserIcon } from '../../style';
import { HeaderContainer, HeaderWrapper, UserProfileWrapper } from './style';
import { useAuth } from '../../../../context/AuthContext';
import { dashboardRoutesByUserType } from '../../../../utils/helpers/permissions';
import { HamburgerIcon } from '../../../../icons';
import { WrapperButton } from '../../../../styles/components';

const Header = () => {
  const { openSidebar, userAcronym, openUserProfile, isPartnerUser } = useContext(TopbarContext) as TopbarContextProps;
  const { isMobileWidth } = useWindowDimensions();
  const { user } = useAuth();

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <WrapperButton onClick={openSidebar} cursor="pointer">
          <HamburgerIcon />
        </WrapperButton>
        <Link to={isPartnerUser ? dashboardRoutesByUserType.COLLABORATOR : dashboardRoutesByUserType.CUSTOMER}>
          <TKELogo width={67} height={28} />
        </Link>
      </HeaderWrapper>
      <UserProfileWrapper>
        {isMobileWidth ? (
          <>
            <UserIcon isPartnerUser={isPartnerUser} onClick={openUserProfile}>
              {userAcronym}
            </UserIcon>
            <UserProfile user={user} />
          </>
        ) : (
          <Floating usePortal button={<UserIcon isPartnerUser={isPartnerUser}>{userAcronym}</UserIcon>}>
            <UserShortProfile user={user} />
          </Floating>
        )}
      </UserProfileWrapper>
    </HeaderContainer>
  );
};

export default Header;
