import React, { useContext } from 'react';
import Button from '../../../../components/Button';
import CustomText from '../../../../components/CustomText';
import Input from '../../../../components/Input';
import { useAuth } from '../../../../context/AuthContext';
import { maskPhone, obfuscatePhone } from '../../../../utils/helpers/mask';
import AccountContext, { AccountContextProps } from '../../context';
import { ActionsType } from '../../type';
import { Container, FormBox, FormControl, TitleBox } from './style';

const Content = () => {
  const { openAction } = useContext(AccountContext) as AccountContextProps;

  const { user } = useAuth();
  const email = user?.email;
  const phone = user?.phone;

  return (
    <Container>
      <TitleBox>
        <CustomText as="h2">Senha, e-mail e número de celular</CustomText>
        <CustomText as="p">
          Para alterar a sua senha, e-mail ou número de celular você receberá um código de validação, digite o código e
          siga com a alteração até a confirmação.
        </CustomText>
      </TitleBox>
      <FormBox>
        <FormControl>
          <Input.Form useHelper={false} label="Senha" defaultValue="*****************" type="password" readOnly />
          <Button variant="primary" onClick={() => openAction(ActionsType.PASSWORD)}>
            Editar senha
          </Button>
        </FormControl>
        <FormControl>
          <Input.Form readOnly useHelper={false} label="E-mail" value={email} type="email" />
          <Button variant="primary" onClick={() => openAction(ActionsType.EMAIL)}>
            Editar e-mail
          </Button>
        </FormControl>
        <FormControl>
          <Input.Form
            useHelper={false}
            label="Número de celular"
            type="text"
            value={maskPhone(obfuscatePhone(phone || ''))}
            readOnly
          />
          <Button variant="primary" onClick={() => openAction(ActionsType.PHONE)}>
            Editar número de celular
          </Button>
        </FormControl>
      </FormBox>
    </Container>
  );
};

export default Content;
