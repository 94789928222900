import React, { useMemo } from 'react';
import { GTMEventName } from '../../../../../@types/analytics';
import { DownloadData } from '../../../../../@types/bill';
import Accordion from '../../../../../components/Accordion';
import Button from '../../../../../components/Button';
import Callout from '../../../../../components/Callout';
import CustomText from '../../../../../components/CustomText';
import ModalHeader from '../../../../../components/ModalHeader';
import Checkbox from '../../../../../components/SelectableButton/Checkbox';
import { emitBillDownload, emitInvoiceDownload } from '../../../../../config/download';
import useHub from '../../../../../config/signalR/hooks/useHub';
import { SignalRHubName } from '../../../../../config/signalR/types';
import useToggle from '../../../../../hooks/useToggle';
import ModalPortal from '../../../../../layouts/ModalPortal';
import { sendMultipleGTMEvents } from '../../../../../lib/DataLayer';
import { WrapperDiv } from '../../../../../styles/components';
import { formatDate } from '../../../../../utils/helpers/date';
import { Container } from './style';

interface DownloadModalProps {
  data: DownloadData[];
  onClose: VoidFunction;
  visible: boolean;
}

const ModalContainer = ({ data, onClose }: Omit<DownloadModalProps, 'visible'>) => {
  const { singleSend: sendBillMessage } = useHub(SignalRHubName.BILL);
  const { singleSend: sendInvoiceMessage } = useHub(SignalRHubName.INVOICE);
  const [billsIsSelected, toggleBillsIsSelected] = useToggle(true);
  const hasNestedInvoices = useMemo(() => data.some((bill) => bill.composition.length), [data]);
  const [invoicesIsSelected, toggleInvoicesIsSelected] = useToggle(hasNestedInvoices);

  const submitIsAllowed = billsIsSelected || invoicesIsSelected;

  const onSubmit = () => {
    if (!submitIsAllowed) return;

    const invoiceIds = invoicesIsSelected
      ? data.reduce((arr: string[], bill) => [...arr, ...bill.composition.map((invoice) => String(invoice.id))], [])
      : [];

    const mappedInvoices = invoiceIds.map((invoiceId) => {
      const searchData = data.find((bill) =>
        bill.composition.some((billComposition) => invoiceId === billComposition.id)
      );

      const invoiceNumber = searchData?.composition.find(
        (billComposition) => billComposition.id === invoiceId
      )?.invoiceNumber;

      return {
        id: invoiceId,
        description: `Nº ${invoiceNumber}`,
        grouper: searchData?.clientName || '',
      };
    });

    const billIds = billsIsSelected ? data.map((bill) => bill.id) : [];

    const mappedBills = billIds.map((billId) => {
      const searchData = data.find((searchBill) =>
        searchBill.composition.some((billComposition) => billId === billComposition.id)
      );

      return {
        id: billId,
        description: `${searchData?.documentNumber} - Vencimento ${
          searchData?.expiresAt && formatDate(new Date(searchData.expiresAt))
        }`,
        grouper: searchData?.clientName || '',
      };
    });

    sendMultipleGTMEvents({ name: GTMEventName.DOWNLOAD_INVOICE }, invoiceIds.length);
    sendMultipleGTMEvents({ name: GTMEventName.DOWNLOAD_BILL }, billIds.length);

    Promise.all([
      billIds.length > 0 ? sendBillMessage(['RequestDocuments', JSON.stringify(billIds)]) : Promise.resolve(),
      invoiceIds.length > 0 ? sendInvoiceMessage(['RequestDocuments', JSON.stringify(invoiceIds)]) : Promise.resolve(),
    ]).then(() => {
      if (billIds.length > 0) {
        emitBillDownload(mappedBills);
      }

      if (invoiceIds.length > 0) {
        emitInvoiceDownload(mappedInvoices);
      }

      onClose();
    });
  };

  return (
    <Container>
      <ModalHeader title="Selecione para fazer o download" onClose={onClose} />
      <Callout className="callout-container">
        <CustomText>
          <CustomText as="span" className="bold">
            Aviso importante:
          </CustomText>{' '}
          aguarde 15 minutos após o primeiro carregamento da lista para fazer um novo download em caso de alterações no
          boleto.
        </CustomText>
      </Callout>
      <Accordion.Root gap={16}>
        <Accordion.Item>
          {() => (
            <WrapperDiv justifyContent="flex-start" alignItems="center" gap={8}>
              <Checkbox value="bill" checked={billsIsSelected} onChange={toggleBillsIsSelected} />
              <CustomText>Boletos</CustomText>
            </WrapperDiv>
          )}
        </Accordion.Item>
        {hasNestedInvoices && (
          <Accordion.Item>
            {() => (
              <WrapperDiv justifyContent="flex-start" alignItems="center" gap={8}>
                <Checkbox value="invoice" checked={invoicesIsSelected} onChange={toggleInvoicesIsSelected} />
                <CustomText>Nota fiscais</CustomText>
              </WrapperDiv>
            )}
          </Accordion.Item>
        )}
      </Accordion.Root>
      <Button variant="primary" disabled={!submitIsAllowed} onClick={onSubmit}>
        Iniciar download
      </Button>
    </Container>
  );
};

const BillDownloadModal = ({ data, onClose, visible }: DownloadModalProps) => (
  <ModalPortal onClose={onClose} visible={visible}>
    <ModalContainer data={data} onClose={onClose} />
  </ModalPortal>
);

export default BillDownloadModal;
