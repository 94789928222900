import React from 'react';
import CustomText from '../../../../../../../components/CustomText';
import { Actions, ActionsHead, Links, NameAndTooltipButtons } from '../../style';
import useTableView from '../../../../../../../components/TableView/hooks/useTableView';
import { BuildingData, BuildingOrder, BuildingOrderBy } from '../../../../../../../@types/building';
import HeaderOrderWrapper from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import BuildingActions from '../BuildingActions';
import FixedTextCell from '../../../../../../../components/FixedTextCell';
import BuildingTooltips from '../../../../../shared/components/BuildingTooltips';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import IntegrationLinks from '../../../../../shared/components/IntegrationLinks';
import TableView from '../../../../../../../components/TableView';
import BuildingPolicy from '../../../../../../../strategy/rules/BuildingPolicy';
import { DocumentIcon, StarIcon } from '../../../../../../../icons';

interface BuildingTableProps {
  data: BuildingData[];
  requestSort: (key: BuildingOrderBy, direction?: 'asc' | 'desc') => void;
  order: BuildingOrder | null;
  style?: React.CSSProperties;
}

const BuildingTable = ({ data, requestSort, order, style }: BuildingTableProps) => {
  const { getTableProps } = useTableView<BuildingData>(data, [
    {
      key: 'actions',
      width: '140px',
      header: (
        <ActionsHead>
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="favorite" align="initial">
            <StarIcon />
          </HeaderOrderWrapper>
          <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="vinculated" align="initial">
            <DocumentIcon />
          </HeaderOrderWrapper>
        </ActionsHead>
      ),
      render: ({ item }) => (
        <Actions>
          <BuildingActions data={item} />
        </Actions>
      ),
    },
    {
      width: '140px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="id">
          <CustomText>Nº Edifício</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'id',
    },
    {
      width: '3fr',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientName">
          <CustomText>Cliente</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'name',
      render: ({ item }) => (
        <NameAndTooltipButtons>
          <FixedTextCell>{item.clientName}</FixedTextCell>
          <BuildingTooltips contacts={item.consultants} businessContracts={item.contracts} />
        </NameAndTooltipButtons>
      ),
    },
    {
      width: '190px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="document">
          <CustomText>CNPJ ou CPF</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'document',
      render: ({ item }) => <CustomText>{maskDocument(item.document)}</CustomText>,
    },
    {
      key: 'location',
      width: '1fr',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="city">
          <CustomText>Localização</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => <FixedTextCell>{`${item.city} - ${item.uf}`}</FixedTextCell>,
    },
    {
      key: 'integration',
      width: 'auto',
      header: <CustomText>Módulos</CustomText>,
      render: ({ item }) => (
        <Links>
          <IntegrationLinks
            buildingId={item.id}
            contractId={item.contracts[0].id}
            disableBuildings={!BuildingPolicy.clientCanAccessNewBuildings(item)}
          />
        </Links>
      ),
    },
  ]);

  const tableStyle = style || getTableProps().style;

  return <TableView {...getTableProps()} keyExtractor={(item) => `${item.id}-${item.document}`} style={tableStyle} />;
};

export default BuildingTable;
