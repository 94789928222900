import styled from 'styled-components';

export const Icons = styled.div`
  display: flex;
  gap: 16px;

  .tooltip_container {
    height: fit-content;
  }
`;
