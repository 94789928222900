import { FileMimeType } from '../../utils/constants/mime';

export interface SignalRDocumentResponse {
  id: string;
  success: boolean;
  failure: {
    failureType: number;
    failureTitle: string;
    failureDetail: string;
  };
}

export interface ToDownload {
  id: string;
  type: DocumentItemType;
  grouper: string | undefined;
  description: string | undefined;
}

export type ToDownloadArray = ToDownload[];

export type PackageDownloadID = Brand<string, 'DOWNLOAD_ID'>;

export enum DownloadItemStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  SUCCESS = 'SUCCESS',
}

export type DownloadItem = {
  id: PackageDownloadID;
  handleCancel: VoidFunction;
  quantity: number;
  status: DownloadItemStatus;
};

export enum DocumentItemType {
  BILL = 'BILL',
  INVOICE = 'INVOICE',
}

export enum DocumentItemAction {
  SENDING_BY_EMAIL = 'SENDING_BY_EMAIL',
  DOWNLOAD = 'DOWNLOAD',
  PRINT = 'PRINT',
}

export interface ErrorItem {
  id: string;
  description: string;
}

export type FileType = keyof typeof FileMimeType;

export type FileContent = {
  fileType: FileType;
  fileName: string | null;
  blob: Blob;
};

export type HandleDownloadFunc = (
  toDownload: ToDownload | ToDownloadArray,
  quantity: number,
  downloadId: PackageDownloadID
) => void;

export type AppendStatusFunc = (id: PackageDownloadID, status: DownloadItemStatus) => void;

export type AppendToDownloadListFunc = (
  toDownload: ToDownloadArray,
  id: PackageDownloadID,
  handleCancel: VoidFunction,
  status: DownloadItemStatus
) => void;

export type RemoveToDownloadListFunc = (id: PackageDownloadID, status?: DownloadItemStatus) => void;

export type AppendToErrorListFunc = (
  id: string | undefined,
  document: ErrorItem,
  documentType: DocumentItemType
) => void;
