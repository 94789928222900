import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { Container, Title } from './style';
import CustomText from '../../../../../../../components/CustomText';
import AccountContext, { AccountContextProps } from '../../../../../context';
import { CloseIcon } from '../../../../../../../icons';
import { WrapperButton } from '../../../../../../../styles/components';

interface HeaderProps {
  title: string;
  description?: ReactNode;
}

const Header: FunctionComponent<HeaderProps> = ({ title, description }) => {
  const { closeAction, isMobileWidth } = useContext(AccountContext) as AccountContextProps;

  return (
    <Container>
      {isMobileWidth ? (
        <CustomText className="mobile_title">{title}</CustomText>
      ) : (
        <Title>
          <CustomText className="title">{title}</CustomText>
          <WrapperButton onClick={closeAction} cursor="pointer">
            <CloseIcon className="icon" />
          </WrapperButton>
        </Title>
      )}
      {description}
    </Container>
  );
};

export default Header;
