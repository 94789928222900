import React, { useContext } from 'react';
import BuildingContext, { BuildingContextProps } from '../../context';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import DisabledBuildingHeader from '../DisabledBuildingHeader';
import { ListContainer, ViewContainer, WarningContainer } from '../../../../style';
import CustomText from '../../../../../../../components/CustomText';
import BuildingGrid from '../BuildingGrid';
import BuildingTable from '../BuildingTable';
import { PaginateFooter } from '../../../../../shared/style';
import Pagination from '../../../../../../../components/Pagination';
import Button from '../../../../../../../components/Button';

const BuildingContent = () => {
  const {
    data,
    requestSort,
    order,
    getPaginationProps,
    filterIsEmpty,
    loading,
    dataIsEmpty,
    showGrid,
    openSearchAction,
  } = useContext(BuildingContext) as BuildingContextProps;
  const { isMobileWidth } = useWindowDimensions();

  if (loading) {
    return (
      <>
        {!isMobileWidth && <DisabledBuildingHeader />}
        <WarningContainer obfuscate>
          <CustomText as="h1">Buscando contratos...</CustomText>
        </WarningContainer>
      </>
    );
  }

  if (filterIsEmpty) {
    return (
      <>
        {!isMobileWidth && <DisabledBuildingHeader />}
        <WarningContainer>
          <CustomText as="h1">Nenhum resultado encontrado.</CustomText>
        </WarningContainer>
      </>
    );
  }

  if (dataIsEmpty) {
    return (
      <WarningContainer>
        <CustomText as="h1">Você não possui contratos na sua lista.</CustomText>
        <Button variant="primary" size="small" onClick={openSearchAction} disabled={loading}>
          Buscar novo contrato
        </Button>
      </WarningContainer>
    );
  }

  return (
    <ListContainer>
      <ViewContainer>
        {showGrid ? (
          <BuildingGrid data={data} />
        ) : (
          <BuildingTable data={data} requestSort={requestSort} order={order} />
        )}
      </ViewContainer>
      <PaginateFooter>
        <Pagination {...getPaginationProps()} />
      </PaginateFooter>
    </ListContainer>
  );
};

export default BuildingContent;
