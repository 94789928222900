import React, { useContext } from 'react';
import AccountContext, { AccountContextProps } from '../../../context';
import { ActionsType } from '../../../type';
import PasswordAction from '../components/PasswordAction';
import EmailAction from '../components/EmailAction';
import PhoneAction from '../components/PhoneAction';
import AsidePortal from '../../../../../layouts/AsidePortal';
import AsideHeader from '../../../../../components/AsideHeader';
import { Container } from './style';

const MobileActions = () => {
  const { type, open, closeAction } = useContext(AccountContext) as AccountContextProps;
  return (
    <AsidePortal visible={open}>
      <AsideHeader
        onBack={() => {
          closeAction();
        }}
      />
      <Container>
        {type === ActionsType.PASSWORD && <PasswordAction />}
        {type === ActionsType.EMAIL && <EmailAction />}
        {type === ActionsType.PHONE && <PhoneAction />}
      </Container>
    </AsidePortal>
  );
};

export default MobileActions;
