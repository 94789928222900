import React from 'react';
import { FinancialHistoricData } from '../../../../../../../@types/bill';
import AutoGrid from '../../../../../../../layouts/AutoGrid';
import Card from '../../../../../../../components/Card';
import { formatDate, formatHour } from '../../../../../../../utils/helpers/date';
import { compose } from '../../../../../../../utils/helpers/string';
import {
  mapFinancialHistoricOperationLabel,
  mapFinancialHistoricOperationToDescription,
  mapHistoricUserTypeLabel,
} from '../../../../constants';

interface FinancialHistoricGridProps {
  data: FinancialHistoricData[];
}

const FinancialHistoricGrid = ({ data }: FinancialHistoricGridProps) => (
  <AutoGrid>
    {data.map((financialHistoricData) => (
      <Card.Root key={financialHistoricData.id} className="__financial-card" height={212}>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Nome do usuário</Card.Label>
            <Card.Data>{financialHistoricData?.userName || 'Anônimo'}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Operação</Card.Label>
            <Card.Data>{mapFinancialHistoricOperationLabel[financialHistoricData.type]}</Card.Data>
          </Card.Cell>
          <Card.Cell>
            <Card.Label>Hora</Card.Label>
            <Card.Data>{formatHour(new Date(financialHistoricData.createdAt))}</Card.Data>
          </Card.Cell>
          <Card.Cell>
            <Card.Label>Data</Card.Label>
            <Card.Data>{formatDate(new Date(financialHistoricData.createdAt))}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Tipo do usuário</Card.Label>
            <Card.Data>{mapHistoricUserTypeLabel[financialHistoricData.userType] || 'Anônimo'}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Descrição</Card.Label>
            <Card.Data>
              {compose(
                mapFinancialHistoricOperationToDescription[financialHistoricData.type],
                '$',
                financialHistoricData.information
              )}
            </Card.Data>
          </Card.Cell>
        </Card.Row>
      </Card.Root>
    ))}
  </AutoGrid>
);

export default FinancialHistoricGrid;
