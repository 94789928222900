import React, { FunctionComponent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from '../../style';
import { Box, ShortNavButton, UserInfos, UserShortProfileContent, UserShortProfileHeader } from './styles';
import { WrapperDiv } from '../../../../styles/components';
import TopbarContext, { TopbarContextProps } from '../../context';
import { PROFILE_CLIENT_ITEMS } from '../../data';
import { User } from '../../../../@types/auth';
import { ChevronArrowIcon, EntryIcon } from '../../../../icons';

interface ProfileProps {
  user: User | null;
}

const UserShortProfile: FunctionComponent<ProfileProps> = ({ user }) => {
  const { isPartnerUser, userAcronym, signOutHandler } = useContext(TopbarContext) as TopbarContextProps;

  const navigate = useNavigate();

  const redirectHandler = (path: string) => {
    navigate(path);
  };

  return (
    <Box className="user_profile_dropdown">
      <UserShortProfileHeader>
        <UserIcon isPartnerUser={isPartnerUser}>{userAcronym}</UserIcon>
        <UserInfos>
          <p className="user_info_name" data-clarity-mask="True">
            {user?.name}
          </p>
          <p className="user_info_email" data-clarity-mask="True">
            {user?.email}
          </p>
        </UserInfos>
      </UserShortProfileHeader>
      <UserShortProfileContent>
        {!isPartnerUser && (
          <div>
            {PROFILE_CLIENT_ITEMS.map(({ title, path, key }) => (
              <ShortNavButton key={key} onClick={() => redirectHandler(path)}>
                <WrapperDiv gap={8} justifyContent="space-between">
                  {title}
                  <ChevronArrowIcon />
                </WrapperDiv>
              </ShortNavButton>
            ))}
          </div>
        )}
        <ShortNavButton onClick={signOutHandler}>
          <WrapperDiv gap={8} justifyContent="none">
            <EntryIcon />
            Sair
          </WrapperDiv>
        </ShortNavButton>
      </UserShortProfileContent>
    </Box>
  );
};

export default UserShortProfile;
