import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import AsideTabs from '../../../../../../../../../layouts/AsideTabs';
import { BillAsideStage } from '../../../../../../../../../@types/bill';
import BillContext, { BillContextProps } from '../../../../context';

const SelectStep = () => {
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;
  const { actionsIsDisabled, loading, signalRConnectionReachedMaxRetries } = useContext(
    BillContext
  ) as BillContextProps;
  return (
    <AsideTabs
      onClose={closeHandler}
      onChange={changeStage}
      actions={[
        {
          label: 'Buscar boletos',
          accessor: BillAsideStage.FILTER_ACTION,
          disabled: actionsIsDisabled,
        },
        {
          label: 'Buscar novo boleto',
          accessor: BillAsideStage.SEARCH_NEW_BILL,
          disabled: loading || signalRConnectionReachedMaxRetries,
        },
        {
          label: 'Configurar data',
          accessor: BillAsideStage.SELECT_DATE_ACTION,
          disabled: loading || signalRConnectionReachedMaxRetries,
        },
      ]}
    />
  );
};

export default SelectStep;
