import { useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext } from 'react';
import { Content, ContentButtons, ContentHeader, ContentUserIcon } from './style';
import { PROFILE_CLIENT_ITEMS } from '../../data';
import TopbarContext, { TopbarContextProps } from '../../context';
import { WrapperDiv } from '../../../../styles/components';
import NavButton from '../NavButton';
import AsidePortal from '../../../AsidePortal';
import AsideHeader from '../../../../components/AsideHeader';
import { User } from '../../../../@types/auth';
import { ChevronArrowIcon, EntryIcon } from '../../../../icons';

interface ProfileProps {
  user: User | null;
}

const UserProfile: FunctionComponent<ProfileProps> = ({ user }) => {
  const { isPartnerUser, closeUserProfile, userProfileIsOpen, userAcronym, signOutHandler } = useContext(
    TopbarContext
  ) as TopbarContextProps;
  const navigate = useNavigate();

  const redirectHandler = (path: string) => {
    closeUserProfile();
    navigate(path);
  };

  return (
    <AsidePortal visible={userProfileIsOpen}>
      <AsideHeader onBack={closeUserProfile} />
      <Content>
        <ContentHeader>
          <ContentUserIcon isPartnerUser={isPartnerUser}>{userAcronym}</ContentUserIcon>
          <p className="name" data-clarity-mask="True">
            {user?.name}
          </p>
          <p className="email" data-clarity-mask="True">
            {user?.email}
          </p>
        </ContentHeader>
        <ContentButtons>
          {!isPartnerUser && (
            <div>
              {PROFILE_CLIENT_ITEMS.map(({ title, path, key }) => (
                <NavButton key={key} onClick={() => redirectHandler(path)}>
                  <WrapperDiv>
                    {title}
                    <ChevronArrowIcon />
                  </WrapperDiv>
                </NavButton>
              ))}
            </div>
          )}
          <NavButton onClick={signOutHandler}>
            <WrapperDiv gap={8} justifyContent="none">
              <EntryIcon />
              Sair
            </WrapperDiv>
          </NavButton>
        </ContentButtons>
      </Content>
    </AsidePortal>
  );
};

export default UserProfile;
