import ReactDOM from 'react-dom';
import React from 'react';
import useAnimatedUnmount from '../../hooks/useAnimatedUnmount';
import { Container } from './style';
import { useScrollLock } from '../../context/ScrollLockContext';

interface AsidePortalProps {
  children: Children;
  visible: boolean;
  onAfterClose?: VoidFunction;
}

const AsidePortal = ({ children, visible, onAfterClose }: AsidePortalProps) => {
  const { shouldRender, ref } = useAnimatedUnmount<HTMLDivElement>(visible, onAfterClose);
  useScrollLock(shouldRender);
  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <Container isLeaving={!visible} ref={ref}>
      {children}
    </Container>,
    document.getElementById('fullscreen_root') as HTMLElement
  );
};

export default AsidePortal;
