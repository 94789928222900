import api from '../../config/http/api';
import { FinancialHistoricData } from '../../@types/bill';
import { FileDataType } from '../../@types/file';

const getFinancialDetailsHistoric = async (id: string) =>
  api.get<undefined, FinancialHistoricData[]>(`/api/v1/finances/bills/${id}/historic`, undefined, {
    isAuthorized: true,
  });

const sendBillByEmail = async (id: string, toEmail: string | null) =>
  api.post<{ toEmail: string | null }, undefined>(
    `/api/v1/finances/bills/${id}/send-email`,
    {
      toEmail,
    },
    {
      isAuthorized: true,
    }
  );

const sendDataByEmail = async (toEmail: string | null, dataType: FileDataType) =>
  api.post<{ toEmail: string | null; dataType: FileDataType }, undefined>(
    `/api/v1/finances/bills/send-email`,
    {
      toEmail,
      dataType: parseInt(dataType.toString(), 10),
    },
    {
      isAuthorized: true,
    }
  );

const sendInvoiceByEmail = async (id: string, toEmail: string | null) =>
  api.post<{ toEmail: string | null }, undefined>(
    `/api/v1/finances/invoices/${id}/send-email`,
    {
      toEmail,
    },
    {
      isAuthorized: true,
    }
  );

export default {
  getFinancialDetailsHistoric,
  sendBillByEmail,
  sendDataByEmail,
  sendInvoiceByEmail,
};
