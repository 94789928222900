import React, { useState } from 'react';
import Calendar from '../../components/DateRangeSelector';
import Button from '../../components/Button';
import AsideHeader from '../../components/AsideHeader';
import { Actions, Container } from './style';
import CustomText from '../../components/CustomText';

interface SelectDateProps {
  title: string;
  description: string;
  onClose: VoidFunction;
  onBack: VoidFunction;
  range: DateRange | undefined;
  onChangeRange: (newRange: DateRange | undefined) => void;
}

const SelectDate = ({ range, onChangeRange, onClose }: Omit<SelectDateProps, 'title' | 'description'>) => {
  const [internalRange, setInternalRange] = useState<DateRange | undefined>(range);

  const onConfirm = () => {
    if (!internalRange) return;
    onChangeRange(internalRange);
    onClose();
  };

  const onClearRange = () => {
    if (!internalRange) return;
    onChangeRange(undefined);
    onClose();
  };

  return (
    <>
      <Calendar value={internalRange} onChange={(newRange) => setInternalRange(newRange)} />
      <Actions>
        <Button variant="tertiary" onClick={onClearRange}>
          Limpar
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={!internalRange}>
          Aplicar
        </Button>
      </Actions>
    </>
  );
};

const MobileSelectDate = ({ title, onClose, onBack, range, onChangeRange, description }: SelectDateProps) => (
  <>
    <AsideHeader onBack={onBack} />
    <Container>
      <CustomText as="h1">{title}</CustomText>
      <CustomText as="p">{description}</CustomText>
      <SelectDate {...{ onClose, range, onChangeRange, onBack }} />
    </Container>
  </>
);

export default MobileSelectDate;
