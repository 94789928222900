import styled from 'styled-components';
import NavButton from '../NavButton';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  width: 200px;
`;

export const UserShortProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const UserShortProfileContent = styled.div`
  padding: 0;
`;

export const UserInfos = styled.div`
  & > p {
    font-family: 'TkeType-Regular', sans-serif;
    font-style: normal;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  & > p.user_info_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    white-space: break-spaces;
  }

  & > p.user_info_email {
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ShortNavButton = styled(NavButton)`
  padding: 16px;
`;
