import { createContext } from 'react';
import { InvoiceAsideStage } from '../../../../../../../@types/invoice';

export interface AsideActionsContextProps {
  changeStage: (newStage: InvoiceAsideStage) => void;
  closeHandler: VoidFunction;
}

const AsideActionsContext = createContext<AsideActionsContextProps | null>(null);

export default AsideActionsContext;
