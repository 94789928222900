import React, { FunctionComponent } from 'react';
import { BusinessContract, Consultant } from '../../../../../@types/building';
import ContactsTooltip from './ContactsTooltip';
import BusinessContractsTooltip from './BusinessContractsTooltip';
import TextIconDropdown from '../../../../../components/Floating/components/TextIconDropdown';
import { Container } from './style';
import { PageIcon, PhoneIcon } from '../../../../../icons';

interface TooltipButtonsProps {
  businessContracts?: Array<BusinessContract>;
  contacts?: Array<Consultant>;
}

const BuildingTooltips: FunctionComponent<TooltipButtonsProps> = ({ contacts, businessContracts }) => (
  <Container>
    {businessContracts && (
      <TextIconDropdown
        label="Contratos"
        icon={<PageIcon />}
        floating={<BusinessContractsTooltip businessContracts={businessContracts} />}
      />
    )}
    {contacts && (
      <TextIconDropdown label="Telefones" icon={<PhoneIcon />} floating={<ContactsTooltip contacts={contacts} />} />
    )}
  </Container>
);

export default BuildingTooltips;
