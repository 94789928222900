import ReactDOM from 'react-dom';
import React, { forwardRef, MouseEventHandler, Ref, useEffect, useRef } from 'react';
import useAnimatedUnmount from '../../hooks/useAnimatedUnmount';
import { Container, Overlay, Spacing } from './style';
import { useScrollLock } from '../../context/ScrollLockContext';

const useRefTargetsClick = <T extends HTMLElement>(cb?: () => void) => {
  const elsRef = useRef<Record<string, T>>({});

  const handleOverlayClick: MouseEventHandler<T> = (event) => {
    if (Object.values(elsRef.current).includes(event.target as T)) {
      cb?.();
    }
  };

  const appendRef = (el: T | null, id: string) => {
    if (el) {
      elsRef.current[id] = el;
    }
  };

  return { handleOverlayClick, elsRef, appendRef };
};

interface ModalPortalProps {
  children: Children;
  onAfterClose?: VoidFunction;
  visible: boolean;
  onClose?: VoidFunction;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

const ModalContent = forwardRef(
  (
    { onClose, visible, fullHeight, fullWidth, children }: Omit<ModalPortalProps, 'callback'>,
    ref: Ref<HTMLDivElement>
  ) => {
    const { handleOverlayClick, appendRef } = useRefTargetsClick<HTMLDivElement>(onClose);
    useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.preventDefault();
          onClose?.();
        }
      };

      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, []);

    return (
      <Overlay isLeaving={!visible} onClick={handleOverlayClick}>
        <Spacing ref={(spacingRef) => appendRef(spacingRef, 'spacing-ref')}>
          <div
            style={{
              maxHeight: '100%',
              height: '100%',
              width: fullWidth ? '100%' : 'fit-content',
            }}
            ref={(modalWrapperRef) => appendRef(modalWrapperRef, 'modal-wrapper-ref')}
          >
            <Container isLeaving={!visible} ref={ref} fullHeight={fullHeight} fullWidth={fullWidth}>
              <div className="modal_content_wrapper">{children}</div>
            </Container>
          </div>
        </Spacing>
      </Overlay>
    );
  }
);

const ModalPortal = ({
  fullWidth = false,
  fullHeight = false,
  visible,
  onAfterClose,
  onClose,
  children,
}: ModalPortalProps) => {
  const { shouldRender, ref } = useAnimatedUnmount<HTMLDivElement>(visible, onAfterClose);

  useScrollLock(shouldRender);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <ModalContent onClose={onClose} fullHeight={fullHeight} fullWidth={fullWidth} visible={visible} ref={ref}>
      {children}
    </ModalContent>,
    document.getElementById('fullscreen_root') as HTMLElement
  );
};

export default ModalPortal;
