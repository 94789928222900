import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCloseEye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M6.781 8.982a3.538 3.538 0 0 0-.531 1.875 3.708 3.708 0 0 0 3.719 3.72 3.46 3.46 0 0 0 1.875-.532l-.469-.469a3.148 3.148 0 0 1-1.438.344 3.1 3.1 0 0 1-3.093-3.094c0-.531.125-1 .343-1.437l-.406-.407ZM13.219 12.732a3.538 3.538 0 0 0 .531-1.875 3.708 3.708 0 0 0-3.719-3.718 3.46 3.46 0 0 0-1.875.53l.469.47a3.147 3.147 0 0 1 1.438-.344 3.1 3.1 0 0 1 3.093 3.094c0 .53-.125 1-.344 1.437l.407.406Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="m10.219 12.389-1.75-1.75c0 .062-.031.156-.031.218 0 .844.687 1.563 1.562 1.563.063 0 .156-.031.219-.031ZM9.781 9.326l1.75 1.75c0-.062.031-.156.031-.219 0-.843-.687-1.562-1.562-1.562-.063 0-.156.031-.219.031ZM5.031 7.201c-1.468.813-2.718 2-3.656 3.5l-.125.156H2A9.588 9.588 0 0 1 5.5 7.64L5.031 7.2ZM10 15.201a9.617 9.617 0 0 1-5.688-1.875l-.343.531c1.719 1.282 3.812 1.97 6.031 1.97 1.094 0 2.156-.157 3.156-.5l-.5-.5c-.844.25-1.75.374-2.656.374Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M18.656 10.701C16.75 7.67 13.531 5.89 10 5.89c-1.094 0-2.156.156-3.156.5l.5.5A9.407 9.407 0 0 1 10 6.514c3.25 0 6.219 1.625 8 4.343a9.588 9.588 0 0 1-3.5 3.22l.469.468c1.469-.813 2.719-2 3.656-3.5l.125-.188-.094-.156ZM13.938 14.357l-1.094-1.093-.438-.438-1.094-1.125-.468-.437-1.25-1.25-.438-.47-1.125-1.093-.437-.437-.907-.907-.468-.468-2.157-2.157-.437.438 1.969 1.969.468.468 1.094 1.094.438.438 1.093 1.125.47.437 1.25 1.25.437.469 1.125 1.094.437.437.906.906.47.47 2.155 2.155.438-.437-1.969-1.969-.469-.469Z"
    />
  </svg>
);
export default SvgCloseEye;
