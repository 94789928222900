import React, { useState } from 'react';
import { Buttons, ChangeViewButton, Container, Header, Panel } from './style';
import { formatDate } from '../../utils/helpers/date';
import CustomText from '../CustomText';
import CalendarPicker from './components/CalendarPicker';
import YearPicker from './components/YearPicker';
import { CalendarIcon } from '../../icons';

interface DateRangeSelectorProps {
  value: DateRange | undefined;
  onChange: (range: DateRange) => void;
}

const DateRangeSelector = ({ value, onChange }: DateRangeSelectorProps) => {
  const [view, setView] = useState<'month' | 'year'>('month');
  const [activeDate, setActiveDate] = useState<Date>(value?.[0] || new Date());
  const onChangeRange = (range: DateRange) => {
    onChange(range);
  };

  const onChangeMonth = (newActiveDate: Date) => {
    setActiveDate(newActiveDate);
  };

  const onChangeYear = (newYear: number) => {
    setActiveDate((prevDate) => {
      prevDate.setFullYear(newYear);
      return prevDate;
    });
    setView('month');
  };

  const panelText = value ? `${formatDate(value[0])} - ${formatDate(value[1])}` : '00/00/0000 - 00/00/0000';

  return (
    <Container>
      <Header>
        <Panel>
          <CustomText>{panelText}</CustomText>
          <CalendarIcon />
        </Panel>
        <Buttons>
          <ChangeViewButton isActive={view === 'month'} onClick={() => setView('month')}>
            Mês
          </ChangeViewButton>
          <ChangeViewButton isActive={view === 'year'} onClick={() => setView('year')}>
            Ano
          </ChangeViewButton>
        </Buttons>
      </Header>
      {view === 'month' ? (
        <CalendarPicker range={value} onChange={onChangeRange} activeDate={activeDate} onChangeMonth={onChangeMonth} />
      ) : (
        <YearPicker activeDate={activeDate} onChangeYear={onChangeYear} />
      )}
    </Container>
  );
};

export default DateRangeSelector;
