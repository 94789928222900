import { createContext } from 'react';
import { BillAsideStage } from '../../../../../../../@types/bill';

export interface AsideActionsContextProps {
  changeStage: (newStage: BillAsideStage) => void;
  closeHandler: VoidFunction;
}

const AsideActionsContext = createContext<AsideActionsContextProps | null>(null);

export default AsideActionsContext;
