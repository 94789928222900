import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  gap: 24px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  button {
    padding: 0;
    min-width: 0;
    width: 135px;
  }
`;
