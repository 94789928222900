import React from 'react';
import getFeedbackIcon from '../../../../../utils/jsx/getFeedbackIcon';
import theme from '../../../../../styles/theme';
import CustomText from '../../../../../components/CustomText';
import { Container, Content } from './style';
import { BillMinimalData, BillStatus } from '../../../../../@types/bill';
import { MAP_BILL_STATUS } from '../../../Client/containers/Bill/constants';
import { ExpiredIcon } from '../../../../../icons';

const mapStatusBadge = {
  [BillStatus.PENDING]: {
    Icon: getFeedbackIcon('warning'),
    text: MAP_BILL_STATUS[BillStatus.PENDING],
    bgColor: theme.color.feedback.alert,
    textColor: theme.color.primary.black,
  },
  [BillStatus.PAID]: {
    Icon: getFeedbackIcon('success'),
    text: MAP_BILL_STATUS[BillStatus.PAID],
    bgColor: theme.color.feedback.success,
    textColor: theme.color.primary.white,
  },
  [BillStatus.OVERDUE]: {
    Icon: getFeedbackIcon('error'),
    text: MAP_BILL_STATUS[BillStatus.OVERDUE],
    bgColor: theme.color.feedback.error,
    textColor: theme.color.primary.white,
  },
  [BillStatus.EXPIRED]: {
    Icon: <ExpiredIcon />,
    text: MAP_BILL_STATUS[BillStatus.EXPIRED],
    bgColor: theme.color.feedback.error,
    textColor: theme.color.primary.white,
  },
} as const;

interface StatusBadgeProps {
  status?: Exclude<BillMinimalData['status'], BillStatus.NOT_GENERATED>;
}

const BillStatusBadge = ({ status }: StatusBadgeProps) => {
  if (!status) return null;

  const { Icon, text, bgColor, textColor } = mapStatusBadge[status];

  return (
    <Container className="status-badge">
      <div
        style={{
          width: 0,
          height: 0,
          borderBottom: `30px solid ${bgColor}`,
          borderLeft: '30px solid transparent',
        }}
      />
      <Content style={{ backgroundColor: bgColor, color: textColor }}>
        {Icon}
        <CustomText>{text}</CustomText>
      </Content>
    </Container>
  );
};

export default BillStatusBadge;
