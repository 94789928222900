import React, { AnchorHTMLAttributes } from 'react';
import { StyledLink } from '../style';
import { Anchor } from './style';
import ArrowLink from '../../../icons/ArrowLink';

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
  disabled?: boolean;
  hoverColor?: string;
}

const ExternalLink = ({ href, text, disabled, color, hoverColor, ...props }: ExternalLinkProps) => (
  <StyledLink className="External_Link" $color={color} $hoverColor={hoverColor} disabled={disabled}>
    <Anchor href={href} {...props}>
      {text}
    </Anchor>
    <ArrowLink />
  </StyledLink>
);

export default ExternalLink;
