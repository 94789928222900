import React, { FunctionComponent } from 'react';
import { Actions, Container } from './style';
import Button from '../../../../../../../components/Button';

interface RequestCodeProps {
  children: Children;
  onSubmit: VoidFunction;
  loading: boolean;
}

const RequestCode: FunctionComponent<RequestCodeProps> = ({ children, loading, onSubmit }) => (
  <Container>
    {children}
    <Actions>
      <Button type="submit" variant="primary" onClick={onSubmit} disabled={loading} loading={loading}>
        Enviar código
      </Button>
    </Actions>
  </Container>
);

export default RequestCode;
