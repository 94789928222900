import { createContext, MutableRefObject } from 'react';
import { ConfirmationToken, ContactType, LoginFormStage, MaskedUserData } from '../../../../@types/auth';
import { GeneralError } from './type';

export interface LoginContextProps {
  stage: LoginFormStage;
  setStage: SetState<LoginFormStage>;
  captchaToken: string | null;
  setCaptchaToken: SetState<string | null>;
  confirmationToken: ConfirmationToken | null;
  setConfirmationToken: SetState<ConfirmationToken | null>;
  user: MaskedUserData | null;
  setUser: SetState<MaskedUserData | null>;
  wrongSubmit: MutableRefObject<number>;
  requestCode: (callback?: VoidFunction) => Promise<void>;
  handleContactTypeChange: (contactType: ContactType) => void;
  resetForm: VoidFunction;
  credentialsInvalid: boolean;
  setCredentialsInvalid: SetState<boolean>;
  setGeneralError: SetState<GeneralError | null>;
  generalError: GeneralError | null;
}

const LoginContext = createContext<LoginContextProps | null>(null);

export default LoginContext;
