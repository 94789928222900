import styled, { css } from 'styled-components';
import { StyledInput } from '../Input/style';
import theme from '../../styles/theme';

export const InputControl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 36px;
`;

const INPUT_MOUSE_EVENTS_STYLE = css`
  color: ${theme.color.secondary.grey[4]};

  &:hover {
    border: 1px solid ${theme.color.primary.interaction};
    color: ${theme.color.primary.interaction};
  }

  &:focus-within {
    border: 1px solid ${theme.color.primary.interaction} !important;
    color: ${theme.color.primary.interaction};
  }

  &:active {
    border: 1px solid ${theme.color.primary.interaction};
  }
`;

export const SearchBox = styled.div<{ disabled: boolean }>`
  height: 36px;
  display: flex;
  border: 0.3px solid ${theme.color.secondary.grey[4]};
  box-sizing: border-box;
  ${INPUT_MOUSE_EVENTS_STYLE};
  ${({ disabled }) =>
    disabled &&
    css`
      ${theme.defaults.disabled}
    `}
`;

export const IconWrapper = styled.div`
  padding-left: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled(StyledInput)`
  padding-top: 9.5px;
  padding-bottom: 9.5px;
`;

export const Addon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
`;
