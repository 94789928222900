import React from 'react';
import { Container, Grid, SkeletonBackground, SkeletonBox } from '../../style';
import FormHeaderSkeleton from '../FormHeaderSkeleton';
import InputSkeleton from '../InputSkeleton';
import { SkeletonForm } from './style';

const SkeletonRegister = () => (
  <Grid>
    <SkeletonBackground />
    <Container>
      <FormHeaderSkeleton />
      <SkeletonForm>
        <InputSkeleton />
        <InputSkeleton />
        <InputSkeleton />
        <InputSkeleton />
        <InputSkeleton />
        <InputSkeleton />
        <SkeletonBox width={240} height={44} />
      </SkeletonForm>
    </Container>
  </Grid>
);

export default SkeletonRegister;
