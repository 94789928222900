import { isFunction } from './function';

/**
 * Compares two arrays for equality.
 * @param {T[]} a - The first array.
 * @param {T[]} b - The second array.
 * @returns {boolean} - Returns true if the arrays are equal, otherwise false.
 */
export const compareArrays = <T>(a: T[], b: T[]): boolean =>
  a.length === b.length && a.every((element, index) => element === b[index]);

/**
 * Checks if an array is not empty.
 * @param {T[]} array - The array to check.
 * @returns {boolean} - Returns true if the array is not empty, otherwise false.
 */
export const isNotEmptyArray = <T>(array: T[]): boolean => Array.isArray(array) && array.length !== 0;

/**
 * Checks if an array is empty.
 * @param {T[]} array - The array to check.
 * @returns {boolean} - Returns true if the array is empty, otherwise false.
 */
export const isEmptyArray = <T>(array: T[]): boolean => Array.isArray(array) && array.length === 0;

/**
 * Checks if the input is not an array or if it's not empty.
 * @param {T[]} array - The array to check.
 * @returns {boolean} - Returns true if the input is not an array or if it's not empty, otherwise false.
 */
export const isNotArrayOrIsNotEmpty = <T>(array: T[]): boolean => !Array.isArray(array) || array.length !== 0;

/**
 * Checks if the input is not an array or if it's empty.
 * @param {T[]} array - The array to check.
 * @returns {boolean} - Returns true if the input is not an array or if it's empty, otherwise false.
 */
export const isNotArrayOrIsEmpty = <T>(array: T[]): boolean => !Array.isArray(array) || array.length === 0;

/**
 * Adds or removes an item from an array based on its presence.
 * @param {T[]} arr - The input array.
 * @param {T} item - The item to add or remove.
 * @returns {T[]} - The updated array after adding or removing the item.
 */
export const addOrRemove = <T extends unknown>(arr: T[], item: T): T[] =>
  arr.includes(item) ? arr.filter((i) => String(i) !== String(item)) : [...arr, item];

/**
 * Finds an element in an array and updates it with the provided value.
 * @param {K} key - The key to search for in the object.
 * @param {T[K]} find - The value to find within the object.
 * @param {T} appendValue - The value to update within the object.
 * @returns {(prevData: T[]) => T[]} - A function that updates the array with the new value.
 */
export const findAndUpdate =
  <T extends object, K extends keyof T>(
    key: K,
    find: T[K],
    appendValue: ((curr: T) => T) | T
  ): ((prevData: T[]) => T[]) =>
  (prevData: T[]) => {
    const index = prevData.findIndex((buildingData) => buildingData[key] === find);
    if (index === -1) {
      return prevData;
    }
    const updatedList = [...prevData];
    updatedList.splice(index, 1, isFunction(appendValue) ? appendValue(updatedList[index]) : appendValue);
    return updatedList;
  };

/**
 * Removes the first element from the array that matches the given condition.
 * @param {T[]} arr - The array from which to remove the element.
 * @param {(value: T) => boolean} extractor - The condition to match for element removal.
 * @returns {T[]} - The updated array after removing the first matching element, if found.
 */
export function removeFirstMatchingElement<T extends unknown>(arr: T[], extractor: (value: T) => boolean): T[] {
  const temp = arr;

  const index = temp.findIndex(extractor);
  if (index !== -1) {
    temp.splice(index, 1);
  }

  return temp;
}

/**
 * Chains multiple functions together, passing the result of each function to the next.
 * @param {T} data - The input data.
 * @param {((data: T) => T)[]} functions - An array of functions to execute sequentially.
 * @returns {T} - The final result after applying all functions to the data.
 */
export function pipe<T>(data: T, functions: ((data: T) => T)[]): T {
  return functions.reduce((result, fn) => fn(result), data);
}

/**
 * Call a function _n_ times.
 * @param {F} callback - The function to call.
 * @param {number} times - The number of times `callback` will be called.
 * @param {Parameters<F>} args - arguments of `callback` (optional).
 * @return {*[]}
 */
export function repeat<F extends (...fnArgs: Parameters<F>) => ReturnType<F>>(
  callback: F,
  times = 1,
  ...args: Parameters<F>
) {
  return Array.from({ length: times }).forEach(() => callback(...args));
}
