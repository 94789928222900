import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;

  width: 100%;
  max-width: 325px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    & > button {
      min-width: unset;
      width: 50% !important;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  flex: 1;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  h1,
  p {
    text-align: center;
  }
`;
