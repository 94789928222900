import React, { forwardRef, ReactNode } from 'react';
import { CheckboxInput, CheckboxLabel } from './style';
import { GroupLabel } from '../style';
import { CheckboxWrapper } from './Group/style';
import { HTMLCheckboxProps } from '../../../@types/html';
import { CheckIcon } from '../../../icons';

interface CheckboxComponentProps extends HTMLCheckboxProps {
  label?: ReactNode;
}

const CheckboxButton = forwardRef<HTMLInputElement, HTMLCheckboxProps>(({ id, disabled, checked, ...props }, ref) => (
  <CheckboxLabel disabled={disabled} checked={checked}>
    <CheckIcon />
    <CheckboxInput id={id} disabled={disabled} type="checkbox" ref={ref} {...props} />
  </CheckboxLabel>
));

const CheckboxComponent = forwardRef<HTMLInputElement, CheckboxComponentProps>(
  ({ id, checked, name, disabled, label, value, onChange, ...props }, ref) => (
    <CheckboxWrapper key={`CheckboxGroup-${label}-${value}`} className="checkbox-wrapper">
      <CheckboxButton
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        {...props}
      />
      {label && (
        <GroupLabel className="CheckboxGroup-Label" checked={checked} htmlFor={id}>
          {label}
        </GroupLabel>
      )}
    </CheckboxWrapper>
  )
);

export default CheckboxComponent;
