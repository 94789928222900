import { createContext } from 'react';
import { BuildingData, BuildingOrder, BuildingOrderBy, LinkContract } from '../../../@types/building';
import { PartnerSearchStatus } from './usePartner';

export interface PartnerContextProps {
  buildings: BuildingData[];
  fetchBuildings: (newContract: LinkContract, signal?: AbortSignal) => Promise<void>;
  requestSort: (key: BuildingOrderBy) => void;
  order: BuildingOrder | null;
  status: PartnerSearchStatus;
  setStatus: SetState<PartnerSearchStatus>;
}

const PartnerContext = createContext<PartnerContextProps | null>(null);

export default PartnerContext;
