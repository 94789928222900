import { createContext } from 'react';
import { BillDetails } from '../../../@types/bill';

export interface FinancialDetailsContextProps {
  id: string | undefined;
  historicIsOpen: boolean;
  details: BillDetails | null;
  toggleHistoric: VoidFunction;
  openHistoric: VoidFunction;
  closeHistoric: VoidFunction;
  backToPanel: VoidFunction;
  openBillSendEmail: VoidFunction;
  handleBillDownload: VoidFunction;
  handleOpenBillDocument: VoidFunction;
  handleInvoiceDownload: (invoiceId: string) => void;
  handleOpenInvoiceDocument: (invoiceId: string) => void;
}

const FinancialDetailsContext = createContext<FinancialDetailsContextProps | null>(null);

export default FinancialDetailsContext;
