import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import AsideTabs from '../../../../../../../../../layouts/AsideTabs';
import { BuildingAsideStage } from '../../../../../../../../../@types/building';
import BuildingContext, { BuildingContextProps } from '../../../../context';

const SelectStep = () => {
  const { dataIsEmpty } = useContext(BuildingContext) as BuildingContextProps;
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <AsideTabs
      onClose={closeHandler}
      onChange={changeStage}
      actions={[
        {
          label: 'Buscar contrato',
          accessor: BuildingAsideStage.FILTER_ACTION,
          disabled: dataIsEmpty,
        },
        {
          label: 'Buscar novo contrato',
          accessor: BuildingAsideStage.SEARCH_NEW_BUILDING,
        },
      ]}
    />
  );
};

export default SelectStep;
