import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

export const ProgressBarContainer = styled.div`
  background-color: #d1d5db;
  border-radius: 9999px;
  height: 0.5rem;
  position: relative;
  overflow: hidden;
`;

export const Progress = styled.div.attrs({
  className: 'progress-bar',
})<{ infinite: boolean }>`
  background-color: ${theme.color.secondary.purple[2]};
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;

  ${({ infinite }) =>
    infinite &&
    css`
      width: 50%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: progress;

      @keyframes progress {
        0% {
          left: -50%;
        }
        100% {
          left: 100%;
        }
      }
    `}
`;

export const BarSize = styled.div`
  width: 100%;
  height: 6px;
`;
