import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ContactType } from '../../../../../../../../@types/auth';
import Button from '../../../../../../../../components/Button';
import CustomText from '../../../../../../../../components/CustomText';
import HookFeedbackError from '../../../../../../../../components/HookFeedbackError';
import Input from '../../../../../../../../components/Input';
import Alert from '../../../../../../../../components/Toast/toast';
import { CustomErrorType, errorHandler } from '../../../../../../../../exceptions/errorHandler';
import useAbortController from '../../../../../../../../hooks/useAbortController';
import AccountService from '../../../../../../../../services/Account/AccountService';
import { EXCEPTIONS_MESSAGE, ExceptionSlug } from '../../../../../../../../utils/constants/exceptions';
import { unmaskValue } from '../../../../../../../../utils/helpers/validate';
import AccountContext, { AccountContextProps } from '../../../../../../context';
import { SubmitCodeContainer } from '../../../../../../style';
import { FormStage } from '../../../../../../type';
import { CredentialsContainer } from '../../../common/style';
import PhoneActionContext, { PhoneActionContextProps } from '../../context';
import RequestForm from '../RequestForm';
import { Form } from './style';

interface CredentialsData {
  phone: string;
  confirmationCode: string;
}

const Credentials = () => {
  const {
    control,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setError,
  } = useForm<CredentialsData>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      confirmationCode: '',
    },
  });

  const [loadingConfirmationCode, setLoadingConfirmationCode] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { abort, getSignal } = useAbortController();

  const { closeAction } = useContext(AccountContext) as AccountContextProps;
  const { setStage, setSelectedPhone } = useContext(PhoneActionContext) as PhoneActionContextProps;

  const onError = (e: unknown) => {
    const handledError = errorHandler(e);
    if (handledError.type === CustomErrorType.AXIOS_ERROR) {
      const { firstSlug } = handledError;
      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_EXPIRED) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_EXPIRED,
        });
        return;
      }

      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_INVALID) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_INVALID,
        });
        return;
      }

      closeAction();
      Alert.ERROR('Ocorreu um erro ao tentar trocar sua senha. Por favor, tente novamente em alguns instantes.');
    }

    if (handledError.type === CustomErrorType.STOCK_ERROR) {
      console.error(e);
    }
  };

  const requestConfirmationCode = async (data: { contactType: ContactType }) => {
    setLoadingConfirmationCode(true);

    abort();
    const signal = getSignal();

    try {
      await AccountService.getConfirmationCode(data.contactType, signal);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingConfirmationCode(false);
    }
  };

  const onSubmit = async (data: CredentialsData) => {
    setLoadingSubmit(true);
    abort();
    const signal = getSignal();
    const newPhone = `55${unmaskValue(data.phone)}`;

    try {
      await AccountService.changePhone({ confirmationCode: data.confirmationCode, newPhone }, signal);
      setSelectedPhone(newPhone);
      setStage(FormStage.CONFIRM);
    } catch (e) {
      onError(e);
    }
  };

  const watchConfirmationCode = watch('confirmationCode');

  const isValidForm = isValid && watchConfirmationCode.length === 6;

  return (
    <CredentialsContainer>
      <RequestForm onSubmit={requestConfirmationCode} loading={loadingConfirmationCode} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubmitCodeContainer>
          <CustomText as="p">Digite o código recebido no e-mail</CustomText>
          <Controller
            name="confirmationCode"
            rules={{
              required: 'Campo obrigatório',
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input.Form
                {...field}
                placeholder="000000"
                label="Código"
                helper={<HookFeedbackError errors={errors} name={field.name} />}
                error={Boolean(error)}
                maxLength={6}
              />
            )}
          />
        </SubmitCodeContainer>
        <CustomText as="p" className="title">
          Digite seu novo número de celular.
        </CustomText>
        <Controller
          name="phone"
          rules={{
            required: 'Campo obrigatório',
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Masked
              {...field}
              inputMode="numeric"
              format="(99) 99999-9999"
              label="Novo número de celular"
              placeholder="(00) 00000-0000"
              error={Boolean(error)}
            />
          )}
        />
        <Button disabled={!isValidForm || loadingSubmit} loading={loadingSubmit} variant="primary">
          Alterar número de celular
        </Button>
      </Form>
    </CredentialsContainer>
  );
};

export default Credentials;
