import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { LocalStorageKey } from '../../utils/helpers/storage';
import { Container } from './style';
import CustomText from '../../components/CustomText';
import Button from '../../components/Button';
import ModalPortal from '../ModalPortal';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const FavoriteWarning = () => {
  const { isMobileWidth } = useWindowDimensions();
  const [alreadyNoticedFavoriteMessage, setAlreadyNoticedFavoriteMessage] = useLocalStorage(
    LocalStorageKey.ALREADY_NOTICED_FAVORITE_MESSAGE,
    false
  );

  if (alreadyNoticedFavoriteMessage) return null;
  if (isMobileWidth) return null;

  const handleConfirm = () => {
    setAlreadyNoticedFavoriteMessage(true);
  };

  return (
    <ModalPortal visible={!alreadyNoticedFavoriteMessage}>
      <Container>
        <div className="header">
          <CustomText as="h1">Ctrl+D ou ⌘+D - Adicione a TKE Digital aos seus Favoritos!</CustomText>
          <CustomText as="p">
            Tenha a TKE Digital sempre à mão! Clique em Ctrl+D (Windows) ou ⌘+D (Mac) agora mesmo e salve nossos
            serviços nos seus favoritos para acessá-los com facilidade no futuro. Simplifique sua vida com a TKE Digital
            ao alcance de um clique!
          </CustomText>
        </div>
        <Button variant="primary" onClick={handleConfirm} size="medium">
          Ok, entendi
        </Button>
      </Container>
    </ModalPortal>
  );
};

export default FavoriteWarning;
