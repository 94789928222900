import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  position: fixed;
  gap: 32px;
`;
