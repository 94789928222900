import { UserType } from '../../@types/auth';

export const routesByUserType: { [key in UserType]: string[] } = {
  COLLABORATOR: ['/partner', '/external', '/redirect', '/apps'],
  CUSTOMER: [
    '/home',
    '/external',
    '/account',
    '/redirect',
    '/home/financial-details/:id',
    '/home/view/bill/:id',
    '/home/view/invoice/:id',
    '/settings/partner',
    '/apps',
  ],
};

export const dashboardRoutesByUserType: { [key in UserType]: string } = {
  COLLABORATOR: '/partner',
  CUSTOMER: '/home',
};
