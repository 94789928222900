import React from 'react';
import CustomText from '../../../../../../../components/CustomText';
import DisabledListHeader from '../../../../../../../components/TableView/components/DisabledTableHeader';
import { DisabledOrderWrapper } from '../../../../../../../components/TableView/components/HeaderOrderWrapper';
import useDisabledTableHeader from '../../../../../../../components/TableView/hooks/useDisabledTableHeader';

const DisableFinancialsDetailsHeader = () => {
  const props = useDisabledTableHeader([
    {
      width: '1fr',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Nome do usuário</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '190px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Data</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '120px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Hora</CustomText>
        </DisabledOrderWrapper>
      ),
    },
    {
      width: '120px',
      render: (
        <DisabledOrderWrapper>
          <CustomText>Operação</CustomText>
        </DisabledOrderWrapper>
      ),
    },
  ]);

  return <DisabledListHeader {...props} />;
};

export default DisableFinancialsDetailsHeader;
