import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding: 0 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {
    font-family: 'TKEType-Medium', sans-serif;
    font-size: 32px;
    line-height: 50px;
    color: #262626;
  }

  p {
    font-family: 'TKEType-Regular', sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #262626;
  }

  button {
    margin-top: 16px;
    align-self: center;
  }
`;
