import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M4.309 14.667V3.277H15v15H4.027M5.434 5.747h8.44M5.434 7.753h8.44M5.434 9.758h8.44M5.434 11.764h5.275M5.434 13.77h8.44M5.434 15.775h6.595"
    />
  </svg>
);

export default SvgPage;
