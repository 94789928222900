import React from 'react';
import Card from '../../../../../../../components/Card';
import { maskDocument } from '../../../../../../../utils/helpers/mask';
import AutoGrid from '../../../../../../../layouts/AutoGrid';
import Checkbox from '../../../../../../../components/SelectableButton/Checkbox';
import { WrapperDiv } from '../../../../../../../styles/components';
import { useWindowDimensions } from '../../../../../../../hooks/useWindowDimensions';
import { formatDate } from '../../../../../../../utils/helpers/date';
import InvoiceActions from '../InvoiceActions';
import { mapInvoiceTypeLabel } from '../../constants';
import { GetSelectionPropsFunc } from '../../../../../../../hooks/useDataSelection';
import { InvoiceData } from '../../../../../../../@types/invoice';

type InvoiceGridProps =
  | {
      data: InvoiceData[];
      getSelectionProps: GetSelectionPropsFunc<InvoiceData>;
      mobile?: false;
    }
  | {
      data: InvoiceData[];
      getSelectionProps?: GetSelectionPropsFunc<InvoiceData>;
      mobile: true;
    };

const InvoiceGrid = ({ data, getSelectionProps, mobile }: InvoiceGridProps) => {
  const selectionProps = getSelectionProps?.();
  const { isMobileWidth } = useWindowDimensions();
  const showCheckbox = !isMobileWidth && !mobile;

  return (
    <AutoGrid>
      {data.map((invoice) => (
        <Card.Root key={invoice.id} className="__financial-card">
          <Card.Row>
            <Card.Cell>
              <Card.Label>CNPJ/CPF</Card.Label>
              <Card.Data>{invoice.clientDocument ? maskDocument(invoice?.clientDocument) : 'Sem documento'}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <WrapperDiv gap={16}>
                {showCheckbox && (
                  <Checkbox
                    onChange={() => selectionProps!.handleSelect(invoice)}
                    checked={selectionProps!.selectedDataHasElement(invoice)}
                    disabled={selectionProps!.disableSelectionPerRowWhen?.(invoice)}
                  />
                )}
              </WrapperDiv>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell>
              <Card.Label>Cliente</Card.Label>
              <Card.Data>{invoice.clientName || 'Sem nome de cliente'}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Contrato</Card.Label>
              <Card.Data>{invoice.contract}</Card.Data>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell>
              <Card.Label>Nº NF</Card.Label>
              <Card.Data>{invoice.invoiceNumber}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Emissão</Card.Label>
              <Card.Data>{formatDate(new Date(invoice.emittedAt))}</Card.Data>
            </Card.Cell>
            <Card.Cell>
              <Card.Label>Materiais/Serviços</Card.Label>
              <Card.Data>{mapInvoiceTypeLabel[invoice.type] || 'N/D'}</Card.Data>
            </Card.Cell>
          </Card.Row>
          <Card.Row>
            <Card.Cell style={{ maxWidth: 'none', gap: '8px' }}>
              <Card.Label>Ações</Card.Label>
              <InvoiceActions data={invoice} ignoreDropdown />
            </Card.Cell>
          </Card.Row>
        </Card.Root>
      ))}
    </AutoGrid>
  );
};

export default InvoiceGrid;
