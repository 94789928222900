import { createContext } from 'react';

export interface TopbarContextProps {
  isPartnerUser: boolean;
  userAcronym: string;
  userProfileIsOpen: boolean;
  sideBarIsOpen: boolean;
  closeSidebar: VoidFunction;
  openSidebar: VoidFunction;
  closeUserProfile: VoidFunction;
  openUserProfile: VoidFunction;
  signOutHandler: VoidFunction;
}

const TopbarContext = createContext<TopbarContextProps | null>(null);

export default TopbarContext;
