import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  .__modal-content-title {
    font-size: 16px;
  }

  .bold {
    font-family: 'TKEType-Bold', sans-serif;
    font-weight: 600;
    font-style: normal;
  }
`;
