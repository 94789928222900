import React from 'react';
import CustomText from '../CustomText';
import { Header } from './style';
import { CloseIcon } from '../../icons';
import { WrapperButton } from '../../styles/components';

interface ModalHeaderProps {
  title?: string;
  onClose?: VoidFunction;
}
const ModalHeader = ({ onClose, title }: ModalHeaderProps) => (
  <Header>
    <div>{title && <CustomText className="__modal-title">{title}</CustomText>}</div>
    {onClose && (
      <WrapperButton onClick={onClose} cursor="pointer">
        <CloseIcon />
      </WrapperButton>
    )}
  </Header>
);

export default ModalHeader;
