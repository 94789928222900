import { useEffect, useState } from 'react';
import EventManager from '../lib/EventManager';

export interface WindowSizes {
  width: number;
  height: number;
}

interface ResizeCallback {
  (dimensions: WindowSizes): void;
}

const eventManager = new EventManager<{ width: number; height: number }>();

window.addEventListener('resize', () => {
  const { innerWidth, innerHeight } = window;
  eventManager.emit('resize', { width: innerWidth, height: innerHeight });
});

export function useWindowDimensions(): WindowSizes & { isMobileWidth: boolean } {
  const [windowDimensions, setWindowDimensions] = useState<WindowSizes>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize: ResizeCallback = ({ width, height }) => {
      setWindowDimensions({ width, height });
    };

    eventManager.on('resize', handleResize);

    return () => {
      eventManager.removeListener('resize', handleResize);
    };
  }, []);

  const isMobileWidth = windowDimensions.width <= 1024;

  return {
    width: windowDimensions.width,
    height: windowDimensions.height,
    isMobileWidth,
  };
}
