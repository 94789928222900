import React from 'react';
import { BuildingData } from '../../../../../../../../@types/building';
import AutoGrid from '../../../../../../../../layouts/AutoGrid';
import Card from '../../../../../../../../components/Card';
import { maskDocument } from '../../../../../../../../utils/helpers/mask';
import { Actions } from '../../../../../../Client/containers/Building/style';
import IntegrationLinks from '../../../../../../shared/components/IntegrationLinks';
import BuildingPolicy from '../../../../../../../../strategy/rules/BuildingPolicy';
import BuildingTooltips from '../../../../../../shared/components/BuildingTooltips';

interface GridProps {
  data: BuildingData[];
}

const Grid = ({ data }: GridProps) => (
  <AutoGrid>
    {data.map((building) => (
      <Card.Root key={building.id}>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Nº Edifício</Card.Label>
            <Card.Data>{building.id}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Cliente</Card.Label>
            <Card.Data>{building.clientName}</Card.Data>
          </Card.Cell>
          <Card.Cell>
            <Card.Label>Localização</Card.Label>
            <Card.Data>{`${building.city} - ${building.uf}`}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>CNPJ ou CPF</Card.Label>
            <Card.Data>{maskDocument(building.document)}</Card.Data>
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell>
            <Card.Label>Contratos</Card.Label>
            <BuildingTooltips businessContracts={building.contracts} />
          </Card.Cell>
        </Card.Row>
        <Card.Row>
          <Card.Cell style={{ maxWidth: 'none', gap: '8px' }}>
            <Card.Label>Módulos</Card.Label>
            <Actions>
              <IntegrationLinks
                buildingId={building.id}
                contractId={building.contracts[0].id}
                disableBuildings={!BuildingPolicy.partnerCanAccessNewBuildings(building)}
                showFinancialLink
              />
            </Actions>
          </Card.Cell>
        </Card.Row>
      </Card.Root>
    ))}
  </AutoGrid>
);

export default Grid;
