import React, { FunctionComponent, ReactElement } from 'react';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { ToasterContentWrapper } from './style';
import theme from '../../styles/theme';
import getFeedbackIcon from '../../utils/jsx/getFeedbackIcon';
import Loader from '../Loader';

type AlertMessage = string | ReactElement;

interface ToastComponentProps {
  message: AlertMessage;
}

export const ErrorToast: FunctionComponent<ToastComponentProps> = ({ message }) => (
  <ToasterContentWrapper $color={theme.color.feedback.error}>
    {getFeedbackIcon('error')}
    <p>{message}</p>
  </ToasterContentWrapper>
);

export const WarningToast: FunctionComponent<ToastComponentProps> = ({ message }) => (
  <ToasterContentWrapper $color={theme.color.feedback.alert}>
    {getFeedbackIcon('warning')}
    <p>{message}</p>
  </ToasterContentWrapper>
);

export const InfoToast: FunctionComponent<ToastComponentProps> = ({ message }) => (
  <ToasterContentWrapper $color={theme.color.feedback.alert}>
    <p style={{ maxWidth: '100%' }}>{message}</p>
  </ToasterContentWrapper>
);

export const SuccessToast: FunctionComponent<ToastComponentProps> = ({ message }) => (
  <ToasterContentWrapper $color={theme.color.feedback.success}>
    {getFeedbackIcon('success')}
    <p>{message}</p>
  </ToasterContentWrapper>
);

export const LoadingToast: FunctionComponent<ToastComponentProps> = ({ message }) => (
  <ToasterContentWrapper $color={theme.color.secondary.purple[2]}>
    <Loader />
    <p>{message}</p>
  </ToasterContentWrapper>
);

export enum AlertTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
  LOADING = 'LOADING',
}

type AlertMethod = (message: AlertMessage, options?: ToastOptions<{}> | undefined) => void;
type AlertPromiseMethod = <T extends unknown>(promise: Promise<T>) => Promise<T>;
type AlertUpdateMethod = (id: string, type: AlertTypes, message: string) => void;

type AlertFunctions = {
  [key in AlertTypes]: AlertMethod;
} & {
  PROMISE: AlertPromiseMethod;
  UPDATE: AlertUpdateMethod;
};

type MapToasts = Record<AlertTypes, React.ComponentType<ToastComponentProps>>;

const mapToasts: MapToasts = {
  [AlertTypes.SUCCESS]: SuccessToast,
  [AlertTypes.ERROR]: ErrorToast,
  [AlertTypes.WARNING]: WarningToast,
  [AlertTypes.INFO]: InfoToast,
  [AlertTypes.LOADING]: LoadingToast,
};

const renderAlert = (type: AlertTypes, message: string) => {
  const ToastComponent = mapToasts[type];
  return <ToastComponent message={message} />;
};

const Alert: AlertFunctions = {
  SUCCESS: (message) => toast(<SuccessToast message={message} />),
  ERROR: (message) => toast(<ErrorToast message={message} />),
  INFO: (message) => toast(<InfoToast message={message} />),
  WARNING: (message) => toast(<WarningToast message={message} />),
  LOADING: (message, options) => toast(<LoadingToast message={message} />, options),
  PROMISE: (promise) =>
    toast.promise(promise, {
      error: { render: <ErrorToast message="Erro" />, icon: false },
      success: { render: <SuccessToast message="Sucesso" />, icon: false },
      pending: { render: <LoadingToast message="Carregando..." />, icon: false },
    }),
  UPDATE: (id: string, type: AlertTypes, message: string) =>
    toast.update(id, {
      render: renderAlert(type, message),
    }),
};

export default Alert;
