import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: unset;
  gap: 8px;
  margin-bottom: 4px !important;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
