/**
 * Checks if the parameter is a function.
 * @param value The value to be checked.
 * @returns A boolean indicating whether the value is a function (true) or not (false).
 * @example
 * isFunction(() => {}); // Returns: true
 * isFunction(function() {}); // Returns: true
 * isFunction(42); // Returns: false
 */
export function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}
