import { useCallback, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

export enum Layout {
  LIST = 'LIST',
  GRID = 'GRID',
}

const useLayout = () => {
  const [layout, setLayout] = useState<Layout>(Layout.LIST);

  const { isMobileWidth } = useWindowDimensions();
  const showGrid = isMobileWidth || layout === Layout.GRID;

  const toggleLayout = useCallback(() => {
    setLayout((prevState) => (prevState === Layout.LIST ? Layout.GRID : Layout.LIST));
  }, []);

  return {
    showGrid,
    toggleLayout,
    layout,
  };
};

export default useLayout;
