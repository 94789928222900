/* eslint @typescript-eslint/naming-convention: 0 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthContext';
import FullScreenPageLayout from '../../../../layouts/FullScreenPageLayout';
import { ExternalToken } from '../../../../@types/auth';
import useQueryParam from '../../../../hooks/useQueryParam';

export const ExternalLogin = () => {
  const navigate = useNavigate();

  const externalToken = useQueryParam('externalToken');
  const clientId = useQueryParam('clientId');

  const { signInExternal } = useAuth();
  const [error, setError] = useState<Boolean>(false);

  const validateCollaboratorLoginRoute = () => {
    if (!externalToken || !clientId) {
      setError(true);
    } else {
      signInExternal(externalToken as ExternalToken, clientId)
        .then((externalInfo) => {
          navigate(`/home?tab=${externalInfo.destination}`);
          setError(false);
        })
        .catch(() => {
          setError(true);
          setTimeout(() => {
            navigate('/');
          }, 10000);
        });
    }
  };

  useEffect(() => {
    validateCollaboratorLoginRoute();
  }, []);

  return error ? (
    <FullScreenPageLayout
      title="Ops...Acesso não validado"
      description="A autenticação via App falhou. Estamos redirecionando você para a tela de login. Por favor, tente novamente."
    />
  ) : (
    <FullScreenPageLayout
      title="Acessando o TKE Digital"
      description="Estamos validando o seu acesso. Aguarde para acessar..."
      loading
    />
  );
};
