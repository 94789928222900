import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import AsideTabs from '../../../../../../../../../layouts/AsideTabs';
import { InvoiceAsideStage } from '../../../../../../../../../@types/invoice';
import InvoiceContext, { InvoiceContextProps } from '../../../../context';

const SelectStep = () => {
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;
  const { dataIsEmpty, loading, signalRConnectionReachedMaxRetries } = useContext(
    InvoiceContext
  ) as InvoiceContextProps;
  return (
    <AsideTabs
      onClose={closeHandler}
      onChange={changeStage}
      actions={[
        {
          label: 'Buscar notas fiscais',
          accessor: InvoiceAsideStage.FILTER_ACTION,
          disabled: dataIsEmpty || loading || signalRConnectionReachedMaxRetries,
        },
        {
          label: 'Buscar nova nota fiscal',
          accessor: InvoiceAsideStage.SEARCH_NEW_INVOICE,
          disabled: loading || signalRConnectionReachedMaxRetries,
        },
        {
          label: 'Configurar data',
          accessor: InvoiceAsideStage.SELECT_DATE_ACTION,
          disabled: loading || signalRConnectionReachedMaxRetries,
        },
      ]}
    />
  );
};

export default SelectStep;
