import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';

export const PasswordTips = styled.div<{ error?: boolean }>`
  font-weight: 400;
  font-size: 1.2rem;
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  line-height: 18px;

  color: ${({ error }) => (error ? theme.color.feedback.error : theme.color.primary.black)};
`;
