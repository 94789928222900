import styled from 'styled-components';

export const StyledReferenceWrapper = styled.div`
  height: fit-content;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;

  /* Resetting font styles */
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  /* Resetting background and text colors */
  background-color: transparent;
  color: inherit;
`;
