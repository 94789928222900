import React, { useEffect, useState } from 'react';

// This hook will set the load to false if the time set by props is up
const useTimedLoading = (timeout = 30000, defaultValue = true) => {
  const [isLoading, setIsLoading] = useState<boolean>(defaultValue);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout | undefined;

    if (isLoading) {
      timerId = setTimeout(() => {
        setIsLoading(false);
      }, timeout);
    }

    return () => clearTimeout(timerId);
  }, [timeout, isLoading]);

  return [isLoading, setIsLoading] as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export default useTimedLoading;
