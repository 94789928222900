/* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
import { createContext } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { GetErrorsFn, MountSingleSendFn, MountRegisterListenerFn, MountRemoveListenerFn } from './types';

export interface SignalRContextProps {
  _mountRemoveListener: MountRemoveListenerFn;
  _mountSingleSend: MountSingleSendFn;
  _mountRegisterListener: MountRegisterListenerFn;
  _connections: Map<string, HubConnection>;
  getErrors: GetErrorsFn;
}

export const SignalRContext = createContext<SignalRContextProps | null>(null);
