import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.028 13.337a6.447 6.447 0 0 1-3.88 1.292 6.49 6.49 0 0 1-6.481-6.482 6.488 6.488 0 0 1 6.481-6.48 6.488 6.488 0 0 1 6.482 6.48 6.448 6.448 0 0 1-1.293 3.88l4.996 4.996-1.309 1.31zm-8.51-5.19a4.636 4.636 0 0 0 4.63 4.63 4.635 4.635 0 0 0 4.63-4.63 4.635 4.635 0 0 0-4.63-4.629 4.635 4.635 0 0 0-4.63 4.63"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSearch;
