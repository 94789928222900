import styled from 'styled-components';
import theme from '../../styles/theme';

export const Message = styled.p`
  font-family: 'TKEType-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: ${theme.color.feedback.error};
`;
