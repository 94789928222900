import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

const DISABLED_BUTTONS_STYLE = css`
  color: ${theme.color.secondary.grey[3]};
`;

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px;
`;

export const StackActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  min-width: 190px;
  width: 100%;
  height: 48px;

  &:hover {
    background: ${theme.color.secondary.purple[4]};
  }

  &[disabled] {
    ${DISABLED_BUTTONS_STYLE};
  }
`;
