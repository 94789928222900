import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import TopbarContext from './context';
import { useAuth } from '../../context/AuthContext';
import useDisclosure from '../../hooks/useDisclosure';

const Topbar = () => {
  const { isOpen: sideBarIsOpen, open: openSidebar, close: closeSidebar } = useDisclosure(false);
  const { isOpen: userProfileIsOpen, open: openUserProfile, close: closeUserProfile } = useDisclosure(false);

  const { getUserRole, signOut, user } = useAuth();

  const isPartnerUser = getUserRole() === 'COLLABORATOR';

  const navigate = useNavigate();

  const signOutHandler = () => {
    signOut();
    navigate('/');
  };

  const getUserNameAcronym = () => {
    const name = user?.name as string;
    if (!name || name === '') return '';
    const nameSections = name.toUpperCase().split(' ');
    const countSections = nameSections.length;

    return nameSections[0].charAt(0) + (countSections > 1 ? nameSections[countSections - 1].charAt(0) : '');
  };

  const userAcronym = getUserNameAcronym();

  const context = useMemo(
    () => ({
      userAcronym,
      sideBarIsOpen,
      openSidebar,
      closeSidebar,
      userProfileIsOpen,
      closeUserProfile,
      openUserProfile,
      signOutHandler,
      isPartnerUser,
    }),
    [userAcronym, sideBarIsOpen, userProfileIsOpen]
  );

  return (
    <TopbarContext.Provider value={context}>
      <Header />
      <Sidebar />
    </TopbarContext.Provider>
  );
};

export default Topbar;
