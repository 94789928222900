import React from 'react';
import { Container, Grid, SkeletonBackground, SkeletonBox } from '../../style';
import FormHeaderSkeleton from '../FormHeaderSkeleton';
import InputSkeleton from '../InputSkeleton';
import { SkeletonForm } from './style';
import { WrapperDiv } from '../../../../styles/components';

const SkeletonForgot = () => (
  <Grid>
    <SkeletonBackground />
    <Container>
      <FormHeaderSkeleton />
      <SkeletonForm>
        <InputSkeleton />
        <InputSkeleton />
        <WrapperDiv gap={16}>
          <SkeletonBox height={44} />
          <SkeletonBox height={44} />
        </WrapperDiv>
      </SkeletonForm>
    </Container>
  </Grid>
);

export default SkeletonForgot;
