export const normalizeString = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const removeSpecialCharacters = (str: string) => str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');

export const stringToObject = (str: string) => {
  // remove #, is in the first position
  const query = str.slice(1);

  return query.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=');
    return { ...acc, [key]: value };
  }, {});
};

/**
 * Compose a string by replacing the given symbol with the provided variables.
 * @param {string} baseString - The base string with the symbol to replace.
 * @param {string} symbol - The symbol to be replaced in the base string.
 * @param {...any} vars - Any number of strings to substitute into the string.
 * @returns {string} - The composed string with substitutions.
 */
export function compose(baseString: string, symbol: string, ...vars: string[]): string {
  let composedString = baseString;
  vars.forEach((_var) => {
    composedString = composedString.replace(symbol, _var);
  });
  return composedString;
}

export function buildUrl(baseUrl: string, path: string) {
  let result = baseUrl;

  if (result.endsWith('/') && path.startsWith('/')) {
    result = result!.slice(0, -1);
  }

  if (!result.endsWith('/') && !path.startsWith('/')) {
    result += '/';
  }

  return result + path;
}

export function isString(value: unknown): value is string {
  if (value === null || value === undefined) return false;
  return typeof value === 'string';
}

export function objectToQueryString<T extends object>(obj: T): string {
  const queryParams: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (Array.isArray(value)) {
        value.forEach((nestValue) => {
          queryParams.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(nestValue)}`);
        });
      } else {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });

  return queryParams.join('&');
}
