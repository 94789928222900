import {
  FinancialHistoricOrderBy,
  FinancialHistoricSearchBy,
  HistoricOperation,
  HistoricUserType,
} from '../../../@types/bill';

export const mapFinancialHistoricOperationToDescription = {
  [HistoricOperation.VIEW]: 'Usuário acessou os detalhes do boleto',
  [HistoricOperation.DOWNLOAD]: 'Usuário fez o download ou imprimiu o boleto',
  [HistoricOperation.SEND_EMAIL]: 'E-mail enviado para $',
  [HistoricOperation.NEW_NEGOTIATION]: 'Nova negociação',
};

export const mapFinancialHistoricOperationLabel = {
  [HistoricOperation.VIEW]: 'Visualização',
  [HistoricOperation.DOWNLOAD]: 'Download',
  [HistoricOperation.SEND_EMAIL]: 'Envio por e-mail',
  [HistoricOperation.NEW_NEGOTIATION]: 'Nova negociação',
};

export const mapHistoricUserTypeLabel = {
  [HistoricUserType.UNDEFINED]: 'Anônimo',
  [HistoricUserType.CUSTOMER]: 'Cliente',
  [HistoricUserType.COLLABORATOR]: 'Download',
  [HistoricUserType.ADMINISTRATOR]: 'Administrador',
  [HistoricUserType.EXTERNAL]: 'Externo',
};

export const FINANCIAL_HISTORIC_ORDER_OPTIONS: Array<{ label: string; option: FinancialHistoricOrderBy }> = [
  {
    label: 'Operação',
    option: 'type',
  },
  {
    label: 'Nome do usuário',
    option: 'userName',
  },
  {
    label: 'Descrição',
    option: 'information',
  },
  {
    label: 'Data',
    option: 'date',
  },
  {
    label: 'Tipo de usuário',
    option: 'userType',
  },
  {
    label: 'Hora',
    option: 'hour',
  },
];

export const FINANCIAL_HISTORIC_FILTER_OPTIONS: Array<{ label: string; option: FinancialHistoricSearchBy }> = [
  {
    label: 'Operação',
    option: 'type',
  },
  {
    label: 'Nome do usuário',
    option: 'userName',
  },
  {
    label: 'Descrição',
    option: 'information',
  },
  {
    label: 'Data',
    option: 'date',
  },
  {
    label: 'Tipo de usuário',
    option: 'userType',
  },
  {
    label: 'Hora',
    option: 'hour',
  },
];
