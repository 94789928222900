import React from 'react';
import { Content } from './style';
import CustomText from '../../../../../components/CustomText';
import { formatNumberToBRL } from '../../../../../utils/helpers/money';
import { formatDate } from '../../../../../utils/helpers/date';

interface SendEmailContentProps {
  documentNumber?: string;
  expiresAt?: string;
  totalAmount?: number;
}

const BillSendEmailContent = ({ documentNumber, expiresAt, totalAmount }: SendEmailContentProps) => (
  <Content>
    <CustomText className="__modal-content-title bold">Boleto</CustomText>
    <CustomText className="__modal-content">
      Nº do documento{' '}
      <CustomText as="span" className="bold">
        {documentNumber}
      </CustomText>
    </CustomText>
    <CustomText className="__modal-content">
      Vencimento{' '}
      <CustomText as="span" className="bold">
        {expiresAt && formatDate(new Date(expiresAt))}
      </CustomText>
    </CustomText>
    <CustomText className="__modal-content bold">Valor final {formatNumberToBRL(totalAmount)}</CustomText>
  </Content>
);

export default BillSendEmailContent;
