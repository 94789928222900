import React, { useMemo } from 'react';
import { Header, Main, TabTitle, Title } from './style';
import ClientContext from './context';
import Tabs from '../../../components/Tabs';
import BillContainer from './containers/Bill';
import BuildingContainer from './containers/Building';
import { WrapperDiv } from '../../../styles/components';
import MobileTabs from '../../../components/MobileTabs';
import { TabAccessor } from '../../../@types/dashboard';
import InvoiceContainer from './containers/Invoice';
import { mapTabLabel, tabs } from './constants';
import PageLayout from '../../../layouts/PageLayout';
import useClient from './useClient';

const Home = () => {
  const { selectedTab, setSelectedTab, scrollToRef, handleTabChange, updateFinancialData, isMobileWidth, scrollRef } =
    useClient();

  const context = useMemo(
    () => ({ selectedTab, setSelectedTab, scrollToRef, handleTabChange, updateFinancialData }),
    [selectedTab, setSelectedTab]
  );

  return (
    <ClientContext.Provider value={context}>
      <PageLayout>
        <Header>
          <Title ref={scrollRef}>Meu painel</Title>
          {isMobileWidth ? (
            <WrapperDiv style={{ width: '100%' }}>
              <TabTitle>{mapTabLabel[selectedTab]}</TabTitle>
              <MobileTabs title="Listas" tabs={tabs} onChange={handleTabChange} />
            </WrapperDiv>
          ) : (
            <Tabs tabs={tabs} onChange={handleTabChange} selectedTab={selectedTab} />
          )}
        </Header>
        <Main>
          {selectedTab === TabAccessor.BUILDING && <BuildingContainer />}
          {selectedTab === TabAccessor.FINANCIAL && <BillContainer />}
          {selectedTab === TabAccessor.INVOICE && <InvoiceContainer />}
        </Main>
      </PageLayout>
    </ClientContext.Provider>
  );
};

export default Home;
