import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
  html {
    height: fill-available;
    height: -webkit-fill-available;
  }

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  :root {
    --toastify-toast-width: 340px !important;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 100%;
    font-size: 62.5%; /* ~10px = 16px(default) * 0.625 */
    vertical-align: baseline;

    @media (max-width: 1366px) {
      font-size: 55%;
    }
  }

  body[data-scroll-lock="true"] {
    touch-action:none;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .Floating {
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 3px 8px rgba(38, 35, 33, 0.16);
  }

  // Hide chatbot in mobile screens
  @media screen and (max-width: 480px) {
    .ib-widget-button {
      display: none !important;
    }
  }

  .ib-widget-wrapper {
    z-index: ${theme.defaults.chatBotZIndex} !important;
  }

  .rotate-90 {
    transform: rotate(90deg);
  }

  .rotate-180 {
    transform: rotate(180deg);
  }
`;
