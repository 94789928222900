import React from 'react';
import { SelectDataContainer } from '../../../style';
import CustomText from '../../../../../../../../components/CustomText';
import RadioGroup from '../../../../../../../../components/SelectableButton/Radio/Group';
import { FileDataType } from '../../../../../../../../@types/file';

interface SelectDataTypeProps {
  selectedDataType: FileDataType;
  onChange: (newSelectedDataType: FileDataType) => void;
}

const SelectDataType = ({ selectedDataType, onChange }: SelectDataTypeProps) => (
  <SelectDataContainer>
    <CustomText as="p">Selecione o formato do arquivo:</CustomText>
    <RadioGroup
      name="modal_select_data"
      options={[
        {
          label: <CustomText>.CSV</CustomText>,
          option: FileDataType.CSV,
        },
        {
          label: <CustomText>.JSON</CustomText>,
          option: FileDataType.JSON,
        },
      ]}
      align="column"
      selectedOption={selectedDataType}
      onChange={onChange}
    />
  </SelectDataContainer>
);

export default SelectDataType;
