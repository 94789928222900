import React, { FunctionComponent } from 'react';
import { Placement } from '@floating-ui/core/src/types';
import Floating from '../../index';
import { StyledReferenceWrapper } from '../../style';

export interface UnstyledWrapperWithFloatingProps {
  content: JSX.Element;
  floating: JSX.Element;
  placement?: Placement;
  tooltipBoxStyleClass?: string;
  usePortal?: boolean;
  disabled?: boolean;
  usingSafePolygon?: boolean;
}

const ReferenceWrapper = React.forwardRef<
  HTMLDivElement,
  Pick<UnstyledWrapperWithFloatingProps, 'content' | 'disabled'> & { open?: boolean }
>(({ content, open, disabled, ...props }, ref) => (
  <StyledReferenceWrapper
    className={open ? 'tooltip_button tooltip_button_open' : 'tooltip_button'}
    ref={ref}
    {...props}
  >
    {content}
  </StyledReferenceWrapper>
));

const UnstyledWrapperWithFloating: FunctionComponent<UnstyledWrapperWithFloatingProps> = ({
  placement,
  floating,
  content,
  tooltipBoxStyleClass,
  usePortal,
  disabled = false,
  usingSafePolygon,
}) => (
  <Floating
    usePortal={usePortal}
    placement={placement}
    button={<ReferenceWrapper content={content} disabled={disabled} />}
    customClassname={tooltipBoxStyleClass}
    disabled={disabled}
    usingSafePolygon={usingSafePolygon}
  >
    {floating}
  </Floating>
);

export default UnstyledWrapperWithFloating;
