import api from '../../config/http/api';
import { ContactType } from '../../@types/auth';

interface ChangePasswordRequest {
  confirmationCode: string;
  oldPassword: string;
  newPassword: string;
}

interface ChangeEmailRequest {
  confirmationCode: string;
  newEmail: string;
}

interface ChangePhoneRequest {
  confirmationCode: string;
  newPhone: string;
}

const getConfirmationCode = async (contactType: ContactType, signal: AbortSignal) =>
  api.post<{ contactType: number }, void>(
    '/api/v1/customers/me/confirmation-code/send',
    {
      contactType: Number(contactType),
    },
    {
      isAuthorized: true,
      signal,
    }
  );

const changePassword = async (
  { confirmationCode, oldPassword, newPassword }: ChangePasswordRequest,
  signal: AbortSignal
) =>
  api.post<ChangePasswordRequest, void>(
    '/api/v1/customers/me/change-password',
    {
      confirmationCode,
      oldPassword,
      newPassword,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

const changePhone = async ({ confirmationCode, newPhone }: ChangePhoneRequest, signal: AbortSignal) =>
  api.post<ChangePhoneRequest, void>(
    '/api/v1/customers/me/change-phone',
    {
      confirmationCode,
      newPhone,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

const changeEmail = async ({ confirmationCode, newEmail }: ChangeEmailRequest, signal: AbortSignal) =>
  api.post<ChangeEmailRequest, void>(
    '/api/v1/customers/me/change-email',
    {
      confirmationCode,
      newEmail,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

const confirmChangeEmail = async ({ confirmationCode, newEmail }: ChangeEmailRequest, signal: AbortSignal) =>
  api.post<ChangeEmailRequest, void>(
    '/api/v1/customers/me/change-email/confirmation',
    {
      confirmationCode,
      newEmail,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

const confirmChangePhone = async ({ confirmationCode, newPhone }: ChangePhoneRequest, signal: AbortSignal) =>
  api.post<ChangePhoneRequest, void>(
    '/api/v1/customers/me/change-phone/confirmation',
    {
      confirmationCode,
      newPhone,
    },
    {
      isAuthorized: true,
      signal,
    }
  );

export default {
  getConfirmationCode,
  changePassword,
  changeEmail,
  changePhone,
  confirmChangePhone,
  confirmChangeEmail,
};
