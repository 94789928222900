import React, { FunctionComponent } from 'react';
import { FormStage } from './type';

interface FormProps {
  stage: FormStage;
}

const formMapping = {
  [FormStage.CREDENTIALS]: React.lazy(() => import('../Steps/Credentials')),
  [FormStage.CAPTCHA]: React.lazy(() => import('../Steps/Captcha')),
  [FormStage.SUBMIT_CODE]: React.lazy(() => import('../Steps/SubmitCode')),
  [FormStage.REQUEST_CODE]: React.lazy(() => import('../Steps/RequestCode')),
  [FormStage.FEEDBACK]: React.lazy(() => import('../Steps/Feedback')),
} as const;

const Form: FunctionComponent<FormProps> = ({ stage }) => {
  const Step = formMapping[stage];

  return <Step />;
};

export default Form;
