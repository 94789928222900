import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  flex: 1;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .disclaimer {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #262626;
  }
`;
