import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CustomText from '../../../../../../../../components/CustomText';
import { Form } from './style';
import Button from '../../../../../../../../components/Button';
import { CredentialsContainer } from '../../../common/style';
import { SubmitCodeContainer } from '../../../../../../style';
import HookFeedbackError from '../../../../../../../../components/HookFeedbackError';
import { REGEX_EMAIL_PATTERN } from '../../../../../../../../utils/constants/regex';
import AccountContext, { AccountContextProps } from '../../../../../../context';
import { FormStage } from '../../../../../../type';
import RequestForm from '../RequestForm';
import { CustomErrorType, errorHandler } from '../../../../../../../../exceptions/errorHandler';
import { EXCEPTIONS_MESSAGE, ExceptionSlug } from '../../../../../../../../utils/constants/exceptions';
import Alert from '../../../../../../../../components/Toast/toast';
import { ContactType } from '../../../../../../../../@types/auth';
import AccountService from '../../../../../../../../services/Account/AccountService';
import useAbortController from '../../../../../../../../hooks/useAbortController';
import EmailActionContext, { EmailActionContextProps } from '../../context';
import Input from '../../../../../../../../components/Input';

interface CredentialsData {
  email: string;
  confirmationCode: string;
}

const Credentials = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setError,
  } = useForm<CredentialsData>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      confirmationCode: '',
    },
  });

  const { closeAction } = useContext(AccountContext) as AccountContextProps;
  const { setStage, setSelectedEmail } = useContext(EmailActionContext) as EmailActionContextProps;
  const [loadingConfirmationCode, setLoadingConfirmationCode] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { abort, getSignal } = useAbortController();

  const onError = (e: unknown) => {
    const handledError = errorHandler(e);
    if (handledError.type === CustomErrorType.AXIOS_ERROR) {
      const { firstSlug } = handledError;
      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_EXPIRED) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_EXPIRED,
        });
        return;
      }

      if (firstSlug === ExceptionSlug.CONFIRMATION_CODE_INVALID) {
        setError('confirmationCode', {
          type: 'value',
          message: EXCEPTIONS_MESSAGE.CONFIRMATION_CODE_INVALID,
        });
        return;
      }

      closeAction();
      Alert.ERROR('Ocorreu um erro ao tentar trocar seu email. Por favor, tente novamente em alguns instantes.');
    }

    if (handledError.type === CustomErrorType.STOCK_ERROR) {
      console.error(e);
    }
  };

  const requestConfirmationCode = async (data: { contactType: ContactType }) => {
    setLoadingConfirmationCode(true);

    abort();
    const signal = getSignal();

    try {
      await AccountService.getConfirmationCode(data.contactType, signal);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingConfirmationCode(false);
    }
  };

  const onSubmit = async (data: CredentialsData) => {
    setLoadingSubmit(true);
    abort();
    const signal = getSignal();

    try {
      await AccountService.changeEmail({ confirmationCode: data.confirmationCode, newEmail: data.email }, signal);
      setSelectedEmail(data.email);
      setStage(FormStage.CONFIRM);
    } catch (e) {
      onError(e);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const watchConfirmationCode = watch('confirmationCode');

  const isValidForm = isValid && watchConfirmationCode.length === 6;

  return (
    <CredentialsContainer>
      <RequestForm onSubmit={requestConfirmationCode} loading={loadingConfirmationCode} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubmitCodeContainer>
          <CustomText as="p">Digite o código recebido por SMS.</CustomText>
          <Controller
            name="confirmationCode"
            rules={{
              required: 'Campo obrigatório',
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input.Form
                {...field}
                label="Código"
                placeholder="000000"
                helper={<HookFeedbackError errors={errors} name={field.name} />}
                error={Boolean(error)}
                maxLength={6}
              />
            )}
          />
        </SubmitCodeContainer>
        <CustomText as="p" className="title">
          Digite seu novo e-mail.
        </CustomText>
        <Controller
          name="email"
          rules={{
            required: 'Campo obrigatório',
            pattern: { value: REGEX_EMAIL_PATTERN, message: 'E-mail inválido.' },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input.Form
              {...field}
              type="email"
              helper={<HookFeedbackError errors={errors} name={field.name} />}
              label="Novo e-mail"
              error={Boolean(error)}
            />
          )}
        />
        <Button disabled={!isValidForm || loadingSubmit} loading={loadingSubmit} variant="primary">
          Alterar e-mail
        </Button>
      </Form>
    </CredentialsContainer>
  );
};

export default Credentials;
