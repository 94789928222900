import React, { useContext, useMemo } from 'react';
import { Container } from './style';
import AsidePortal from '../../../../../../../layouts/AsidePortal';
import AsideActionsContext from './context';
import SelectStep from './components/SelectStep';
import FilterStep from './components/FilterStep';
import SelectDateStep from './components/SelectDateStep';
import { InvoiceAsideStage } from '../../../../../../../@types/invoice';
import InvoiceContext, { InvoiceContextProps } from '../../context';
import SearchNewInvoiceStep from './components/SearchNewInvoiceStep';
import { CloseIcon } from '../../../../../../../icons';
import TextIconButton from '../../../../../../../components/TextIconButton';

const stepMapping = {
  [InvoiceAsideStage.SELECT_ACTION]: SelectStep,
  [InvoiceAsideStage.FILTER_ACTION]: FilterStep,
  [InvoiceAsideStage.SEARCH_NEW_INVOICE]: SearchNewInvoiceStep,
  [InvoiceAsideStage.SELECT_DATE_ACTION]: SelectDateStep,
} as const;

const AsideActions = () => {
  const {
    dataIsEmpty,
    asideActionsIsOpen,
    asideStage,
    setAsideStage,
    openAsideActions,
    closeAsideActions,
    search,
    clearSearch,
    range,
    clearRangeSelect,
  } = useContext(InvoiceContext) as InvoiceContextProps;

  const openHandler = () => {
    openAsideActions();
  };

  const closeHandler = () => {
    closeAsideActions();
    setAsideStage(InvoiceAsideStage.SELECT_ACTION);
  };

  const clearHandler = () => {
    clearSearch();
  };

  const changeStage = (newStage: InvoiceAsideStage) => {
    setAsideStage(newStage);
  };

  const context = useMemo(
    () => ({
      changeStage,
      closeHandler,
    }),
    []
  );

  const showButtons = !dataIsEmpty;

  const Step = stepMapping[asideStage];

  return (
    <AsideActionsContext.Provider value={context}>
      <AsidePortal visible={asideActionsIsOpen}>
        <Step />
      </AsidePortal>
      {showButtons && (
        <Container>
          <input readOnly placeholder="Buscar" onClick={openHandler} />
          {search && (
            <TextIconButton onClick={clearHandler} label="Busca ativada" icon={<CloseIcon width={12} height={12} />} />
          )}
          {range && (
            <TextIconButton
              onClick={clearRangeSelect}
              label="Configurar data ativado"
              icon={<CloseIcon width={12} height={12} />}
            />
          )}
        </Container>
      )}
    </AsideActionsContext.Provider>
  );
};

export default AsideActions;
