import { useCallback, useContext, useEffect, useState, useTransition } from 'react';
import FinancialDetailsContext, { FinancialDetailsContextProps } from '../../context';
import useLayout from '../../../../../hooks/useLayout';
import {
  FINANCIAL_HISTORIC_ALL_SEARCH_KEYS,
  FinancialHistoricData,
  FinancialHistoricOrder,
  FinancialHistoricOrderBy,
  FinancialHistoricSearchBy,
} from '../../../../../@types/bill';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import useSearch from '../../../../../hooks/useSearch';
import { compose } from '../../../../../utils/helpers/string';
import { formatDate, formatHour } from '../../../../../utils/helpers/date';
import {
  mapFinancialHistoricOperationLabel,
  mapFinancialHistoricOperationToDescription,
  mapHistoricUserTypeLabel,
} from '../../constants';
import getOrderDirection from '../../../../../utils/helpers/getOrderDirection';
import usePagination from '../../../../../hooks/usePagination';
import FinancialService from '../../../../../services/Financial/FinancialService';
import Alert from '../../../../../components/Toast/toast';
import { pipe } from '../../../../../utils/helpers/array';

const useHistoric = () => {
  const { id } = useContext(FinancialDetailsContext) as FinancialDetailsContextProps;
  const { layout, toggleLayout, showGrid } = useLayout();
  const [order, setOrder] = useState<FinancialHistoricOrder | null>(null);
  const [data, setData] = useState<FinancialHistoricData[]>([]);
  const [paginatedData, setPaginatedData] = useState<FinancialHistoricData[][]>([]);
  const { isMobileWidth } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();

  const { clearSearch, applySearch, search, searchKeys, handleSearchKeys, handleSearch } = useSearch({
    data,
    allSearchKeys: FINANCIAL_HISTORIC_ALL_SEARCH_KEYS,
    searchBy: (searchByKeys, searchFinancialHistoricData) => [
      ...searchByKeys.map((key) => {
        if (key === 'date') {
          return formatDate(new Date(searchFinancialHistoricData.createdAt));
        }

        if (key === 'hour') {
          return formatHour(new Date(searchFinancialHistoricData.createdAt));
        }

        if (key === 'type') {
          return mapFinancialHistoricOperationLabel[searchFinancialHistoricData.type];
        }

        if (key === 'userType') {
          return mapHistoricUserTypeLabel[searchFinancialHistoricData.userType] || 'Anônimo';
        }

        if (key === 'userName') {
          return String(searchFinancialHistoricData.userName) || 'Anônimo';
        }

        if (key === 'information') {
          return compose(
            mapFinancialHistoricOperationToDescription[searchFinancialHistoricData.type],
            '$',
            searchFinancialHistoricData.information
          );
        }

        return String(searchFinancialHistoricData[key]);
      }),
    ],
  });

  const actionsIsDisabled = loading || data.length < 1;

  const handleOrder = (newOrder: FinancialHistoricOrder | null) => {
    setOrder(newOrder);
  };

  const requestSearch = useCallback((newSearch: string, newSearchKeys: Array<FinancialHistoricSearchBy>) => {
    handleSearchKeys(newSearchKeys);
    handleSearch(newSearch);
  }, []);

  const applyOrder = (dataToTransform: FinancialHistoricData[]) => {
    const sortableItems = [...dataToTransform];
    if (!order) return sortableItems;

    const isAscendingOrder = order.direction === 'asc';

    return sortableItems.sort((a, b) => {
      if (order.orderBy === 'userName' || order.orderBy === 'userType') {
        const aElement = a[order.orderBy] ?? '';
        const bElement = b[order.orderBy] ?? '';

        if (aElement < bElement) {
          return isAscendingOrder ? -1 : 1;
        }
        if (aElement > bElement) {
          return isAscendingOrder ? 1 : -1;
        }

        return 0;
      }

      if (order.orderBy === 'hour' || order.orderBy === 'date') {
        const aElement = a.createdAt;
        const bElement = b.createdAt;

        if (aElement < bElement) {
          return isAscendingOrder ? -1 : 1;
        }
        if (aElement > bElement) {
          return isAscendingOrder ? 1 : -1;
        }

        return 0;
      }

      if (a[order.orderBy] < b[order.orderBy]) {
        return isAscendingOrder ? -1 : 1;
      }
      if (a[order.orderBy] > b[order.orderBy]) {
        return isAscendingOrder ? 1 : -1;
      }

      return 0;
    });
  };

  const requestSort = useCallback(
    (key: FinancialHistoricOrderBy, direction?: 'asc' | 'desc') =>
      setOrder((prevOrder) => ({
        orderBy: key,
        direction: direction || getOrderDirection(key, prevOrder?.orderBy!, prevOrder?.direction!),
      })),
    [order]
  );

  const { applyPagination, getPaginationProps, currentPageIndex } = usePagination<FinancialHistoricData>(data.length);

  const currentPage = paginatedData[currentPageIndex];

  useEffect(() => {
    const fetchHistoricData = async () => {
      setLoading(true);
      try {
        if (id) {
          const response = await FinancialService.getFinancialDetailsHistoric(id);
          setData(response.data);
        }
      } catch (e) {
        console.error(e);
        Alert.ERROR('Ocorreu um erro ao buscar histórico. Por favor, tente novamente em alguns instantes.');
      } finally {
        setLoading(false);
      }
    };

    fetchHistoricData();
  }, [id]);

  useEffect(() => {
    startTransition(() => {
      const filteredHistoricData = pipe(data, [applySearch, applyOrder]);
      const paginatedHistoricData = applyPagination(filteredHistoricData);
      setPaginatedData(paginatedHistoricData);
    });
  }, [data, order, search, searchKeys]);

  return {
    data,
    currentPage,
    paginatedData,
    actionsIsDisabled,
    clearSearch,
    layout,
    toggleLayout,
    showGrid,
    order,
    handleOrder,
    handleSearch,
    searchKeys,
    handleSearchKeys,
    requestSearch,
    search,
    loading,
    filterIsPending: isPending,
    requestSort,
    isMobileWidth,
    getPaginationProps,
  };
};

export default useHistoric;
