export enum InvoiceType {
  MATERIAL = 1,
  TASK = 2,
}

export interface InvoiceData {
  id: string;
  clientName: string;
  clientDocument: string;
  emittedAt: string;
  invoiceNumber: string;
  contract: string;
  type: InvoiceType;
  documentUrl: string;
}

export type InvoiceSearchBy = 'clientName' | 'clientDocument' | 'emittedAt' | 'invoiceNumber' | 'contract' | 'type';

export const INVOICE_ALL_SEARCH_KEYS: Array<InvoiceSearchBy> = [
  'clientName',
  'clientDocument',
  'contract',
  'emittedAt',
  'invoiceNumber',
  'type',
];

export type InvoiceOrderBy = 'clientName' | 'clientDocument' | 'emittedAt' | 'invoiceNumber' | 'contract' | 'type';

export interface InvoiceOrder {
  orderBy: InvoiceOrderBy;
  direction: 'asc' | 'desc';
}

export enum InvoiceAsideStage {
  SELECT_ACTION = 'SELECT_ACTION',
  FILTER_ACTION = 'FILTER_ACTION',
  SEARCH_NEW_INVOICE = 'SEARCH_NEW_INVOICE',
  SELECT_DATE_ACTION = 'SELECT_DATE_ACTION',
}
