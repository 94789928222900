import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Message } from './style';

// eslint-disable-next-line react/prop-types
const HookFeedbackError: typeof ErrorMessage = ({ errors, name }) => (
  <ErrorMessage errors={errors} name={name} render={({ message }) => message && <Message>{message}</Message>} />
);

export default HookFeedbackError;
