import { createContext } from 'react';
import { TabAccessor } from '../../../@types/dashboard';

export interface ClientContextProps {
  selectedTab: TabAccessor;
  handleTabChange: (accessor: TabAccessor) => void;
  scrollToRef: VoidFunction;
  updateFinancialData: VoidFunction;
}

const ClientContext = createContext<ClientContextProps | null>(null);

export default ClientContext;
