import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { addMonths, subMonths } from '../../../../utils/helpers/date';
import CalendarHeader from '../CalendarHeader';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import theme from '../../../../styles/theme';
import { BREAKPOINTS } from '../../../../utils/constants/breakpoints';

const StyledCalendar = styled.div`
  .react-calendar {
    border: none !important;
  }

  .react-calendar__viewContainer {
    justify-content: space-between;
  }

  .react-calendar--doubleView {
    width: unset;
    max-width: 100%;
  }

  .react-calendar__month-view {
    width: 252px !important;
  }

  abbr {
    font-size: 100%;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    font-family: 'TKEType-Medium', sans-serif;
    padding: 10px;
  }

  .react-calendar__tile {
    font-size: 14px !important;
    font-family: 'TKEType-Regular', sans-serif;
    font-style: normal;
    padding: 10px;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    pointer-events: none;
  }

  .react-calendar__tile:disabled {
    color: #9d9d9d;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000;
  }

  .react-calendar__tile--range {
    color: #000;
    background: #e9e9e9;
  }

  .react-calendar__tile--range:hover {
    background: #e9e9e9 !important;
    filter: brightness(105%);
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--rangeBothEnds {
    color: #fff !important;
    background: ${theme.color.primary.interaction} !important;
  }

  .react-calendar__tile--rangeStart:hover,
  .react-calendar__tile--rangeEnd:hover,
  .react-calendar__tile--rangeBothEnds:hover {
    background: ${theme.color.primary.interaction} !important;
    filter: brightness(105%);
  }

  .react-calendar__viewContainer {
    margin: 0 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: #fff !important;
    color: gray !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: #fff !important;
    color: gray !important;
    cursor: initial !important;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .react-calendar__navigation__label__divider {
      visibility: hidden;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: flex;
    justify-content: center;

    .react-calendar {
      width: 252px;
    }

    .react-calendar__viewContainer {
      width: 252px !important;
    }
  }
`;

interface CalendarPickerProps {
  range: DateRange | undefined;
  activeDate: Date;
  onChange: (newRange: DateRange) => void;
  onChangeMonth: (newActiveDate: Date) => void;
}

const CalendarPicker = ({ range, onChange, activeDate, onChangeMonth }: CalendarPickerProps) => {
  const [startRange, setStartRange] = useState<Date>();

  const maxDate = startRange ? addMonths(startRange, 4) : undefined;
  const minDate = startRange ? subMonths(startRange, 4) : undefined;

  const onPrevious = () => onChangeMonth(subMonths(activeDate, 1));
  const onNext = () => onChangeMonth(addMonths(activeDate, 1));

  const { isMobileWidth } = useWindowDimensions();

  return (
    <>
      <CalendarHeader activeDate={activeDate} onNext={onNext} onPrevious={onPrevious} showDoubleView={!isMobileWidth} />
      <StyledCalendar>
        <Calendar
          formatShortWeekday={(locale, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()]}
          onClickDay={(value) => setStartRange(value)}
          maxDate={maxDate}
          minDate={minDate}
          showNavigation={false}
          activeStartDate={activeDate}
          showNeighboringMonth={false}
          prev2Label={null}
          next2Label={null}
          showDoubleView={!isMobileWidth}
          selectRange
          onChange={(value) => {
            if (Array.isArray(value)) {
              onChange(value as [Date, Date]);
            }
          }}
          value={range}
          goToRangeStartOnSelect
          locale="pt"
        />
      </StyledCalendar>
    </>
  );
};

export default CalendarPicker;
