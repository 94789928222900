import styled, { css } from 'styled-components';
import theme from './theme';
import { BREAKPOINTS } from '../utils/constants/breakpoints';

interface WrapperDivProps {
  display?: 'flex' | 'block' | 'inline-block';
  gap?: number;
  padding?: number;
  mt?: number;
  mb?: number;
  justifyContent?: 'center' | 'space-between' | 'none' | 'flex-end' | 'flex-start';
  alignItems?: 'center' | 'right' | 'left' | 'initial' | 'flex-end' | 'flex-start';
  flexDirection?: 'column' | 'initial' | 'row';
  height?: string | number;
  fit?: boolean;
}

export const Divisor = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
  background-color: ${theme.color.secondary.grey[3]};
`;

export const WrapperDiv = styled.div<WrapperDivProps>`
  font: inherit;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  display: ${({ display }) => display || 'flex'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'initial'};
  gap: ${({ gap }) => gap || 0}px;
  padding: ${({ padding }) => padding || 0}px;
  margin-top: ${({ mt }) => mt || 0}px;
  margin-bottom: ${({ mb }) => mb || 0}px;
  height: ${({ height }) => {
    if (!height) {
      return 'initial';
    }

    return typeof height === 'string' ? height : `${height}px`;
  }};
  width: ${({ fit }) => (fit ? '100%' : 'initial')};
`;

type CursorType = 'auto' | 'default' | 'pointer' | 'wait' | 'text';

interface ButtonProps {
  cursor?: CursorType;
}

export const WrapperButton = styled.button.attrs({
  type: 'button',
})<ButtonProps>`
  height: fit-content;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;

  /* Resetting font styles */
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  /* Resetting background and text colors */
  background-color: transparent;
  color: inherit;

  cursor: ${({ cursor }) => cursor ?? 'initial'};
`;

interface TransparentBoxProps {
  width?: number;
  height?: number;
}

export const TransparentBox = styled.div<TransparentBoxProps>`
  width: ${({ width }) => `${width}px` || '100%'};
  height: ${({ height }) => `${height}px` || '100%'};
  background-color: transparent;
`;

export const boxStyle = css`
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 4px ${theme.color.secondary.grey[4]};
  padding: 16px 24px;

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    box-shadow: none;
  }
`;
