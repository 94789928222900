import React, { cloneElement, forwardRef, ReactNode, useImperativeHandle } from 'react';
import { Placement } from '@floating-ui/core/src/types';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import useCustomFloating from '../../hooks/useCustomFloating';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import theme from '../../styles/theme';
import { mergeClassNames } from '../../utils/helpers/css';

interface FloatingProps {
  children: ReactNode;
  button: JSX.Element;
  placement?: Placement;
  customClassname?: string;
  disabled?: boolean;
  usingSafePolygon?: boolean;
  usePortal?: boolean;
}

export interface FloatingParentRef {
  buttonElementIsOverflowing: () => boolean;
  width: number;
}

const Floating = forwardRef<FloatingParentRef, FloatingProps>(
  (
    {
      disabled,
      children,
      button,
      placement = 'bottom-start',
      customClassname,
      usingSafePolygon = true,
      usePortal = false,
    },
    parentRef
  ) => {
    const {
      open,
      data: { reference, refs, strategy, floating, x, y },
      getFloatingProps,
      getReferenceProps,
    } = useCustomFloating({ placement, usingSafePolygon });

    const isOpen = !disabled && open;

    useImperativeHandle(
      parentRef,
      () => ({
        width: (refs.reference.current as HTMLElement).clientWidth,
        buttonElementIsOverflowing() {
          const element = refs.reference.current as HTMLElement;
          return element.scrollWidth > element.clientWidth;
        },
      }),
      []
    );

    const floatingContent = (
      <div
        {...getFloatingProps({
          ref: floating,
          className: mergeClassNames('Floating', customClassname),
          style: {
            zIndex: theme.defaults.floatingZIndex,
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          },
        })}
      >
        {children}
      </div>
    );

    const { isMobileWidth } = useWindowDimensions();

    const renderPortal = isMobileWidth || usePortal;

    return (
      <>
        {cloneElement(
          button,
          getReferenceProps({
            ref: reference,
            open: isOpen,
            ...button?.props,
          })
        )}
        {isOpen &&
          (renderPortal ? <FloatingPortal id="floating_root">{floatingContent}</FloatingPortal> : floatingContent)}
      </>
    );
  }
);

export default Floating;
