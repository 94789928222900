import React, { useContext } from 'react';
import AsideActionsContext, { AsideActionsContextProps } from '../../context';
import { BuildingAsideStage } from '../../../../../../../../../@types/building';
import { BUILDING_FILTER_OPTIONS } from '../../../../constants';
import BuildingContext, { BuildingContextProps } from '../../../../context';
import MobileSearch from '../../../../../../../shared/components/MobileSearch';

const FilterStep = () => {
  const { requestSearch, search } = useContext(BuildingContext) as BuildingContextProps;
  const { closeHandler, changeStage } = useContext(AsideActionsContext) as AsideActionsContextProps;

  return (
    <MobileSearch
      title="Buscar contratos"
      defaultValue={search}
      filterOptions={BUILDING_FILTER_OPTIONS}
      onSubmit={requestSearch}
      onBack={() => changeStage(BuildingAsideStage.SELECT_ACTION)}
      onClose={closeHandler}
    />
  );
};

export default FilterStep;
