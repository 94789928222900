import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPartner = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.4}
      d="M15.484 2.532a6.3 6.3 0 0 0-6.29 6.094h-1.41V9.964H10.132a5.36 5.36 0 0 0 5.352 5.156 5.36 5.36 0 0 0 5.353-5.156h2.347V8.626h-1.409a6.3 6.3 0 0 0-6.29-6.094Zm2.075 2.544V4.33a4.958 4.958 0 0 1 2.878 4.297h-9.905A4.968 4.968 0 0 1 13.41 4.33V5.276H14.747V3.928a5.08 5.08 0 0 1 .069-.01v1.827h1.337V3.918l.069.01V5.276h1.337v-.2Zm-2.075 8.706a4.026 4.026 0 0 1-4.014-3.818h8.028a4.026 4.026 0 0 1-4.014 3.818ZM5.047 27.845H27.403v1.337H3.566v-1.327l.019-.04 4.237-8.977-2.775 9.007Zm0 0 3.976-8.438c.418-.888 1.392-1.475 2.496-1.475h7.922c1.104 0 2.077.587 2.495 1.475l1.027 2.161.054.114h1.478l-.134-.285-1.205-2.56c-.645-1.37-2.103-2.242-3.706-2.242h-7.922c-1.603 0-3.06.873-3.706 2.243l-2.775 9.007Z"
    />
  </svg>
);
export default SvgPartner;
