export interface SideBarItem {
  key: number | string;
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: Children;
}

export const PROFILE_CLIENT_ITEMS: Array<SideBarItem> = [
  {
    key: 'ProfileData1',
    title: 'Editar perfil',
    path: '/account',
  },
];

export const SIDEBAR_PARTNER_ITEMS: Array<SideBarItem> = [
  {
    key: 'SidebarData1',
    title: 'Buscar contrato',
    path: '/partner',
  },
  {
    key: 'SidebarData2',
    title: 'Aplicações TKE',
    path: '/apps',
  },
];

export const SIDEBAR_CLIENT_ITEMS: Array<SideBarItem> = [
  {
    key: 'SidebarData1',
    title: 'Meu painel',
    path: '/home',
  },
  {
    key: 'SidebarData2',
    title: 'Aplicações TKE',
    path: '/apps',
  },
];
