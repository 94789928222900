import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} viewBox="0 0 22 24" fill="none" {...props}>
    <path
      fill="#000"
      stroke="#000"
      strokeWidth={0.3}
      d="M20.494 8.783v-.15H1.51v1.05H20.493v-.9ZM20.494 12.533v-.15H1.51v1.05H20.493v-.9ZM20.494 16.283v-.15H1.51v1.05H20.493v-.9Z"
    />
  </svg>
);
export default SvgHamburger;
