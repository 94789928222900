import React, { FunctionComponent, useContext } from 'react';
import { BuildingData } from '../../../../../../../../@types/building';
import useTableView from '../../../../../../../../components/TableView/hooks/useTableView';
import { Links } from '../../../../../../Client/containers/Building/style';
import HeaderOrderWrapper from '../../../../../../../../components/TableView/components/HeaderOrderWrapper';
import CustomText from '../../../../../../../../components/CustomText';
import FixedTextCell from '../../../../../../../../components/FixedTextCell';
import { maskDocument } from '../../../../../../../../utils/helpers/mask';
import IntegrationLinks from '../../../../../../shared/components/IntegrationLinks';
import TableView from '../../../../../../../../components/TableView';
import PartnerContext, { PartnerContextProps } from '../../../../../context';
import BuildingPolicy from '../../../../../../../../strategy/rules/BuildingPolicy';
import BuildingTooltips from '../../../../../../shared/components/BuildingTooltips';
import { NameAndTooltipButtons } from '../../../Mobile/style';

interface ListProps {
  data: BuildingData[];
}

const PartnerBuildingTable: FunctionComponent<ListProps> = ({ data }) => {
  const { requestSort, order } = useContext(PartnerContext) as PartnerContextProps;

  const { getTableProps } = useTableView<BuildingData>(data, [
    {
      key: 'number',
      width: '130px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="id">
          <CustomText>Nº Edifício</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'id',
    },
    {
      key: 'name',
      width: '3fr',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="clientName">
          <CustomText>Cliente</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item }) => (
        <NameAndTooltipButtons>
          <FixedTextCell>{item.clientName}</FixedTextCell>
          <BuildingTooltips businessContracts={item.contracts} />
        </NameAndTooltipButtons>
      ),
    },
    {
      key: 'document',
      width: '190px',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="document">
          <CustomText>CNPJ ou CPF</CustomText>
        </HeaderOrderWrapper>
      ),
      accessor: 'document',
      render: ({ item: building }) => <CustomText>{maskDocument(building.document)}</CustomText>,
    },
    {
      key: 'location',
      width: '1fr',
      header: (
        <HeaderOrderWrapper requestSort={requestSort} currentOrder={order} orderBy="city">
          <CustomText>Localização</CustomText>
        </HeaderOrderWrapper>
      ),
      render: ({ item: building }) => <FixedTextCell>{`${building.city} - ${building.uf}`}</FixedTextCell>,
    },
    {
      key: 'integration',
      width: '1fr',
      header: <CustomText>Módulos</CustomText>,
      render: ({ item: building }) => (
        <Links>
          <IntegrationLinks
            buildingId={building.id}
            contractId={building.contracts[0].id}
            disableBuildings={!BuildingPolicy.partnerCanAccessNewBuildings(building)}
            showFinancialLink
          />
        </Links>
      ),
    },
  ]);

  return <TableView {...getTableProps()} keyExtractor={(building) => building.id} />;
};
export default PartnerBuildingTable;
