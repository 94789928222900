import { ReactNode } from 'react';
import { Column } from '../types';
import getGridTemplateColumns from '../helpers/getGridTemplateColumns';

const useTableView = <T extends object>(
  data: T[],
  columns: Array<Column<T>>,
  config?: { withSelection: boolean; customCheckbox?: (item: T) => ReactNode }
) => {
  const gridTemplateColumns = getGridTemplateColumns(columns, config?.withSelection);

  return {
    getTableProps: () => ({
      data,
      columns,
      customCheckbox: config?.customCheckbox,
      style: {
        display: 'grid',
        gridTemplateColumns,
      },
    }),
    data,
    columns,
  };
};

export default useTableView;
