import styled from 'styled-components';
import { StepComponentContainer } from '../../style';

export const Container = styled(StepComponentContainer)`
  margin-top: 0;
  padding: 24px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  flex: 1;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .disclaimer {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #262626;
  }
`;

export const MobileEmptyResult = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9d9d9d;
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    font-style: italic;
    font-size: 1.4rem;
    color: #262626;
  }
`;

export const CalloutContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    width: 100%;
  }
`;
