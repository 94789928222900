import React, { memo, MouseEvent, useState } from 'react';
import { getProp } from '../../utils/helpers/object';
import CustomText from '../CustomText';
import { TableDataProps, TableRowCellProps, TableRowProps, TableViewProps } from './types';
import { HoverDiv, ListData, ListHead, Table } from './styles';
import Checkbox from '../SelectableButton/Checkbox';

function TableRowCell<T extends object>({ item, column, ...props }: TableRowCellProps<T>) {
  const value = column.accessor ? getProp(item, column.accessor) : null;
  return (
    <ListData {...props}>
      {column.render ? (
        column.render({ column, item, control: { isHover: props.isHover } })
      ) : (
        <CustomText>{value}</CustomText>
      )}
    </ListData>
  );
}

function TableRow<T extends object>({
  item,
  columns,
  withSelection,
  selectedDataHasElement,
  handleSelect,
  disableSelectionPerRowWhen,
  keyExtractor,
  customCheckbox,
}: TableRowProps<T>) {
  const [isHover, setHover] = useState(false);
  const hasSelection = withSelection && selectedDataHasElement && handleSelect;

  return (
    <HoverDiv
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseOut={(event) => {
        const isMouseOverChildren =
          event.relatedTarget &&
          event.currentTarget.contains((event as unknown as MouseEvent).relatedTarget as HTMLDivElement);

        if (!isMouseOverChildren) {
          setHover(false);
        }
      }}
    >
      {hasSelection && (
        <ListData>
          {customCheckbox ? (
            customCheckbox(item, {
              onChange: () => handleSelect(item),
              checked: selectedDataHasElement(item),
              disabled: disableSelectionPerRowWhen?.(item),
            })
          ) : (
            <Checkbox
              onChange={() => handleSelect(item)}
              checked={selectedDataHasElement(item)}
              disabled={disableSelectionPerRowWhen?.(item)}
            />
          )}
        </ListData>
      )}
      {columns.map((column) => (
        <TableRowCell
          isHover={isHover}
          key={`table-row-cell-${keyExtractor(item)}-${column.accessor ?? column.key}`}
          item={item}
          column={column}
        />
      ))}
    </HoverDiv>
  );
}

function TableData<T extends object>({
  data,
  columns,
  keyExtractor,
  withSelection,
  handleSelect,
  selectedDataHasElement,
  disableSelectionPerRowWhen,
  customCheckbox,
}: TableDataProps<T>) {
  return (
    <>
      {data.map((item) => (
        <TableRow
          key={`table-row-${keyExtractor(item)}`}
          item={item}
          columns={columns}
          withSelection={withSelection}
          handleSelect={handleSelect}
          selectedDataHasElement={selectedDataHasElement}
          disableSelectionPerRowWhen={disableSelectionPerRowWhen}
          keyExtractor={keyExtractor}
          customCheckbox={customCheckbox}
        />
      ))}
    </>
  );
}

const TableView = <T extends object>({
  data,
  columns,
  keyExtractor,
  style,
  withSelection,
  handleSelect,
  handleSelectAll,
  selectedDataHasElement,
  allDataAreSelected,
  disableSelectionPerRowWhen,
  customCheckbox,
}: TableViewProps<T>) => (
  <Table style={style}>
    {withSelection && (
      <ListHead>
        <Checkbox onChange={() => handleSelectAll()} checked={allDataAreSelected} />
      </ListHead>
    )}
    {columns.map(({ accessor, key, header }, columnIndex) => (
      <ListHead key={`table-list-head-cell-${columnIndex}-${accessor ?? key}`}>{header}</ListHead>
    ))}
    {data && (
      <TableData
        disableSelectionPerRowWhen={disableSelectionPerRowWhen}
        withSelection={Boolean(withSelection)}
        handleSelect={handleSelect}
        selectedDataHasElement={selectedDataHasElement}
        keyExtractor={keyExtractor}
        data={data}
        columns={columns}
        customCheckbox={customCheckbox}
      />
    )}
  </Table>
);

export default memo(TableView) as typeof TableView;
