const getEnvironment = () => {
  const isDevMode = process.env && process.env.NODE_ENV === 'development';

  let BASE_URL = process.env.REACT_APP_API;
  let CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  let CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  let AAD_TENANT_ID = process.env.REACT_APP_AAD_TENANT_ID;
  let AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID;
  let AAD_REDIRECT_URI = process.env.REACT_APP_AAD_REDIRECT_URI;
  let BUILDING_PORTAL_AUTH_URI = process.env.REACT_APP_BUILDING_PORTAL_AUTH_URI;
  let FINANCIAL_PORTAL_AUTH_URI = process.env.REACT_APP_FINANCIAL_PORTAL_AUTH_URI;

  if (!isDevMode) {
    BASE_URL = window.env.REACT_APP_API;
    CLIENT_ID = window.env.REACT_APP_CLIENT_ID;
    CAPTCHA_KEY = window.env.REACT_APP_CAPTCHA_SITE_KEY;
    AAD_TENANT_ID = window.env.REACT_APP_AAD_TENANT_ID;
    AAD_CLIENT_ID = window.env.REACT_APP_AAD_CLIENT_ID;
    AAD_REDIRECT_URI = window.env.REACT_APP_AAD_REDIRECT_URI;
    BUILDING_PORTAL_AUTH_URI = window.env.REACT_APP_BUILDING_PORTAL_AUTH_URI;
    FINANCIAL_PORTAL_AUTH_URI = window.env.REACT_APP_FINANCIAL_PORTAL_AUTH_URI;
  }

  return {
    CLIENT_ID,
    BASE_URL,
    CAPTCHA_KEY,
    AAD_TENANT_ID,
    AAD_CLIENT_ID,
    AAD_REDIRECT_URI,
    BUILDING_PORTAL_AUTH_URI,
    FINANCIAL_PORTAL_AUTH_URI,
  };
};

export default getEnvironment;
