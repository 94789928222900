import React, { useMemo } from 'react';
import { SignalRContext } from './context';
import useSignalR from './hooks/useSignalR';

const SignalRProvider = ({ children }: ContextProvider) => {
  const {
    connections,
    _mountRegisterListener,
    _mountRemoveListener,
    _mountSingleSend,
    getErrors,
    connectionsRetryMap,
  } = useSignalR();

  const context = useMemo(
    () => ({
      _mountRemoveListener,
      _mountRegisterListener,
      _mountSingleSend,
      _connections: connections,
      getErrors,
    }),
    [_mountRegisterListener, connections, connectionsRetryMap.current]
  );

  return <SignalRContext.Provider value={context}>{children}</SignalRContext.Provider>;
};

export default SignalRProvider;
