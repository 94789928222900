import React, { useContext } from 'react';
import ModalPortal from '../../../../../layouts/ModalPortal';
import { ActionsType } from '../../../type';
import PasswordAction from '../components/PasswordAction';
import { Container } from './style';
import EmailAction from '../components/EmailAction';
import PhoneAction from '../components/PhoneAction';
import AccountContext, { AccountContextProps } from '../../../context';

const DesktopActions = () => {
  const { type, open, closeAction } = useContext(AccountContext) as AccountContextProps;

  return (
    <ModalPortal visible={open} onClose={closeAction}>
      <Container>
        {type === ActionsType.PASSWORD && <PasswordAction />}
        {type === ActionsType.EMAIL && <EmailAction />}
        {type === ActionsType.PHONE && <PhoneAction />}
      </Container>
    </ModalPortal>
  );
};

export default DesktopActions;
